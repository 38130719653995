import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import DateRangeSelector from 'design-system/date/DateRangeSelector';
import { isWeb } from '../../../../constants';
const SelectDateRangeModalContent = ({ data, onFinish, subtitle = 'Select the period you are expecting this recurring payment to happen', }) => {
    const [range, setRange] = useState(data);
    const save = useCallback(() => {
        const { start, end } = range;
        if (start && end) {
            onFinish({ start, end });
        }
    }, [onFinish, range]);
    return (<View>
      {!isWeb ? (<Text Text-24 style={styles.title}>
          Select a range
        </Text>) : (<Spacer h={16}/>)}
      {subtitle.length ? (<Text TextThin-14 Gray style={styles.subtitle}>
          {subtitle}
        </Text>) : (<Spacer.H16 />)}
      <DateRangeSelector type="range" allowReverseDates start={range?.start} end={range?.end} onChange={setRange} style={styles.bottomMargin} showReset/>
      <Button brand title="Save" onPress={save} style={styles.bottomMargin}/>
    </View>);
};
const styles = StyleSheet.create({
    title: {
        marginTop: rem(40),
        marginBottom: rem(16),
    },
    subtitle: {
        marginBottom: rem(24),
    },
    bottomMargin: {
        marginBottom: rem(16),
    },
});
export default SelectDateRangeModalContent;
