import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import ToolTip from 'design-system/ToolTip';
const AnalyticsGraphLabel = ({ title, color, showFraction = false, value, toolTipTitle, toolTipSubTitle, blurValue, }) => (<View style={styles.container}>
    <View style={[styles.indicator, { backgroundColor: color }]}/>
    <View style={styles.textContainer}>
      <View style={styles.row}>
        <Text TextThin-14 Gray>
          {title}
        </Text>
        {!!toolTipTitle && !!toolTipSubTitle && (<>
            <Spacer.W8 />
            <ToolTip colorKey="secondary" title={toolTipTitle} subtitle={toolTipSubTitle} small/>
          </>)}
      </View>
      <Amount Numbers-16 scramblable={blurValue} showFraction={showFraction} value={{ value: value.amount, currency: value.currency }}/>
    </View>
  </View>);
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: rem(8),
        flexShrink: 1,
    },
    indicator: {
        width: rem(8),
        height: rem(8),
        borderRadius: rem(4),
    },
    textContainer: {
        marginStart: rem(16),
    },
    row: {
        alignItems: 'center',
        flexDirection: 'row',
    },
});
export default AnalyticsGraphLabel;
