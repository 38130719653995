import React, { memo, useEffect } from 'react';
import { Platform, View } from 'react-native';
import Color from 'color';
import Animated, { Easing, interpolate, useAnimatedStyle, useSharedValue, withRepeat, withTiming, } from 'react-native-reanimated';
import { rem } from 'design-system';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
const Status = ({ active, activeText, inactiveText, inActiveColor, style, }) => {
    const styles = useStyles(styleSet);
    const outerScale = useSharedValue(1);
    useEffect(() => {
        if (active && Platform.OS !== 'android' && !__DEV__) {
            const timeout = setTimeout(() => {
                outerScale.value = withRepeat(withTiming(0, { duration: 1000, easing: Easing.elastic(1) }), -1, true);
            }, 400);
            return () => {
                clearTimeout(timeout);
            };
        }
        return () => { };
    }, [active, outerScale]);
    const outerStyle = useAnimatedStyle(() => {
        const size = interpolate(outerScale.value, [1, 0], [OUTER_WIDTH, INNER_WIDTH]);
        return {
            width: size,
            height: size,
        };
    }, []);
    const colors = useColors();
    return (<View style={[styles.outerContainer, style]}>
      <View style={styles.container}>
        <Animated.View style={[
            styles.outer,
            // eslint-disable-next-line no-nested-ternary
            active
                ? styles.activeOuter
                : inActiveColor
                    ? {
                        backgroundColor: Color(inActiveColor).fade(0.95).toString(),
                    }
                    : styles.inactiveOuter,
            outerStyle,
        ]}/>
        <View style={[
            styles.inner,
            // eslint-disable-next-line no-nested-ternary
            active
                ? styles.activeInner
                : inActiveColor
                    ? {
                        backgroundColor: inActiveColor,
                    }
                    : styles.inactiveInner,
        ]}/>
      </View>
      <Text Text-14 color={
        // eslint-disable-next-line no-nested-ternary
        active
            ? colors.status.positive
            : inActiveColor || colors.status.negative}>
        {active ? activeText : inactiveText}
      </Text>
    </View>);
};
const OUTER_WIDTH = rem(14);
const INNER_WIDTH = rem(6);
const INNER_PADDING = rem(4);
const styleSet = createStyleSheets((colors) => ({
    outerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    container: {
        width: OUTER_WIDTH,
        height: OUTER_WIDTH,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: rem(8),
    },
    outer: {
        width: INNER_WIDTH,
        height: INNER_WIDTH,
        borderRadius: OUTER_WIDTH,
    },
    inner: {
        width: INNER_WIDTH,
        height: INNER_WIDTH,
        borderRadius: INNER_WIDTH,
        position: 'absolute',
        top: INNER_PADDING,
        left: INNER_PADDING,
    },
    activeInner: {
        backgroundColor: colors.status.positive,
    },
    inactiveInner: {
        backgroundColor: colors.status.negative,
    },
    activeOuter: {
        backgroundColor: colors.status.positiveLight,
    },
    inactiveOuter: {
        backgroundColor: colors.status.negativeLight,
    },
}));
export default memo(Status);
