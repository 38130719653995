import React, { memo, useCallback, useMemo, useState } from 'react';
import { Platform, View, } from 'react-native';
import Animated from 'react-native-reanimated';
import { Button, HEIGHT_PLUS_PADDING } from 'design-system/Button';
import { SettingsItem, SettingsItemRightContent, SettingsItemSwitch, } from 'design-system/SettingsItem';
import { Indicator } from 'design-system/Indicator';
import { CardView } from 'design-system/CardView';
import { HelpCallToAction } from 'design-system/HelpCallToAction';
import { SCROLL_INDICATOR_INSETS, rem } from 'design-system/values';
import { Spacer } from 'design-system/Spacer';
import ListEmptyView from 'design-system/ListEmptyView';
import { IconArrow, IconBell } from 'design-system/icons';
import Text from 'design-system/Text';
import IconBlock from 'design-system/icons/IconBlock';
import useStyles from 'hooks/useStyles';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { formatPlural } from 'utils/formatting';
import TouchableBounceCard from 'utils/packages/TouchableBounceCard';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useColors from 'hooks/useColors';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { selectInactiveSubscriptionsLength } from '../selectors';
import RowSubscription from './row/RowSubscription';
import SwitchingButton from './row/SwitchingButton';
import { isWeb } from '../../../constants';
import UpgradeToSeeMoreCard from './UpgradeToSeeMoreCard';
import { SUBSCRIPTIONS_ON_FREE_TIER } from '../constants';
import { addTransactionToSubscriptionById } from '../actions';
const SCROLL_EVENT_THROTTLE = 10;
const keyExtractor = (item) => item.id.toString();
const strings = {
    title: 'Select a recurring payment',
    description: 'The transaction will be marked as a payment for a recurring payment you choose',
};
const SubscriptionsSection = ({ data, openSearch, openInfo, onOpenCompare, onOpenSubscription, onScroll, fetching, billRemindersOn, setBillRemindersOn, transactionId, }) => {
    const colors = useColors();
    const dispatch = useAppDispatch();
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const styles = useStyles(styleSet);
    const navigation = useMainStackNavigation();
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', HEIGHT_PLUS_PADDING, paddingHorizontalStyle, styles.contentContainer);
    const inactiveSubscriptionsLength = useAppSelector(selectInactiveSubscriptionsLength);
    const onInactiveSubsPress = useCallback(() => {
        navigation.navigate('InactivePayments');
    }, []);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'recurringPayments',
    });
    const [selectedRecurringPaymentId, setSelectedRecurringPaymentId] = useState(undefined);
    const [isPosting, SetisPosting] = useState(false);
    const inactiveSubscription = useMemo(() => {
        if (!inactiveSubscriptionsLength)
            return null;
        return (<>
        <Spacer h={16}/>
        <TouchableBounceCard onDark noMarginTop noMarginBottom onPress={onInactiveSubsPress} style={styles.inactivePaymentsCard}>
          <SettingsItem icon={<IconBlock color={colors.status.offline}/>} title={`${inactiveSubscriptionsLength} inactive ${formatPlural('payment', 'payments', inactiveSubscriptionsLength)}`} disabled>
            <SettingsItemRightContent>
              <View style={styles.row}>
                <IconArrow />
                <Spacer w={8}/>
              </View>
            </SettingsItemRightContent>
          </SettingsItem>
        </TouchableBounceCard>
      </>);
    }, [
        colors.status.offline,
        inactiveSubscriptionsLength,
        onInactiveSubsPress,
        styles.inactivePaymentsCard,
        styles.row,
    ]);
    const renderHeader = useCallback(() => (<View style={styles.headerContainer}>
        <Text Text-24 style={styles.title}>
          {strings.title}
        </Text>
        <Text TextThin-14 Gray>
          {strings.description}
        </Text>
      </View>), [styles.headerContainer, styles.title]);
    const renderEmpty = useMemo(() => (<CardView noMarginBottom noMarginTop>
        <Spacer h={24}/>
        <ListEmptyView compact title="Nothing to show" subTitle="You don’t have any upcoming transactions">
          <Button hug small brandReversed title="Add new" onPress={openSearch}/>
        </ListEmptyView>
        <Spacer h={24}/>
      </CardView>), [openSearch]);
    const onSelectRecurringPayment = useCallback(async () => {
        if (!selectedRecurringPaymentId || !transactionId) {
            return;
        }
        SetisPosting(true);
        await dispatch(addTransactionToSubscriptionById(selectedRecurringPaymentId, transactionId));
        SetisPosting(false);
        navigation.navigate('Success', {
            title: 'Success',
            subTitle: 'The payment has been added to the recurring payment',
            secondaryButtonText: 'See the recurring payment',
            onSecondaryButtonPress: () => {
                navigation.pop(2);
                navigation.navigate('Subscription', {
                    id: selectedRecurringPaymentId,
                });
                return true;
            },
            buttonText: 'Go back to the transaction',
            onContinue: () => {
                navigation.pop(2);
                return true;
            },
        });
    }, [selectedRecurringPaymentId, transactionId]);
    const renderFooter = useMemo(() => data.length ? (<View>
          {inactiveSubscription}
          {!transactionId && !!setBillRemindersOn && (<CardView noMarginBottom webPadding>
              <SettingsItemSwitch icon={<IconBell gradientKey="ultimate"/>} title="Bill reminders" value={billRemindersOn} onValueChange={setBillRemindersOn} description={'Receive reminders about \nupcoming payments'}/>
              <Spacer h={rem(8)}/>
            </CardView>)}

          {!transactionId && (<>
              <Spacer h={16}/>
              <HelpCallToAction onDark title="Need to amend your recurring payments?" subTitle="Learn how to do it" onPress={openInfo}/>
            </>)}
        </View>) : (<>
          {inactiveSubscription}
          <Spacer h={16}/>
          <HelpCallToAction onDark title="Need to amend your recurring payments?" subTitle="Learn how to do it" onPress={openInfo}/>
        </>), [
        data.length,
        inactiveSubscription,
        setBillRemindersOn,
        billRemindersOn,
        transactionId,
        openInfo,
    ]);
    const showUpgradeCard = !isUnlockedForSpace && data.length > SUBSCRIPTIONS_ON_FREE_TIER;
    const renderItem = useCallback(({ item, index }) => {
        const card = SUBSCRIPTIONS_ON_FREE_TIER === index && showUpgradeCard;
        return (<>
          {card && <UpgradeToSeeMoreCard onPressUpgrade={onPressUpgrade}/>}
          <View style={[
                styles.rowSubscription,
                (index === 0 || card) && styles.itemContainerFirst,
                (index === data.length - 1 ||
                    (index === SUBSCRIPTIONS_ON_FREE_TIER - 1 &&
                        showUpgradeCard)) &&
                    styles.itemContainerLast,
            ]}>
            <RowSubscription data={item} onPress={transactionId
                ? () => setSelectedRecurringPaymentId(item.id)
                : onOpenSubscription} switchingButton={<SwitchingButton data={item} onOpenCompare={onOpenCompare} guessedHomeCountry={guessedHomeCountry}/>} blurValues={!isUnlockedForSpace && index >= SUBSCRIPTIONS_ON_FREE_TIER} selectable={!!transactionId} selected={selectedRecurringPaymentId === item.id}/>
          </View>
        </>);
    }, [
        showUpgradeCard,
        onPressUpgrade,
        styles.rowSubscription,
        styles.itemContainerFirst,
        styles.itemContainerLast,
        data.length,
        transactionId,
        onOpenSubscription,
        onOpenCompare,
        guessedHomeCountry,
        isUnlockedForSpace,
        selectedRecurringPaymentId,
    ]);
    if (fetching && !data.length) {
        return <Indicator dark/>;
    }
    return (<>
      <Animated.FlatList onScroll={onScroll} renderItem={renderItem} data={data} keyExtractor={keyExtractor} ListEmptyComponent={renderEmpty} ListHeaderComponent={transactionId ? renderHeader : undefined} ListFooterComponent={renderFooter} scrollEventThrottle={SCROLL_EVENT_THROTTLE} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS} contentContainerStyle={paddingBottom}/>
      {transactionId && selectedRecurringPaymentId && (<Button title="Continue" floating brand onPress={onSelectRecurringPayment} loading={isPosting}/>)}
    </>);
};
const styleSet = createStyleSheets((colors) => ({
    rowSubscription: {
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    inactivePaymentsCard: {
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    itemContainerFirst: {
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        ...(Platform.OS !== 'android' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingTop: rem(8),
        }),
    },
    itemContainerLast: {
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
        ...(Platform.OS !== 'android' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingBottom: rem(8),
        }),
    },
    row: {
        flexDirection: 'row',
    },
    addNewBtn: {
        paddingHorizontal: rem(22),
    },
    contentContainer: {
        paddingTop: rem(16),
    },
    headerContainer: {
        paddingHorizontal: rem(16),
        paddingBottom: rem(16),
    },
    title: {
        marginBottom: rem(9),
    },
}));
export default memo(SubscriptionsSection);
