import React, { memo, useCallback } from 'react';
import { View, StyleSheet, LayoutAnimation } from 'react-native';
import { Spacer, rem } from 'design-system';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import IconCheck from 'design-system/icons/IconCheck';
import Squircle from 'design-system/Squircle';
import { IconBoldSubtract } from 'design-system/icons/IconBoldSubtract';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { formatDateForItem } from 'utils/formatting';
import useColors from 'hooks/useColors';
import EditTransactionSelect from 'features/transactions/components/multiple/EditTransactionSelect';
import { TransactionBankLogo } from 'features/transactions/components/TransactionBankLogo';
import TransactionLogo from 'features/transactions/components/TransactionLogo';
import { selectFeatureFlag } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import PriceChange from '../row/PriceChange';
import SubscriptionLogo from '../SubscriptionLogo';
import { isWeb } from '../../../../constants';
const RowSubscriptionTransaction = ({ data, onPress, canEdit, isEditing, canSelect, selected, isTracking, addTxnToDelete, isTransaction, }) => {
    const bankLogoTransaction = useAppSelector((store) => selectFeatureFlag(store, 'bank_logo_transaction')).value;
    const colors = useColors();
    const name = data.customName ||
        data.counterpartName ||
        data.merchant?.displayName ||
        data.merchantInfo?.displayName;
    const iconUrl = data.merchant?.iconUrl || data.merchantInfo?.iconUrl;
    const onRowPressed = useCallback(() => {
        if (isEditing && addTxnToDelete) {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            addTxnToDelete(data.id);
        }
        else if (onPress) {
            onPress(data);
        }
    }, [addTxnToDelete, data, isEditing, onPress]);
    const { amount, currency, nativeAmount } = data;
    const nativePrice = 'nativePrice' in data ? data.nativePrice : null;
    const priceChange = 'subscriptionPriceChange' in data ? data.subscriptionPriceChange : null;
    let amountToDisplay = amount;
    let currencyToDisplay = currency;
    if (nativePrice) {
        amountToDisplay = -nativePrice.amount;
        currencyToDisplay = nativePrice.currency;
    }
    if (nativeAmount) {
        amountToDisplay = nativeAmount.amount;
        currencyToDisplay = nativeAmount.currency;
    }
    const dateValue = data.customDate ||
        data.bookingDate ||
        ('predictions' in data ? data.predictions[0]?.date : undefined);
    const bookingDate = dateValue ? formatDateForItem(dateValue) : '';
    return (<TouchableHighlight onCard hoverOnLight activeOpacity={1} onPress={onRowPressed} disabled={!onPress && !(isEditing && addTxnToDelete)} underlayColor={colors.background.underlay}>
      <View style={styles.rowContainer}>
        {canEdit && isEditing && (<>
            <IconBoldSubtract />
            <Spacer w={16}/>
          </>)}
        {canSelect && (<EditTransactionSelect selected={selected} isMultipleSelect={false}/>)}
        <View>
          {isTransaction ? (<TransactionLogo R44 transaction={data} backgroundDark={false}/>) : (<SubscriptionLogo name={name} url={iconUrl}/>)}
          {bankLogoTransaction && (<TransactionBankLogo id={data.accountId} isSmall/>)}
        </View>
        <Spacer w={16}/>
        <View style={styles.leftContainer}>
          <Text Text-16 numberOfLines={1} ellipsizeMode="tail">
            {name}
          </Text>
          {priceChange && priceChange !== 0 ? (<PriceChange currency={currencyToDisplay} priceChange={priceChange}/>) : null}
          {Boolean(isTracking) && (<View style={styles.trackingContainer}>
              <Squircle width={rem(16)} height={rem(16)} color={colors.elements.positive}>
                <Spacer h={2}/>
                <IconCheck thick width={12} color={colors.elements.onLight}/>
              </Squircle>
              <Spacer w={8}/>
              <Text TextThin-14 Positive numberOfLines={1}>
                Tracking
              </Text>
            </View>)}
        </View>
        <View style={styles.rightContainer}>
          <Amount Numbers-16 value={{ value: amountToDisplay, currency: currencyToDisplay }}/>
          <Text TextThin-14 Secondary numberOfLines={1} ellipsizeMode="tail">
            {bookingDate}
          </Text>
        </View>
      </View>
    </TouchableHighlight>);
};
export const ROW_SUB_TRANSACTION_HEIGHT = rem(77);
const styles = StyleSheet.create({
    rowContainer: {
        padding: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    leftContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    rightContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    trackingContainer: {
        alignItems: 'center',
        flexDirection: 'row',
    },
});
export default memo(RowSubscriptionTransaction);
