import React, { memo } from 'react';
import { View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import { SquareBadge } from 'design-system/Badge';
import IconPlus from 'design-system/icons/IconPlus';
import IconCheck from 'design-system/icons/IconCheck';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import { selectFeatureFlag } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import useIsTablet from 'hooks/useIsTablet';
import { isDesktopOrWeb, isWeb } from '../../../../constants';
const EditCategoryRow = ({ item, isDefaultCategory, selected, onPress, onLongPress, }) => {
    const newPickScreen = useAppSelector((store) => selectFeatureFlag(store, 'new_pick_screen')).value;
    const press = () => {
        onPress(item, isDefaultCategory);
    };
    const longPress = () => {
        onLongPress(item, isDefaultCategory);
    };
    const renderImage = () => {
        if (item && (isDefaultCategory || 'emoji' in item)) {
            return (<IconTransactionCategory width={rem(32)} height={rem(32)} category={item}/>);
        }
        const title = item && item.displayName
            ? item.displayName.substring(0, 2).toUpperCase()
            : '';
        return (<View style={[styles.image]}>
        <Text Text-24 OffBlack>
          {title}
        </Text>
      </View>);
    };
    const { width } = useAppFrameDimensions(isWeb);
    const colors = useColors();
    const styles = useStyles(styleSet);
    const isTablet = useIsTablet();
    return (<TouchableOpacity onCard hoverOnDark onPress={press} style={[
            styles.category,
            newPickScreen
                ? { width: isDesktopOrWeb || isTablet ? width / 4 : rem(76) }
                : { width: width / 3, paddingVertical: rem(24) },
        ]} {...(!isWeb && {
        onLongPress: longPress,
    })}>
      <View>
        <View style={styles.categoryContent}>
          <View style={styles.imageBadgeWrapper}>
            {item.id === 'custom1' ? (<LinearGradient {...colors.gradients.ultimate} {...(newPickScreen && {
            colors: [
                colors.cards.onDark,
                colors.cards.onDark,
            ],
        })} style={styles.imageContainer}>
                <IconPlus width={rem(20)} height={rem(20)} {...(newPickScreen && { color: colors.elements.brand })}/>
              </LinearGradient>) : (<>
                <View style={styles.imageContainer}>{renderImage()}</View>
                {selected && (<SquareBadge fill={colors.elements.brand} offset={0} border>
                    <IconCheck color={colors.elements.white}/>
                  </SquareBadge>)}
              </>)}
          </View>
          <Text Text-14 numberOfLines={1} ellipsizeMode="tail" style={styles.text}>
            {item.displayName}
          </Text>
        </View>
      </View>
    </TouchableOpacity>);
};
const styleSet = createStyleSheets((colors) => ({
    category: {
        ...(isWeb && {
            padding: rem(16),
        }),
    },
    categoryContent: {
        alignItems: 'center',
    },
    imageBadgeWrapper: {
        width: rem(56),
        marginBottom: rem(8),
    },
    imageContainer: {
        height: rem(56),
        width: rem(56),
        borderRadius: rem(28),
        backgroundColor: colors.cards.onDark,
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        height: rem(56),
        width: rem(56),
        borderRadius: rem(28),
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        maxWidth: '100%',
        textAlign: 'center',
    },
}));
export default memo(EditCategoryRow);
