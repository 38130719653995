import React, { memo } from 'react';
import { TouchableOpacity, View } from 'react-native';
import FastImage from 'utils/packages/rnFastImage';
import { IconCloseSquircle, rem } from 'design-system';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
import Pdf from 'utils/packages/pdf';
import EmptyComponent from 'design-system/EmptyComponent';
import { IconPdf } from 'design-system/icons';
import { isWeb } from '../../../../constants';
const TransactionReceiptBox = ({ data, onPressDelete, onPressImage, }) => {
    const pressDelete = () => {
        if (!data.isUploading) {
            onPressDelete();
        }
    };
    const styles = useStyles(styleSet);
    const colors = useColors();
    const uri = data.fileUri ?? data.url;
    const isPdf = data.fileName?.endsWith('.pdf') || uri?.endsWith('.pdf');
    return (<View style={styles.container}>
      <TouchableBounce onPress={onPressImage} disabled={!onPressImage} style={styles.addBox}>
        {isPdf ? (<View style={[styles.addBox, styles.pdfBox]}>
            {isWeb ? (<IconPdf />) : (<Pdf source={{ uri, cache: true }} maxScale={1} singlePage trustAllCerts={false} spacing={0} style={styles.pdf} renderActivityIndicator={EmptyComponent}/>)}
          </View>) : (<FastImage style={styles.addBox} source={{ uri }} resizeMode={'cover'}/>)}
      </TouchableBounce>
      {!!onPressDelete && (<TouchableOpacity style={styles.deleteBadge} onPress={pressDelete}>
          <IconCloseSquircle width={rem(24)} height={rem(24)} crossColor={colors.elements.white}/>
        </TouchableOpacity>)}
    </View>);
};
export const styleSet = createStyleSheets((colors) => ({
    container: {
        marginEnd: rem(8),
    },
    addBox: {
        width: rem(64),
        height: rem(64),
        alignItems: 'center',
        borderRadius: rem(12),
        justifyContent: 'center',
        backgroundColor: colors.cards.transparentOnDark,
    },
    pdfBox: {
        borderWidth: 1,
        borderColor: colors.borders.logoStroke,
        overflow: 'hidden',
        backgroundColor: colors.background.brandLight,
    },
    pdf: {
        width: rem(64),
        // large number to fill container
        height: 999,
    },
    hoverStyle: {
        backgroundColor: colors.control.hoverOnLight,
    },
    deleteBadge: {
        position: 'absolute',
        top: rem(-4),
        right: rem(-4),
        // hit slop
        paddingBottom: 10,
        paddingLeft: 10,
    },
}));
export default memo(TransactionReceiptBox);
