import React, { useMemo, useState, memo, useCallback } from 'react';
import { FlatList, View, } from 'react-native';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { rem } from 'design-system';
import Text from 'design-system/Text';
import { TextButton } from 'design-system/TextButton';
import { LineSeparator } from 'design-system/LineSeparator';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { getOrdinalSuffix } from 'utils/formatting';
const DAYS = Array.from({ length: 35 }, (_, i) => i + 1);
const keyExtractor = (item) => item.toString();
const DateRangeSelector = ({ start, end, style, small, showReset = false, ...props }) => {
    const styles = useStyles(styleSet);
    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end);
    const textSizeProps = useMemo(() => small
        ? {
            'Numbers-14': true,
        }
        : {
            'Numbers-16': true,
        }, [small]);
    const onPressDay = useCallback((day) => {
        if ('type' in props && props.type === 'single') {
            setStartDate(day);
            props.onChange(day);
        }
        if ('type' in props && props.type === 'range') {
            if (startDate && endDate) {
                setStartDate(day);
                setEndDate(undefined);
                props.onChange({ start: day, end: undefined });
                return;
            }
            if (!props?.allowReverseDates && startDate && day < startDate) {
                setEndDate(startDate);
                setStartDate(day);
                props.onChange({ start: day, end: startDate });
                return;
            }
            if (!startDate) {
                setStartDate(day);
                props.onChange({ start: day, end: endDate });
                return;
            }
            if (!endDate && day !== startDate) {
                setEndDate(day);
                props.onChange({ end: day, start: startDate });
            }
        }
    }, [endDate, props, startDate]);
    const getLeftAndRightFills = useCallback((item) => {
        const hasBothDates = !!(startDate && endDate);
        if (hasBothDates) {
            return {
                rightFill: endDate > startDate
                    ? startDate <= item && endDate > item
                    : item < endDate || item >= startDate,
                leftFill: endDate > startDate
                    ? startDate < item && endDate >= item
                    : item <= endDate || item > startDate,
            };
        }
        return {
            leftFill: false,
            rightFill: false,
        };
    }, [startDate, endDate]);
    const renderItem = useCallback(({ item }) => {
        if (item > 31)
            return <View style={styles.eachContainer}/>;
        const selected = item === startDate || item === endDate;
        const isFirstInRow = item % 7 === 1;
        const isLastInRow = item % 7 === 0;
        const { rightFill, leftFill } = getLeftAndRightFills(item);
        const press = () => onPressDay(item);
        return (<View style={[styles.eachContainer, isLastInRow && styles.lastDayInRow]}>
          {leftFill && (<View style={[
                    styles.dayLeftFill,
                    isFirstInRow && styles.dayLeftFillFirstInRow,
                ]}/>)}
          {rightFill && (<View style={[
                    styles.dayRightFill,
                    isLastInRow && styles.dayRightFillLastInRow,
                ]}/>)}
          <TouchableBounce onPress={press} style={[styles.day, selected && styles.daySelected]} hoverStyle={styles.daySelected}>
            {selected ? (<Text {...textSizeProps} White>
                {item}
              </Text>) : (<Text {...textSizeProps} Gray>
                {item}
              </Text>)}
          </TouchableBounce>
        </View>);
    }, [
        endDate,
        getLeftAndRightFills,
        onPressDay,
        startDate,
        styles.day,
        styles.dayLeftFill,
        styles.dayLeftFillFirstInRow,
        styles.dayRightFill,
        styles.dayRightFillLastInRow,
        styles.daySelected,
        styles.eachContainer,
        styles.lastDayInRow,
        textSizeProps,
    ]);
    const handleReset = useCallback(() => {
        setStartDate(undefined);
        setEndDate(undefined);
        if ('type' in props && props.type === 'single') {
            props.onChange(0);
        }
        if ('type' in props && props.type === 'range') {
            props.onChange({ start: undefined, end: undefined });
        }
    }, [props]);
    const selectionText = useMemo(() => {
        if (!startDate)
            return 'Any day';
        if ('type' in props && props.type === 'single') {
            return `${startDate}${getOrdinalSuffix(startDate)}`;
        }
        if (startDate && endDate) {
            return `${startDate}${getOrdinalSuffix(startDate)} to ${endDate}${getOrdinalSuffix(endDate)}`;
        }
        return `${startDate}${getOrdinalSuffix(startDate)}`;
    }, [startDate, endDate, props]);
    return (<View style={style}>
      {showReset && (<>
          <View style={styles.selectionContainer}>
            <Text Text-14 Secondary>
              {selectionText}
            </Text>

            <TextButton small onPress={handleReset} text="Reset"/>
          </View>

          <LineSeparator noMargin style={styles.separator}/>
        </>)}

      <FlatList scrollEnabled={false} numColumns={7} data={DAYS} renderItem={renderItem} columnWrapperStyle={styles.columnWrapper} keyExtractor={keyExtractor} extraData={{ startDate, endDate }} scrollEventThrottle={50}/>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    eachContainer: {
        flex: 1,
        paddingEnd: rem(8),
    },
    columnWrapper: {
        marginBottom: rem(16),
    },
    selectionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    day: {
        flex: 1,
        aspectRatio: 1,
        alignItems: 'center',
        borderRadius: rem(50),
        justifyContent: 'center',
        backgroundColor: colors.background.dark,
    },
    small: {
        width: rem(38),
        height: rem(38),
    },
    daySelected: {
        backgroundColor: colors.gradients.ultimate.colors[0],
    },
    lastDayInRow: {
        paddingEnd: rem(0),
    },
    dayRightFill: {
        backgroundColor: colors.background.dark,
        position: 'absolute',
        width: rem(29),
        bottom: 0,
        right: -1,
        top: 0,
    },
    dayLeftFill: {
        backgroundColor: colors.background.dark,
        position: 'absolute',
        width: rem(29),
        bottom: 0,
        left: -1,
        top: 0,
    },
    dayRightFillLastInRow: {
        borderTopRightRadius: rem(8),
        borderBottomRightRadius: rem(8),
    },
    dayLeftFillFirstInRow: {
        borderTopLeftRadius: rem(8),
        borderBottomLeftRadius: rem(8),
    },
    separator: {
        marginVertical: rem(16),
    },
}));
export default memo(DateRangeSelector);
