export const GET_FILTERS_REQUEST = 'GET_FILTERS_REQUEST';
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS';
export const GET_FILTERS_FAILURE = 'GET_FILTERS_FAILURE';
export const EDIT_FILTER_REQUEST = 'EDIT_FILTER_REQUEST';
export const EDIT_FILTER_SUCCESS = 'EDIT_FILTER_SUCCESS';
export const EDIT_FILTER_FAILURE = 'EDIT_FILTER_FAILURE';
export const CREATE_FILTER_REQUEST = 'CREATE_FILTER_REQUEST';
export const CREATE_FILTER_SUCCESS = 'CREATE_FILTER_SUCCESS';
export const CREATE_FILTER_FAILURE = 'CREATE_FILTER_FAILURE';
export const GET_FILTER_PREVIEW_REQUEST = 'GET_FILTER_PREVIEW_REQUEST';
export const GET_FILTER_PREVIEW_SUCCESS = 'GET_FILTER_PREVIEW_SUCCESS';
export const GET_FILTER_PREVIEW_FAILURE = 'GET_FILTER_PREVIEW_FAILURE';
export const GET_SMART_RULES_REQUEST = 'GET_SMART_RULES_REQUEST';
export const GET_SMART_RULES_SUCCESS = 'GET_SMART_RULES_SUCCESS';
export const GET_SMART_RULES_FAILURE = 'GET_SMART_RULES_FAILURE';
export const DELETE_SMART_RULE_REQUEST = 'DELETE_SMART_RULE_REQUEST';
export const DELETE_SMART_RULE_SUCCESS = 'DELETE_SMART_RULE_SUCCESS';
export const DELETE_SMART_RULE_FAILURE = 'DELETE_SMART_RULE_FAILURE';
export const CREATE_SMART_RULE_REQUEST = 'CREATE_SMART_RULE_REQUEST';
export const CREATE_SMART_RULE_SUCCESS = 'CREATE_SMART_RULE_SUCCESS';
export const CREATE_SMART_RULE_FAILURE = 'CREATE_SMART_RULE_FAILURE';
export const EDIT_SMART_RULE_REQUEST = 'EDIT_SMART_RULE_REQUEST';
export const EDIT_SMART_RULE_SUCCESS = 'EDIT_SMART_RULE_SUCCESS';
export const EDIT_SMART_RULE_FAILURE = 'EDIT_SMART_RULE_FAILURE';
export const GET_SMART_RULE_CREATED_TRANSACTIONS_REQUEST = 'GET_SMART_RULE_CREATED_TRANSACTIONS_REQUEST';
export const GET_SMART_RULE_CREATED_TRANSACTIONS_SUCCESS = 'GET_SMART_RULE_CREATED_TRANSACTIONS_SUCCESS';
export const GET_SMART_RULE_CREATED_TRANSACTIONS_FAILURE = 'GET_SMART_RULE_CREATED_TRANSACTIONS_FAILURE';
