// web backbutton with hover effect
import React from 'react';
import { StyleSheet, Platform } from 'react-native';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import { IconArrowLeft } from 'design-system/icons/IconArrowLeft';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const BackButton = () => {
    const navigation = useMainStackNavigation();
    return (<TouchableOpacity hoverOnDark style={styles.backButton} onPress={() => navigation.goBack()}>
      <IconArrowLeft />
    </TouchableOpacity>);
};
export default BackButton;
const styles = StyleSheet.create({
    backButton: {
        ...Platform.select({
            web: {
                cursor: 'pointer',
            },
        }),
        padding: 8,
        borderRadius: 10,
        marginLeft: 16,
    },
});
