import React, { memo } from 'react';
import Logo from 'design-system/Logo';
import { Avatar } from 'design-system/avatar';
import { CIRCLE_BORDER_SIZE, CIRCLE_SIZE } from 'design-system/LogoBankAccount';
import useColors from 'hooks/useColors';
const SubscriptionLogo = ({ name, url }) => {
    const colors = useColors();
    if (!url ||
        url === 'https://app.emma-cdn.com/images/icons/subscriptions.png') {
        return (<Avatar size={CIRCLE_SIZE} borderWidth={CIRCLE_BORDER_SIZE} name={name || 'Emma'} fontColor={colors.text.textOnBrand} color={colors.background.brandLight} medium/>);
    }
    return <Logo uri={url} resizeMode="contain" R44/>;
};
export default memo(SubscriptionLogo);
