import { IconBank, IconBell, IconCalendar, IconCart, IconCoinStack, IconDocument, IconEdit, IconGoal, IconLightBulb, IconLock, IconMenuBold, IconMoneyBill, IconSearch, IconWork, } from 'design-system/icons';
import { IconBankCard } from 'design-system/icons/IconBankCard';
import { IconBoldFilter } from 'design-system/icons/IconBoldFilter';
import { IconSplit } from 'design-system/icons/IconSplit';
import IconChartFavourite from 'design-system/icons/IconChartFavourite';
import IconEye from 'design-system/icons/IconEye';
import IconGift from 'design-system/icons/IconGift';
import IconGraph from 'design-system/icons/IconGraph';
import IconInvest from 'design-system/icons/IconInvest';
import IconSpace from 'design-system/icons/IconSpace';
import { IconLink } from 'design-system/icons/IconLink';
import { IconMessage3 } from 'design-system/icons/IconMessage3';
import { IconMoneyCheck } from 'design-system/icons/IconMoneyCheck';
import { IconNotificationStatus } from 'design-system/icons/IconNotificationStatus';
import IconPercent from 'design-system/icons/IconPercent';
import { IconPiggy } from 'design-system/icons/IconPiggy';
import { IconPlane } from 'design-system/icons/IconPlane';
import IconRepeat from 'design-system/icons/IconRepeat';
import { IconRocket } from 'design-system/icons/IconRocket';
import { IconRollingBudgets } from 'design-system/icons/IconRollingBudgets';
import { IconShareSquare } from 'design-system/icons/IconShareSquare';
import IconStatusUp from 'design-system/icons/IconStatusUp';
import IconThreeUser from 'design-system/icons/IconThreeUser';
import IconTicketSolid from 'design-system/icons/IconTicketSolid';
import IconTransfer from 'design-system/icons/IconTransfer';
import { IconWifi } from 'design-system/icons/IconWifi';
import { rem } from 'design-system/values';
import { formatRentReportingAgencies } from 'features/rent-reporting/utils';
import React from 'react';
import checkFeatureFlag from 'utils/featureFlags';
import { formatAmount } from 'utils/formatting';
import { IconGlobeGradient } from 'design-system/icons/IconGlobeGradient';
import { Tier, } from './entities';
export const filterBenefitsArray = (items, { homeCountry }, featureFlags, hasInterestPotProduct) => items.filter((bd) => {
    if (!hasInterestPotProduct && bd.id === 'potsInterest') {
        return false;
    }
    let passesCountry = true;
    if (homeCountry && bd.filters?.countries) {
        passesCountry = bd.filters.countries.includes(homeCountry);
    }
    let passesFlags = true;
    if (featureFlags && bd.visibleOnFlag) {
        if (Array.isArray(bd.visibleOnFlag)) {
            passesFlags =
                bd.visibleOnFlag.find((item) => checkFeatureFlag(featureFlags, item.flag, item.value, item.negativeValue)) !== undefined;
        }
        else {
            passesFlags = checkFeatureFlag(featureFlags, bd.visibleOnFlag.flag, bd.visibleOnFlag.value, bd.visibleOnFlag.negativeValue);
        }
    }
    return passesCountry && passesFlags;
});
export function mapBenefitsToBenefitsDataArray(benefits, benefitsData) {
    return benefitsData.flatMap((data) => {
        const benefitData = {
            ...data,
            data: data.data.flatMap((id) => (benefits[id] ? [benefits[id]] : [])),
        };
        if (benefitData.data.length === 0) {
            return [];
        }
        return [benefitData];
    });
}
export const tierThemes = {
    [Tier.ultimate]: {
        name: 'Emma Ultimate',
        shortName: 'Ultimate',
        description: 'The Ultimate Emma experience',
        descriptionGB: 'The Ultimate Emma experience to build and grow your wealth over time',
        activeDescription: 'Enjoy your top Emma experience',
        width: rem(96),
    },
    [Tier.pro]: {
        name: 'Emma Pro',
        shortName: 'Pro',
        description: 'A custom experience made for you',
        descriptionGB: 'The all-in-one financial membership to build your credit, save more and spend less',
        activeDescription: 'Enjoy your Emma custom experience',
        width: rem(57),
    },
    [Tier.plus]: {
        name: 'Emma Plus',
        shortName: 'Plus',
        description: 'Boost your everyday finances',
        descriptionGB: 'Boost your every day finances and start saving and investing with Emma',
        activeDescription: 'Boost your everyday finances',
        width: rem(63),
    },
    [Tier.free]: {
        name: 'Emma Free',
        shortName: 'Free',
        descriptionGB: 'Start your financial journey with Emma Free and learn to budget, save and invest',
        width: rem(63),
    },
};
export const getCustomNameAndDescription = (benefit, featureFlags, currentTier, tierLimits, tierRate, tierFxRateMap, connectionsLimitMap, rentReportingAgenciesTierMap, guessedHomeCountry, savingsPlanTierFees) => {
    const isGb = guessedHomeCountry === 'GB';
    switch (benefit.id) {
        case 'transfers': {
            const currentTierLimits = tierLimits?.[currentTier];
            if (currentTierLimits &&
                !(currentTierLimits?.monthly === null ||
                    (currentTierLimits?.monthly === undefined &&
                        currentTier === Tier.ultimate))) {
                return {
                    customDescription: `Transfer up to ${formatAmount(currentTierLimits.monthly, currentTierLimits.currency)}/mo between your linked accounts and pay bills via Emma`,
                };
            }
            break;
        }
        case 'automatedSavings': {
            if (savingsPlanTierFees &&
                typeof savingsPlanTierFees[currentTier] === 'number') {
                if (savingsPlanTierFees[currentTier] === 0) {
                    return {
                        customDescription: 'Put your money on autopilot and let Emma save for you for free',
                    };
                }
                return {
                    customDescription: `Put your money on autopilot and let Emma save for you with a ${formatAmount(savingsPlanTierFees[currentTier], 'GBP', 2)} fee per save`,
                };
            }
            break;
        }
        case 'potsInterest': {
            if (tierRate) {
                return {
                    customDescription: `${formatAmount(tierRate.rate)}% AER interest${tierRate.interestPaymentFrequency
                        ? `, paid to you ${tierRate.interestPaymentFrequency.toLowerCase()}`
                        : ''}`,
                };
            }
            break;
        }
        case 'fxFees': {
            if (tierFxRateMap && tierFxRateMap[currentTier]) {
                if (currentTier === Tier.ultimate) {
                    return {
                        customDescription: `Our best FX fee of ${tierFxRateMap[currentTier]}%. Capital at risk`,
                    };
                }
                if (currentTier === Tier.free) {
                    return {
                        customName: 'Emma Invest',
                        customDescription: `FX fee of ${tierFxRateMap[currentTier]}%. Capital at risk`,
                    };
                }
                return {
                    customDescription: `Reduced FX fee of ${tierFxRateMap[currentTier]}%. Capital at risk`,
                };
            }
            break;
        }
        case 'connections': {
            const connectionsLimit = connectionsLimitMap?.[currentTier];
            const asterisk = isGb ? '*' : '';
            if (connectionsLimit === null) {
                return {
                    customName: `Unlimited bank logins${asterisk}`,
                    customDescription: isGb
                        ? `See your balances and transactions, move your money around with easy bank transfers`
                        : 'Add unlimited bank logins to see all your money in one place',
                };
            }
            if (connectionsLimit) {
                if (currentTier === Tier.free && !isGb) {
                    return {
                        customName: `Up to ${connectionsLimit} bank logins${asterisk}`,
                        customDescription: `Add up to ${connectionsLimit} bank logins`,
                    };
                }
                return {
                    customName: `Up to ${connectionsLimit} bank logins${asterisk}`,
                    customDescription: isGb
                        ? `Send money to friends and family, and see your balances and transactions from up to ${connectionsLimit} bank logins`
                        : `Add up to ${connectionsLimit} bank logins to see all your money in one place`,
                };
            }
            break;
        }
        case 'rentReporting': {
            const formattedAgencies = formatRentReportingAgencies(rentReportingAgenciesTierMap?.[currentTier]);
            return {
                customDescription: `Improve your credit position with ${formattedAgencies} by reporting rent payments`,
            };
        }
        case 'turboUpdates': {
            if (currentTier !== Tier.plus) {
                return {
                    customName: 'Turbo and on demand updates',
                    customDescription: `Up to 4 automatic syncs a day and on demand across all your bank logins. Enjoy the fastest experience`,
                };
            }
            break;
        }
        case 'netWorth': {
            if (isGb)
                return {
                    customName: 'Net worth',
                    customDescription: 'Track your net worth as accurately as possible with the help of our expert concierge',
                };
            break;
        }
        case 'spaces': {
            if (isGb) {
                return {
                    customDescription: 'Organise your cash with your life or business partners',
                };
            }
            break;
        }
        default:
            return {};
    }
    return {};
};
export const benefits = {
    analytics: {
        id: 'analytics',
        name: 'Spend analytics',
        icon: <IconChartFavourite gradientKey="ultimate"/>,
        text: 'Enjoy automatic categorisation, discover trends and understand where your money goes',
        minTier: Tier.free,
        maxTier: Tier.free,
    },
    advancedTransactionsEditing: {
        id: 'advancedTransactionsEditing',
        name: 'Advanced transactions editing',
        icon: <IconBoldFilter gradientKey="red"/>,
        text: 'Edit your transactions 10x faster by selecting them all in one go',
        withArrow: true,
        minTier: Tier.pro,
    },
    automatedSavings: {
        id: 'automatedSavings',
        name: 'Autosave',
        icon: <IconRocket gradientKey="ultimate"/>,
        text: 'Put your money on autopilot and let Emma AI save for you',
        withArrow: true,
        minTier: Tier.plus,
        filters: {
            countries: ['GB'],
        },
        isComingSoon: true,
    },
    billReminders: {
        id: 'billReminders',
        name: 'Bill reminders',
        icon: <IconBell gradientKey="lightPink"/>,
        text: 'Never miss a bill again, Emma will remind you in advance',
        activeColor: '#1bcaca',
        minTier: Tier.plus,
    },
    brokerageAccount: {
        id: 'brokerageAccount',
        name: 'Free brokerage account',
        icon: <IconTicketSolid gradientKey="ultimate" alt/>,
        text: 'Start your investing journey with Emma Invest',
        minTier: Tier.free,
        maxTier: Tier.free,
        filters: {
            countries: ['GB'],
        },
    },
    budgeting: {
        id: 'budgeting',
        icon: <IconMoneyBill alt gradientKey="peach"/>,
        name: 'Budgeting & enhanced tracking*',
        text: 'Set targets, find wasteful subscriptions and track your entire transaction history',
        minTier: Tier.plus,
        maxTier: Tier.free,
    },
    business: {
        id: 'business',
        icon: <IconWork gradientKey="deepPink"/>,
        name: 'Business accounts',
        text: 'Link all your business accounts in one place',
        withArrow: true,
        minTier: Tier.ultimate,
        filters: {
            countries: ['GB'],
        },
    },
    cashback: {
        id: 'cashback',
        name: 'Cashback',
        icon: <IconCoinStack gradientKey="yellow"/>,
        text: 'Earn cashback at more than 500+ brands and make your plan pay for itself!',
        withArrow: true,
        minTier: Tier.plus,
        quest: 'rewards.earn_reward',
    },
    compareLoansAndCards: {
        id: 'compareLoansAndCards',
        icon: <IconBankCard gradientKey="green"/>,
        name: 'Compare loans and cards',
        text: 'See offers from more than 60 lenders to find the ones that fit your needs',
        withArrow: true,
        minTier: Tier.free,
        maxTier: Tier.free,
        filters: {
            countries: ['GB'],
        },
    },
    connections: {
        id: 'connections',
        name: 'Bank logins',
        icon: <IconBank gradientKey="skyBlue"/>,
        text: `Add extra bank logins to your plan to see all your money in one place`,
        withArrow: true,
        minTier: Tier.free,
    },
    changeTransactionDate: {
        id: 'changeTransactionDate',
        name: 'Change of date',
        icon: <IconCalendar gradientKey="lightPink"/>,
        text: "Change your transactions' dates to make them more accurate",
        minTier: Tier.pro,
        quest: 'first_time.change_transaction_date',
    },
    customCategories: {
        id: 'customCategories',
        name: 'Custom categories',
        icon: <IconMenuBold gradientKey="peach"/>,
        text: 'Create custom categories and unlimited budgets',
        withArrow: true,
        minTier: Tier.pro,
    },
    dataProtection: {
        id: 'dataProtection',
        name: 'Fraud protection',
        icon: <IconLock alt/>,
        text: 'Protect your online identity and find if your personal data has been leaked on the dark web',
        withArrow: true,
        minTier: Tier.plus,
    },
    linkAccounts: {
        id: 'linkAccounts',
        icon: <IconLink alt gradientKey="skyBlue"/>,
        name: 'Link accounts*',
        text: 'See your balance and transactions from your primary spending accounts in Emma',
        withArrow: true,
        minTier: Tier.free,
        maxTier: Tier.free,
        filters: {
            countries: ['GB'],
        },
    },
    eMoneyAccount: {
        id: 'eMoneyAccount',
        name: 'Free e-money account',
        icon: <IconMoneyCheck gradientKey="orange"/>,
        text: 'Get a free UK account number and sort code to manage your money',
        minTier: Tier.free,
        maxTier: Tier.free,
        filters: {
            countries: ['GB'],
        },
    },
    export: {
        id: 'export',
        name: 'Exporting of data',
        icon: <IconShareSquare gradientKey="green"/>,
        text: 'Export all your data to excel, including categories, notes and tags!',
        withArrow: true,
        minTier: Tier.pro,
    },
    groups: {
        id: 'groups',
        name: 'Split your spending in Groups',
        icon: <IconThreeUser gradientKey="darkPurple"/>,
        text: 'Say goodbye to Splitwise and use Emma to split your spending with friends and family',
        withArrow: true,
        minTier: Tier.free,
        maxTier: Tier.free,
    },
    forceSync: {
        id: 'forceSync',
        name: 'On demand updates',
        icon: <IconRepeat gradientKey="darkPurple"/>,
        text: 'Sync all your connections whenever you want',
        minTier: Tier.pro,
    },
    fxFees: {
        id: 'fxFees',
        icon: <IconInvest gradientKey="red"/>,
        name: 'Lower FX fee on your trades',
        text: 'Reduced FX fees. Capital at risk',
        minTier: Tier.plus,
        filters: {
            countries: ['GB'],
        },
    },
    merchantBudgets: {
        id: 'merchantBudgets',
        icon: <IconCart gradientKey="darkPink" alt/>,
        name: 'Merchant budgets',
        text: 'Set how much you want to spend per merchant and get reminders',
        withArrow: true,
        minTier: Tier.plus,
    },
    netWorth: {
        id: 'netWorth',
        name: 'Accurate net worth',
        icon: <IconGraph gradientKey="darkPurple"/>,
        text: 'Include all your property in your net worth, such as houses, cars and even artwork!',
        withArrow: true,
        minTier: Tier.pro,
        quest: 'first_time.open_net_worth',
    },
    notifications: {
        id: 'notifications',
        name: 'Get notifications',
        icon: <IconNotificationStatus />,
        text: 'Receive real-time notifications and alerts on your spending',
        withArrow: false,
        minTier: Tier.free,
        maxTier: Tier.free,
    },
    offlineAccounts: {
        id: 'offlineAccounts',
        name: 'Offline accounts',
        icon: <IconWifi off gradientKey="skyBlue"/>,
        text: 'Manual transactions and balances for unlimited accounts',
        withArrow: true,
        minTier: Tier.pro,
    },
    pay: {
        id: 'pay',
        name: 'Pay, request & transfer money',
        icon: <IconTransfer gradientKey="skyBlue"/>,
        text: 'Move your money between your accounts, pay bills or request payments all in one app',
        minTier: Tier.free,
        maxTier: Tier.free,
        withArrow: false,
        filters: {
            countries: ['GB'],
        },
    },
    potsInterest: {
        id: 'potsInterest',
        icon: <IconPercent gradientKey="pro"/>,
        name: 'Interest on your savings',
        text: 'Annual interest',
        minTier: Tier.free,
        filters: {
            countries: ['GB'],
        },
    },
    prioritySupport: {
        id: 'prioritySupport',
        icon: <IconMessage3 gradientKey="orange"/>,
        name: 'Priority support',
        text: 'Get faster live chat support. Mon-Fri 9.30am-6.30pm',
        withArrow: true,
        minTier: Tier.plus,
    },
    recurringPayments: {
        id: 'recurringPayments',
        name: 'Recurring payments',
        icon: <IconRepeat gradientKey="apricot" alt/>,
        text: 'Track & monitor all your bills and subscription in one place',
        minTier: Tier.plus,
        maxTier: Tier.free,
    },
    renameTransactions: {
        id: 'renameTransactions',
        name: 'Rename transactions',
        icon: <IconEdit gradientKey="darkPurple"/>,
        text: 'Customise your transactions’ names to your needs for better tracking',
        minTier: Tier.pro,
        quest: 'first_time.rename_transaction',
    },
    rentReporting: {
        id: 'rentReporting',
        name: 'Credit building',
        icon: <IconStatusUp />,
        text: 'Improve your credit position with Experian by reporting rent payments',
        filters: {
            countries: ['GB'],
        },
        visibleOnFlag: {
            flag: 'rent_reporting_two',
        },
        minTier: Tier.plus,
        withArrow: true,
    },
    rollingBudgets: {
        id: 'rollingBudgets',
        name: 'Rolling budgets',
        icon: <IconRollingBudgets gradientKey="orange"/>,
        text: 'Let your budgets roll over to the next period',
        activeColor: '#1bcaca',
        minTier: Tier.pro,
    },
    savingsAccounts: {
        id: 'savingsAccounts',
        icon: <IconPiggy gradientKey="deepPink"/>,
        name: 'Free savings account',
        text: 'Open a savings account to build your first emergency fund',
        withArrow: true,
        minTier: Tier.free,
        maxTier: Tier.free,
    },
    savingGoals: {
        id: 'savingGoals',
        icon: <IconGoal gradientKey="skyBlue"/>,
        name: 'Savings goals',
        text: 'Track your progress and set a goal on top of your pots and savings accounts',
        withArrow: true,
        minTier: Tier.plus,
    },
    search: {
        id: 'search',
        name: 'Power search',
        icon: <IconSearch alt gradientKey="orange"/>,
        text: 'Find the most hidden transactions with a powerful search',
        minTier: Tier.plus,
        maxTier: Tier.free,
    },
    smartRules: {
        id: 'smartRules',
        name: 'Smart rules',
        icon: <IconLightBulb alt gradientKey="orange"/>,
        text: 'Let Emma update your offline accounts when you transfer money from your linked accounts',
        withArrow: true,
        minTier: Tier.pro,
    },
    spaces: {
        id: 'spaces',
        icon: <IconSpace gradientKey="skyBlue"/>,
        name: 'Spaces',
        text: '• Separate your Analytics into layers\n• Manage your personal, joint, and business accounts all in Emma\n• Share Spaces with your partner(s)',
        withArrow: true,
        minTier: Tier.ultimate,
    },
    spendingTargets: {
        id: 'spendingTargets',
        icon: <IconGoal gradientKey="darkPink"/>,
        name: 'Spending targets',
        text: 'Create category or merchant targets to help you spend less and save more',
        withArrow: true,
        minTier: Tier.free,
        maxTier: Tier.free,
        filters: {
            countries: ['GB'],
        },
    },
    reports: {
        id: 'reports',
        icon: <IconDocument gradientKey="green"/>,
        name: 'Weekly & monthly reports',
        text: 'Get weekly and monthly reports to review your past spending and make the right decisions',
        withArrow: true,
        minTier: Tier.free,
        maxTier: Tier.free,
        filters: {
            countries: ['GB'],
        },
    },
    split: {
        id: 'split',
        name: 'Split transactions',
        icon: <IconSplit gradientKey="lime"/>,
        text: 'Split your transactions to improve your budgeting',
        minTier: Tier.pro,
        quest: 'first_time.split_transaction',
    },
    stocks: {
        id: 'stocks',
        name: 'Our complete stock universe',
        icon: <IconGlobeGradient />,
        text: 'Invest in more than 2,000 international stocks. Capital at risk',
        minTier: Tier.plus,
    },
    transactionEnhancements: {
        id: 'transactionEnhancements',
        name: 'Transaction enhancements',
        icon: (<IconLightBulb width={rem(28)} height={rem(28)} gradientKey="yellow"/>),
        text: 'Rename, split and change the date of your transactions',
        withArrow: true,
        minTier: Tier.pro,
    },
    transfers: {
        id: 'transfers',
        icon: <IconTransfer />,
        name: 'Fee-free bank transfers*',
        text: 'Transfer money between your linked accounts and pay bills in Emma',
        filters: {
            countries: ['GB'],
        },
        withArrow: true,
        minTier: Tier.free,
    },
    trueBalance: {
        id: 'trueBalance',
        icon: <IconEye gradientKey="darkPurple"/>,
        name: 'True balance',
        text: 'See how much money you have left before payday',
        minTier: Tier.plus,
    },
    turboUpdates: {
        id: 'turboUpdates',
        name: 'Turbo updates',
        icon: <IconPlane gradientKey="green"/>,
        text: 'Up to 4 automatic syncs a day across all your connections. Enjoy the fastest experience',
        minTier: Tier.plus,
    },
    quiz: {
        id: 'quiz',
        icon: <IconGift gradientKey="orange"/>,
        name: 'Win up to £100 every week',
        text: 'Take part in our free Emma Quiz with money prizes every week',
        withArrow: true,
        minTier: Tier.free,
        maxTier: Tier.free,
        filters: {
            countries: ['GB'],
        },
    },
};
export const benefitsTopFeaturesGB = {
    [Tier.free]: [
        'eMoneyAccount',
        'brokerageAccount',
        'savingsAccounts',
        'linkAccounts',
    ],
    [Tier.plus]: ['rentReporting', 'potsInterest', 'cashback'],
    [Tier.pro]: ['rentReporting', 'potsInterest', 'fxFees'],
    [Tier.ultimate]: ['rentReporting', 'potsInterest', 'fxFees'],
};
export const benefitsTopFeatures = {
    [Tier.free]: ['pay', 'groups', 'connections'],
    [Tier.plus]: ['cashback', 'savingGoals', 'trueBalance'],
    [Tier.pro]: ['connections', 'turboUpdates', 'customCategories'],
    [Tier.ultimate]: ['spaces', 'netWorth', 'customCategories'],
};
export const benefitsData = [
    {
        name: 'Everyday Benefits',
        data: [
            'connections',
            'notifications',
            'search',
            'groups',
            'cashback',
            'transfers',
            'spaces',
            'business',
            'turboUpdates',
            'forceSync',
            'rentReporting',
            'prioritySupport',
            'pay',
            'budgeting',
            'recurringPayments',
        ],
    },
    {
        name: 'Everyday Protection',
        data: ['dataProtection', 'trueBalance', 'billReminders'],
    },
    {
        name: 'Investments & Savings',
        data: ['potsInterest', 'fxFees', 'netWorth', 'savingGoals'],
    },
    {
        name: 'Custom Experience',
        data: [
            'customCategories',
            'offlineAccounts',
            'merchantBudgets',
            'rollingBudgets',
            'export',
            'smartRules',
            'advancedTransactionsEditing',
            'split',
            'renameTransactions',
            'changeTransactionDate',
        ],
    },
];
// TODO THIS MIGHT NOT BE NEEDED, WE CAN PROBABLY FILTER ENTIRELY USING FLAGS
export const benefitsDataHideDigital = [
    {
        name: 'Everyday Benefits',
        data: [
            'connections',
            'transfers',
            'spaces',
            'cashback',
            'eMoneyAccount',
            'compareLoansAndCards',
            'notifications',
            'quiz',
            'budgeting',
            'prioritySupport',
        ],
    },
    {
        name: 'Spending management',
        data: ['analytics', 'spendingTargets', 'groups', 'reports'],
    },
    {
        name: 'Everyday Protection',
        data: ['rentReporting', 'dataProtection', 'billReminders', 'trueBalance'],
    },
    {
        name: 'Investments & Savings',
        data: [
            'brokerageAccount',
            'savingsAccounts',
            'potsInterest',
            'automatedSavings',
            'fxFees',
            'stocks',
            'netWorth',
            'savingGoals',
        ],
    },
];
export const benefitsDataProgress = [
    'connections',
    'notifications',
    'search',
    'groups',
    'cashback',
    'transfers',
    'spaces',
    'business',
    'turboUpdates',
    'forceSync',
    'rentReporting',
    'prioritySupport',
    'pay',
    'budgeting',
    'recurringPayments',
    'dataProtection',
    'trueBalance',
    'billReminders',
    'potsInterest',
    'fxFees',
    'netWorth',
    'savingGoals',
    'customCategories',
    'offlineAccounts',
    'merchantBudgets',
    'rollingBudgets',
    'export',
    'smartRules',
    'advancedTransactionsEditing',
    'split',
    'renameTransactions',
    'changeTransactionDate',
];
