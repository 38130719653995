import React, { useId } from 'react';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconRectangleListSolid = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="deepPink" {...props}>
      <G clip-path="url(#clip0_2964_3352)">
        <Path d="M3.7373 7.40938C3.7373 6.3952 4.55926 5.57324 5.57345 5.57324H18.4264C19.4392 5.57324 20.2626 6.3952 20.2626 7.40938V16.5901C20.2626 17.6028 19.4392 18.4262 18.4264 18.4262H5.57345C4.55926 18.4262 3.7373 17.6028 3.7373 16.5901V7.40938ZM8.32766 11.9997C8.32766 11.4919 7.91739 11.0817 7.40959 11.0817C6.90178 11.0817 6.49152 11.4919 6.49152 11.9997C6.49152 12.5075 6.90178 12.9178 7.40959 12.9178C7.91739 12.9178 8.32766 12.5075 8.32766 11.9997ZM8.32766 9.24552C8.32766 8.73772 7.91739 8.32745 7.40959 8.32745C6.90178 8.32745 6.49152 8.73772 6.49152 9.24552C6.49152 9.75333 6.90178 10.1636 7.40959 10.1636C7.91739 10.1636 8.32766 9.75333 8.32766 9.24552ZM8.32766 14.7539C8.32766 14.2461 7.91739 13.8359 7.40959 13.8359C6.90178 13.8359 6.49152 14.2461 6.49152 14.7539C6.49152 15.2618 6.90178 15.672 7.40959 15.672C7.91739 15.672 8.32766 15.2618 8.32766 14.7539ZM10.1638 8.55697C9.78223 8.55697 9.47525 8.86395 9.47525 9.24552C9.47525 9.6271 9.78223 9.93408 10.1638 9.93408H16.5903C16.9719 9.93408 17.2788 9.6271 17.2788 9.24552C17.2788 8.86395 16.9719 8.55697 16.5903 8.55697H10.1638ZM10.1638 11.3112C9.78223 11.3112 9.47525 11.6182 9.47525 11.9997C9.47525 12.3813 9.78223 12.6883 10.1638 12.6883H16.5903C16.9719 12.6883 17.2788 12.3813 17.2788 11.9997C17.2788 11.6182 16.9719 11.3112 16.5903 11.3112H10.1638ZM10.1638 14.0654C9.78223 14.0654 9.47525 14.3724 9.47525 14.7539C9.47525 15.1355 9.78223 15.4425 10.1638 15.4425H16.5903C16.9719 15.4425 17.2788 15.1355 17.2788 14.7539C17.2788 14.3724 16.9719 14.0654 16.5903 14.0654H10.1638Z" fill={`url(#gradient_${id})`}/>
      </G>
      <Defs>
        <ClipPath id="clip0_2964_3352">
          <Rect width="16.5253" height="14.6891" fill={`url(#gradient_${id})`} transform="translate(3.7373 4.65527)"/>
        </ClipPath>
      </Defs>
    </IconWithGradient>);
};
