import React from 'react';
import { rem } from 'design-system/values';
import Selector from 'design-system/Selector';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const SECTIONS = ['Category', 'Merchant'];
const AnalyticsSelector = ({ setSelection, style }) => {
    const styles = useStyles(styleSet);
    return (<Selector Text-14 style={[styles.selector, style]} sections={SECTIONS} onSelected={setSelection}/>);
};
const styleSet = createStyleSheets(() => ({
    selector: {
        marginHorizontal: rem(16),
        width: null,
    },
}));
export default AnalyticsSelector;
