import React, { useCallback, useState } from 'react';
import Section from 'design-system/Section';
import { CardView } from 'design-system/CardView';
import IconEyeSlash from 'design-system/icons/IconEyeSlash';
import { SettingsItemSwitch } from 'design-system/SettingsItem';
import { editTransactions } from 'actions/transactions';
import { useAppDispatch, useAppStore } from 'store/hooks';
import { styles } from './styles';
const TransactionExcludedSection = ({ transaction }) => {
    const store = useAppStore();
    const dispatch = useAppDispatch();
    const [state, setState] = useState({
        previousEdit: null,
        excludedValue: false,
    });
    const onSetExcluded = useCallback(() => {
        const { category } = transaction;
        if (!category)
            return;
        const { id } = category;
        const editToCategoryId = state.previousEdit || 'internal';
        const editCategory = store
            .getState()
            .user.categories?.find((cat) => cat.id === editToCategoryId) ||
            store
                .getState()
                .user.customCategories?.find((cat) => cat.id === editToCategoryId) ||
            {
                id: editToCategoryId,
            };
        dispatch(editTransactions({ [transaction.id]: transaction.category.id }, editCategory));
        setState((s) => ({
            ...s,
            previousEdit: id,
            excludedValue: !s.excludedValue,
        }));
    }, [state.previousEdit, transaction]);
    if (transaction?.category?.id === 'internal' && !state.previousEdit)
        return null;
    return (<Section title="Transaction options" style={styles.section}>
      <CardView noMarginTop noMarginBottom webPadding>
        <SettingsItemSwitch title="Exclude from analytics" value={state.excludedValue} onValueChange={onSetExcluded} icon={<IconEyeSlash gradientKey="skyBlue"/>}/>
      </CardView>
    </Section>);
};
export default TransactionExcludedSection;
