import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { ScrollView, View } from 'react-native';
import emoji from 'node-emoji';
import Clipboard from '@react-native-clipboard/clipboard';
import Share from 'utils/packages/rnShare';
import Alert from 'utils/packages/Alert';
import { AppView } from 'design-system/AppView';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import { SettingsItem, SettingsItemRightContent, } from 'design-system/SettingsItem';
import Spacer from 'design-system/Spacer';
import StatusBar from 'design-system/StatusBar';
import useColors from 'hooks/useColors';
import { IconArrow } from 'design-system/icons/IconArrow';
import { IconEdit } from 'design-system/icons/IconEdit';
import SectionHeader from 'design-system/SectionHeader';
import { SettingsItemIcon } from 'design-system/SettingsItemIcon';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import IconSpace from 'design-system/icons/IconSpace';
import { IconLink } from 'design-system/icons/IconLink';
import ListEmptyView from 'design-system/ListEmptyView';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import IconCopy from 'design-system/icons/IconCopy';
import useStyles from 'hooks/useStyles';
import { selectUserId } from 'reducers/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { IconTrash } from 'design-system/icons';
import { formatPlural } from 'utils';
import createStyleSheets from 'utils/createStyleSheets';
import EditSpaceItem from './components/EditSpaceItem';
import SpaceMemberItem from './containers/SpaceMemberItem';
import { useDeleteSpace, useSpaceInfo } from './hooks';
import { Share as ShareAction } from './img';
import { isWeb } from '../../constants';
import { fetchSpacesAction } from './actions';
const EditSpace = withAnimatedNavigationHeader(({ route, navigation, onScroll }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const dispatch = useAppDispatch();
    const propsSpace = route.params?.space;
    const id = useAppSelector(selectUserId);
    const [spaceInfo, spaceInfoBusy, fetch] = useSpaceInfo(route.params.id ?? -1);
    const space = spaceInfo?.space ?? propsSpace;
    const onRemoved = useCallback(() => {
        navigation.pop();
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [removeBusy, remove] = useDeleteSpace(propsSpace?.id ?? -1, onRemoved);
    useLayoutEffect(() => {
        const isDeletable = space?.createdBy === id;
        const onPress = space?.createdBy !== id
            ? undefined
            : () => {
                if (space.accountsCount) {
                    Alert.alert(emoji.emojify('Slow down :face_with_rolling_eyes:'), 'Move your accounts to another space before deletion');
                }
                else {
                    Alert.alert('Delete Space', 'Do you want to delete this space?', [
                        { text: 'Cancel', style: 'cancel' },
                        { text: 'Delete', onPress: remove, style: 'destructive' },
                    ]);
                }
            };
        navigation.setOptions({
            headerRight: isDeletable
                ? () => (<NavigationHeaderRightIconButton onPress={onPress}>
                <IconTrash color={colors.text.primary}/>
              </NavigationHeaderRightIconButton>)
                : undefined,
        });
    }, [
        colors.text.primary,
        id,
        remove,
        space?.accountsCount,
        space?.createdBy,
    ]);
    const accountsTitle = useMemo(() => space?.accountsCount
        ? `${space.accountsCount} ${formatPlural('account', 'accounts', space.accountsCount)}`
        : undefined, [space?.accountsCount]);
    const link = useMemo(() => space?.accessLink?.replace(/^https?:\/\//, '') ?? '', [space?.accessLink]);
    const onEditName = () => {
        if (!removeBusy && space)
            navigation.navigate('EditSpaceName', { space });
    };
    const onEditAccounts = () => {
        if (!removeBusy)
            navigation.navigate('EditSpaceAccounts', { spaceInfo });
    };
    const onShare = async () => {
        try {
            await Share.open({ message: space?.accessLink });
        }
        catch (e) {
            if (e.message === 'User did not share') {
                return;
            }
            throw e;
        }
    };
    const [copied, setCopied] = useState(false);
    const handleShareOnWeb = () => {
        Clipboard.setString(link);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000); // Reset after 3 seconds
    };
    return (<AppView type="secondary" withFrame={false}>
        <StatusBar barStyle="dark-content" animated/>
        <ScrollView scrollIndicatorInsets={SCROLL_INDICATOR_INSETS} onScroll={onScroll} scrollEventThrottle={8} style={paddingHorizontalStyle}>
          {!spaceInfoBusy && !space ? (<>
              <Spacer.H16 />
              <ListEmptyView onCard title="Space not found"/>
            </>) : (<View style={styles.content}>
              <View style={[styles.subContainer, isWeb && styles.hover]}>
                <SettingsItem title="Space name" description={space?.name ?? ''} icon={<IconSpace color={colors.gradients.ultimate.colors[0]}/>} onPress={removeBusy || spaceInfoBusy || space?.createdBy !== id
                ? undefined
                : onEditName}>
                  <SettingsItemRightContent>
                    {space?.createdBy === id ? <IconArrow /> : null}
                  </SettingsItemRightContent>
                </SettingsItem>
                <SettingsItem title="Manage accounts" description={accountsTitle} icon={<IconEdit gradientColors={colors.gradients.orange.colors}/>} onPress={removeBusy || spaceInfoBusy ? undefined : onEditAccounts}>
                  <SettingsItemRightContent>
                    <IconArrow />
                  </SettingsItemRightContent>
                </SettingsItem>
              </View>
              <SectionHeader horizontalMargins verticalMargins>
                Members
              </SectionHeader>
              <View style={styles.subContainer}>
                <EditSpaceItem title="Share space" subTitle={link} leftIcon={<SettingsItemIcon>
                      <IconLink color={colors.gradients.ultimate.colors[0]}/>
                    </SettingsItemIcon>} rightIcon={isWeb ? (<IconCopy fill={colors.elements.brand}/>) : (<ShareAction />)} onPress={(() => {
                if (removeBusy || spaceInfoBusy) {
                    return undefined;
                }
                return isWeb ? handleShareOnWeb : onShare;
            })()} copied={copied}/>
                {spaceInfo?.users?.map((it) => (<React.Fragment key={`${it.id}-${it.handle}`}>
                    <Spacer h={4}/>
                    <SpaceMemberItem spaceId={space?.id ?? -1} ownerId={space?.createdBy ?? -1} member={it} disabled={removeBusy || spaceInfoBusy} hasAccounts={spaceInfo.accounts &&
                    spaceInfo.accounts?.filter((acc) => it.id === acc.userId).length > 0} onRemoved={() => {
                    if (it.id === id) {
                        dispatch(fetchSpacesAction());
                        navigation.pop();
                    }
                    else
                        fetch();
                }}/>
                  </React.Fragment>))}
              </View>
            </View>)}
        </ScrollView>
      </AppView>);
}, ({ colors }) => ({
    title: 'Edit',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styleSet = createStyleSheets((colors) => ({
    subContainer: {
        backgroundColor: colors.cards.onDark,
        borderRadius: rem(16),
        marginHorizontal: rem(16),
        overflow: 'hidden',
    },
    content: {
        flex: 1,
        flexDirection: 'column',
        paddingVertical: rem(16),
    },
    hover: {
        padding: 8,
    },
}));
export default EditSpace;
