import React, { memo, useLayoutEffect, useMemo, useState } from 'react';
import Animated, { withTiming, useSharedValue, useAnimatedStyle, interpolate, Extrapolation, } from 'react-native-reanimated';
import { StyleSheet, View } from 'react-native';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import { CardView } from 'design-system/CardView';
import PageControl from 'design-system/PageControl';
import LineChart from 'design-system/LineChart/LineChart';
import ChartLoadingOverlay from 'design-system/LineChart/ChartLoadingOverlay';
import TimeFrameController from 'design-system/LineChart/TimeFrameController';
import { OVERVIEW_DEFAULT_PERIODS } from 'design-system/LineChart/useLineGraphData';
import useColors from 'hooks/useColors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import ChartOpacityOverlay from 'design-system/LineChart/ChartOpacityOverlay';
import UpgradeButton from 'design-system/UpgradeButton';
import OverviewPieChart from './OverviewPieChart';
import useOverviewChart from '../hooks/useOverviewChart';
import { ChartType } from '../types';
import GraphTypeSelector, { SELECTOR_HEIGHT } from './GraphTypeSelector';
import AccountsOverviewAnimatedTitle from './overview/AccountsOverviewAnimatedTitle';
import { isWeb } from '../../../constants';
export const GRAPH_HEIGHT = rem(330);
const CHART_HEIGHT = rem(272);
const ACTUAL_CHART_HEIGHT = CHART_HEIGHT - rem(24 * 2);
const PADDING_HORIZONTAL = rem(16);
const AccountsHeaderGraph = ({ title, scrollX, hidePagination, blurValues, onPressUpgrade, }) => {
    const navigation = useMainStackNavigation();
    const colors = useColors();
    const opacity = useSharedValue(1);
    const { width } = useAppFrameDimensions();
    const [renderGraph, setRenderGraph] = React.useState(false);
    const { pieData, chartType, requesting, setChartType, selectedPeriod, selectedInfoObj, selectedInfoObj2, portfolioDataSet, portfolioDataSet2, setSelectedPeriod, } = useOverviewChart(title);
    const isWebUnlocked = isWeb &&
        (title === 'Net Worth - Main' ||
            title === 'Net Worth - Asset' ||
            title === 'Net Worth - Debt');
    const upgradeContainerStyle = useAnimatedStyle(() => blurValues && onPressUpgrade
        ? {
            opacity: isWebUnlocked
                ? 1
                : interpolate(scrollX.value, [width * 2.5, width * 3], [0, 1], Extrapolation.CLAMP),
        }
        : { opacity: 0 }, [blurValues, isWebUnlocked, onPressUpgrade, scrollX.value, width]);
    const graphContainerStyle = useAnimatedStyle(() => blurValues && onPressUpgrade
        ? {
            opacity: isWebUnlocked
                ? 0
                : interpolate(scrollX.value, [width * 2.25, width * 2.75], [1, 0], Extrapolation.CLAMP),
        }
        : {
            opacity: 1,
        }, [blurValues, isWebUnlocked, onPressUpgrade, scrollX.value, width]);
    const chartWidth = width - rem(32);
    const [selectedInfoObjState, setSelectedInfoObjState] = useState();
    const [selectedInfoObj2State, setSelectedInfoObj2State] = useState();
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTitle: () => (<AccountsOverviewAnimatedTitle title={title} scrollX={scrollX} chartType={chartType} selectedInfoObj={selectedInfoObj} selectedInfoObjState={isWeb ? selectedInfoObjState : undefined}/>),
            headerTitleAlign: 'center',
            headerTitleContainerStyle: styles.headerTitleContainer,
        });
    }, [chartType, scrollX, selectedInfoObj, selectedInfoObjState, title]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setRenderGraph(true);
            opacity.value = withTiming(0, { duration: 100 });
        }, 300);
        return () => {
            clearTimeout(timeout);
        };
    }, [opacity]);
    const points = useMemo(() => {
        if (portfolioDataSet[selectedPeriod]) {
            return portfolioDataSet[selectedPeriod];
        }
        return [];
    }, [portfolioDataSet, selectedPeriod]);
    const points2 = useMemo(() => {
        if (chartType === ChartType.assetVsDebt) {
            if (portfolioDataSet2[selectedPeriod]) {
                return portfolioDataSet2[selectedPeriod];
            }
            return [];
        }
        return undefined;
    }, [chartType, portfolioDataSet2, selectedPeriod]);
    const content = useMemo(() => {
        if (!renderGraph)
            return null;
        return (<View pointerEvents="box-none">
        <LineChart points={points} points2={points2} width={chartWidth} height={ACTUAL_CHART_HEIGHT} selectedInfoObj={selectedInfoObj} chartWrapperHeight={CHART_HEIGHT} selectedInfoObj2={selectedInfoObj2} paddingHorizontal={PADDING_HORIZONTAL} setSelectedInfoObj={setSelectedInfoObjState} setSelectedInfoObj2={setSelectedInfoObj2State}/>
      </View>);
    }, [
        chartWidth,
        points,
        points2,
        renderGraph,
        selectedInfoObj,
        selectedInfoObj2,
    ]);
    const pieChartContent = useMemo(() => (<View style={styles.pieChartContainer}>
        <OverviewPieChart title={title} data={pieData}/>
      </View>), [pieData, title]);
    const lineChartContent = useMemo(() => (<View style={styles.graphContainer}>
        <View style={styles.graphTop}>
          <View style={styles.chartContainer}>{content}</View>
          <View style={styles.flex}/>
          <ChartOpacityOverlay opacitySharedValue={opacity}/>
          <ChartLoadingOverlay requesting={requesting}/>
        </View>
        <TimeFrameController selectedPeriod={selectedPeriod} periods={OVERVIEW_DEFAULT_PERIODS} setSelectedPeriod={setSelectedPeriod} paddingHorizontal={PADDING_HORIZONTAL}/>
      </View>), [content, opacity, requesting, selectedPeriod, setSelectedPeriod]);
    const chart = useMemo(() => {
        switch (chartType) {
            case ChartType.pie: {
                return pieChartContent;
            }
            default: {
                return lineChartContent;
            }
        }
    }, [chartType, pieChartContent, lineChartContent]);
    return (<>
      <CardView noMarginBottom>
        <Animated.View style={graphContainerStyle} pointerEvents={blurValues &&
            (title === 'Net Worth - Main' ||
                title === 'Net Worth - Asset' ||
                title === 'Net Worth - Debt')
            ? 'none'
            : undefined}>
          <GraphTypeSelector title={title} type={chartType} setType={setChartType} selectedInfoObj={selectedInfoObj} selectedInfoObjState={selectedInfoObjState} selectedInfoObj2={selectedInfoObj2} selectedInfoObj2State={selectedInfoObj2State} blurValues={blurValues}/>
          {chart}
        </Animated.View>
        {blurValues &&
            onPressUpgrade &&
            (title === 'Net Worth - Main' ||
                title === 'Net Worth - Asset' ||
                title === 'Net Worth - Debt') &&
            (!isWeb || !requesting) && (<Animated.View style={[upgradeContainerStyle, styles.upgradeContainer]}>
              <UpgradeButton text="Net worth is available for Emma Pro and Ultimate customers. Get started today by learning more." onCard onPress={onPressUpgrade}/>
            </Animated.View>)}
      </CardView>
      {!isWeb && scrollX && !hidePagination && (<View style={styles.flex}>
          <PageControl finite inverted length={6} scrollX={scrollX} style={styles.indicator} backgroundColor={colors.background.dark}/>
        </View>)}
      {(!!hidePagination || isWeb) && <Spacer h={16}/>}
    </>);
};
const styles = StyleSheet.create({
    headerTitleContainer: {
        width: '100%',
        alignItems: 'center',
    },
    graphContainer: {
        paddingBottom: rem(2),
        height: GRAPH_HEIGHT + SELECTOR_HEIGHT,
    },
    indicator: {
        marginTop: rem(16),
        marginBottom: rem(16),
    },
    flex: {
        flex: 1,
    },
    chartContainer: {
        height: CHART_HEIGHT,
    },
    graphTop: {
        paddingTop: SELECTOR_HEIGHT,
        height: CHART_HEIGHT + SELECTOR_HEIGHT,
    },
    pieChartContainer: {
        paddingTop: SELECTOR_HEIGHT,
        height: rem(330) + SELECTOR_HEIGHT,
    },
    upgradeContainer: {
        ...StyleSheet.absoluteFillObject,
        justifyContent: 'center',
        paddingHorizontal: rem(32),
    },
});
export const TOTAL_CHART_HEIGHT = GRAPH_HEIGHT + SELECTOR_HEIGHT + rem(52);
export default memo(AccountsHeaderGraph);
