import React from 'react';
import { View, StyleSheet } from 'react-native';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import { isWeb } from '../../../constants';
const TapableCard = ({ title, subtitle, IconComponent, onPress, }) => {
    const colors = useColors();
    return (<TouchableHighlight hoverOnDark onPress={onPress} underlayColor={colors.background.underlay} activeOpacity={1} style={styles.containerHighlight}>
      <View style={styles.container}>
        <IconComponent />
        <View style={styles.infoContainer}>
          <Text Text-16>{title}</Text>
          {subtitle && (<Text TextThin-14 Gray>
              {subtitle}
            </Text>)}
        </View>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    containerHighlight: {
        ...(isWeb && {
            borderRadius: 16,
        }),
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: rem(16),
        paddingHorizontal: rem(isWeb ? 8 : 16),
    },
    infoContainer: {
        flex: 1,
        marginLeft: rem(16),
    },
});
export default TapableCard;
