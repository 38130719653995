import equal from 'fast-deep-equal/es6';
import _, { isEqual } from 'lodash';
import { LOGGED_OUT } from 'actions/types';
import { sortAccordingToTimestamp } from 'utils/sort';
import { filterActivities } from '../utils';
import { SellOrderType, DividendSubType, } from '../types';
import { getAnalystDetails, getParsedAnalystData } from '../utils/format';
import { GET_ACTIVITIES_SUCCESS, REMOVE_SYMBOL_TO_FETCH_REAL_TIME_PRICES, SET_SYMBOL_TO_FETCH_REAL_TIME_PRICES, } from '../actions/types';
import { ALL_STOCKS_KEY, stockScreenDefaultEventTypes } from '../constants';
import { getEventsNearToToday } from './selectors';
export const initialState = {
    filledDayTrades: undefined,
    pendingDayTrades: undefined,
    recurringBuys: [],
    recurringBuysForAssetId: {},
    historyForRecurringBuyId: {},
    pendingStockAlerts: {},
    isErrorInFetchingInvestAcc: undefined,
    stockMarketPerformanceInfoArr: undefined,
};
export const removeFirstOccurrenceOfSymbol = (arr, symbol) => {
    if (arr?.length) {
        const index = arr.findIndex((el) => el === symbol);
        if (index !== -1) {
            return [...arr.slice(0, index), ...arr.slice(index + 1)];
        }
    }
    return arr;
};
const unpersistedInvestReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INVEST_GET_COMPACT_TRADING_ACTIVITY_SUCCESS': {
            return {
                ...state,
                compactActivityForAssetId: {
                    ...state.compactActivityForAssetId,
                    [action.extra.assetId]: action.payload.activities,
                },
            };
        }
        case GET_ACTIVITIES_SUCCESS: {
            const { payload } = action;
            const newCache = {
                ...state.activityIdForSymbolCache,
            };
            const activityCache = {
                ...state.activityCache,
            };
            let i = 0;
            payload.activities.forEach((activity) => {
                const { id } = activity;
                if ('symbol' in activity) {
                    const { status } = activity;
                    if (status !== 'failed' && status !== 'pending') {
                        i += 1;
                        const { symbol } = activity;
                        const cacheForSymbol = newCache[symbol];
                        if (cacheForSymbol) {
                            // prevent duplicates
                            if (cacheForSymbol.findIndex((x) => x === id) === -1) {
                                cacheForSymbol.push(id);
                            }
                        }
                        else {
                            newCache[symbol] = [id];
                        }
                        activityCache[id] = activity;
                    }
                }
            });
            if (i > 0) {
                const newActivityIdForSymbolCache = {
                    ...state.activityIdForSymbolCache,
                    ...newCache,
                };
                if (!equal(activityCache, state.activityCache) ||
                    !equal(newActivityIdForSymbolCache, state.activityIdForSymbolCache)) {
                    return {
                        ...state,
                        activityCache,
                        activityIdForSymbolCache: newActivityIdForSymbolCache,
                    };
                }
            }
            return state;
        }
        case SET_SYMBOL_TO_FETCH_REAL_TIME_PRICES: {
            return {
                ...state,
                realTimePricesSymbols: [...(state.realTimePricesSymbols || []), action.symbol],
            };
        }
        case REMOVE_SYMBOL_TO_FETCH_REAL_TIME_PRICES: {
            return {
                ...state,
                realTimePricesSymbols: removeFirstOccurrenceOfSymbol(state.realTimePricesSymbols, action.symbol),
            };
        }
        case 'INVEST_GET_CORPORATE_EVENTS_REQUEST': {
            if (action.extra.calendarKey === 'portfolio') {
                const portfolioObj = state.investmentCalendar?.portfolio;
                return {
                    ...state,
                    loadingPortfolioCalendar: true,
                    portfolioCalendarFilter: action.extra.types,
                    loadingPortfolioCalendarToken: action.extra.futureToken,
                    ...((action.extra.types || (!action.extra.types && state.portfolioCalendarFilter)) &&
                        !action.extra.futureToken &&
                        portfolioObj && {
                        investmentCalendar: {
                            ...state.investmentCalendar,
                            portfolio: {
                                ...portfolioObj,
                                events: [],
                                futureToken: '',
                            },
                        },
                    }),
                };
            }
            const dataObj = state.investmentCalendar?.[action.extra.calendarKey];
            return {
                ...state,
                calendarFilterForAssetId: action.extra.types,
                loadingCalendarForAssetId: action.extra.calendarKey,
                loadingCalendarForAssetToken: action.extra.futureToken,
                ...(!isEqual(action.extra.types, state.calendarFilterForAssetId) &&
                    !action.extra.futureToken &&
                    dataObj && {
                    investmentCalendar: {
                        ...state.investmentCalendar,
                        [action.extra.calendarKey]: {
                            ...dataObj,
                            events: [],
                            futureToken: '',
                        },
                    },
                }),
            };
        }
        case 'INVEST_GET_CORPORATE_EVENTS_FAILURE': {
            if (action.extra.calendarKey === 'portfolio' &&
                action.extra.futureToken === state.loadingPortfolioCalendarToken &&
                _.isEqual(action.extra.types, state.portfolioCalendarFilter)) {
                return {
                    ...state,
                    loadingPortfolioCalendar: false,
                };
            }
            if (action.extra.calendarKey === state.loadingCalendarForAssetId &&
                action.extra.futureToken === state.loadingCalendarForAssetToken &&
                _.isEqual(action.extra.types, state.calendarFilterForAssetId)) {
                return {
                    ...state,
                    loadingCalendarForAssetId: undefined,
                    loadingCalendarForAssetToken: undefined,
                };
            }
            return state;
        }
        case 'INVEST_GET_CORPORATE_EVENTS_SUCCESS': {
            const parsedEvents = action.payload.events.map((event) => {
                if (event.type === 'DIVIDEND' &&
                    (event.subType === DividendSubType.EX_CASH || event.subType === DividendSubType.EX_STOCK)) {
                    return {
                        ...event,
                        type: 'EX_DIVIDEND',
                    };
                }
                return event;
            });
            const usedFutureToken = action.extra.futureToken;
            if (action.extra.calendarKey === 'portfolio' &&
                action.extra.futureToken === state.loadingPortfolioCalendarToken &&
                _.isEqual(action.extra.types, state.portfolioCalendarFilter)) {
                const currObj = state.investmentCalendar?.portfolio;
                return {
                    ...state,
                    investmentCalendar: {
                        ...state.investmentCalendar,
                        portfolio: {
                            events: usedFutureToken ? [...(currObj?.events || []), ...parsedEvents] : parsedEvents,
                            futureToken: action.payload.futureToken,
                            eventsForSection: usedFutureToken || action.extra.types
                                ? currObj?.eventsForSection || []
                                : getEventsNearToToday(parsedEvents, true),
                        },
                    },
                    loadingPortfolioCalendar: false,
                    loadingPortfolioCalendarToken: undefined,
                };
            }
            if (state.loadingCalendarForAssetId === action.extra.calendarKey &&
                action.extra.futureToken === state.loadingCalendarForAssetToken &&
                _.isEqual(action.extra.types, state.calendarFilterForAssetId)) {
                const currObj = state.investmentCalendar?.[action.extra.calendarKey];
                return {
                    ...state,
                    investmentCalendar: {
                        ...state.investmentCalendar,
                        [action.extra.calendarKey]: {
                            futureToken: action.payload.futureToken,
                            eventsForSection: !usedFutureToken && isEqual(action.extra.types, stockScreenDefaultEventTypes)
                                ? getEventsNearToToday(parsedEvents, false)
                                : currObj?.eventsForSection || [],
                            events: usedFutureToken ? [...(currObj?.events || []), ...parsedEvents] : parsedEvents,
                        },
                    },
                    loadingCalendarForAssetId: undefined,
                    loadingCalendarForAssetToken: undefined,
                };
            }
            return state;
        }
        case 'INVEST_GET_STOCK_MARKET_PERFORMANCE_SUCCESS': {
            return {
                ...state,
                stockMarketPerformanceInfoArr: action.payload.stocks,
            };
        }
        case 'GET_ORDERS_REQUEST': {
            // case for limit/stop orders
            if (action.extra.orderTypes.includes(SellOrderType.LIMIT) ||
                action.extra.orderTypes.includes(SellOrderType.STOP)) {
                // case for portfolio list
                if (!action.extra.assetIds?.length) {
                    return {
                        ...state,
                        requestingLimitStopOrders: true,
                        requestingLimitStopOrdersPaginationToken: action.extra.paging?.token,
                    };
                }
                // case for list for a stock
                return {
                    ...state,
                    requestingLimitStopOrdersForAssetId: true,
                    requestingLimitStopOrdersAssetId: action.extra.assetIds[0],
                    requestingLimitStopOrdersPaginationTokenForAssetId: action.extra.paging?.token,
                };
            }
            // case for portfolio list
            if (!action.extra.assetIds?.length) {
                return {
                    ...state,
                    requestingMarketOrders: true,
                    requestingMarketOrdersPaginationToken: action.extra.paging?.token,
                };
            }
            // case for list for a stock
            return {
                ...state,
                requestingMarketOrdersForAssetId: true,
                requestingMarketOrdersAssetId: action.extra.assetIds[0],
                requestingMarketOrdersPaginationTokenForAssetId: action.extra.paging?.token,
            };
        }
        case 'GET_ORDERS_SUCCESS': {
            // case for limit/stop orders
            if (action.extra.orderTypes.includes(SellOrderType.LIMIT) ||
                action.extra.orderTypes.includes(SellOrderType.STOP)) {
                // case for portfolio list
                if (!action.extra.assetIds?.length) {
                    return {
                        ...state,
                        requestingLimitStopOrders: false,
                        requestingLimitStopOrdersPaginationToken: action.payload.paging.next,
                    };
                }
                // case for list for a stock
                return {
                    ...state,
                    requestingLimitStopOrdersForAssetId: false,
                    requestingLimitStopOrdersPaginationTokenForAssetId: action.payload.paging.next,
                };
            }
            // case for portfolio list
            if (!action.extra.assetIds?.length) {
                return {
                    ...state,
                    requestingMarketOrders: false,
                    requestingMarketOrdersPaginationToken: action.payload.paging.next,
                };
            }
            // case for list for a stock
            return {
                ...state,
                requestingMarketOrdersForAssetId: false,
                requestingMarketOrdersPaginationTokenForAssetId: action.payload.paging.next,
            };
        }
        case 'GET_ORDERS_FAILURE': {
            // case for limit/stop orders
            if (action.extra.orderTypes.includes(SellOrderType.LIMIT) ||
                action.extra.orderTypes.includes(SellOrderType.STOP)) {
                // case for portfolio list
                if (!action.extra.assetIds?.length) {
                    return {
                        ...state,
                        requestingLimitStopOrders: false,
                    };
                }
                // case for list for a stock
                return {
                    ...state,
                    requestingLimitStopOrdersForAssetId: false,
                };
            }
            // case for portfolio list
            if (!action.extra.assetIds?.length) {
                return {
                    ...state,
                    requestingMarketOrders: false,
                };
            }
            // case for list for a stock
            return {
                ...state,
                requestingMarketOrdersForAssetId: false,
            };
        }
        case 'INVEST_GET_ANALYST_INFO_SUCCESS_ACTION': {
            const analysisInfo = getParsedAnalystData(action.payload.analystInfo);
            if (analysisInfo) {
                return {
                    ...state,
                    analystInfoMap: {
                        ...state.analystInfoMap,
                        [action.extra.symbol]: analysisInfo,
                    },
                };
            }
            return state;
        }
        case 'INVEST_GET_TRIGGERED_STOCK_ALERTS_REQUEST_ACTION': {
            return {
                ...state,
                requestingTriggeredStockAlerts: true,
                triggeredStockAlertsPaginationAssetId: action.extra.assetId,
                triggeredStockAlertsPaginationToken: action.extra.paginationToken,
            };
        }
        case 'INVEST_GET_TRIGGERED_STOCK_ALERTS_SUCCESS_ACTION': {
            if (state.triggeredStockAlertsPaginationAssetId === action.extra.assetId) {
                return {
                    ...state,
                    triggeredStockAlerts: {
                        ...state.triggeredStockAlerts,
                        [action.extra.assetId]: action.payload.paging.page === 1
                            ? action.payload.alerts
                            : [...(state.triggeredStockAlerts?.[action.extra.assetId] || []), ...action.payload.alerts],
                    },
                    triggeredStockAlertsPaginationToken: action.payload.paging?.next,
                    requestingTriggeredStockAlerts: false,
                };
            }
            return state;
        }
        case 'INVEST_GET_TRIGGERED_STOCK_ALERTS_FAILURE_ACTION': {
            if (action.extra.assetId === state.triggeredStockAlertsPaginationAssetId) {
                return {
                    ...state,
                    requestingTriggeredStockAlerts: false,
                };
            }
            return state;
        }
        case 'INVEST_GET_STOCK_ALERTS_REQUEST_ACTION': {
            if (action.extra.assetId === ALL_STOCKS_KEY) {
                return {
                    ...state,
                    requestingAllPendingStockAlerts: true,
                    allPendingStockAlertsPaginationToken: action.extra.paginationToken,
                };
            }
            return {
                ...state,
                requestingPendingStockAlerts: true,
                pendingStockAlertsPaginationAssetId: action.extra.assetId,
                pendingStockAlertsPaginationToken: action.extra.paginationToken,
            };
        }
        case 'INVEST_GET_STOCK_ALERTS_FAILURE_ACTION': {
            if (action.extra.assetId === ALL_STOCKS_KEY) {
                return {
                    ...state,
                    requestingAllPendingStockAlerts: false,
                };
            }
            if (action.extra.assetId === state.pendingStockAlertsPaginationAssetId) {
                return {
                    ...state,
                    requestingPendingStockAlerts: false,
                };
            }
            return state;
        }
        case 'INVEST_GET_STOCK_ALERTS_SUCCESS_ACTION': {
            if (action.extra.assetId === ALL_STOCKS_KEY) {
                return {
                    ...state,
                    pendingStockAlerts: {
                        ...state.pendingStockAlerts,
                        [action.extra.assetId]: action.payload.paging.page === 1
                            ? action.payload.alerts
                            : [...(state.pendingStockAlerts?.[action.extra.assetId] || []), ...action.payload.alerts],
                    },
                    requestingAllPendingStockAlerts: false,
                    allPendingStockAlertsPaginationToken: action.payload.paging?.next,
                };
            }
            if (state.pendingStockAlertsPaginationAssetId === action.extra.assetId) {
                return {
                    ...state,
                    pendingStockAlerts: {
                        ...state.pendingStockAlerts,
                        [action.extra.assetId]: action.payload.paging.page === 1
                            ? action.payload.alerts
                            : [...(state.pendingStockAlerts?.[action.extra.assetId] || []), ...action.payload.alerts],
                    },
                    requestingPendingStockAlerts: false,
                    pendingStockAlertsPaginationToken: action.payload.paging?.next,
                };
            }
            return state;
        }
        case 'INVEST_EDIT_STOCK_ALERT_SUCCESS_ACTION': {
            return {
                ...state,
                pendingStockAlerts: {
                    ...state.pendingStockAlerts,
                    [action.extra.assetId]: (state.pendingStockAlerts?.[action.extra.assetId] || []).map((alert) => {
                        if (alert.alertId === action.extra.id) {
                            return action.payload.alert;
                        }
                        return alert;
                    }),
                    ALL_STOCKS: (state.pendingStockAlerts?.[ALL_STOCKS_KEY] || []).map((alert) => {
                        if (alert.alertId === action.extra.id) {
                            return action.payload.alert;
                        }
                        return alert;
                    }),
                },
            };
        }
        case 'INVEST_CREATE_STOCK_ALERT_SUCCESS_ACTION': {
            return {
                ...state,
                pendingStockAlerts: {
                    ...state.pendingStockAlerts,
                    [action.extra.assetId]: [action.payload.alert, ...(state.pendingStockAlerts?.[action.extra.assetId] || [])],
                    ALL_STOCKS: [action.payload.alert, ...(state.pendingStockAlerts?.[ALL_STOCKS_KEY] || [])],
                },
            };
        }
        case 'INVEST_DELETE_STOCK_ALERT_SUCCESS': {
            const newAllStocksArr = state.pendingStockAlerts?.[ALL_STOCKS_KEY]?.filter((stockAlert) => stockAlert.alertId !== action.extra.alertId);
            const assetIdArr = state.pendingStockAlerts?.[action.extra.assetId]?.filter((stockAlert) => stockAlert.alertId !== action.extra.alertId);
            return {
                ...state,
                pendingStockAlerts: {
                    ...state.pendingStockAlerts,
                    ALL_STOCKS: newAllStocksArr,
                    [action.extra.assetId]: assetIdArr,
                },
            };
        }
        case 'GET_ANALYST_RATINGS_DETAILS_SUCCESS_ACTION': {
            const analystDetails = getAnalystDetails(action.payload.analystInfo);
            return {
                ...state,
                analystInfoDetailsMap: {
                    ...state.analystInfoDetailsMap,
                    [action.payload.symbol]: analystDetails,
                },
            };
        }
        case 'CACHE_ACCOUNTS':
        case 'CACHE_TRADING_ACCOUNT':
        case 'GET_TRADING_ACCOUNT_SUCCESS': {
            return {
                ...state,
                isErrorInFetchingInvestAcc: false,
            };
        }
        case 'GET_PATTERN_DAY_TRADING_INFO_SUCCESS': {
            return {
                ...state,
                filledDayTrades: action.payload.filledDayTrades,
                pendingDayTrades: action.payload.pendingDayTrades,
            };
        }
        case 'GET_PATTERN_DAY_TRADING_INFO_FAILURE': {
            return {
                ...state,
                filledDayTrades: undefined,
                pendingDayTrades: undefined,
            };
        }
        case 'GET_TRADING_ACCOUNT_FAILURE': {
            return {
                ...state,
                isErrorInFetchingInvestAcc: true,
            };
        }
        case 'GET_RECURRING_BUYS_SUCCESS': {
            if (action.extra) {
                return {
                    ...state,
                    recurringBuysForAssetId: {
                        ...state.recurringBuysForAssetId,
                        [action.extra]: action.payload.recurringBuys,
                    },
                };
            }
            return {
                ...state,
                recurringBuys: action.payload.recurringBuys,
            };
        }
        case 'DELETE_RECURRING_BUY_SUCCESS': {
            const { id, assetId } = action.extra;
            return {
                ...state,
                recurringBuys: state.recurringBuys?.filter((recurringBuy) => recurringBuy.id !== id),
                recurringBuysForAssetId: {
                    ...state.recurringBuysForAssetId,
                    [assetId]: state.recurringBuysForAssetId?.[assetId]?.filter((recurringBuy) => recurringBuy.id !== id) || [],
                },
            };
        }
        case 'UPDATE_RECURRING_BUY_SUCCESS': {
            const { recurringBuy } = action.payload;
            return {
                ...state,
                recurringBuys: state.recurringBuys?.map((oldRecurringBuy) => {
                    if (oldRecurringBuy.id === recurringBuy.id) {
                        return recurringBuy;
                    }
                    return oldRecurringBuy;
                }),
                recurringBuysForAssetId: {
                    ...state.recurringBuysForAssetId,
                    [recurringBuy.assetId]: state.recurringBuysForAssetId?.[recurringBuy.assetId]?.map((oldRecurringBuy) => {
                        if (oldRecurringBuy.id === recurringBuy.id) {
                            return recurringBuy;
                        }
                        return oldRecurringBuy;
                    }) || [],
                },
            };
        }
        case 'GET_RECURRING_BUY_ACTIVITIES_SUCCESS': {
            const { id } = action.extra;
            const { activities, paging } = action.payload;
            const filteredActivities = activities.filter(filterActivities);
            if (paging.page === 1) {
                return {
                    ...state,
                    historyForRecurringBuyId: {
                        ...state.historyForRecurringBuyId,
                        [id]: filteredActivities,
                    },
                };
            }
            return {
                ...state,
                historyForRecurringBuyId: {
                    ...state.historyForRecurringBuyId,
                    [id]: _.uniqBy(state.historyForRecurringBuyId?.[id]?.concat(filteredActivities) ?? filteredActivities, (i) => i.id).sort(sortAccordingToTimestamp),
                },
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
export default unpersistedInvestReducer;
