import React, { memo, useCallback } from 'react';
import { View, Linking, FlatList, StyleSheet, } from 'react-native';
import emoji from 'node-emoji';
import { rem } from 'design-system/values';
import SectionHeader from 'design-system/SectionHeader';
import { IconDocument } from 'design-system/icons/IconDocument';
import { SettingsItemNavigate } from 'design-system/SettingsItem';
import UploadReceiptModal from 'design-system/UploadReceiptModal.web';
import Alert from 'utils/packages/Alert';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteReceipt, uploadTransactionReceipts } from 'actions/transactions';
import { selectTransactionReceiptsById } from 'features/transactions/selectors';
import { formatPlural } from 'utils';
import { getReceiptTransactionId } from 'reducers/unpersistedTransactions';
import TransactionReceiptBox from './TransactionReceiptBox';
import TransactionReceiptBoxAdd from './TransactionReceiptBoxAdd';
const keyExtractor = (receipt) => receipt.id.toString();
const TransactionReceiptItem = ({ transaction }) => {
    const dispatch = useAppDispatch();
    const receipts = useAppSelector((store) => selectTransactionReceiptsById(store, getReceiptTransactionId(transaction.id, false)));
    const onImagesGot = useCallback((files) => {
        if (files.length) {
            const body = new FormData();
            files.forEach((file) => {
                body.append('image', file.data);
            });
            dispatch(uploadTransactionReceipts(transaction.id, body, files.map((file) => ({
                uri: file.selectedImg,
                name: file.data.path,
            }))));
        }
    }, [transaction.id]);
    const uploadReceipt = useCallback(() => {
        UploadReceiptModal.show(onImagesGot);
    }, [onImagesGot]);
    const pressedDelete = useCallback((data, index) => {
        Alert.alert(emoji.emojify('Delete Receipt :face_with_rolling_eyes:'), "Are you sure you want to delete this receipt? You won't be able to get it back.", [
            {
                text: 'Yes',
                style: 'destructive',
                onPress: () => dispatch(deleteReceipt(data, transaction.id, index)),
            },
            { text: 'Cancel', style: 'cancel', onPress: () => { } },
        ]);
    }, [transaction.id]);
    const renderItem = useCallback(({ item, index }) => (<TransactionReceiptBox data={item} onPressDelete={() => pressedDelete(item, index)} onPressImage={() => {
            const url = item?.url;
            if (url) {
                Linking.openURL(url);
            }
        }}/>), [pressedDelete]);
    const renderHeader = useCallback(() => <TransactionReceiptBoxAdd onPressAdd={uploadReceipt}/>, [uploadReceipt]);
    if (!receipts?.length) {
        return (<View>
        <SettingsItemNavigate title="Receipt" icon={<IconDocument />} onPress={uploadReceipt}/>
      </View>);
    }
    return (<View>
      <SectionHeader style={styles.header}>
        {formatPlural('Receipt', 'Receipts', receipts.length)}
      </SectionHeader>
      <FlatList horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={styles.scroll} keyExtractor={keyExtractor} renderItem={renderItem} data={receipts} ListHeaderComponent={renderHeader}/>
    </View>);
};
const styles = StyleSheet.create({
    scroll: {
        flexDirection: 'row',
        paddingTop: rem(4),
        paddingBottom: rem(24),
        paddingLeft: rem(16),
        paddingRight: rem(16),
    },
    header: {
        marginLeft: rem(16),
        marginTop: rem(16),
    },
});
export default memo(TransactionReceiptItem);
