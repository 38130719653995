import React, { useCallback, useMemo } from 'react';
import { CardView } from 'design-system/CardView';
import Section from 'design-system/Section';
import { useAppDispatch } from 'store/hooks';
import { uploadTransactionReceipts } from 'actions';
import TransactionTagsItem from './TransactionTagsItem';
import TransactionNotesItem from './TransactionNotesItem';
import TransactionSplitItem from './TransactionSplitItem';
import TransactionDeleteItem from './TransactionDeleteItem';
import TransactionHelpSection from './TransactionHelpSection';
import TransactionReceiptItem from './TransactionReceiptItem';
import TransactionCashbackSection from './TransactionCashbackSection';
import TransactionShareSection from './TransactionShareSection';
import TransactionExcludedSection from './TransactionExcludedSection';
import TransactionOriginalDateItem from './TransactionOriginalDateItem';
import TransactionSubscriptionSection from './TransactionSubscriptionSection';
import TransactionMerchantHistorySection from './TransactionMerchantHistorySection';
import TransactionLinkedTransactionSection from './TransactionLinkedTransactionSection';
import TransactionOriginalTransactionSection from './TransactionOriginalTransactionSection';
import { styles } from './styles';
import { isWeb } from '../../../../constants';
const TransactionDetailContent = ({ transaction, paymentActivities, onRemovePaymentActivity, reward, }) => {
    const dispatch = useAppDispatch();
    const onUploadReceipt = useCallback((transactionId, body, files) => dispatch(uploadTransactionReceipts(transactionId, body, files)), []);
    const UpperContent = useMemo(() => (<>
        <TransactionOriginalDateItem transaction={transaction}/>
        <TransactionTagsItem transaction={transaction}/>
        <TransactionNotesItem transaction={transaction}/>
        <TransactionReceiptItem transaction={transaction} onUpload={onUploadReceipt}/>
        <TransactionSplitItem transaction={transaction}/>
        <TransactionDeleteItem transaction={transaction}/>
      </>), [onUploadReceipt, transaction]);
    return (<>
      {isWeb ? (<Section title="Details" style={styles.section}>
          <CardView noMarginTop noMarginBottom webPadding>
            {UpperContent}
          </CardView>
        </Section>) : (<Section>
          <CardView noMarginTop style={styles.topCard}>
            {UpperContent}
          </CardView>
        </Section>)}
      {!isWeb && (<>
          <TransactionCashbackSection reward={reward}/>
          <TransactionShareSection transaction={transaction} paymentActivities={paymentActivities} onRemove={onRemovePaymentActivity}/>
        </>)}
      <TransactionLinkedTransactionSection transaction={transaction}/>
      <TransactionOriginalTransactionSection transaction={transaction}/>
      <TransactionSubscriptionSection transaction={transaction}/>
      <TransactionMerchantHistorySection transaction={transaction}/>
      <TransactionExcludedSection transaction={transaction}/>
      <TransactionHelpSection transaction={transaction}/>
    </>);
};
export default TransactionDetailContent;
