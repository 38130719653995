import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import InteractivePieChart from 'design-system/InteractivePieChart';
import DropShadow from 'react-native-drop-shadow';
import Text from 'design-system/Text';
import useSelectedCategory from 'features/analytics/hooks/useSelectedCategory';
import { runOnJS, useDerivedValue } from 'react-native-reanimated';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useCategories, useCustomCategoryColorsMap } from 'hooks/useCategory';
import Amount from 'design-system/Amount';
import BlurAmountView from 'design-system/BlurAmountView';
import { rem } from 'design-system/values';
const CategoryPieChart = ({ spending, currency, monthlyCategories, isInFocus, blurValue, }) => {
    const categoriesDisplayConfig = useCategories();
    const customCategoryColorsMap = useCustomCategoryColorsMap();
    const pieSlices = useMemo(() => {
        const monthlyCategoriesFrom = monthlyCategories?.categories || [];
        const newPieSlices = monthlyCategoriesFrom.map((mc) => ({
            key: mc.id,
            value: mc.total > 0 ? 0 : Math.abs(mc.total),
            selectedColor: categoriesDisplayConfig[mc.id]?.color ||
                customCategoryColorsMap[mc.color]?.[0] ||
                mc.color,
            category: mc,
        }));
        return newPieSlices;
    }, [
        categoriesDisplayConfig,
        customCategoryColorsMap,
        monthlyCategories?.categories,
    ]);
    const styles = useStyles(styleSet);
    const { selectedCategoryId, selectCategoryId } = useSelectedCategory();
    useDerivedValue(() => {
        if (isInFocus.value && !selectedCategoryId.value) {
            const biggestSlice = pieSlices.reduce((previousValue, currentValue) => {
                if (!previousValue || currentValue.value > previousValue?.value) {
                    return currentValue;
                }
                return previousValue;
            }, undefined);
            if (biggestSlice && biggestSlice.value > 0) {
                runOnJS(selectCategoryId)(biggestSlice.key);
            }
        }
    }, [isInFocus, pieSlices, selectedCategoryId]);
    const onSelectPieSlice = useCallback((pieSlice) => {
        selectCategoryId(pieSlice.key);
    }, [selectCategoryId]);
    return (<View>
      <InteractivePieChart pieSlices={pieSlices} onSelectPieSlice={onSelectPieSlice} selectedPieSliceKey={selectedCategoryId}>
        <DropShadow style={styles.circleShadow}>
          <View style={styles.circle}>
            <BlurAmountView enabled={blurValue}>
              <Amount Numbers-24 value={{ value: spending, currency }}/>
            </BlurAmountView>
            <Text NumbersThin-14 Gray>
              Total Spent
            </Text>
          </View>
        </DropShadow>
      </InteractivePieChart>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    circleShadow: {
        ...colors.shadows.floatingButton,
    },
    circle: {
        width: rem(150),
        height: rem(150),
        alignItems: 'center',
        borderRadius: rem(150),
        justifyContent: 'center',
        backgroundColor: colors.cards.onDark,
    },
}));
export default React.memo(CategoryPieChart);
