import { createSelector } from 'reselect';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import { assertUnreachable } from 'utils/types';
import { Tier } from 'features/premium/entities';
import { createNonMemoizedSelector } from 'store/selectors';
import { addLastInSectionInfo } from 'utils/getTransactions';
import { selectUserTier } from 'features/premium/selectors/tier';
import { formatAmount, formatDateForSectionHeader } from 'utils/formatting';
import { selectIsInvestWalkthroughActive } from 'reducers/selectors/walkthrough';
import { SellOrderType, AssetFilterCategory, } from '../types';
import * as debug from '../debug';
import { ALL_STOCKS_KEY } from '../constants';
import { formatNumberOfShares } from '../utils';
import { filterDataByKey } from '../utils/format';
export const activitySelector = createSelector((state, symbol) => symbol ? state.unpersistedInvestReducer.activityIdForSymbolCache?.[symbol] : undefined, (state) => state.unpersistedInvestReducer.activityCache, (activityIdsForSymbolCache, activityCache) => {
    if (!activityIdsForSymbolCache) {
        return undefined;
    }
    const result = [];
    const idSet = new Set(); // to avoid duplicate activities
    activityIdsForSymbolCache.forEach((activityId) => {
        if (!idSet.has(activityId)) {
            const activity = activityCache?.[activityId];
            if (activity) {
                result.push(activity);
                idSet.add(activity.id);
            }
            else if (__DEV__) {
                // eslint-disable-next-line no-console
                console.warn('Activity is not cached');
            }
        }
    });
    return result;
});
export const selectAccountStatus = (state) => debug.fakeStatus || (state.invest.account ? state.invest.account.status : state.invest.status);
export function selectWatchlists(state) {
    return state.invest.watchlists;
}
export const selectRecurringBuys = (state) => state.unpersistedInvestReducer.recurringBuys;
/**
 * Selects the array of symbols that are top trending during the current session.
 */
export const selectTopTrending = (state) => state.invest.topTrending;
export const selectFirstTopTrendingStockForInvestWalkthrough = createSelector((state) => state.invest.topTrending, selectIsInvestWalkthroughActive, (topTrending, isInvestWalkthroughActive) => {
    if (!topTrending.length && isInvestWalkthroughActive) {
        return 'AAPL';
    }
    return topTrending[0];
});
/**
 * Selects the array of symbols that are most watched during the current session.
 */
export const selectMostWatchedStocks = (state) => state.invest.mostWatchedStocks;
/**
 * Selects the array of symbols that are most held during the current session.
 */
export const selectMostHeldStocks = (state) => state.invest.mostHeldStocks;
/**
 * Selects the array of symbols that are newly added.
 */
export const selectNewlyAdded = (state) => state.invest.newlyAdded;
/**
 * Selects the array of symbols that fell down in price the most during the current session.
 */
export const selectLosers = (state) => state.invest.losers;
/**
 * Selects the array of symbols that went up in price the most during the current session.
 */
export const selectGainers = (state) => state.invest.gainers;
/**
 * Selects the watchListAssetCache from watchList reducer.
 */
export const selectWatchListAssetCache = (state) => state.watchList.watchListAssetCache;
/**
 * Selects the currentPriceCache from invest reducer.
 */
export const selectCurrentPriceCache = (state) => state.invest.currentPriceCache;
/**
 * Selects the currentPriceChangeCache from invest reducer.
 */
export const selectPriceCache = (state) => state.invest.priceChangeCache;
/**
 * Selects the array of AssetWithPrice that is sorted according to the selected watchList filter(AssetFilterCategory).
 */
export const selectAssetsForWatchListFilter = createSelector([
    selectWatchListAssetCache,
    (_state, watchListId, filter) => ({
        watchListId,
        filter,
    }),
    selectCurrentPriceCache,
    selectPriceCache,
], (watchListAssetCache, { filter, watchListId }, currentPrices, priceChanges) => {
    const watchListAssetsForWatchListId = watchListAssetCache?.[watchListId] || [];
    if (filter === AssetFilterCategory.NAME) {
        // watchListAssetCache is already sorted alphabetically by name
        return watchListAssetsForWatchListId;
    }
    const assets = [];
    watchListAssetsForWatchListId.forEach((asset) => {
        const currentPrice = currentPrices?.[asset.symbol];
        const priceChange = priceChanges?.[asset.symbol];
        if (currentPrice && priceChange) {
            assets.push({
                ...asset,
                sharePrice: currentPrice.value,
                sharePriceChange: priceChange.value,
                sharePriceChangePct: priceChange.percent,
            });
        }
    });
    if (filter === AssetFilterCategory.SYMBOL) {
        // sort by symbol
        assets.sort((a, b) => {
            if (a.symbol < b.symbol) {
                return -1;
            }
            if (a.symbol > b.symbol) {
                return 1;
            }
            return 0;
        });
        return assets;
    }
    if (filter === AssetFilterCategory.PRICE) {
        // sort by price
        assets.sort((a, b) => b.sharePrice - a.sharePrice);
        return assets;
    }
    if (filter === AssetFilterCategory.PRICE_CHANGE) {
        // sort by priceChangePct
        assets.sort((a, b) => b.sharePriceChangePct - a.sharePriceChangePct);
        return assets;
    }
    assertUnreachable(filter);
    return [];
});
export const selectSlicedLearnVideos = createSelector([
    (state) => state.utils.featureFlags,
    (_state, videoIndex) => videoIndex,
    (_state, _videoIndex, category) => category,
], (featureFlags, videoIndex, category) => {
    const learnVideos = featureFlags.learn_educational_content?.extra?.[category]?.videos;
    if (learnVideos?.length) {
        return learnVideos.slice(videoIndex, learnVideos.length);
    }
    return [];
});
/**
 * Selects the entire stock news cache - `{ [symbol]: News }`
 */
export const selectStockNews = (state) => state.investStockNews.cache;
/**
 * Selects array of news for given symbol, guaranteed to be unique by `id` key.
 */
export const selectStockNewsForSymbol = createSelector((state, symbol) => (symbol ? state.investStockNews.cache[symbol] : undefined), (state, _symbol, totalNumber) => totalNumber, (news, totalNumber) => {
    if (!news) {
        return [];
    }
    const result = Array.from(new Map(news.map((newsItem) => [newsItem.id, newsItem])).values());
    return totalNumber ? result.slice(0, totalNumber) : result;
});
/**
 * Simply selects ordersCache from invest reducer.
 */
export const selectOrdersCache = (state) => state.invest.ordersCache;
/**
 * Selects the ids or first 4 pending orders, which are being cached by Portfolio screen
 */
export const selectPendingOrderIds = (state) => state.invest.pendingOrderIdCache;
/**
 * Selects the ids or first 4 queued orders, which are being cached by Portfolio screen
 */
export const selectQueuedOrderIds = (state) => state.invest.orderIdCache;
export const selectPendingAndQueuedOrders = createSelector([selectOrdersCache, selectPendingOrderIds, selectQueuedOrderIds], (ordersCache, pendingOrderIdCache, queuedOrderIdCache) => {
    const queuedOrders = [];
    const pendingOrders = [];
    pendingOrderIdCache.forEach((id) => {
        const order = ordersCache[id];
        if (order) {
            if (order.status === 'pending' && order.orderType === SellOrderType.MARKET) {
                pendingOrders.push(order);
            }
        }
    });
    queuedOrderIdCache.forEach((id) => {
        const order = ordersCache[id];
        if (order) {
            if (order.status === 'queued' && order.orderType === SellOrderType.MARKET) {
                queuedOrders.push(order);
            }
        }
    });
    return {
        queuedOrders,
        pendingOrders,
    };
});
/**
 * Selects a boolean flag indicating that `/pending-top-ups` returned some pending topup payments.
 */
export const selectPendingTopupsFlag = (state) => Boolean(debug.emulatePendingTopups || state.invest.pendingTopupsFlag);
export const selectStaticCollections = createSelector([
    (state) => state.invest,
    (state) => state.collections,
    (_state, type) => type,
], (investStore, collectionsStore, type) => {
    switch (type) {
        case 'BIGGEST_GAINERS':
            return investStore.gainers || [];
        case 'BIGGEST_LOSERS':
            return investStore.losers || [];
        case 'HIGHEST_EARNERS':
            return collectionsStore.highEarners || [];
        case 'HIGHEST_NUMBER_OF_EMPLOYEES':
            return collectionsStore.biggestEmployers || [];
        case 'HIGHEST_DIVIDEND_YIELD':
            return collectionsStore.dividendYield || [];
        case 'HIGHEST_MARKET_CAP':
            return collectionsStore.highestMarketCap || [];
        case 'MOST_WATCHED':
            return collectionsStore.mostWatched || [];
        case 'TOP_TRENDING':
            return investStore.topTrending || [];
        case 'MOST_POPULAR':
            return investStore.mostHeldStocks || [];
        default:
            return [];
    }
});
export const selectSetupOnboardingList = createSelector([
    (_state, type) => type,
    (state) => state.invest.topTrending,
    (state) => state.invest.mostHeldStocks,
    (state) => state.invest.mostWatchedStocks,
    (state) => state.collections.highestMarketCap,
], (type, topTrending, mostHeld, mostWatchedStocks, highestMarketCap) => {
    switch (type) {
        case 'top100':
            return highestMarketCap;
        case 'mostTraded':
            return topTrending;
        case 'mostHeld':
            return mostHeld;
        case 'mostWatched':
            return mostWatchedStocks;
        default:
            assertUnreachable(type);
            return [];
    }
});
export const selectIsInvestAccountActive = (state) => state.invest.account?.status === 'ACTIVE';
export const selectIsInvestAccountNotStarted = (state) => state.invest.account?.status === 'NOT_STARTED';
export const selectHasFxFeesFeature = (store) => store.user.user.guessedHomeCountry === 'GB';
export const selectUserFxFee = createSelector(selectUserTier, (state) => state.invest.tierFxRateMap, selectHasFxFeesFeature, (tier, tierFxRateMap, hasFxFeesFeature) => {
    if (!hasFxFeesFeature) {
        return undefined;
    }
    return tierFxRateMap?.[tier];
});
export const selectShowLowerFxFeeContent = createSelector(selectUserTier, selectHasFxFeesFeature, (tier, hasFxFeesFeature) => {
    if (tier === Tier.ultimate || !hasFxFeesFeature) {
        return false;
    }
    return true;
});
export const selectPositions = createSelector((state) => state.invest.positionsCache, (state) => state.invest.cache, (state) => state.invest.categoryIdSectorMap, (positionsCache, cache, categoryIdSectorMap) => {
    const symbols = Object.keys(positionsCache);
    filterDataByKey(symbols, positionsCache, 'amount');
    const result = [];
    symbols.forEach((symbol) => {
        const stockMeta = cache[symbol];
        const position = positionsCache[symbol];
        // the following condition will always return true since we are looping over keys of positionsCache
        if (position) {
            result.push({
                symbol,
                name: stockMeta?.name,
                iconUrl: stockMeta?.iconUrl,
                quantity: position.amount.value,
                categoryId: position.categoryId,
                sector: position.categoryId && categoryIdSectorMap
                    ? categoryIdSectorMap[position.categoryId]?.name || 'Others'
                    : 'Others',
            });
        }
    });
    return result;
});
export const selectPositionAssetIds = createSelector((state) => state.invest.positionsCache, (positionsCache) => {
    const symbols = Object.keys(positionsCache);
    const result = [];
    symbols.forEach((symbol) => {
        const position = positionsCache[symbol];
        // the following condition will always return true since we are looping over keys of positionsCache
        if (position) {
            result.push(position.assetId);
        }
    });
    return result.sort();
});
export const selectHasCountryOfTaxResidence = (store) => store.user.user.countryOfTaxResidence;
export const selectMissingRequiredNationalityIdentifiers = (store) => store.invest.requiredNationalityIdentifiers;
export const selectShouldShowPortfolioBlock = (store) => !!store.invest.requiredNationalityIdentifiers?.length;
export const selectShouldAskForExtraInsuranceNumber = selectShouldShowPortfolioBlock;
export const selectAnnualIncome = createSelector((store) => store.user.userAdditionalInfo, (store) => store.invest.onboarding?.params, (userAdditionalInfo, onboardingParams) => {
    if (userAdditionalInfo.annualIncomeMin !== null &&
        userAdditionalInfo.annualIncomeMin !== undefined &&
        userAdditionalInfo.annualIncomeMax !== null &&
        userAdditionalInfo.annualIncomeMax !== undefined) {
        return {
            annualIncomeMin: userAdditionalInfo.annualIncomeMin,
            annualIncomeMax: userAdditionalInfo.annualIncomeMax,
        };
    }
    if (onboardingParams?.annualIncomeMin !== null &&
        onboardingParams?.annualIncomeMin !== undefined &&
        onboardingParams?.annualIncomeMax !== null &&
        onboardingParams?.annualIncomeMax !== undefined) {
        return {
            annualIncomeMin: onboardingParams.annualIncomeMin,
            annualIncomeMax: onboardingParams.annualIncomeMax,
        };
    }
    return undefined;
});
export const selectHasAnnualIncome = createSelector(selectAnnualIncome, (annualIncome) => !!annualIncome);
export const selectNetworth = createSelector((store) => store.user.userAdditionalInfo, (store) => store.invest.onboarding?.params, (userAdditionalInfo, onboardingParams) => {
    if (userAdditionalInfo.liquidNetWorthMin !== null &&
        userAdditionalInfo.liquidNetWorthMin !== undefined &&
        userAdditionalInfo.liquidNetWorthMax !== null &&
        userAdditionalInfo.liquidNetWorthMax !== undefined) {
        return {
            liquidNetWorthMin: userAdditionalInfo.liquidNetWorthMin,
            liquidNetWorthMax: userAdditionalInfo.liquidNetWorthMax,
        };
    }
    if (onboardingParams?.liquidNetWorthMin !== null &&
        onboardingParams?.liquidNetWorthMin !== undefined &&
        onboardingParams?.liquidNetWorthMax !== null &&
        onboardingParams?.liquidNetWorthMax !== undefined) {
        return {
            liquidNetWorthMin: onboardingParams.liquidNetWorthMin,
            liquidNetWorthMax: onboardingParams.liquidNetWorthMax,
        };
    }
    return undefined;
});
export const selectHasNetworth = createSelector(selectNetworth, (networth) => !!networth);
export const selectFundingSource = createSelector((store) => store.user.userAdditionalInfo, (store) => store.invest.onboarding?.params, (userAdditionalInfo, onboardingParams) => {
    if (userAdditionalInfo.fundingSource) {
        return userAdditionalInfo.fundingSource;
    }
    if (onboardingParams?.fundingSource) {
        return onboardingParams.fundingSource;
    }
    return undefined;
});
export const selectEmploymentStatus = createSelector((store) => store.user.userAdditionalInfo, (store) => store.invest.onboarding?.params, (userAdditionalInfo, onboardingParams) => {
    if (userAdditionalInfo.employmentStatus) {
        return userAdditionalInfo.employmentStatus;
    }
    if (onboardingParams?.employmentStatus) {
        return onboardingParams.employmentStatus;
    }
    return undefined;
});
export const selectTotalDayTradeCount = createSelector((state) => state.unpersistedInvestReducer.filledDayTrades, (state) => state.unpersistedInvestReducer.pendingDayTrades, (filledDayTrades, pendingDayTrades) => {
    if (filledDayTrades && pendingDayTrades) {
        return filledDayTrades.length + pendingDayTrades.length;
    }
    return undefined;
});
export const selectLimitStopOrders = createSelector((state) => state.invest.ordersCache, (state) => state.invest.limitStopOrderIdCache, (ordersCache, limitStopOrderIdCache) => {
    const orders = [];
    if (ordersCache && limitStopOrderIdCache?.length) {
        limitStopOrderIdCache.forEach((id) => {
            const order = ordersCache[id];
            if (order) {
                orders.push(order);
            }
        });
    }
    return orders;
});
export const selectIsRequestingOrders = createSelector([
    (state) => state.unpersistedInvestReducer.requestingLimitStopOrders,
    (state) => state.unpersistedInvestReducer.requestingLimitStopOrdersAssetId,
    (state) => state.unpersistedInvestReducer.requestingLimitStopOrdersForAssetId,
    (state) => state.unpersistedInvestReducer.requestingMarketOrders,
    (state) => state.unpersistedInvestReducer.requestingMarketOrdersAssetId,
    (state) => state.unpersistedInvestReducer.requestingMarketOrdersForAssetId,
    (_state, infoObj) => infoObj,
], (requestingLimitStopOrders, requestingLimitStopOrdersAssetId, requestingLimitStopOrdersForAssetId, requestingMarketOrders, requestingMarketOrdersAssetId, requestingMarketOrdersForAssetId, infoObj) => {
    if (infoObj.orderType === 'limitAndStop') {
        if (!infoObj.assetId) {
            return !!requestingLimitStopOrders;
        }
        if (requestingLimitStopOrdersAssetId && requestingLimitStopOrdersForAssetId) {
            if (infoObj.assetId === requestingLimitStopOrdersAssetId) {
                return requestingLimitStopOrdersForAssetId;
            }
        }
    }
    else if (infoObj.orderType === 'pending' || infoObj.orderType === 'queued') {
        if (!infoObj.assetId) {
            return !!requestingMarketOrders;
        }
        if (requestingMarketOrdersAssetId && requestingMarketOrdersForAssetId) {
            if (infoObj.assetId === requestingMarketOrdersAssetId) {
                return requestingMarketOrdersForAssetId;
            }
        }
    }
    return false;
});
export const selectFirstThreePendingAlerts = createSelector([(state) => state.unpersistedInvestReducer.pendingStockAlerts, (_state, assetId) => assetId], (pendingAlerts, assetId) => {
    if (assetId) {
        if (assetId !== ALL_STOCKS_KEY) {
            // Trying to find the array for the assetId
            const dataArr = pendingAlerts?.[assetId];
            if (dataArr?.length) {
                // return the first three if found
                return dataArr.slice(0, 3);
            }
            // trying to find the relevant data in allStocks list
            const allStocksDataArr = pendingAlerts?.[ALL_STOCKS_KEY]?.filter((stockAlert) => stockAlert.assetId === assetId);
            if (allStocksDataArr?.length) {
                // return the first three if found
                return allStocksDataArr.slice(0, 3);
            }
        }
        else {
            return pendingAlerts?.[assetId]?.slice(0, 3);
        }
    }
    return [];
});
export const selectHasPendingStockAlert = createSelector([(state) => state.unpersistedInvestReducer.pendingStockAlerts, (_state, assetId) => assetId], (pendingAlerts, assetId) => {
    if (assetId) {
        // Trying to find the array for the assetId
        const dataArr = pendingAlerts?.[assetId];
        if (dataArr?.length) {
            return true;
        }
        // trying to find the relevant data in allStocks list
        const allStocksDataArr = pendingAlerts?.[ALL_STOCKS_KEY]?.filter((stockAlert) => stockAlert.assetId === assetId);
        if (allStocksDataArr?.length) {
            return true;
        }
    }
    return false;
});
export const selectIsInitialRequestingOrders = createSelector([
    (state) => state.unpersistedInvestReducer.requestingLimitStopOrders,
    (state) => state.unpersistedInvestReducer.requestingLimitStopOrdersPaginationToken,
    (state) => state.unpersistedInvestReducer.requestingLimitStopOrdersAssetId,
    (state) => state.unpersistedInvestReducer.requestingLimitStopOrdersForAssetId,
    (state) => state.unpersistedInvestReducer.requestingLimitStopOrdersPaginationTokenForAssetId,
    (state) => state.unpersistedInvestReducer.requestingMarketOrders,
    (state) => state.unpersistedInvestReducer.requestingMarketOrdersPaginationToken,
    (state) => state.unpersistedInvestReducer.requestingMarketOrdersAssetId,
    (state) => state.unpersistedInvestReducer.requestingMarketOrdersForAssetId,
    (state) => state.unpersistedInvestReducer.requestingMarketOrdersPaginationTokenForAssetId,
    (_state, infoObj) => infoObj,
], (requestingLimitStopOrders, requestingLimitStopOrdersPaginationToken, requestingLimitStopOrdersAssetId, requestingLimitStopOrdersForAssetId, requestingLimitStopOrdersPaginationTokenForAssetId, requestingMarketOrders, requestingMarketOrdersPaginationToken, requestingMarketOrdersAssetId, requestingMarketOrdersForAssetId, requestingMarketOrdersPaginationTokenForAssetId, infoObj) => {
    if (infoObj.orderType === 'limitAndStop') {
        if (!infoObj.assetId) {
            return !!(requestingLimitStopOrders && !requestingLimitStopOrdersPaginationToken);
        }
        if (requestingLimitStopOrdersAssetId && requestingLimitStopOrdersForAssetId) {
            if (infoObj.assetId === requestingLimitStopOrdersAssetId) {
                return !!(requestingLimitStopOrdersForAssetId && !requestingLimitStopOrdersPaginationTokenForAssetId);
            }
        }
    }
    else if (infoObj.orderType === 'pending' || infoObj.orderType === 'queued') {
        if (!infoObj.assetId) {
            return !!(requestingMarketOrders && !requestingMarketOrdersPaginationToken);
        }
        if (requestingMarketOrdersAssetId && requestingMarketOrdersForAssetId) {
            if (infoObj.assetId === requestingMarketOrdersAssetId) {
                return !!(requestingMarketOrdersForAssetId && !requestingMarketOrdersPaginationTokenForAssetId);
            }
        }
    }
    return false;
});
export const selectIsRequestingTriggeredStockAlerts = createSelector([
    (state) => state.unpersistedInvestReducer.requestingTriggeredStockAlerts,
    (state) => state.unpersistedInvestReducer.triggeredStockAlertsPaginationAssetId,
    (_state, assetId) => assetId,
], (requesting, requestingAssetId, assetId) => {
    if (assetId && requestingAssetId) {
        if (assetId === requestingAssetId) {
            return requesting;
        }
    }
    return false;
});
export const selectIsPaginationTokenForOrders = createSelector([
    (state) => state.unpersistedInvestReducer.requestingLimitStopOrdersPaginationToken,
    (state) => state.unpersistedInvestReducer.requestingLimitStopOrdersAssetId,
    (state) => state.unpersistedInvestReducer.requestingLimitStopOrdersPaginationTokenForAssetId,
    (state) => state.unpersistedInvestReducer.requestingMarketOrdersPaginationToken,
    (state) => state.unpersistedInvestReducer.requestingMarketOrdersAssetId,
    (state) => state.unpersistedInvestReducer.requestingMarketOrdersPaginationTokenForAssetId,
    (_state, infoObj) => infoObj,
], (requestingLimitStopOrdersPaginationToken, requestingLimitStopOrdersAssetId, requestingLimitStopOrdersPaginationTokenForAssetId, requestingMarketOrdersPaginationToken, requestingMarketOrdersAssetId, requestingMarketOrdersPaginationTokenForAssetId, infoObj) => {
    if (infoObj.orderType === 'limitAndStop') {
        if (!infoObj.assetId) {
            return requestingLimitStopOrdersPaginationToken;
        }
        if (requestingLimitStopOrdersAssetId && infoObj.assetId === requestingLimitStopOrdersAssetId) {
            return requestingLimitStopOrdersPaginationTokenForAssetId;
        }
    }
    else if (infoObj.orderType === 'pending' || infoObj.orderType === 'queued') {
        if (!infoObj.assetId) {
            return requestingMarketOrdersPaginationToken;
        }
        if (requestingMarketOrdersAssetId && infoObj.assetId === requestingMarketOrdersAssetId) {
            return requestingMarketOrdersPaginationTokenForAssetId;
        }
    }
    return undefined;
});
export const selectIsInitialRequestingTriggeredStockAlerts = createSelector([
    (state) => state.unpersistedInvestReducer.requestingTriggeredStockAlerts,
    (state) => state.unpersistedInvestReducer.triggeredStockAlertsPaginationToken,
    (state) => state.unpersistedInvestReducer.triggeredStockAlertsPaginationAssetId,
    (_state, assetId) => assetId,
], (requesting, triggeredPaginationToken, requestingAssetId, assetId) => {
    if (assetId && requestingAssetId) {
        if (assetId === requestingAssetId) {
            // we can tell if it is initial loading when it is requesting and pagination token is undefined
            // there will not be load more calls if paginationToken is undefined
            return requesting && !triggeredPaginationToken;
        }
    }
    return false;
});
export const selectIsRequestingPendingStockAlerts = createSelector([
    (state) => state.unpersistedInvestReducer.requestingAllPendingStockAlerts,
    (state) => state.unpersistedInvestReducer.requestingPendingStockAlerts,
    (state) => state.unpersistedInvestReducer.pendingStockAlertsPaginationAssetId,
    (_state, assetId) => assetId,
], (requestingAllPendingStocks, requesting, requestingAssetId, assetId) => {
    if (assetId === ALL_STOCKS_KEY) {
        return requestingAllPendingStocks;
    }
    if (assetId && requestingAssetId) {
        if (assetId === requestingAssetId) {
            return requesting;
        }
    }
    return false;
});
export const selectIsInitialRequestingPendingStockAlerts = createSelector([
    (state) => state.unpersistedInvestReducer.requestingAllPendingStockAlerts,
    (state) => state.unpersistedInvestReducer.allPendingStockAlertsPaginationToken,
    (state) => state.unpersistedInvestReducer.requestingPendingStockAlerts,
    (state) => state.unpersistedInvestReducer.pendingStockAlertsPaginationToken,
    (state) => state.unpersistedInvestReducer.pendingStockAlertsPaginationAssetId,
    (_state, assetId) => assetId,
], (requestingAllPendingStocks, allPendingStocksPaginationToken, requesting, pendingPaginationToken, requestingAssetId, assetId) => {
    if (assetId === ALL_STOCKS_KEY) {
        return requestingAllPendingStocks && !allPendingStocksPaginationToken;
    }
    if (assetId && requestingAssetId) {
        if (assetId === requestingAssetId) {
            // we can tell if it is initial loading when it is requesting and pagination token is undefined
            // there will not be load more calls if paginationToken is undefined
            return requesting && !pendingPaginationToken;
        }
    }
    return false;
});
const emptyStocksObj = {};
export const selectStocksObjForStockMarketPerformance = createSelector((store) => store.utils.featureFlags, (featureFlags) => {
    const infoArr = featureFlags?.stock_market_performance?.extra?.infoArr;
    if (infoArr?.length) {
        return infoArr.reduce((prev, curr) => ({
            ...prev,
            [curr.symbol]: curr,
        }), {});
    }
    return emptyStocksObj;
});
export const selectSymbolsForStockMarketPerformance = createSelector(selectStocksObjForStockMarketPerformance, (infoObj) => Object.keys(infoObj));
const sortData = (a, b) => {
    if (a.symbol && b.symbol) {
        if (a.symbol < b.symbol)
            return -1;
        if (a.symbol > b.symbol)
            return 1;
    }
    return 0;
};
export const selectStockMarketPerformanceData = createSelector(selectStocksObjForStockMarketPerformance, (store) => store.unpersistedInvestReducer.stockMarketPerformanceInfoArr, (infoObj, stockMarketPerformanceInfoArr) => {
    if (stockMarketPerformanceInfoArr?.length) {
        const stockMarketPerformanceData = [];
        stockMarketPerformanceInfoArr.forEach((stockMarketPerformanceInfo) => {
            if (infoObj[stockMarketPerformanceInfo.symbol]?.displayName) {
                stockMarketPerformanceData.push({
                    ...stockMarketPerformanceInfo,
                    displayName: infoObj[stockMarketPerformanceInfo.symbol].displayName,
                });
            }
        });
        if (stockMarketPerformanceData.length) {
            return stockMarketPerformanceData.sort(sortData);
        }
    }
    return undefined;
});
const emptyEvents = [];
export const getEventsNearToToday = (events, isForPortfolio) => {
    const today = new Date();
    const indexForToday = events.findIndex((event) => !isBefore(event.date, today));
    if (indexForToday !== -1) {
        const eventsLen = events.length;
        if (indexForToday === eventsLen - 1) {
            return events.slice(isForPortfolio ? -3 : -1);
        }
        if (indexForToday === eventsLen - 2) {
            return events.slice(isForPortfolio ? -3 : -2);
        }
        if (indexForToday === eventsLen - 3) {
            return events.slice(-3);
        }
        return events.slice(indexForToday, indexForToday + 3);
    }
    return isForPortfolio ? events.slice(-3) : emptyEvents;
};
export const selectInvestmentCalendarShortList = createSelector([(state) => state.unpersistedInvestReducer.investmentCalendar, (_state, key) => key], (investmentCalendar, key) => {
    const eventsForSection = investmentCalendar?.[key]?.eventsForSection;
    const allEvents = investmentCalendar?.[key]?.events;
    if (!eventsForSection && !allEvents)
        return undefined;
    return {
        canSeeMore: !!allEvents?.length,
        events: eventsForSection || emptyEvents,
    };
});
export const getActivitiesSectionedFlashlist = (activities, key) => {
    if (!activities?.length || !key) {
        return {
            flashListData: [],
            initialScrollIndex: 0,
        };
    }
    let activeDate;
    const flashListData = [];
    let initialScrollIndex;
    let lastSectionHeaderIndex;
    const currentMonth = format(new Date(), 'MMMM');
    activities.forEach((activity) => {
        let didAddNewSection = false;
        const dateToUseRaw = activity[key];
        const dateToUse = formatDateForSectionHeader(dateToUseRaw);
        if (!activeDate || dateToUse !== activeDate) {
            activeDate = dateToUse;
            addLastInSectionInfo(flashListData);
            flashListData.push({
                id: dateToUse,
                sectionTitle: dateToUse,
            });
            const dataLen = flashListData.length;
            if (currentMonth === dateToUse) {
                initialScrollIndex = dataLen > 0 ? dataLen - 1 : dataLen;
            }
            lastSectionHeaderIndex = dataLen;
            didAddNewSection = true;
        }
        if (!didAddNewSection) {
            flashListData.push(activity);
        }
        else {
            flashListData.push({ ...activity, isFirstInSection: true });
        }
    });
    addLastInSectionInfo(flashListData);
    if (initialScrollIndex === undefined) {
        initialScrollIndex = lastSectionHeaderIndex || 0;
    }
    if (flashListData.length) {
        flashListData.push({
            id: 'lastElement',
            lastElement: true,
        });
    }
    return { flashListData, initialScrollIndex };
};
const emptySectionedEvents = [];
export const selectCorporateEventsFlashListData = createSelector([(state) => state.unpersistedInvestReducer.investmentCalendar, (_state, key) => key], (investmentCalendar, key) => {
    const calendarEventObj = investmentCalendar?.[key];
    if (calendarEventObj) {
        const { flashListData: events, initialScrollIndex } = getActivitiesSectionedFlashlist(calendarEventObj.events, 'date');
        return {
            events,
            initialScrollIndex,
            dataLength: events.length,
            futureToken: calendarEventObj.futureToken,
        };
    }
    return {
        dataLength: 0,
        futureToken: '',
        events: emptySectionedEvents,
    };
});
export const selectCorporateEventsInitialLoading = createSelector([
    (state) => state.unpersistedInvestReducer.loadingPortfolioCalendar,
    (state) => state.unpersistedInvestReducer.loadingPortfolioCalendarToken,
    (state) => state.unpersistedInvestReducer.loadingCalendarForAssetId,
    (state) => state.unpersistedInvestReducer.loadingCalendarForAssetToken,
    (_state, key) => key,
], (requesting, paginationToken, assetId, paginationTokenForAssetId, selectorKey) => {
    if (selectorKey === 'portfolio') {
        return !!(requesting && !paginationToken);
    }
    if (selectorKey === assetId) {
        return !!assetId && !paginationTokenForAssetId;
    }
    return false;
});
export const selectIsLoadingCorporateEvents = createSelector([
    (state) => state.unpersistedInvestReducer.loadingPortfolioCalendar,
    (state) => state.unpersistedInvestReducer.loadingCalendarForAssetId,
    (_state, key) => key,
], (requesting, assetId, selectorKey) => {
    if (selectorKey === 'portfolio') {
        return !!requesting;
    }
    if (selectorKey === assetId) {
        return !!assetId;
    }
    return false;
});
const getTradeInfo = (quantity, amount) => `${formatNumberOfShares(quantity)} • ${formatAmount(amount, 'USD', 2)}`;
const emptyActivities = [];
export const selectCompactActivities = createNonMemoizedSelector((state, assetId) => assetId ? state.unpersistedInvestReducer.compactActivityForAssetId?.[assetId] : undefined, (activities) => activities ?? emptyActivities);
export const selectBuyAndSellPoints = createSelector([
    selectCompactActivities,
    (_state, _assetId, selectedPeriod) => selectedPeriod,
    (_state, _assetId, _selectedPeriod, points) => points,
    (_state, _assetId, _selectedPeriod, _points, points2) => points2,
], (activities, selectedPeriod, points1, points2) => {
    if (!activities || !points1.length)
        return undefined;
    const firstTimeStamp = new Date(points1[0].timeStampRaw * 60000).toISOString();
    const filteredActivities = [];
    for (const eachActivity of activities) {
        if (isAfter(eachActivity.timestamp, firstTimeStamp) || firstTimeStamp === eachActivity.timestamp) {
            if (eachActivity.type === 'buy' || eachActivity.type === 'sell') {
                filteredActivities.push(eachActivity);
            }
        }
        else {
            break;
        }
    }
    const activitiesLen = filteredActivities.length;
    if (!activitiesLen) {
        return undefined;
    }
    filteredActivities.reverse();
    const points = [...points1, ...points2];
    // filter activities according to the selectedPeriod
    // find all relevant point for each activity
    let i = 0; // pointer for activities
    let j = 0; // pointer for points
    const pointsLen = points.length;
    const buySellPointsObj = {};
    while (i < activitiesLen) {
        const activity = filteredActivities[i];
        while (j < pointsLen) {
            const point = points[j];
            const pointDate = new Date(point.timeStampRaw * 60000);
            if ((isSameDay(pointDate, activity.timestamp) && selectedPeriod !== '1D' && selectedPeriod !== '1W') ||
                isBefore(activity.timestamp, pointDate)) {
                const isBuy = activity.type === 'buy';
                if (buySellPointsObj[String(point.timeStampRaw)]) {
                    if (isBuy) {
                        buySellPointsObj[String(point.timeStampRaw)].totalBuyShares += activity.shares;
                        buySellPointsObj[String(point.timeStampRaw)].buy.push({
                            timestamp: activity.timestamp,
                            tradeInfoDesc: getTradeInfo(activity.shares, activity.price),
                        });
                    }
                    else {
                        buySellPointsObj[String(point.timeStampRaw)].totalSellShares += activity.shares;
                        buySellPointsObj[String(point.timeStampRaw)].sell.push({
                            timestamp: activity.timestamp,
                            tradeInfoDesc: getTradeInfo(activity.shares, activity.price),
                        });
                    }
                }
                else {
                    buySellPointsObj[String(point.timeStampRaw)] = {
                        x: point.x,
                        y: point.y,
                        pointTimeStamp: point.timeStampRaw,
                        totalBuyShares: isBuy ? activity.shares : 0,
                        totalSellShares: !isBuy ? activity.shares : 0,
                        buy: isBuy
                            ? [
                                {
                                    timestamp: activity.timestamp,
                                    tradeInfoDesc: getTradeInfo(activity.shares, activity.price),
                                },
                            ]
                            : [],
                        sell: !isBuy
                            ? [
                                {
                                    timestamp: activity.timestamp,
                                    tradeInfoDesc: getTradeInfo(activity.shares, activity.price),
                                },
                            ]
                            : [],
                    };
                }
                break;
            }
            else {
                j += 1;
            }
        }
        i += 1;
    }
    const buySellPoints = Object.values(buySellPointsObj).map((buySellPoint) => {
        const buyCount = buySellPoint.buy.length;
        const sellCount = buySellPoint.sell.length;
        return {
            buyCount,
            sellCount,
            x: buySellPoint.x,
            y: buySellPoint.y,
            buy: buySellPoint.buy,
            sell: buySellPoint.sell,
            pointTimeStamp: buySellPoint.pointTimeStamp,
            colorKey: buySellPoint.totalSellShares > buySellPoint.totalBuyShares ? 'negative' : 'positive',
        };
    });
    return buySellPoints;
});
export const selectShouldShowDiscoverLikeInvestFirstScreen = createSelector((store) => store.utils.featureFlags, (featureFlags) => !!(featureFlags.invest_onb_first_screen_test?.value === 'yes'));
export const selectShowSetupInvestAccountInInvestScreens = createSelector(selectIsInvestAccountActive, selectShouldShowDiscoverLikeInvestFirstScreen, (isInvestAccountActive, shouldShowDiscoverLikeInvestFirstScreen) => shouldShowDiscoverLikeInvestFirstScreen && !isInvestAccountActive);
export const selectPortfolioTotalBalance = createSelector((store) => store.invest.positionsCache, (positionsCache) => {
    const positions = Object.values(positionsCache);
    return {
        value: positions.reduce((prev, curr) => prev + (curr?.amount?.value || 0), 0),
        currency: 'GBP',
    };
});
