import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import useColors from 'hooks/useColors';
import IconClose from './icons/IconClose';
import { hitSlop } from './values';
const NavigationCloseButton = ({ onPress, colorKey = 'primary', }) => {
    const colors = useColors();
    return (<TouchableOpacity onPress={onPress} hitSlop={hitSlop} style={styles.container}>
      <IconClose alt color={colors.elements[colorKey]}/>
    </TouchableOpacity>);
};
const styles = StyleSheet.create({
    container: {
        right: 6,
    },
});
export default NavigationCloseButton;
