import React, { memo, useCallback } from 'react';
import { View, StyleSheet, InteractionManager } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import { CardView } from 'design-system/CardView';
import { useNotificationsPermissions } from 'hooks/useNotificationsPermissions';
import { NotificationsPermissionState } from 'hooks/useNotificationPermissionTypes';
const CARD_HEIGHT = rem(168);
const NotificationBadge = () => {
    const { permissionState, activatePush } = useNotificationsPermissions('settings');
    const handleTurnOn = useCallback(() => {
        InteractionManager.runAfterInteractions(activatePush);
    }, [activatePush]);
    if (permissionState === NotificationsPermissionState.Enabled ||
        permissionState === NotificationsPermissionState.Unknown)
        return null;
    return (<View style={styles.container}>
      <CardView withPadding noMarginTop>
        <View style={styles.row}>
          <Text Text-16 flex>
            Turn on notifications
          </Text>
        </View>
        <Spacer h={4}/>
        <Text TextThin-14 Secondary>
          {'Get real time updates on your finances to\xa0stay\xa0on\xa0track and never miss anything.'}
        </Text>
        <Spacer h={16}/>
        <View style={styles.row}>
          <Button square brandReversed title="Turn On" onPress={handleTurnOn} containerStyle={styles.btn}/>
        </View>
      </CardView>
    </View>);
};
export default memo(NotificationBadge);
const styles = StyleSheet.create({
    container: {
        overflow: 'hidden',
        height: CARD_HEIGHT,
    },
    row: {
        flexDirection: 'row',
    },
    btn: {
        paddingHorizontal: rem(24),
    },
});
