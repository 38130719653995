import React from 'react';
import { StyleSheet, View } from 'react-native';
import FastImage from 'utils/packages/rnFastImage';
import { CardView } from 'design-system/CardView';
import { rem } from 'design-system/values';
import Text from 'design-system/Text';
import { selectRentReportingAgenciesForOnboarding } from 'features/premium/selectors';
import { formatRentReportingAgencies, RENT_REPORTING_AGENCIES, } from 'features/rent-reporting/utils';
import { useAppSelector } from 'store/hooks';
import RentReportingStartButton from './RentReportingStartButton';
const RentReportingEmptyCard = () => {
    const rentReportingAgenciesForTier = useAppSelector(selectRentReportingAgenciesForOnboarding);
    const agenciesToShow = formatRentReportingAgencies(rentReportingAgenciesForTier);
    return (<CardView noMarginTop noMarginBottom withPadding>
      <View style={styles.logoContainer}>
        {rentReportingAgenciesForTier?.map((agency) => {
            const agencyData = RENT_REPORTING_AGENCIES[agency];
            return agencyData ? (<FastImage source={agencyData.image} style={styles.logo} resizeMode="contain" key={agency}/>) : null;
        })}
      </View>
      <Text Text-16 flex style={styles.title}>
        Improve your credit position with Rent{'\xa0'}Reporting
      </Text>
      <Text TextThin-14 Secondary style={styles.subTitle}>
        We&apos;ll report each new rent payment to {agenciesToShow} to improve
        your credit position.
      </Text>
      <RentReportingStartButton />
    </CardView>);
};
const styles = StyleSheet.create({
    title: {
        marginBottom: rem(4),
    },
    subTitle: {
        marginBottom: rem(16),
    },
    logoContainer: {
        flexDirection: 'row',
        marginBottom: rem(16),
    },
    logo: {
        height: rem(44),
        width: rem(44),
    },
});
export default RentReportingEmptyCard;
