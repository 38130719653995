import { REHYDRATE } from 'redux-persist';
import * as types from 'actions/types';
const initialState = {
    groups: [],
    transactionsCache: {},
    analyticsCache: {},
};
const groups = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_GROUPS_SUCCESS: {
            const groups = action.payload.groups.map(({ group, netBalance }) => ({
                ...group,
                netBalance,
            }));
            return { ...state, groups };
        }
        case types.GET_GROUP_BY_ID_SUCCESS: {
            const { group, netBalance, analytics, transactions } = action.payload;
            const groups = state.groups.map((g) => g.id === group.id ? { ...g, ...group, netBalance } : g);
            return {
                ...state,
                groups,
                analyticsCache: {
                    ...state.analyticsCache,
                    [group.id]: analytics,
                },
                transactionsCache: {
                    ...state.transactionsCache,
                    [group.id]: transactions,
                },
            };
        }
        case types.JOIN_GROUP_SUCCESS:
        case types.CREATE_GROUP_SUCCESS: {
            const { group, netBalance } = action.payload;
            if (state.groups.find((g) => g.id === group.id)) {
                return state;
            }
            return {
                ...state,
                groups: [...state.groups, { ...group, netBalance }],
            };
        }
        case types.LEAVE_GROUP_SUCCESS:
        case types.DELETE_GROUP_SUCCESS: {
            const transactionsCache = { ...state.transactionsCache };
            const analyticsCache = { ...state.analyticsCache };
            delete transactionsCache[action.extra];
            delete analyticsCache[action.extra];
            return {
                ...state,
                groups: state.groups.filter(({ id }) => id !== action.extra),
                transactionsCache,
                analyticsCache,
            };
        }
        case types.DELETE_GROUP_USER_SUCCESS: {
            const groups = state.groups.map((group) => group.id === action.extra.groupId
                ? {
                    ...group,
                    userIds: group.userIds.filter((u) => u !== action.extra.userId),
                }
                : group);
            const analyticsCache = {
                ...state.analyticsCache,
                [action.extra.groupId]: {
                    ...state.analyticsCache[action.extra.groupId],
                    users: state.analyticsCache[action.extra.groupId].users.filter((u) => u.id !== action.extra.userId),
                },
            };
            return { ...state, groups, analyticsCache };
        }
        case types.RENAME_GROUP_REQUEST: {
            const groups = state.groups.map((group) => group.id === action.extra.groupId
                ? {
                    ...group,
                    name: action.extra.newName,
                }
                : group);
            return { ...state, groups };
        }
        case types.PATCH_GROUP_TRANSACTION_SUCCESS: {
            const transactionsCache = {
                ...state.transactionsCache,
                [action.extra.groupId]: state.transactionsCache[action.extra.groupId].map((transaction) => {
                    if (transaction.id === action.extra.transactionId) {
                        return { ...transaction, ...action.payload.transaction };
                    }
                    return transaction;
                }),
            };
            return { ...state, transactionsCache };
        }
        case types.RENAME_GROUP_FAILURE: {
            const groups = state.groups.map((group) => group.id === action.extra.groupId
                ? {
                    ...group,
                    name: action.extra.oldName,
                }
                : group);
            return { ...state, groups };
        }
        case types.UPLOAD_GROUP_IMAGE_SUCCESS: {
            const { id, iconUrl } = action.payload.group;
            return {
                ...state,
                groups: state.groups.map((g) => (g.id === id ? { ...g, iconUrl } : g)),
            };
        }
        case types.DELETE_GROUP_IMAGE_REQUEST: {
            return {
                ...state,
                groups: state.groups.map((g) => g.id === action.extra.groupId ? { ...g, iconUrl: undefined } : g),
            };
        }
        case types.DELETE_GROUP_IMAGE_FAILURE: {
            const { groupId, iconUrl } = action.extra;
            return {
                ...state,
                groups: state.groups.map((g) => g.id === groupId ? { ...g, iconUrl } : g),
            };
        }
        case REHYDRATE: {
            const { groups } = action.payload || {};
            if (groups) {
                return { ...state, ...groups };
            }
            return state;
        }
        case types.LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
export default groups;
