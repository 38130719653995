import { toZonedTime } from 'date-fns-tz';
import appsFlyer from 'utils/packages/rnAppsflyer';
import { analytics } from 'utils/packages/rnFirebase';
import { CREATE_SPACE_SUCCESS } from 'features/spaces/constants';
import { amplitudeSource } from 'features/invest/utils/analytics';
import { logAppsFlyerEvent } from 'utils/appsflyer';
import Facebook from 'utils/facebook';
import { AppEventsLogger } from 'utils/packages/fbsdk';
import { GET_BUDGETS_SUCCESS, SAVE_BUDGETS_SUCCESS, } from 'features/budgeting/actions/types';
import { Crisp } from 'crisp-sdk-web';
import { getPotEventName } from 'features/pots/utils';
import getSupportUrl from 'features/support/utils/getSupportUrl';
import * as types from '../actions/types';
import Amplitude from '../utils/amplitude';
import Sentry from '../utils/sentry';
import { isWeb } from '../constants';
const track = (action) => {
    switch (action.type) {
        case types.SPLIT_TRANSACTION_SUCCESS:
            Amplitude.logEvent('Splitted Transaction');
            break;
        case types.EDIT_CATEGORY_SUCCESS:
            Amplitude.logEvent('Transaction.EditCategory');
            Facebook.logEvent('Edited Category');
            analytics().logEvent('Edited_Category');
            break;
        case types.RENAME_TRANSACTION_SUCCESS:
            Amplitude.logEvent('Transaction.RenameCounterpartname');
            Facebook.logEvent('Renamed Counterpartname');
            analytics().logEvent('Renamed_Counterpartname');
            break;
        case types.SAVE_NOTE_SUCCESS:
            Amplitude.logEvent('Transaction.NoteAdded');
            Facebook.logEvent('Added Note');
            analytics().logEvent('Added_Note');
            break;
        case types.SET_TAGS_SUCCESS:
            Amplitude.logEvent('Transaction.Tags');
            Facebook.logEvent('Added Tags');
            analytics().logEvent('Added_Tags');
            break;
        case types.ON_SUBMIT_FEEDBACK_SUCCESS:
            Amplitude.logEvent('Transaction.MerchantFeedback');
            Facebook.logEvent('Submit Merchant Feedback');
            analytics().logEvent('Submit_Merchant_Feedback');
            break;
        case GET_BUDGETS_SUCCESS:
            const hasTotalBudget = action &&
                action.payload &&
                action.payload.budgets &&
                action.payload.budgets[0] &&
                action.payload.budgets[0].key === 'overall.monthly' &&
                action.payload.budgets[0].limit;
            if (hasTotalBudget) {
                Amplitude.setUserProperties({ hasTotalBudget: true });
            }
            else {
                Amplitude.setUserProperties({ hasTotalBudget: false });
            }
            break;
        case SAVE_BUDGETS_SUCCESS:
            Amplitude.logEvent('Budgets Set');
            Facebook.logEvent('Saved Budgets');
            analytics().logEvent('Saved_Budgets');
            break;
        case types.ADD_SUBSCRIPTIONS_SUCCESS:
            Amplitude.logEvent('Subscription Added');
            Facebook.logEvent('Added Subscription');
            analytics().logEvent('Added_Subscription');
            break;
        case types.CANCEL_SUBSCRIPTIONS_SUCCESS:
            Amplitude.logEvent('Subscription Removed');
            Facebook.logEvent('Removed Subscription');
            analytics().logEvent('Removed_Subscription');
            break;
        case types.EDIT_SUBSCRIPTIONS_SUCCESS:
            Amplitude.logEvent('Subscription Edited');
            Facebook.logEvent('Edited Subscription');
            analytics().logEvent('Edited_Subscription');
            break;
        case types.SET_ACCOUNT_NAME_SUCCESS:
            Amplitude.logEvent('Account.NameChanged');
            break;
        case types.SET_ACCOUNT_TYPE_SUCCESS:
            Amplitude.logEvent('Account.TypeChanged');
            break;
        case types.SET_OVERDRAFT_SUCCESS:
            Amplitude.logEvent('Account.OverdraftSet');
            break;
        case types.HIDE_SUCCESS:
            Amplitude.logEvent('Account.AccountHidden');
            break;
        case types.SET_INCOME_SUCCESS:
            Amplitude.logEvent('Payday set');
            break;
        case types.SET_PRIMARY_INCOME_SUCCESS:
            Amplitude.logEvent('Primary Income Set');
            break;
        case types.DELETE_INCOME_SUCCESS:
            Amplitude.logEvent('Payday deleted');
            break;
        case types.LOGIN_SUCCESS:
            Amplitude.setUserProperties({ isLogged: true });
            break;
        case types.CONNECTIONS_SUCCESS:
            Amplitude.setUserProperties({
                connections: action.payload.bankConnections.length,
            });
            break;
        case types.DELETE_CONNECTION_SUCCESS:
            Amplitude.logEvent('Connection deleted');
            break;
        case types.GET_ACTIVE_REFERRAL_PROGRAM_SUCCESS:
            Amplitude.setUserProperties({
                program: action.payload.program,
            });
            break;
        case types.USER_SUCCESS:
            const hasProfilePic = typeof action.payload.userImage !== 'undefined';
            const { email, firstName, lastName, phoneNumber, guessedHomeCountry, emmaProStatus, numberOfIncomes, referredByUserId, referralsSummary, questsSummary, createdAt, firstConnectionAt, dateOfBirth, } = action.payload;
            Amplitude.setUserProperties({
                homeCountry: guessedHomeCountry,
                emmapro: emmaProStatus,
                incomes: numberOfIncomes,
                profilePicSet: hasProfilePic,
                referredBy: referredByUserId,
                referralsSummary,
                questsSummary,
                firstConnectionAt,
                createdAt,
                yearOfBirth: dateOfBirth
                    ? toZonedTime(dateOfBirth, 'UTC').getUTCFullYear()
                    : undefined,
            });
            Amplitude.setUserId(action.payload.id.toString());
            AppEventsLogger.setUserID(action.payload.id.toString());
            AppEventsLogger.setUserData({
                email,
                firstName,
                lastName,
                phone: phoneNumber,
                country: guessedHomeCountry,
            });
            appsFlyer.setCustomerUserId(action.payload.id.toString(), () => { });
            Sentry.setUser(action.payload);
            if (isWeb) {
                Crisp.user.setNickname(getSupportUrl(action.payload).nickName);
                Crisp.user.setEmail(email);
                Crisp.setTokenId(action.payload.crispTokenId);
                Crisp.load();
                Crisp.chat.show();
            }
            break;
        case types.GET_ADDITIONAL_USER_INFO_SUCCESS:
        case types.POST_ADDITIONAL_USER_INFO_SUCCESS:
            Amplitude.setUserProperties({
                isRenting: action.payload.userAdditionalInfo?.isRenting,
            });
            break;
        case types.GET_FEATURE_FLAGS_SUCCESS:
            Amplitude.setUserProperties({ activeFlags: action.payload });
            break;
        case types.GET_COMMON_FLAGS_SUCCESS:
            Amplitude.setUserProperties({ commonFlags: action.payload });
            break;
        case 'SET_ACCOUNT_STATUS':
            const { status } = action;
            Amplitude.setUserProperties({
                tradingAccountStatus: status,
            });
            break;
        case 'CREATE_TRADING_ACCOUNT_SUCCESS':
            Amplitude.setUserProperties({
                tradingAccountStatus: 'IDENTITY_CHECKS_IN_PROGRESS',
            });
            Amplitude.logEvent('Submitted Request', {
                source: amplitudeSource,
                type: 'trading_account_application',
            });
            logAppsFlyerEvent('Submitted Trading Application');
            break;
        case 'CACHE_ACCOUNTS':
            const { tradingAccount } = action;
            Amplitude.setUserProperties({
                tradingAccountStatus: tradingAccount.status,
            });
            break;
        case 'CACHE_TRADING_ACCOUNT':
        case 'GET_TRADING_ACCOUNT_SUCCESS':
            const { payload } = action;
            Amplitude.setUserProperties({
                tradingAccountStatus: payload.account.status,
            });
            break;
        case 'CREATE_WATCHLIST_SUCCESS':
            Amplitude.logEvent('Created List', { source: amplitudeSource });
            break;
        case 'GET_POSITIONS_SUCCESS': {
            const positionLength = action?.payload?.positions?.length;
            if (positionLength !== undefined) {
                Amplitude.setUserProperties({
                    investment_positions: positionLength,
                });
            }
            break;
        }
        case 'ADD_ASSET_TO_WATCHLIST_SUCCESS':
            Amplitude.logEvent('Added Stock', {
                source: amplitudeSource,
                type: 'list',
                symbol: action.extra.symbol,
            });
            break;
        case 'GET_POTS_ACCOUNT_SUCCESS':
        case 'CREATE_SAVINGS_PLAN_SUCCESS': {
            Amplitude.setUserProperties({
                isAutosaving: action.payload.savingsPlan?.mandateStatus || 'NOT_STARTED',
            });
            break;
        }
        case CREATE_SPACE_SUCCESS:
            Amplitude.logEvent('Created Space');
            break;
        case 'CREATE_POT_SUCCESS':
            Amplitude.logEvent('Created Pot', {
                type: 'personal',
                product: getPotEventName(action.payload.pot.type),
            });
            break;
        case types.LOGGED_OUT: {
            if (isWeb) {
                Crisp.setTokenId(undefined);
                Crisp.session.reset();
                Crisp.chat.hide();
            }
            break;
        }
        default:
            break;
    }
};
export default track;
