import React, { memo, useState, useCallback } from 'react';
import { View, Pressable } from 'react-native';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import FastImageCommon from 'utils/packages/rnFastImage';
import Text from './Text';
import { rem } from './values';
export const HelpCallToAction = memo(({ title, subTitle, onPress, onLight, onDark, style }) => {
    const styles = useStyles(styleSet);
    const [isHovering, setIsHovering] = useState(false);
    const handleHoverIn = useCallback(() => {
        setIsHovering(true);
    }, []);
    const handleHoverOut = useCallback(() => {
        setIsHovering(false);
    }, []);
    return (<Pressable onPress={onPress} style={[
            styles.helpHint,
            onLight && styles.onLight,
            onDark && styles.onDark,
            style,
        ]} onHoverIn={handleHoverIn} onHoverOut={handleHoverOut}>
        <FastImageCommon source={require('./img/pencil.png')} style={styles.image}/>
        <View style={styles.textContainer}>
          <Text TextThin-14 Gray>
            {title}
          </Text>
          <Text Text-14 Primary underline={isHovering}>
            {subTitle}
          </Text>
        </View>
      </Pressable>);
});
const styleSet = createStyleSheets((colors) => ({
    helpHint: {
        marginHorizontal: rem(16),
        padding: rem(12),
        borderRadius: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.background.dark,
    },
    textContainer: {
        marginStart: rem(8),
        flexShrink: 1,
    },
    onLight: {
        backgroundColor: colors.cards.onLight,
    },
    onDark: {
        backgroundColor: colors.cards.onDark,
    },
    image: {
        width: rem(44),
        height: rem(44),
    },
}));
