export var Gender;
(function (Gender) {
    Gender["M"] = "M";
    Gender["F"] = "F";
    Gender["O"] = "O";
})(Gender || (Gender = {}));
export var Title;
(function (Title) {
    Title["Mr"] = "Mr";
    Title["Mrs"] = "Mrs";
    Title["Miss"] = "Miss";
    Title["Ms"] = "Ms";
    Title["Dr"] = "Dr";
    Title["Other"] = "Other";
})(Title || (Title = {}));
export var Frequencies;
(function (Frequencies) {
    Frequencies["Weekly"] = "Weekly";
    Frequencies["EveryTwoWeeks"] = "EveryTwoWeeks";
    Frequencies["EveryFourWeeks"] = "EveryFourWeeks";
    Frequencies["EveryMonth"] = "EveryMonth";
    Frequencies["Monthly"] = "Monthly";
    Frequencies["TwiceAMonth"] = "TwiceAMonth";
    Frequencies["LastWorkingDay"] = "LastWorkingDay";
    Frequencies["FixedDate"] = "FixedDate";
})(Frequencies || (Frequencies = {}));
export var FrequencyTypes;
(function (FrequencyTypes) {
    FrequencyTypes["Weekly"] = "WEEKLY";
    FrequencyTypes["Monthly"] = "MONTHLY";
})(FrequencyTypes || (FrequencyTypes = {}));
export var BankLoginStatus;
(function (BankLoginStatus) {
    BankLoginStatus["New"] = "NEW";
    BankLoginStatus["Pending"] = "PENDING";
    BankLoginStatus["Syncing"] = "SYNCING";
    BankLoginStatus["Ready"] = "READY";
    BankLoginStatus["SyncFailed"] = "SYNC_FAILED";
    BankLoginStatus["WrongCredentials"] = "WRONG_CREDENTIALS";
    BankLoginStatus["TemporaryAccountLock"] = "TEMPORARY_ACCOUNT_LOCK";
    BankLoginStatus["PermanentAccountLock"] = "PERMANENT_ACCOUNT_LOCK";
    BankLoginStatus["UserInputRequired"] = "USER_INPUT_REQUIRED";
    BankLoginStatus["AccessRevoked"] = "ACCESS_REVOKED";
    BankLoginStatus["PandingManual"] = "PENDING_MANUAL";
    BankLoginStatus["SyncingManual"] = "SYNCING_MANUAL";
    BankLoginStatus["InteractiveLoginRequired"] = "INTERACTIVE_LOGIN_REQUIRED";
    BankLoginStatus["BankPaused"] = "BANK_PAUSED";
    BankLoginStatus["MustMigrateToOB"] = "MUST_MIGRATE_TO_OB";
    BankLoginStatus["MustMigrate"] = "MUST_MIGRATE";
    BankLoginStatus["ConsentRequired"] = "CONSENT_REQUIRED";
})(BankLoginStatus || (BankLoginStatus = {}));
export var AnalyticsLoadingState;
(function (AnalyticsLoadingState) {
    AnalyticsLoadingState["INITIAL"] = "INITIAL";
    AnalyticsLoadingState["LOADING"] = "LOADING";
    AnalyticsLoadingState["RELOADING"] = "RELOADING";
    AnalyticsLoadingState["ERROR"] = "ERROR";
    AnalyticsLoadingState["LOADED"] = "LOADED";
})(AnalyticsLoadingState || (AnalyticsLoadingState = {}));
export function isLoadedState(loadingState) {
    return (loadingState === AnalyticsLoadingState.RELOADING ||
        loadingState === AnalyticsLoadingState.LOADED);
}
export function isNotLoadedState(loadingState) {
    return (loadingState === AnalyticsLoadingState.INITIAL ||
        loadingState === AnalyticsLoadingState.LOADING ||
        loadingState === AnalyticsLoadingState.ERROR);
}
export function isNotLoadedStateForMerchant(loadingState) {
    return (loadingState === AnalyticsLoadingState.LOADING ||
        loadingState === AnalyticsLoadingState.ERROR);
}
export var TransactionDebitCreditType;
(function (TransactionDebitCreditType) {
    TransactionDebitCreditType["DEBIT"] = "DEBIT";
    TransactionDebitCreditType["CREDIT"] = "CREDIT";
})(TransactionDebitCreditType || (TransactionDebitCreditType = {}));
export var RewardTypes;
(function (RewardTypes) {
    RewardTypes["Health"] = "HEALTH_FITNESS";
    RewardTypes["Popular"] = "POPULAR";
    RewardTypes["TopOffers"] = "TOP_OFFERS";
    RewardTypes["NewOffers"] = "LATEST_OFFERS";
    RewardTypes["Entertainment"] = "TV_PHONE";
    RewardTypes["Fashion"] = "FASHION";
    RewardTypes["Food"] = "FOOD";
    RewardTypes["Home"] = "HOME";
    RewardTypes["Tech"] = "TECH";
    RewardTypes["Beauty"] = "BEAUTY";
    RewardTypes["Travel"] = "TRAVEL";
})(RewardTypes || (RewardTypes = {}));
