import React, { useMemo } from 'react';
import { Alert } from 'react-native';
import emoji from 'node-emoji';
import { Indicator } from 'design-system/Indicator';
import { Avatar } from 'design-system/avatar';
import { CIRCLE_BORDER_SIZE, CIRCLE_SIZE } from 'design-system/LogoBankAccount';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { selectUserId } from 'reducers/selectors';
import { IconTrash } from 'design-system/icons';
import EditSpaceItem from '../components/EditSpaceItem';
import { useDeleteSpaceMember } from '../hooks';
const SpaceMemberItem = ({ spaceId, ownerId, member, disabled, hasAccounts, onRemoved, }) => {
    const { id, handle, firstName, lastName, imageUrl } = member;
    const myId = useAppSelector(selectUserId);
    const myName = useAppSelector((store) => store.user.user);
    const { firstName: myFirstName, lastName: myLastName } = myName;
    const [removable, name, desc, firstLetter, lastLetter] = useMemo(() => {
        if (id === myId)
            return [
                ownerId !== myId,
                'Me',
                ownerId === myId ? 'Owner' : 'Member',
                myFirstName ?? '?',
                myLastName ?? '',
            ];
        return [
            ownerId === myId,
            firstName ?? lastName ?? handle,
            `@${handle}`,
            firstName ?? '?',
            lastName ?? '',
        ];
    }, [id, myId, ownerId, myFirstName, myLastName, firstName, lastName, handle]);
    const [remove, removing] = useDeleteSpaceMember(spaceId, () => {
        onRemoved?.();
    });
    const onRemove = () => {
        if (hasAccounts) {
            Alert.alert(emoji.emojify('Slow down :face_with_rolling_eyes:'), myId === member.id
                ? `You still have accounts in this space. Move your accounts to another space before removing yourself.`
                : `${member.firstName} still has accounts in this space. They'll need to move them to another space before you can remove them.`);
        }
        else if (!removing)
            remove(id);
    };
    const colors = useColors();
    const leftIcon = (<Avatar name={firstLetter} lastName={lastLetter} size={rem(CIRCLE_SIZE)} color={colors.background.brandLight} fontColor={colors.text.textOnBrand} imageUrl={imageUrl} borderWidth={CIRCLE_BORDER_SIZE} medium/>);
    return removable ? (<EditSpaceItem title={name} subTitle={desc} leftIcon={leftIcon} rightIcon={removing ? (<Indicator dark/>) : (<IconTrash color={colors.elements.secondary}/>)} onPressRight={removing || disabled ? undefined : onRemove}/>) : (<EditSpaceItem title={name} subTitle={desc} leftIcon={leftIcon}/>);
};
export default SpaceMemberItem;
