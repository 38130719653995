import React, { useMemo } from 'react';
import { View } from 'react-native';
import { selectAccountsMap } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import LogoBankAccount from 'design-system/LogoBankAccount';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { rem } from 'design-system';
export const TransactionBankLogo = ({ id, isSmall, }) => {
    const styleSheet = useMemo(() => getStyleSheet(isSmall), [isSmall]);
    const styles = useStyles(styleSheet);
    const accountsMap = useAppSelector(selectAccountsMap);
    if (id == null)
        return null;
    const bankAccount = accountsMap[id];
    if (!bankAccount)
        return null;
    return (<View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.logo}>
          <LogoBankAccount light={isSmall} data={bankAccount}/>
        </View>
      </View>
    </View>);
};
const getStyleSheet = (isSmall) => createStyleSheets((colors) => ({
    container: {
        position: 'absolute',
        right: isSmall ? rem(-5) : rem(-2),
        bottom: isSmall ? rem(-1) : rem(-2),
        width: isSmall ? rem(20) : rem(26),
        height: isSmall ? rem(20) : rem(26),
    },
    innerContainer: {
        position: 'absolute',
        transform: [{ scale: isSmall ? 0.4 : 0.5 }],
        transformOrigin: 'top left',
    },
    logo: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 1,
        backgroundColor: isSmall
            ? colors.borders.logoStroke
            : colors.borders.iconStroke,
        borderWidth: isSmall ? 1 : 2,
        borderColor: isSmall
            ? colors.borders.logoStroke
            : colors.borders.iconStroke,
        borderRadius: 999,
        overflow: 'hidden',
    },
}));
