import React, { useCallback, memo } from 'react';
import { StyleSheet } from 'react-native';
import { Avatar } from 'design-system/avatar';
import { IconProfile } from 'design-system/icons/IconProfile';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { useNavigationWebState } from 'hooks/useNavigationWebState';
import { AVATAR_SIZE } from './constants';
const hitSlop = { left: 5, right: 5, top: 15, bottom: 15 };
const AvatarFeed = () => {
    const { currentScreen } = useNavigationWebState();
    const isHighlight = currentScreen === 'AccountSettings';
    const colors = useColors();
    const navigation = useMainStackNavigation();
    const firstName = useAppSelector((store) => store.user.user.firstName);
    const lastName = useAppSelector((store) => store.user.user.lastName);
    const imageUrl = useAppSelector((store) => store.user.user.userImage?.url);
    const userId = useAppSelector((store) => store.user.user.id);
    const onPressAvatar = useCallback(() => {
        navigation.navigate('AccountSettings');
    }, []);
    return (<TouchableOpacity hoverOnDark onPress={onPressAvatar} style={[
            styles.avatarContainer,
            {
                backgroundColor: isHighlight
                    ? colors.borders.iconStroke
                    : colors.background.dark,
            },
        ]} hitSlop={hitSlop}>
      {userId === -1 ? (<IconProfile colorKey="brand"/>) : (<Avatar name={firstName} size={AVATAR_SIZE} lastName={lastName} fontColor={colors.text.secondary} imageUrl={imageUrl} color={colors.background.transparent} borderWidth={2} style={{
                borderColor: imageUrl
                    ? colors.borders.iconStroke
                    : colors.text.secondary,
            }} small/>)}
    </TouchableOpacity>);
};
const styles = StyleSheet.create({
    avatarContainer: {
        overflow: 'hidden',
        borderRadius: 12,
        width: 56,
        height: 56,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default memo(AvatarFeed);
