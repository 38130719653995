import { StyleSheet } from 'react-native';
import React, { useCallback } from 'react';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import { Button } from 'design-system/Button';
import Squircle from 'design-system/Squircle';
import { TextButtonLinkSeeAll } from 'design-system/TextButton';
import { IconArrowBack } from 'design-system/icons/IconArrowBack';
import IconPlus from 'design-system/icons/IconPlus';
import Card from 'features/feed/components/Card';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import RowSubscription from 'features/subscriptions/components/row/RowSubscription';
import SwitchingButton from 'features/subscriptions/components/row/SwitchingButton';
import useColors from 'hooks/useColors';
import { selectFeatureFlags } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import Amplitude from 'utils/amplitude';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import useOpenSubscription from 'features/subscriptions/hooks/useOpenSubscription';
import UpgradeToSeeMoreCard from 'features/subscriptions/components/UpgradeToSeeMoreCard';
import { SUBSCRIPTIONS_ON_FREE_TIER } from 'features/subscriptions/constants';
import Section from '../Section';
import SubscriptionsSummaryCard from './SubscriptionsSummaryCard';
import useSeeAllSubscriptions from './useSeeAllSubscriptions';
const strings = {
    subscriptions: 'Recurring payments',
    trackYourSubscriptions: 'Track your recurring payments',
    seeAllInOnePlace: 'See all your upcoming payments in one place',
    addPayment: 'Add payment',
};
const Subscriptions = ({ onLayout }) => {
    const navigation = useMainStackNavigation();
    const onPressSeeAll = useSeeAllSubscriptions();
    const subscriptions = useAppSelector((store) => store.feed.feed.cards.subscriptions);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'recurringPayments',
    });
    const onOpenCompare = useCallback((routeWithParams) => {
        navigation.navigate(routeWithParams);
        Amplitude.logEvent('Feed.openCompare', { type: routeWithParams.name });
    }, []);
    const openSubscription = useOpenSubscription();
    const onSubscriptionPressed = useCallback((data) => {
        Amplitude.logEvent('Feed.OpenSingleSubscription');
        openSubscription(data.id);
    }, [openSubscription]);
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const colors = useColors();
    const empty = !subscriptions || subscriptions.length <= 0;
    const featureFlags = useAppSelector(selectFeatureFlags);
    const flagValue = featureFlags.subscriptions_in_feed?.value;
    return (<Section title={strings.subscriptions} button={!empty && <TextButtonLinkSeeAll onPress={onPressSeeAll}/>} onLayout={onLayout}>
      {empty ? (<Card style={[styles.container, styles.emptyContainer]}>
          <Squircle color={colors.background.dark} style={styles.emptyIcon}>
            <IconArrowBack gradientKey="darkPink"/>
          </Squircle>
          <Text Text-16 style={styles.emptyTitle}>
            {strings.trackYourSubscriptions}
          </Text>
          <Text TextThin-14 Secondary style={styles.emptyDescription}>
            {strings.seeAllInOnePlace}
          </Text>
          <Button square brandLight title={strings.addPayment} onPress={onPressSeeAll} leftIcon={<IconPlus width={rem(10.6)} height={rem(10.6)} color={colors.text.textOnBrand}/>} hug containerStyle={styles.buttonContainer}/>
        </Card>) : (<Card style={styles.container} webPadding>
          {!isUnlockedForSpace &&
                (flagValue === 'with_total' || flagValue === 'with_total_blurred') ? (<SubscriptionsSummaryCard subscriptions={subscriptions} onPressSeeAll={onPressSeeAll} blur={flagValue === 'with_total_blurred'}/>) : (subscriptions.map((item, index) => (<React.Fragment key={item.id}>
                {index === SUBSCRIPTIONS_ON_FREE_TIER &&
                    !isUnlockedForSpace && (<UpgradeToSeeMoreCard withPadding={false} onPressUpgrade={onPressUpgrade}/>)}
                <RowSubscription data={item} onPress={onSubscriptionPressed} switchingButton={<SwitchingButton key={item.id} data={item} guessedHomeCountry={guessedHomeCountry} onOpenCompare={onOpenCompare}/>} blurValues={!isUnlockedForSpace && index >= SUBSCRIPTIONS_ON_FREE_TIER}/>
              </React.Fragment>)))}
        </Card>)}
    </Section>);
};
const styles = StyleSheet.create({
    container: {
        marginHorizontal: rem(16),
    },
    emptyContainer: {
        padding: rem(16),
    },
    emptyIcon: {
        marginBottom: rem(16),
    },
    emptyTitle: {
        marginBottom: rem(4),
    },
    emptyDescription: {
        fontWeight: '300',
        marginBottom: rem(16),
    },
    buttonContainer: {
        alignSelf: 'flex-start',
        paddingHorizontal: rem(24),
    },
});
export default Subscriptions;
