import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { View, Alert, ScrollView, StyleSheet, TouchableOpacity, } from 'react-native';
import emoji from 'node-emoji';
import { CommonActions } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/elements';
import { format, isValid, isSameDay, parseISO } from 'date-fns';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import { Spacer } from 'design-system/Spacer';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import SafeAreaTop from 'design-system/SafeAreaTop';
import DateInput, { toDate } from 'design-system/date/DateInput';
import KeyboardAvoidingContainer from 'design-system/KeyboardAvoidingContainer';
import useColors from 'hooks/useColors';
import { useFetchOld, muteAll, muteErrorAndResultButThrows } from 'hooks';
import { changeTransactionDate } from 'actions';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import { isWeb } from '../../../constants';
import { useTransaction } from '../hooks/useTransaction';
import useRateAppAfterTransactionEdit from '../hooks/useRateAppAfterTransactionEdit';
const strings = {
    title: 'What date would you like to\xa0set?',
};
const EditDateScreen = ({ navigation, transaction, route, }) => {
    const { groupTxn, onSave } = route.params;
    const [, , requesting, fetch] = useFetchOld(isWeb ? muteErrorAndResultButThrows : muteAll);
    const navHeaderHeight = useHeaderHeight();
    const rateApp = useRateAppAfterTransactionEdit();
    const [time, setTime] = useState(() => {
        const { bookingDate, customDate } = transaction || {};
        const date = customDate || bookingDate || groupTxn?.date || new Date().toISOString();
        return toDate(parseISO(date));
    });
    const openFixedPrediction = useRequiredBenefitCallbackWithSpaces(useCallback(async (date) => {
        try {
            if (transaction) {
                if (isWeb) {
                    await fetch(changeTransactionDate(transaction, date));
                }
                else {
                    fetch(changeTransactionDate(transaction, date));
                }
            }
            if (navigation.canGoBack()) {
                navigation.goBack();
            }
            else {
                navigation.dispatch(CommonActions.reset({
                    index: 0,
                    routes: [{ name: 'Feed' }],
                }));
            }
            rateApp();
        }
        catch {
            //
        }
    }, [transaction, rateApp]), 'changeTransactionDate');
    const onSubmit = useCallback(() => {
        try {
            const error = () => {
                Alert.alert(emoji.emojify('Come on :face_with_rolling_eyes:'), 'This is clearly not a valid date.');
            };
            const { day, month, year } = time || {};
            if (!day ||
                !month ||
                !year ||
                Number(month) > 12 ||
                Number(day) > 31 ||
                (year && year.length < 4)) {
                error();
                return;
            }
            const date = new Date(Number(year), Number(month) - 1, Number(day));
            if (!isValid(date)) {
                error();
                return;
            }
            if (transaction) {
                openFixedPrediction(format(date, 'yyyy-MM-dd'));
            }
            onSave?.(format(date, 'yyyy-MM-dd'));
        }
        catch {
            //
        }
    }, [time, transaction, openFixedPrediction, onSave]);
    const onPressRevert = useCallback(() => {
        const date = transaction?.bookingDate || groupTxn?.date || new Date().toISOString();
        setTime(toDate(parseISO(date)));
    }, [groupTxn?.date, transaction?.bookingDate]);
    const isUsingOriginal = useMemo(() => {
        if (time?.year && time.month && time.day) {
            const { bookingDate, customDate } = transaction || {};
            const originalDate = parseISO(bookingDate || groupTxn?.date || new Date().toISOString());
            return ((customDate && isSameDay(originalDate, parseISO(customDate))) ||
                isSameDay(originalDate, new Date(Number(time.year), Number(time.month) - 1, Number(time.day))));
        }
        return undefined;
    }, [groupTxn?.date, time.day, time.month, time.year, transaction]);
    return (<AppView isInTab={isWeb}>
      <StatusBar animated barStyle="dark-content"/>
      <SafeAreaTop excludeNavHeader>
        <ScrollView scrollEnabled={false} keyboardShouldPersistTaps="handled" contentContainerStyle={styles.contentContainer}>
          <KeyboardAvoidingContainer keyboardVerticalOffset={navHeaderHeight}>
            <View style={styles.inputContainer}>
              {(!!transaction || !!groupTxn) && (<View style={styles.titleContainer}>
                  <Text Text-24>{strings.title}</Text>
                  <Spacer h={8}/>
                  <Text TextThin-16 Secondary style={[isUsingOriginal && styles.hidden]}>
                    The original one was{' '}
                    {format(parseISO(transaction?.bookingDate ||
                groupTxn?.date ||
                new Date().toISOString()), 'do MMMM, yyyy')}
                  </Text>
                </View>)}
              {!!time && <DateInput onChange={setTime} state={time} onDark/>}
            </View>
            {isUsingOriginal !== undefined && (<View>
                <TouchableOpacity style={[
                styles.revertButton,
                isUsingOriginal && styles.hidden,
            ]} onPress={onPressRevert} disabled={isUsingOriginal}>
                  <Text Text-16 Secondary>
                    Revert to original
                  </Text>
                </TouchableOpacity>
                <Button brand title="Save" isFetching={requesting} onPress={onSubmit}/>
              </View>)}
          </KeyboardAvoidingContainer>
        </ScrollView>
      </SafeAreaTop>
    </AppView>);
};
const EditDateScreenWrapper = (props) => {
    const { id, groupTxn } = props.route.params;
    const { transaction, LoadingOr404 } = useTransaction(id);
    const colors = useColors();
    useLayoutEffect(() => {
        props.navigation.setOptions({
            headerTintColor: colors.text.primary,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colors.text.primary]);
    if (!transaction && !groupTxn) {
        return LoadingOr404;
    }
    return <EditDateScreen {...props} transaction={transaction}/>;
};
const styles = StyleSheet.create({
    titleContainer: {
        marginBottom: rem(64),
    },
    inputContainer: {
        flex: 1,
    },
    revertButton: {
        marginBottom: rem(16),
        alignSelf: 'center',
    },
    hidden: {
        opacity: 0,
    },
    contentContainer: {
        flexGrow: 1,
    },
});
export default EditDateScreenWrapper;
