import React, { memo, useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, { Extrapolation, interpolate, useAnimatedStyle, } from 'react-native-reanimated';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import UpgradeCard from 'design-system/UpgradeCard';
import IconPieChart from 'design-system/icons/IconPieChart';
import NotificationBanner from 'design-system/NotificationBanner';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import { IconCreditCard } from 'design-system/icons/IconCreditCard';
import { CardView } from 'design-system/CardView';
import normalize from 'common/Normalize';
import { useHeaderHeight } from 'hooks';
import { isDefaultCategory } from 'hooks/useCategory';
import useColors from 'hooks/useColors';
import { getCategoryExpenses, getMerchantExpenses } from 'actions';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { formatPeriod } from 'utils/formatting';
import { selectDefaultCurrency } from 'reducers/selectors';
import { selectCategoryTotals, selectCurrentMonthlyTotals, } from '../../selectors';
import CategoriesHistogram from './CategoriesHistogram';
import CategoryValueCard from './CategoryValueCard';
const getPeriodAverage = (data) => {
    if (!data.length)
        return null;
    let sum = 0;
    let value = 0;
    for (let index = 0; index < data.length - 1; index++) {
        value = data[index].spending ? data[index].spending : data[index].income;
        sum += value;
    }
    const average = sum / (data.length - 1);
    return average;
};
const CategoryTopContainer = ({ scrollY, categoryId, merchantId, step, accountIds, initialPositionDate, defaultAmount, defaultFrom, defaultTo, to, onSelectedPeriod, }) => {
    // We don't pass this down as we don't want this component recreating each time by the list header changing
    const categoryTotals = useAppSelector(selectCategoryTotals);
    const monthlyTotals = useAppSelector(selectCurrentMonthlyTotals);
    // We can't really preload the graph as we don't know the ranges if the to isn't equal
    // (useOpenCategory generates a custom range that may be different from the category totals)
    const preloadGraph = monthlyTotals[0].to === to;
    const lineGraph = useMemo(() => {
        if (categoryTotals.graph || !preloadGraph) {
            return categoryTotals.graph;
        }
        return monthlyTotals.map((total) => ({ ...total, value: 0, spending: 0 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryTotals.graph]);
    const dispatch = useAppDispatch();
    const onMonthSelected = useCallback((data) => {
        const { graph = [] } = categoryTotals;
        if (!graph[data]) {
            return;
        }
        const { from, to } = graph[data];
        if (categoryId) {
            dispatch(getCategoryExpenses(from, to, categoryId, accountIds));
        }
        else if (merchantId) {
            dispatch(getMerchantExpenses(from, to, merchantId, accountIds));
        }
        onSelectedPeriod({
            selectedPeriodStartDate: from,
            selectedPeriodEndDate: to,
            index: data,
        });
    }, [
        accountIds,
        categoryId,
        categoryTotals,
        dispatch,
        merchantId,
        onSelectedPeriod,
    ]);
    const currency = useAppSelector(selectDefaultCurrency);
    const [state, setState] = useState({
        titleCurrency: currency,
        titleAmount: defaultAmount ? Math.abs(defaultAmount) : 0,
        subTitle: defaultFrom && defaultTo
            ? formatPeriod({ from: defaultFrom, to: defaultTo })
            : '',
        selectedIndex: preloadGraph
            ? monthlyTotals.map((e) => e.from).indexOf(initialPositionDate)
            : 0,
    });
    const initialIndexSet = useRef(false);
    useEffect(() => {
        if (categoryTotals.graph) {
            const index = initialIndexSet.current
                ? state.selectedIndex
                : categoryTotals.graph.map((e) => e.from).indexOf(initialPositionDate);
            initialIndexSet.current = true;
            selected(index, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryTotals.graph]);
    const selected = useCallback((data, isSettingTitle) => {
        const { graph } = categoryTotals;
        let from = null;
        let to = null;
        let currency = null;
        let value = 0;
        if (graph && graph[data] && graph[data].from) {
            from = graph[data].from;
            to = graph[data].to;
            currency = graph[data].currency;
            value = graph[data].value;
        }
        const subTitle = from && to ? formatPeriod({ from, to }) : '';
        const titleAmount = value >= 0 ? value : Math.abs(value);
        setState({
            ...state,
            subTitle,
            titleAmount,
            titleCurrency: currency || 'GBP',
            selectedIndex: data,
        });
        if (!isSettingTitle)
            onMonthSelected(data);
    }, [categoryTotals, onMonthSelected, state]);
    const { subTitle, titleAmount, titleCurrency } = state;
    const headerHeight = useHeaderHeight();
    const imageOpacity = useAnimatedStyle(() => ({
        opacity: interpolate(scrollY.value, [0, headerHeight * 2], [1, 0.1], Extrapolation.CLAMP),
    }), [headerHeight]);
    const average = useMemo(() => {
        if (!categoryTotals.graph || !lineGraph) {
            return undefined;
        }
        return getPeriodAverage(lineGraph && lineGraph[0] && lineGraph[0].isPayday
            ? lineGraph.slice(1)
            : lineGraph);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineGraph]);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'customCategories',
    });
    const { isUnlockedForSpace: isSearchUnlockedForSpace, onPressUpgrade: onPressUpgradeForSearch, } = useShouldShowUpgrade({
        benefitId: 'search',
    });
    const colors = useColors();
    const getIcon = useCallback(() => {
        const id = categoryId || (merchantId !== undefined ? String(merchantId) : 'general');
        if (isDefaultCategory(id)) {
            return <IconTransactionCategory category={{ id }}/>;
        }
        return <IconCreditCard color={colors.elements.brand}/>;
    }, [categoryId, merchantId, colors.elements.brand]);
    if (categoryId !== undefined &&
        !isUnlockedForSpace &&
        !isDefaultCategory(categoryId)) {
        return (<View>
        <Spacer.H16 />
        <UpgradeCard title="See your spending" description="Upgrade your plan to continue tracking your custom categories" icon={<IconPieChart />} onPressUpgrade={onPressUpgrade}/>
      </View>);
    }
    const valueOnly = step === 'custom' || merchantId === -1 || categoryId === 'internal';
    const blurValue = !isSearchUnlockedForSpace && state.selectedIndex >= 2;
    return (<View>
      {valueOnly ? (<Spacer.H16 />) : (<CardView style={styles.histogramCard}>
          <Animated.View style={[styles.histogramContainer, imageOpacity]}>
            {lineGraph && (<CategoriesHistogram lineGraph={lineGraph} categoryMerchantSelected={categoryId || merchantId || null} selectedIndex={state.selectedIndex} onMonthSelected={selected} average={average
                    ? { amount: average, currency: titleCurrency }
                    : undefined}/>)}
          </Animated.View>
        </CardView>)}
      {blurValue && (<View>
          <NotificationBanner title="Unlock enhanced tracking" description="Enhanced tracking is available across all our premium plans. Get started today by learning more." positiveButtonTitle="Learn more" onPositiveBtnPress={onPressUpgradeForSearch}/>
        </View>)}

      <View style={styles.valueContainer}>
        <CategoryValueCard icon={getIcon()} value={{
            amount: titleAmount,
            currency: titleCurrency,
        }} blurValue={blurValue}>
          {subTitle}
        </CategoryValueCard>
      </View>
    </View>);
};
const styles = StyleSheet.create({
    histogramContainer: {
        height: normalize(154),
        marginVertical: rem(32),
    },
    histogramCard: {
        flex: 1,
        overflow: 'visible',
        marginHorizontal: rem(16),
    },
    valueContainer: {
        flexDirection: 'row',
        marginHorizontal: rem(12),
    },
});
export default memo(CategoryTopContainer);
