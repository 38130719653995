import React from 'react';
import { View, StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
const ExcludedInfoModalContent = ({ closeModal }) => (<View style={styles.container}>
    <Spacer h={8}/>
    <Text TextThin-14 Secondary centered>
      Here you will find all the transactions that belong to the Excluded
      category. This must be used to hide transactions from analytics and
      budgeting, such as internal transfers and credit card repayments.
    </Text>
    <Spacer h={40}/>
    <Button title="Good to know!" brandReversed onPress={() => {
        closeModal();
    }}/>
  </View>);
export default ExcludedInfoModalContent;
const styles = StyleSheet.create({
    container: {
        paddingHorizontal: rem(24),
    },
});
