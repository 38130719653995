import React, { memo } from 'react';
import { TouchableOpacity, View } from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import Text from 'design-system/Text';
import { rem } from 'design-system';
import IconCopy from 'design-system/icons/IconCopy';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
const TransactionFooter = ({ realCounterpartName, account, }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    const name = realCounterpartName?.toUpperCase();
    const onPressName = () => {
        if (name) {
            Clipboard.setString(name);
        }
    };
    return (<View style={styles.container}>
      {name && (<TouchableOpacity onPress={onPressName} style={styles.transactionFooter}>
          <Text Text-16 Gray style={styles.name}>
            {name}
          </Text>
          <IconCopy fill={colors.elements.secondary} width={20} height={20} style={styles.copyIcon}/>
        </TouchableOpacity>)}
      {account && (<View style={styles.accountLabel}>
          {account.name ? (<Text TextThin-16 Gray numberOfLines={1} style={styles.accountLabelText}>
              {account.name}
            </Text>) : null}
          {Boolean(account.accountNumber?.length) && (<Text TextThin-16 OffBlack style={styles.accountNumberText}>
              *** {account.accountNumber}
            </Text>)}
        </View>)}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    closeIconContainer: {
        padding: rem(4),
        borderRadius: rem(8),
        backgroundColor: colors.background.transparent,
    },
    container: {
        paddingHorizontal: rem(16),
        flexGrow: 0,
    },
    transactionFooter: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    name: {
        textAlign: 'center',
        flexShrink: 1,
    },
    accountLabel: {
        marginTop: rem(10),
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'space-between',
    },
    accountLabelText: {
        flexShrink: 1,
    },
    accountNumberText: {
        paddingStart: rem(16),
    },
    copyIcon: {
        marginHorizontal: rem(12),
    },
}));
export default memo(TransactionFooter);
