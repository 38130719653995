import React, { memo, useMemo, useState, useCallback, useLayoutEffect, } from 'react';
import { View, Text, Platform, } from 'react-native';
import Animated, { interpolate, useSharedValue, interpolateColor, useAnimatedStyle, useAnimatedScrollHandler, } from 'react-native-reanimated';
import LinearGradient from 'react-native-linear-gradient';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import StatusBar from 'design-system/StatusBar';
import useStyles from 'hooks/useStyles';
import { useHeaderHeight } from 'hooks';
import useColors from 'hooks/useColors';
import useColorScheme from 'hooks/useColorScheme';
import createStyleSheets from 'utils/createStyleSheets';
import { usePureHeaderHeight } from 'hooks/useHeaderHeight';
import { useNativeStackScreenOptions } from 'hooks/navigation/useStackNavigatorScreenOptions';
import StackHeader from './StackHeader';
import { isWeb } from '../../constants';
const LinearGradientAnimated = Animated.createAnimatedComponent(LinearGradient);
const headerWidth = isWeb ? 600 : 250;
const withAnimatedNavigationHeader = (ScreenComponent, screenOptionsRaw) => {
    const Wrapper = (props) => {
        const scrollY = useSharedValue(0);
        const { navigation } = props;
        const styles = useStyles(styleSet);
        const colors = useColors();
        const { top } = useSafeAreaInsets();
        const screenOptions = useMemo(() => typeof screenOptionsRaw === 'function'
            ? screenOptionsRaw({
                route: props.route,
                navigation: props.navigation,
                colors,
            })
            : screenOptionsRaw, [colors, props.navigation, props.route]);
        const scroll = useCallback((scrollYValue = 0) => {
            scrollY.value = scrollYValue;
        }, [scrollY]);
        const onScroll = useCallback(({ nativeEvent }) => {
            scrollY.value = nativeEvent.contentOffset.y;
        }, [scrollY]);
        const scrollHandler = useAnimatedScrollHandler((handle) => {
            scrollY.value = handle.contentOffset.y;
        }, []);
        const renderContent = screenOptions?.renderContent;
        const globalScreenOptions = useNativeStackScreenOptions();
        useLayoutEffect(() => {
            const childOptions = screenOptions;
            const headerTitleStyle = screenOptions.headerTitleStyle ||
                globalScreenOptions.headerTitleStyle;
            navigation.setOptions({
                ...(!screenOptions.hasCustomTitle && {
                    headerTitle: screenOptions.hideTitleOnScroll
                        ? () => (<HeaderTitle title={screenOptions.title} headerTitleStyle={headerTitleStyle} scrollY={scrollY} hideTitleOnScroll={childOptions.hideTitleOnScroll}/>)
                        : () => (<StackHeader width={headerWidth} title={screenOptions.title || ''}/>),
                }),
                ...(renderContent ? { headerShown: false } : {}),
                ...(isWeb ? { title: screenOptions.title } : {}),
                ...(isWeb &&
                    screenOptions.pageTitle && {
                    title: screenOptions.pageTitle,
                }),
                headerTintColor: colors.text.primary,
                headerTransparent: true,
            });
        }, [
            colors.text.primary,
            globalScreenOptions.headerTitleStyle,
            renderContent,
            screenOptions,
            scrollY,
        ]);
        const theme = useColorScheme();
        const { headerColorOutputRange } = screenOptions;
        const headerColors = useMemo(() => headerColorOutputRange ||
            [
                colors.background.dark,
                theme === 'light' ? colors.background.light : colors.background.dark,
            ], [
            colors.background.dark,
            colors.background.light,
            headerColorOutputRange,
            theme,
        ]);
        const headerStyle = useAnimatedStyle(() => ({
            backgroundColor: interpolateColor(scrollY.value, [0, 40], headerColors),
            shadowOpacity: interpolate(scrollY.value, [0, 40], [0, 1]),
        }), [headerColors, colors, theme]);
        const androidShadowStyle = useAnimatedStyle(() => ({
            opacity: interpolate(scrollY.value, [0, 40], [0, 1]),
        }), []);
        const pureHeaderHeight = usePureHeaderHeight();
        const headerMinHeight = useHeaderHeight(pureHeaderHeight);
        const [headerHeight, setHeaderHeight] = useState(headerMinHeight);
        const onLayout = (layoutEvent) => {
            setHeaderHeight(Math.max(headerMinHeight, layoutEvent.nativeEvent.layout.height));
        };
        const mainContainerStyles = useMemo(() => [
            styles.flex,
            {
                backgroundColor: headerColors[0],
            },
        ], [headerColors, styles.flex]);
        const screen = useMemo(() => (<ScreenComponent {...props} onScrollY={scroll} onScroll={onScroll} scrollY={scrollY} scrollHandler={scrollHandler}/>), [onScroll, props, scroll, scrollHandler, scrollY]);
        return (<View style={mainContainerStyles}>
        <StatusBar barStyle="dark-content" animated/>
        <Animated.View onLayout={onLayout} style={[
                headerStyle,
                {
                    paddingTop: top,
                    height: screenOptions.headerHeight
                        ? screenOptions.headerHeight + top
                        : headerHeight,
                },
            ]}>
          {(Platform.OS === 'android' || Platform.OS === 'web') && (<>
              {renderContent?.()}
              {screenOptions.Overlay && <screenOptions.Overlay />}
            </>)}
        </Animated.View>
        <View style={styles.screenContent}>{screen}</View>
        {Platform.select({
                ios: (<Animated.View style={[
                        styles.shadow,
                        headerStyle,
                        {
                            paddingTop: top,
                            height: screenOptions.headerHeight
                                ? screenOptions.headerHeight + top
                                : headerHeight,
                        },
                    ]}>
              {renderContent?.()}
              {screenOptions.Overlay && <screenOptions.Overlay />}
            </Animated.View>),
                android: (<LinearGradientAnimated style={[
                        styles.androidShadow,
                        {
                            top: screenOptions.headerHeight
                                ? screenOptions.headerHeight + top
                                : headerHeight,
                        },
                        androidShadowStyle,
                    ]} colors={[
                        colors.shadows.androidShadowGradientStart,
                        colors.shadows.androidShadowGradientEnd,
                    ]}/>),
            })}
      </View>);
    };
    return memo(Wrapper);
};
export const HeaderTitle = ({ headerTitleStyle, title, scrollY, hideTitleOnScroll = false, }) => {
    const styles = useStyles(styleSet);
    const titleContainerStyle = useAnimatedStyle(() => ({
        opacity: interpolate(scrollY?.value, [0, 40], hideTitleOnScroll ? [0, 1] : [1, 1]),
    }));
    return (<Animated.View style={[
            styles.headerTitleContainer,
            titleContainerStyle,
            !hideTitleOnScroll && styles.fixedOpacity,
        ]}>
      <Text style={[
            headerTitleStyle,
            styles.headerTitle,
            Platform.OS === 'android' && { lineHeight: undefined },
        ]}>
        {title}
      </Text>
    </Animated.View>);
};
const styleSet = createStyleSheets((colors) => ({
    flex: {
        flex: 1,
    },
    headerTitleContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerTitle: {
        color: colors.text.primary,
    },
    fixedOpacity: {
        opacity: 1,
    },
    shadow: {
        left: 0,
        right: 0,
        position: 'absolute',
        ...colors.shadows.card,
    },
    androidShadow: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 10,
    },
    screenContent: {
        flex: 1,
        overflow: 'hidden',
    },
}));
export default withAnimatedNavigationHeader;
