export const useFakeData = __DEV__ && false; // please always use __DEV__ && something
export const useFakeOrderPreview = __DEV__ && false;
// export const fakeStatus: invest.TradingAccountStatus | false = __DEV__ && 'ACTIVE';
export const fakeStatus = __DEV__ && false;
// export const fakeRequiredUserAction: invest.IdentityVerificationStep | false = __DEV__ && 'UPLOAD_PROOF_OF_ADDRESS';
export const fakeRequiredUserAction = __DEV__ && false;
export const emulateNoConnectedAccount = __DEV__ && false;
/**
 * This will show "Your top-up is pending" in InvestAccount,
 * and will also trigger periodic fetch for `/pending-top-ups` endpoint for 90 seconds.
 */
export const emulatePendingTopups = __DEV__ && false;
export const fakeAccount = __DEV__ &&
    false && {
    currency: 'GBP',
    availableToInvest: { value: 10000, currency: 'GBP' },
    cashWithdrawable: { value: 3000, currency: 'GBP' },
    cashUnsettled: { value: 2000, currency: 'GBP' },
    cashReserved: { value: 4000, currency: 'GBP' },
    cashTotal: { value: 10000, currency: 'GBP' },
    longPositionsMarketValue: { value: 7500, currency: 'GBP' },
    status: 'ACTIVE',
    requiredUserAction: undefined,
    timestamp: new Date(2021, 1, 1).toISOString(), // date/time when account was created (in ISO format)
};
export const showPortfolioModeSwitch = __DEV__ && false;
export const showUploadDocumentButton = __DEV__ && false;
export const fakeNullsInPortfolioData = __DEV__ && false;
export const disableAnimatedChartRendering = false;
/* Stock Search customizations */
export const LOAD_NEXT_PAGE_RIGHT_AWAY = false;
// test data for bar chart
export const testBars = [
    {
        timestamp: '2025-01-30T14:30:00.000Z',
        priceOpen: 27.84,
        priceHigh: 28.2,
        priceLow: 27.7125,
        priceClose: 28.04,
    },
    {
        timestamp: '2025-01-30T14:35:00.000Z',
        priceOpen: 28.0485,
        priceHigh: 28.18,
        priceLow: 27.94,
        priceClose: 28.1084,
    },
    {
        timestamp: '2025-01-30T14:40:00.000Z',
        priceOpen: 28.1,
        priceHigh: 28.19,
        priceLow: 27.9903,
        priceClose: 28.06,
    },
    {
        timestamp: '2025-01-30T14:45:00.000Z',
        priceOpen: 28.075,
        priceHigh: 28.23,
        priceLow: 28.04,
        priceClose: 28.129,
    },
    {
        timestamp: '2025-01-30T14:50:00.000Z',
        priceOpen: 28.14,
        priceHigh: 28.21,
        priceLow: 28.06,
        priceClose: 28.1129,
    },
    {
        timestamp: '2025-01-30T14:55:00.000Z',
        priceOpen: 28.1417,
        priceHigh: 28.17,
        priceLow: 27.95,
        priceClose: 28,
    },
    {
        timestamp: '2025-01-30T15:00:00.000Z',
        priceOpen: 27.99,
        priceHigh: 28.22,
        priceLow: 27.9412,
        priceClose: 28.215,
    },
    {
        timestamp: '2025-01-30T15:05:00.000Z',
        priceOpen: 28.1991,
        priceHigh: 28.21,
        priceLow: 28.04,
        priceClose: 28.0834,
    },
    {
        timestamp: '2025-01-30T15:10:00.000Z',
        priceOpen: 28.075,
        priceHigh: 28.2188,
        priceLow: 28.07,
        priceClose: 28.1595,
    },
    {
        timestamp: '2025-01-30T15:15:00.000Z',
        priceOpen: 28.16,
        priceHigh: 28.18,
        priceLow: 28.039,
        priceClose: 28.0544,
    },
    {
        timestamp: '2025-01-30T15:20:00.000Z',
        priceOpen: 28.0672,
        priceHigh: 28.07,
        priceLow: 27.92,
        priceClose: 28.04,
    },
    {
        timestamp: '2025-01-30T15:25:00.000Z',
        priceOpen: 28.04,
        priceHigh: 28.04,
        priceLow: 27.82,
        priceClose: 27.8601,
    },
    {
        timestamp: '2025-01-30T15:30:00.000Z',
        priceOpen: 27.8396,
        priceHigh: 28.02,
        priceLow: 27.8101,
        priceClose: 28.0192,
    },
    {
        timestamp: '2025-01-30T15:35:00.000Z',
        priceOpen: 27.99,
        priceHigh: 28.06,
        priceLow: 27.9295,
        priceClose: 28.0305,
    },
    {
        timestamp: '2025-01-30T15:40:00.000Z',
        priceOpen: 28.03,
        priceHigh: 28.0799,
        priceLow: 28.002,
        priceClose: 28.0023,
    },
    {
        timestamp: '2025-01-30T15:45:00.000Z',
        priceOpen: 28.025,
        priceHigh: 28.13,
        priceLow: 28.02,
        priceClose: 28.07,
    },
    {
        timestamp: '2025-01-30T15:50:00.000Z',
        priceOpen: 28.05,
        priceHigh: 28.075,
        priceLow: 27.9301,
        priceClose: 27.94,
    },
    {
        timestamp: '2025-01-30T15:55:00.000Z',
        priceOpen: 27.92,
        priceHigh: 27.9599,
        priceLow: 27.85,
        priceClose: 27.88,
    },
    {
        timestamp: '2025-01-30T16:00:00.000Z',
        priceOpen: 27.8506,
        priceHigh: 28.03,
        priceLow: 27.84,
        priceClose: 27.98,
    },
    {
        timestamp: '2025-01-30T16:05:00.000Z',
        priceOpen: 27.9787,
        priceHigh: 28.0152,
        priceLow: 27.9308,
        priceClose: 27.9308,
    },
    {
        timestamp: '2025-01-30T16:10:00.000Z',
        priceOpen: 27.9568,
        priceHigh: 27.9568,
        priceLow: 27.87,
        priceClose: 27.87,
    },
    {
        timestamp: '2025-01-30T16:15:00.000Z',
        priceOpen: 27.8701,
        priceHigh: 27.8905,
        priceLow: 27.8305,
        priceClose: 27.87,
    },
    {
        timestamp: '2025-01-30T16:20:00.000Z',
        priceOpen: 27.89,
        priceHigh: 27.9594,
        priceLow: 27.89,
        priceClose: 27.93,
    },
    {
        timestamp: '2025-01-30T16:25:00.000Z',
        priceOpen: 27.91,
        priceHigh: 27.97,
        priceLow: 27.91,
        priceClose: 27.97,
    },
    {
        timestamp: '2025-01-30T16:30:00.000Z',
        priceOpen: 27.94,
        priceHigh: 28.02,
        priceLow: 27.9107,
        priceClose: 28.02,
    },
    {
        timestamp: '2025-01-30T16:35:00.000Z',
        priceOpen: 27.98,
        priceHigh: 28,
        priceLow: 27.9004,
        priceClose: 27.9029,
    },
    {
        timestamp: '2025-01-30T16:40:00.000Z',
        priceOpen: 27.9,
        priceHigh: 27.99,
        priceLow: 27.9,
        priceClose: 27.96,
    },
    {
        timestamp: '2025-01-30T16:45:00.000Z',
        priceOpen: 27.931,
        priceHigh: 27.99,
        priceLow: 27.9304,
        priceClose: 27.96,
    },
    {
        timestamp: '2025-01-30T16:50:00.000Z',
        priceOpen: 27.96,
        priceHigh: 28.0399,
        priceLow: 27.96,
        priceClose: 28.025,
    },
    {
        timestamp: '2025-01-30T16:55:00.000Z',
        priceOpen: 28.0135,
        priceHigh: 28.04,
        priceLow: 27.96,
        priceClose: 28.015,
    },
];
