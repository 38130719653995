import React, { useState, useCallback, useEffect, useRef, memo, useMemo } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import Image from 'utils/packages/rnFastImage';
import { SquircleView } from 'react-native-figma-squircle';
import MaskedView from '@react-native-masked-view/masked-view';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assertUnreachable(_x) {
    // we should catch this on typecheck stage
}
const defaultImageSource = require('./img/logo-placeholder.png');
const Logo = ({ uri, fallbackSource, onColoredBackground, resizeMode, onError, onLoad, ...size }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const initialImage = useMemo(() => {
        if (typeof uri === 'number') {
            return uri;
        }
        if (uri) {
            return { uri };
        }
        return fallbackSource ?? defaultImageSource;
    }, [uri, fallbackSource]);
    const imageRef = useRef(initialImage);
    const [image, setImage] = useState(initialImage);
    useEffect(() => {
        if ((typeof initialImage === 'number' && initialImage !== imageRef.current) ||
            (typeof initialImage !== 'number' && imageRef?.current?.uri !== initialImage.uri)) {
            imageRef.current = initialImage;
            setImage(initialImage);
        }
    }, [initialImage]);
    const error = useCallback(() => {
        if (onError) {
            onError();
        }
        imageRef.current = fallbackSource ?? defaultImageSource;
        setImage(fallbackSource ?? defaultImageSource);
    }, [fallbackSource, onError]);
    if ('S64' in size) {
        let outlineColor = colors.background.light;
        if (onColoredBackground) {
            outlineColor = colors.background.transparent;
        }
        else if (size.withOutline) {
            outlineColor = colors.cards.onLight;
        }
        return (<SquircleView style={styles.s64OuterContainer} squircleParams={{
                cornerSmoothing: 1,
                cornerRadius: rem(24),
                fillColor: outlineColor,
            }}>
        <MaskedView style={styles.s64InnerContainer} maskElement={Platform.OS === 'ios' ? (<SquircleView style={styles.s64InnerContainer} squircleParams={{
                    cornerSmoothing: 1,
                    cornerRadius: rem(20),
                    fillColor: 'white',
                }}/>) : (<View style={styles.s64InnerMaskAndroid}/>)}>
          {image !== null && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {size.withExtraPadding ? (<View style={[
                        styles.r64,
                        styles.centered,
                        styles.whiteBackground,
                    ]}>
                  <Image style={[
                        styles.r64WithExtraPadding,
                        styles.whiteBackground,
                    ]} source={image} onError={error} resizeMode={resizeMode}/>
                </View>) : (<Image style={[styles.r64, styles.whiteBackground]} source={image} onError={error} resizeMode={resizeMode}/>)}
            </>)}
        </MaskedView>
      </SquircleView>);
    }
    if ('R24' in size) {
        return (<View style={[styles24.rBg, styles24.rBorder, onColoredBackground ? styles.white20 : styles.gray5]}>
        {image !== null && (<View style={[styles24.r, styles.whiteBackground]}>
            <Image style={[styles24.rImg, styles.whiteBackground]} source={image} onError={error} resizeMode={resizeMode}/>
          </View>)}
      </View>);
    }
    if ('R32' in size ||
        'R36' in size ||
        'R46' in size ||
        'R44' in size ||
        'R56' in size ||
        'R64' in size ||
        'R72' in size) {
        if ('R72' in size) {
            return (<View style={[styles.r72OuterContainer, onColoredBackground ? styles.white20Background : styles.gray5Background]}>
          {image !== null && (<Image style={[styles.r72InnerContainer, styles.whiteBackground]} source={image} onError={error} resizeMode={resizeMode}/>)}
        </View>);
        }
        if ('R64' in size) {
            return (<View style={[styles.r64OuterContainer, onColoredBackground ? styles.white20Background : styles.gray5Background]}>
          {image !== null && (<Image style={[styles.r64InnerContainer, styles.whiteBackground]} source={image} onError={error} resizeMode={resizeMode}/>)}
        </View>);
        }
        if ('R56' in size) {
            const background = 'transparent' in size && size.transparent ? styles.transparent : styles.whiteBackground;
            return (<View style={styles56.r}>
          <View style={[
                    styles.borderContainer,
                    styles.border1,
                    styles56.rBorder,
                    onColoredBackground ? styles.white20 : styles.gray5,
                    'internalPadding' in size && size.internalPadding && background,
                ]}/>
          {image !== null && (<Image style={[
                        styles56.r,
                        'internalPadding' in size && size.internalPadding
                            ? styles56.rInternalPadding
                            : background,
                    ]} source={image} onError={error} onLoad={onLoad} resizeMode={resizeMode}/>)}
        </View>);
        }
        if ('R44' in size) {
            const background = 'transparent' in size && size.transparent ? styles.transparent : styles.whiteBackground;
            return (<View style={styles44.r}>
          <View style={[
                    styles.borderContainer,
                    styles.border1,
                    styles44.rBorder,
                    onColoredBackground ? styles.white20 : styles.gray5,
                    'internalPadding' in size && size.internalPadding && background,
                ]}/>
          {image !== null && (<Image style={[
                        styles44.r,
                        'internalPadding' in size && size.internalPadding
                            ? styles44.rInternalPadding
                            : background,
                    ]} source={image} onError={error} onLoad={onLoad} resizeMode={resizeMode}/>)}
        </View>);
        }
        if ('R32' in size) {
            return (<View style={styles32.r}>
          <View style={[
                    styles.borderContainer,
                    styles.border1,
                    styles32.rBorder,
                    onColoredBackground ? styles.white20 : styles.gray5,
                ]}/>
          {image !== null && (<Image style={[styles32.r, styles.whiteBackground]} source={image} onError={error} resizeMode={resizeMode}/>)}
        </View>);
        }
        if ('R36' in size) {
            return (<View style={styles36.r}>
          <View style={[
                    styles.borderContainer,
                    styles.border1,
                    styles36.rBorder,
                    onColoredBackground ? styles.white20 : styles.gray5,
                ]}/>
          {image !== null && (<Image style={[styles36.r, styles.whiteBackground]} source={image} onError={error} resizeMode={resizeMode}/>)}
        </View>);
        }
        if ('R46' in size) {
            return (<View style={styles46.r}>
          <View style={[
                    styles.borderContainer,
                    styles.border2,
                    styles46.rBorder,
                    onColoredBackground ? styles.white20 : styles.gray5,
                ]}/>
          {image !== null && (<Image style={[styles46.r, styles.whiteBackground]} source={image} onError={error} resizeMode={resizeMode}/>)}
        </View>);
        }
        assertUnreachable(size);
    }
    assertUnreachable(size);
    if (__DEV__) {
        return <View style={styles.unsupportedSizeWarning}/>;
    }
    return null;
};
const r24bg = rem(26);
const r24 = rem(24);
const r24img = rem(18);
const r44 = rem(44);
const r56 = rem(56);
const r32 = rem(32);
const r36 = rem(36);
const r46 = rem(46);
const r52 = rem(52);
const r58 = rem(58);
const r64 = rem(64);
const r72 = rem(72);
export const styleSet = createStyleSheets((colors) => ({
    unsupportedSizeWarning: {
        width: r44,
        height: r44,
        borderRadius: r44,
        backgroundColor: 'red',
    },
    whiteBackground: {
        backgroundColor: colors.background.logo,
    },
    transparent: {
        backgroundColor: undefined,
    },
    borderContainer: { position: 'absolute' },
    border1: { top: -1, bottom: -1, left: -1, right: -1, borderWidth: 1 },
    border2: { top: -2, bottom: -2, left: -2, right: -2, borderWidth: 2 },
    border6: { top: -rem(6), bottom: -rem(6), left: -rem(6), right: -rem(6), borderWidth: rem(6) },
    white20: {
        borderColor: colors.background.transparent,
    },
    gray5: {
        borderColor: colors.borders.logoStroke,
    },
    white20Background: {
        backgroundColor: colors.background.transparent,
    },
    gray5Background: { backgroundColor: colors.cards.transparentOnDark },
    s64OuterContainer: { width: r72, height: r72, alignItems: 'center', justifyContent: 'center' },
    r72OuterContainer: { width: r72, height: r72, borderRadius: r72, alignItems: 'center', justifyContent: 'center' },
    r64OuterContainer: { width: r64, height: r64, borderRadius: r64, alignItems: 'center', justifyContent: 'center' },
    s64InnerContainer: { width: r64, height: r64 },
    s64InnerMaskAndroid: { width: r64, height: r64, borderRadius: rem(20), backgroundColor: 'white' },
    r72InnerContainer: { width: r58, height: r58, borderRadius: r52 },
    r64InnerContainer: { width: r52, height: r52, borderRadius: r52 },
    r64: { width: r64, height: r64 },
    r64WithExtraPadding: { width: r52, height: r52 },
    centered: { alignItems: 'center', justifyContent: 'center' },
}));
export const styles24 = StyleSheet.create({
    rBg: { width: r24bg, height: r24bg, borderRadius: r24bg },
    r: { width: r24, height: r24, borderRadius: r24, alignItems: 'center', justifyContent: 'center' },
    rImg: { width: r24img, height: r24img, borderRadius: r24img },
    rBorder: { borderWidth: rem(1) },
});
export const styles32 = StyleSheet.create({
    r: { width: r32, height: r32, borderRadius: r32 },
    rBorder: { borderRadius: r32 },
});
export const styles36 = StyleSheet.create({
    r: { width: r36, height: r36, borderRadius: r36 },
    rBorder: { borderRadius: r36 },
});
export const styles44 = StyleSheet.create({
    r: { width: r44, height: r44, borderRadius: r44, justifyContent: 'center', alignItems: 'center' },
    rInternalPadding: { width: rem(32), height: rem(32), borderRadius: rem(32) },
    rBorder: { borderRadius: r44 },
});
export const styles56 = StyleSheet.create({
    r: { width: r56, height: r56, borderRadius: r56, justifyContent: 'center', alignItems: 'center' },
    rInternalPadding: { width: rem(32), height: rem(32), borderRadius: rem(32) },
    rBorder: { borderRadius: r56 },
});
export const styles46 = StyleSheet.create({
    r: { width: r46, height: r46, borderRadius: r46 },
    rBorder: { borderRadius: r46 },
});
export default memo(Logo);
