import { StyleSheet, View, } from 'react-native';
import React from 'react';
import * as SVG from 'react-native-svg';
import LinearGradient from 'react-native-linear-gradient';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
export const BadgeBase = ({ style = {}, children, position = 'bottom-right', offset = rem(4), ...props }) => {
    const containerStyles = [
        badgeStyles.absolutePosition,
        badgeStyles.centeredContent,
        style,
        position === 'bottom-right' && {
            right: -offset,
            bottom: -offset,
        },
        position === 'top-right' && { right: -offset, top: -offset },
        position === 'top-left' && { left: -offset, top: -offset },
    ];
    return (<View style={containerStyles} {...props}>
      {children}
    </View>);
};
export const SquareBadge = ({ children, border, fill = 'url(#paint0_linear_8641_12010)', gradientKey = 'lightPink', ...props }) => {
    const width = rem(24);
    const height = rem(24);
    const colors = useColors();
    return (<BadgeBase {...props}>
      <SVG.Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <SVG.Path d="M21.6599 14.5423C21.1947 18.2637 18.2635 21.1949 14.5421 21.6601C12.8539 21.8711 11.1461 21.8711 9.45791 21.6601C5.73649 21.1949 2.80525 18.2637 2.34008 14.5423C2.12906 12.8541 2.12906 11.1463 2.34008 9.45811C2.80526 5.73669 5.73649 2.80545 9.45791 2.34028C11.1461 2.12926 12.8539 2.12926 14.5421 2.34028C18.2635 2.80546 21.1947 5.73669 21.6599 9.45811C21.8709 11.1463 21.8709 12.8541 21.6599 14.5423Z" fill={fill}/>
        {border && (<SVG.Path fillRule="evenodd" clipRule="evenodd" d="M9.1912 0.175069C11.0565 -0.0580932 12.9435 -0.0580931 14.8088 0.175069C19.5228 0.76432 23.2359 4.47738 23.8251 9.19139C24.0583 11.0567 24.0583 12.9437 23.8251 14.809C23.2359 19.523 19.5228 23.2361 14.8088 23.8253C12.9435 24.0585 11.0565 24.0585 9.19119 23.8253C4.47718 23.2361 0.76412 19.523 0.174868 14.809C-0.0582914 12.9437 -0.0582912 11.0567 0.174869 9.19139C0.764122 4.47738 4.47718 0.764319 9.1912 0.175069ZM14.5346 21.6317C18.2451 21.1679 21.1677 18.2453 21.6315 14.5348C21.8419 12.8516 21.8419 11.1488 21.6315 9.4656C21.1677 5.75514 18.2451 2.83254 14.5346 2.36873C12.8514 2.15833 11.1486 2.15833 9.4654 2.36873C5.75494 2.83254 2.83234 5.75514 2.36853 9.4656C2.15813 11.1488 2.15813 12.8516 2.36853 14.5348C2.83234 18.2453 5.75494 21.1679 9.4654 21.6317C11.1486 21.8421 12.8514 21.8421 14.5346 21.6317Z" fill={colors.background.light}/>)}

        <SVG.LinearGradient id="paint0_linear_8641_12010" x1="27.7148" y1="21.8184" x2="-0.75646" y2="21.1012" gradientUnits="userSpaceOnUse">
          <SVG.Stop offset="0.00279598" stopColor={colors.gradients[gradientKey].colors[0]}/>
          <SVG.Stop offset="1" stopColor={colors.gradients[gradientKey].colors[1]}/>
        </SVG.LinearGradient>
      </SVG.Svg>
      <View style={badgeStyles.absolutePosition}>{children}</View>
    </BadgeBase>);
};
export const CircleBadge = ({ children, size = 24, borderColor, backgroundColor, ...props }) => {
    const colors = useColors();
    const badgeStyle = [
        {
            width: rem(size),
            height: rem(size),
            borderRadius: rem(50),
            backgroundColor: borderColor ?? colors.elements.white,
        },
        badgeStyles.centeredContent,
    ];
    const contentStyle = [
        {
            width: rem(size - 4),
            height: rem(size - 4),
            borderRadius: rem(50),
            backgroundColor: backgroundColor || colors.background.brand,
        },
        badgeStyles.centeredContent,
    ];
    return (<BadgeBase style={badgeStyle} {...props}>
      {backgroundColor ? (<View style={[contentStyle, { backgroundColor }]}>
          <View style={badgeStyles.absolutePosition}>{children}</View>
        </View>) : (<LinearGradient style={contentStyle} colors={colors.gradients.ultimate.colors} start={{ x: 1, y: 0 }} end={{ x: 0, y: 1 }}>
          <View style={badgeStyles.absolutePosition}>{children}</View>
        </LinearGradient>)}
    </BadgeBase>);
};
const badgeStyles = StyleSheet.create({
    centeredContent: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    absolutePosition: {
        position: 'absolute',
        zIndex: 100,
    },
});
