import React from 'react';
import useColors from 'hooks/useColors';
import IconStatusCancelled from './icons/status/IconStatusCancelled';
import IconStatusDone from './icons/status/IconStatusDone';
import IconStatusPending from './icons/status/IconStatusPending';
import IconStatusReceived from './icons/status/IconStatusReceived';
import IconStatusSent from './icons/status/IconStatusSent';
const StatusIcon = ({ state }) => {
    const colors = useColors();
    switch (state) {
        case 'cancelled-red':
            return <IconStatusCancelled colorKey="negative"/>;
        case 'cancelled-gray':
            return <IconStatusCancelled colorKey="secondaryLight"/>;
        case 'in-progress':
            return (<IconStatusPending colorKey="white" bgColor={colors.status.informational}/>);
        case 'done':
            return <IconStatusDone colorKey="positive"/>;
        case 'sent':
            return <IconStatusSent colorKey="positive"/>;
        case 'received':
            return <IconStatusReceived colorKey="brand"/>;
        default: {
            return null;
        }
    }
};
export default StatusIcon;
