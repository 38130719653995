import React, { memo, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { CommonActions } from '@react-navigation/native';
import { IconTrash } from 'design-system/icons/IconTrash';
import { IconEdit } from 'design-system/icons/IconEdit';
import IconBlock from 'design-system/icons/IconBlock';
import { IconArrowBack } from 'design-system/icons/IconArrowBack';
import PopUpMenuModal from 'design-system/PopUpMenuModal.web';
import { SettingsItem, rem } from 'design-system';
import useColors from 'hooks/useColors';
import Modal from 'utils/packages/Modal';
import { muteAll, useFetchOld } from 'hooks';
import { isWeb } from '../../../../constants';
import { cancelSubscriptionAction, editSubscription } from '../../actions';
const ModalToUse = isWeb ? PopUpMenuModal : Modal;
const SubscriptionMenu = ({ subscription, mode, navigation, }) => {
    const colors = useColors();
    const [, , , fetch] = useFetchOld(muteAll);
    const onPressRename = () => {
        ModalToUse.hide(() => {
            navigation.navigate('RenameSubscription', {
                data: subscription,
            });
        });
    };
    const goBack = useCallback(() => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
        else {
            navigation.dispatch(CommonActions.reset({
                index: 0,
                routes: [{ name: 'Feed' }],
            }));
        }
    }, []);
    const onPressIncorrect = () => {
        const onConfirm = () => {
            fetch(cancelSubscriptionAction(subscription.id));
            Modal.hide();
            goBack();
        };
        ModalToUse.hide(() => {
            Modal.showConfirmation('Not a recurring payment', 'Would you like to delete it? You won’t be able to see this recurring payment afterwards.', 'No, thanks', 'Yes, delete', Modal.hide, onConfirm);
        });
    };
    const onPressActiveInactive = () => {
        const onConfirm = () => {
            fetch(editSubscription(subscription.id, {
                isActive: !subscription.isActive,
            }));
            Modal.hide();
            goBack();
        };
        ModalToUse.hide(() => {
            Modal.showConfirmation('Incorrect type', `Is this recurring payment ${subscription.isActive ? 'inactive' : 'active'}?`, 'No, thanks', 'Yes, it is', Modal.hide, onConfirm);
        });
    };
    return (<View style={styles.container}>
      {mode !== 'editPrediction' ? (<View>
          <SettingsItem title="Rename" onPress={onPressRename} icon={<IconEdit color={colors.elements.brand}/>}/>
          <SettingsItem title={subscription.isActive ? 'This is inactive' : 'This is active'} onPress={onPressActiveInactive} icon={subscription.isActive ? (<IconBlock color={colors.elements.secondary}/>) : (<IconArrowBack gradientKey="darkPink"/>)}/>
          <SettingsItem title="This is not a recurring payment" onPress={onPressIncorrect} icon={<IconTrash color={colors.elements.negative}/>}/>
        </View>) : null}
    </View>);
};
const styles = StyleSheet.create({
    container: {
        ...(!isWeb && {
            marginTop: rem(8),
        }),
    },
});
export default memo(SubscriptionMenu);
