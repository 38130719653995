import React, { memo, useCallback, useState, useRef, useEffect, } from 'react';
import { View, InteractionManager } from 'react-native';
import Animated, { withTiming, useSharedValue, useAnimatedStyle, } from 'react-native-reanimated';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import Text from './Text';
import { Button, CardView, rem, Spacer } from '.';
const DURATION = 300;
const NotificationBanner = ({ title, onLight, description, cancelButtonTitle, positiveButtonTitle, lightButton, hideOnPositive, noMarginBottom, noMarginTop = true, noMargin, onCancel, onPositiveBtnPress, }) => {
    const hideCard = useSharedValue(false);
    const [measuredCardHeight, setCardHeight] = useState();
    const styles = useStyles(styleSet);
    const animatedStyles = useAnimatedStyle(() => {
        if (hideCard.value) {
            return { height: withTiming(0, { duration: DURATION }) };
        }
        if (measuredCardHeight) {
            return { height: measuredCardHeight };
        }
        return {};
    }, [measuredCardHeight]);
    const hideView = useCallback(() => {
        hideCard.value = true;
    }, [hideCard]);
    const timeoutRef = useRef();
    const handleCancel = useCallback(() => {
        hideView();
        if (onCancel) {
            timeoutRef.current = setTimeout(() => {
                onCancel();
            }, DURATION);
        }
    }, [hideView, onCancel]);
    const handlePositiveBtn = useCallback(() => {
        if (hideOnPositive) {
            hideView();
            timeoutRef.current = setTimeout(() => {
                onPositiveBtnPress();
            }, DURATION);
        }
        else {
            InteractionManager.runAfterInteractions(onPositiveBtnPress);
        }
    }, [hideOnPositive, hideView, onPositiveBtnPress]);
    useEffect(() => () => {
        clearTimeout(timeoutRef.current);
    }, []);
    const onLayout = useCallback((event) => {
        if (!measuredCardHeight && !hideCard.value) {
            setCardHeight(event.nativeEvent.layout.height + rem(16));
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [measuredCardHeight]);
    const onTextLayout = useCallback(() => {
        if (!hideCard.value) {
            setCardHeight(undefined);
        }
    }, [hideCard]);
    return (<Animated.View style={[animatedStyles, styles.container]}>
      <CardView withPadding noMargin={noMargin} noMarginTop={noMarginTop} noMarginBottom={noMarginBottom} style={onLight && styles.onLight} onLayout={onLayout}>
        <View style={styles.row}>
          <Text Text-16 flex onTextLayout={onTextLayout}>
            {title}
          </Text>
        </View>
        <Spacer h={4}/>
        <Text TextThin-14 Secondary onTextLayout={onTextLayout}>
          {description}
        </Text>
        <Spacer h={16}/>
        <View style={styles.row}>
          {cancelButtonTitle !== undefined ? (<>
              <Button {...(lightButton ? { brandReversed: true } : { brand: true })} square title={positiveButtonTitle} onPress={handlePositiveBtn} containerStyle={styles.btn}/>
              <Spacer w={8}/>
              <Button square {...(lightButton
            ? { brandBorder: true }
            : { brandReversed: true })} onPress={handleCancel} title={cancelButtonTitle} containerStyle={styles.btn}/>
            </>) : (<Button square brandReversed title={positiveButtonTitle} onPress={handlePositiveBtn} containerStyle={styles.btn}/>)}
        </View>
      </CardView>
    </Animated.View>);
};
export default memo(NotificationBanner);
const styleSet = createStyleSheets((colors) => ({
    container: {
        overflow: 'hidden',
    },
    row: {
        flexDirection: 'row',
    },
    btn: {
        paddingHorizontal: rem(24),
    },
    onLight: {
        backgroundColor: colors.cards.onLight,
    },
}));
