import React from 'react';
import { StyleSheet, View, } from 'react-native';
import Text from 'design-system/Text';
import { CardView } from 'design-system/CardView';
import { LineSeparator } from 'design-system/LineSeparator';
import { rem } from 'design-system/values';
export const SettingsSection = React.memo(({ children, name, renderHeader, title, withBottomMargin, noMarginTop, renderTitle, style, pointerEvents, ...props }) => (<View style={[
        styles.container,
        withBottomMargin && styles.withBottomMargin,
        noMarginTop && styles.noMarginTop,
        style,
    ]} pointerEvents={pointerEvents}>
      {name && (<Text Gray Text-16 style={styles.text}>
          {name}
        </Text>)}
      {renderHeader && renderHeader()}
      <CardView {...props} style={styles.cardView} webPadding>
        {(renderTitle || title) && (<View>
            {renderTitle ? (renderTitle()) : (<View style={styles.titleContainer}>
                <Text Text-16>{title}</Text>
              </View>)}
            <LineSeparator style={styles.lineSeparator}/>
          </View>)}
        {children}
      </CardView>
    </View>));
const styles = StyleSheet.create({
    container: {
        marginTop: rem(24),
        marginHorizontal: rem(16),
    },
    noMarginTop: {
        marginTop: 0,
    },
    withBottomMargin: {
        marginTop: 0,
        marginBottom: rem(24),
    },
    titleContainer: {
        padding: rem(16),
    },
    cardView: {
        margin: 0,
        overflow: 'hidden',
    },
    lineSeparator: {
        marginHorizontal: 0,
    },
    text: {
        paddingBottom: rem(16),
    },
});
