import React, { useCallback } from 'react';
import { AppView } from 'design-system/AppView';
import StatusBar from 'design-system/StatusBar';
import { SCROLL_INDICATOR_INSETS } from 'design-system/values';
import Animated from 'react-native-reanimated';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isWeb } from '../../../constants';
import BudgetingMerchantsList from '../components/BudgetingMerchantsList';
const EditMerchantBudgetsScreen = withAnimatedNavigationHeader(({ scrollHandler, navigation }) => {
    const openMerchant = useCallback((merchant) => {
        navigation.navigate('EditMerchantBudget', {
            id: merchant.id,
            displayName: merchant.displayName,
            currency: merchant.currency,
            iconUrl: merchant.iconUrl,
            budgetLimit: merchant.budgetLimit,
            color: merchant.color,
        });
    }, []);
    const openSelectMerchant = useCallback(() => {
        navigation.navigate('SelectMerchant');
    }, []);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    return (<AppView isInTab={isWeb} withFrame={false}>
          <StatusBar barStyle="dark-content" animated/>
          <Animated.ScrollView scrollEventThrottle={16} onScroll={scrollHandler} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS} contentContainerStyle={paddingHorizontalStyle}>
            <BudgetingMerchantsList onPressMerchant={openMerchant} onPressAdd={openSelectMerchant} isCurrentPeriod withSection={false}/>
          </Animated.ScrollView>
        </AppView>);
}, () => ({
    title: 'Edit budgets',
}));
export default EditMerchantBudgetsScreen;
