import React, { memo, useEffect, useMemo } from 'react';
import Animated, { useAnimatedStyle, useSharedValue, withSpring, } from 'react-native-reanimated';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { rem } from 'design-system/values';
import Text from 'design-system/Text';
import { formatPeriod } from 'utils/formatting';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { useCategories, useCustomCategoryColorsMap } from 'hooks/useCategory';
import useColors from 'hooks/useColors';
const springConfig = {
    mass: 0.8,
    stiffness: 100,
};
const CategoriesHistogramBar = ({ index, selectedIndex, item, categoryMerchantSelected, onPress, }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    const categories = useCategories();
    const customCategoryColorsMap = useCustomCategoryColorsMap();
    const onSelectedBar = () => {
        onPress(index);
    };
    const { scaledSpending, value } = item;
    const [label, scaled] = useMemo(() => {
        const label = formatPeriod(item, true);
        let scaled = scaledSpending;
        if (categoryMerchantSelected === 'income' && value < 0)
            scaled = 0;
        else if (categoryMerchantSelected !== 'income' && value > 0)
            scaled = 0;
        return [label, scaled];
    }, [item, scaledSpending, categoryMerchantSelected, value]);
    const isSelected = index === selectedIndex;
    const color = isSelected ? { OffBlack: true } : { Gray: true };
    const categoryColor = useMemo(() => {
        if (typeof categoryMerchantSelected === 'string') {
            if (categories[categoryMerchantSelected]) {
                return categories[categoryMerchantSelected].color;
            }
            if (customCategoryColorsMap[categoryMerchantSelected]) {
                return customCategoryColorsMap[categoryMerchantSelected][0];
            }
        }
        return colors.elements.brand;
    }, [
        categoryMerchantSelected,
        categories,
        customCategoryColorsMap,
        colors.elements.brand,
    ]);
    const scaledHeight = useSharedValue(0);
    useEffect(() => {
        scaledHeight.value = withSpring(scaled, springConfig);
    }, [scaled, scaledHeight]);
    const animatedBar = useAnimatedStyle(() => ({
        height: scaledHeight.value,
    }));
    return (<TouchableOpacity onPress={onSelectedBar}>
      <Animated.View style={[
            styles.bar,
            {
                backgroundColor: categoryMerchantSelected === 'cash' ||
                    categoryMerchantSelected === 'housing'
                    ? `${String(categoryColor)}4D` // 0.3 opacity (4D in hex)
                    : `${String(categoryColor)}33`, // 0.2 opacity (33 in hex)
            },
            isSelected && { backgroundColor: categoryColor },
            animatedBar,
        ]}/>
      <Text Text-12 centered {...color}>
        {label}
      </Text>
    </TouchableOpacity>);
};
const styleSet = createStyleSheets(() => ({
    bar: {
        width: rem(54),
        marginLeft: rem(13.5),
        marginRight: rem(13.5),
        borderTopLeftRadius: rem(9),
        borderTopRightRadius: rem(9),
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginBottom: rem(16),
    },
}));
export default memo(CategoriesHistogramBar);
