import { selectAccountStatus } from 'features/invest/reducers/selectors';
import { selectPotsStatuses } from 'features/pots/reducers/selectors';
import { format } from 'date-fns';
import { selectIsGBUser } from 'reducers/selectors';
import { createSelector } from 'reselect';
import checkFeatureFlag from 'utils/featureFlags';
import { formatPlural, formatUnsignedInteger } from 'utils/formatting';
const supportedTypes = [
    'GET_PENSIONBEE',
    'SWITCH_BROADBAND',
    'GET_LOANS',
];
const extendSupportedTypes = ['SWITCH_ENERGY'];
export const selectRecommendations = (store) => store.recommendations.recommendations || [];
const selectFeatureFlags = (store) => store.utils.featureFlags;
export const selectFilteredRecommendations = createSelector([selectRecommendations, selectFeatureFlags], (recommendations, featureFlags) => {
    const showEnergySwitch = checkFeatureFlag(featureFlags, 'energy_switch_two');
    return recommendations.filter(({ type }) => showEnergySwitch
        ? [...supportedTypes, ...extendSupportedTypes].includes(type)
        : supportedTypes.includes(type));
});
export const selectTotalBreaches = createSelector((state) => state.dataProtection.monitoredAccEmails, (state) => state.dataProtection.monitoredAccPhones, (monitoredEmails, monitoredPhones) => {
    let totalBreaches = 0;
    let lastScannedAt = null;
    monitoredPhones.forEach((phone) => {
        if (phone.lastScannedAt &&
            new Date(phone.lastScannedAt).getTime() -
                new Date(lastScannedAt || '1970-01-01').getTime() >=
                1) {
            lastScannedAt = phone.lastScannedAt;
        }
        if (phone.breachesCount) {
            totalBreaches += phone.breachesCount;
        }
    }, 0);
    monitoredEmails.forEach((email) => {
        if (email.lastScannedAt &&
            new Date(email.lastScannedAt).getTime() -
                new Date(lastScannedAt || '1970-01-01').getTime() >=
                1) {
            lastScannedAt = email.lastScannedAt;
        }
        if (email.breachesCount) {
            totalBreaches += email.breachesCount;
        }
    }, 0);
    const totalPhones = monitoredPhones.length;
    const totalEmails = monitoredEmails.length;
    return {
        totalPhones,
        totalEmails,
        totalBreaches,
        formattedText: totalPhones || totalEmails
            ? `Monitoring ${totalPhones} ${formatPlural('number', 'numbers', totalPhones)} and ${totalEmails} ${formatPlural('email', 'emails', totalEmails)}`
            : '',
        lastScannedAt: lastScannedAt
            ? format(new Date(lastScannedAt), 'd MMM yyyy')
            : undefined,
        totalNumberOfBreaches: formatUnsignedInteger(totalBreaches),
    };
});
export const selectIsFeedRefreshing = (store) => store.feed.isRefreshing;
export const selectShowWalkthroughOnInvest = createSelector([
    selectAccountStatus,
    (state) => state.invest.onboarding,
    (state) => state.deviceSettings.hasTriedSettingsUpInvest,
    (state) => state.user.user.kycStartedAt,
    selectPotsStatuses,
], (investAccountStatus, investOnboardingState, hasTriedSettingsUpInvest, kycStartedAt, { status: potsFeatureStatus }) => investAccountStatus === 'NOT_STARTED' &&
    investOnboardingState?.params === undefined &&
    !((kycStartedAt ||
        (potsFeatureStatus !== 'NOT_STARTED' &&
            potsFeatureStatus !== undefined)) &&
        hasTriedSettingsUpInvest));
export const selectShowWalkthroughOnSave = createSelector([selectIsGBUser, selectPotsStatuses], (isGBUser, { status }) => isGBUser && status === 'NOT_STARTED');
export const selectShowWalkthroughOnCurrentScreen = createSelector([(store) => store.utils.currentScreen], (currentScreen) => currentScreen === 'Feed' ||
    currentScreen === 'MainStack' ||
    currentScreen === 'Main' ||
    currentScreen === null);
export const selectShowInvestWalkthroughOnCurrentScreen = createSelector([(store) => store.utils.currentScreen], (currentScreen) => currentScreen === 'Invest');
export const selectIsWalkthroughInactive = createSelector([(store) => store.feed.walkthrough?.status], (status) => !status ||
    status === 'skipped' ||
    status === 'exited' ||
    status === 'complete');
export const selectFeedHeader = createSelector([(store) => store.feed.feed], (feed) => ({
    hasFeed: feed?.cards && Object.keys(feed.cards).length > 0,
    notificationsNum: feed?.cards?.numberOfUnreadNotifications || 0,
}), {
    memoizeOptions: {
        resultEqualityCheck: (a, b) => a.hasFeed === b.hasFeed && a.notificationsNum === b.notificationsNum,
    },
});
