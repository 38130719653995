import { CALL_API } from '../middleware/api';
export const GET_CONVERT_CURRENCY_REQUEST = 'GET_CONVERT_CURRENCY_REQUEST';
export const GET_CONVERT_CURRENCY_SUCCESS = 'GET_CONVERT_CURRENCY_SUCCESS';
export const GET_CONVERT_CURRENCY_FAILURE = 'GET_CONVERT_CURRENCY_FAILURE';
export const getConvertCurrency = (params) => {
    let endpoint = '/convert-currency?';
    Object.entries(params).forEach(([key, value]) => {
        if (key == null && value == null)
            return;
        endpoint += `&${key}=${value}`;
    });
    return {
        [CALL_API]: {
            endpoint,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: [
                GET_CONVERT_CURRENCY_REQUEST,
                GET_CONVERT_CURRENCY_SUCCESS,
                GET_CONVERT_CURRENCY_FAILURE,
            ],
        },
    };
};
