export var Sign;
(function (Sign) {
    Sign["Positive"] = "";
    Sign["Negative"] = "-";
})(Sign || (Sign = {}));
export var OnboardingFlow;
(function (OnboardingFlow) {
    OnboardingFlow[OnboardingFlow["Invest"] = 0] = "Invest";
    OnboardingFlow[OnboardingFlow["Pots"] = 1] = "Pots";
    OnboardingFlow[OnboardingFlow["Wallet"] = 2] = "Wallet";
    OnboardingFlow[OnboardingFlow["Address"] = 3] = "Address";
})(OnboardingFlow || (OnboardingFlow = {}));
export var POAType;
(function (POAType) {
    POAType[POAType["BILL"] = 0] = "BILL";
    POAType[POAType["ACCOUNT_STATEMENT"] = 1] = "ACCOUNT_STATEMENT";
    POAType[POAType["DRIVING_LICENCE"] = 2] = "DRIVING_LICENCE";
    POAType[POAType["HMRC_INSURANCE"] = 3] = "HMRC_INSURANCE";
    POAType[POAType["DWP_INSURANCE"] = 4] = "DWP_INSURANCE";
})(POAType || (POAType = {}));
export var Categories;
(function (Categories) {
    Categories["SECTORS"] = "SECTORS";
    Categories["COLLECTIONS"] = "COLLECTIONS";
})(Categories || (Categories = {}));
export const smartRuleTypeLabels = {
    spending: 'From this account',
    income: 'To this account',
    both: 'Two-way transactions',
};
export var AddToWatchListFlow;
(function (AddToWatchListFlow) {
    AddToWatchListFlow[AddToWatchListFlow["AddToWatchListFromModal"] = 0] = "AddToWatchListFromModal";
    AddToWatchListFlow[AddToWatchListFlow["AddToWatchListFromInvest"] = 1] = "AddToWatchListFromInvest";
    AddToWatchListFlow[AddToWatchListFlow["UpdateWatchList"] = 2] = "UpdateWatchList";
})(AddToWatchListFlow || (AddToWatchListFlow = {}));
export var CustomSplitType;
(function (CustomSplitType) {
    CustomSplitType["PERCENTAGE"] = "%";
    CustomSplitType["SHARE"] = "Share";
    CustomSplitType["AMOUNT"] = "Amount";
})(CustomSplitType || (CustomSplitType = {}));
export const screensToAvoidRenderingTopOf = {
    ModalNotificationScreen: true,
    NPS: true,
    ReverseTrialIntroScreen: true,
    ReverseTrialEndedScreen: true,
    PromoDiscountScreen: true,
    LoginsReconsent: true,
    LoginsUpgradeIntro: true,
    WalkthroughModal: true,
    YearlyReportIntro: true,
    EmmaProModal: true,
    SelectPremiumTierScreen: true,
    WalkthroughEnded: true,
    PremiumSubscriptionWelcome: true,
};
