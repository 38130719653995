import * as React from 'react';
import Svg, { Path, G, Defs, ClipPath } from 'react-native-svg';
const IconCategoryBusiness = (props) => (<Svg width={props.width} height={props.height} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path fill={props.fill} d="M19.848 12.652c.058.033.07.101.071.128l.001.01-.064 4.132c0 1.648-1.277 2.992-2.885 3.074l-.157.004H7.178c-1.629 0-2.957-1.293-3.038-2.92l-.004-.158-.056-4.132s0-.097.072-.138c.064-.04.144.008.152.016 1.617 1.086 3.562 1.807 5.603 2.107a.603.603 0 0 0 .656-.438 1.45 1.45 0 0 1 1.299-1.096l.126-.005h.024c.68 0 1.249.437 1.425 1.101.08.292.368.478.656.438 2.041-.3 3.986-1.021 5.603-2.107l.032-.016c.032-.016.08-.024.12 0Zm-7.852 1.742c-.3 0-.555.231-.595.526l-.005.082v1.045c0 .34.272.607.6.607a.596.596 0 0 0 .595-.524l.005-.083V15a.603.603 0 0 0-.6-.607ZM13.028 4c1.201 0 2.186.915 2.338 2.09h1.585C18.63 6.09 20 7.475 20 9.177c0 0-.048.72-.064 1.723a.264.264 0 0 1-.103.204c-.385.284-.737.52-.77.536a12.2 12.2 0 0 1-4.517 1.83.254.254 0 0 1-.267-.13 2.637 2.637 0 0 0-2.283-1.32 2.68 2.68 0 0 0-2.298 1.314.255.255 0 0 1-.266.128 12.393 12.393 0 0 1-4.496-1.814l-.768-.535a.229.229 0 0 1-.104-.195C4.04 10.505 4 9.177 4 9.177 4 7.475 5.369 6.09 7.05 6.09h1.576C8.778 4.915 9.763 4 10.964 4h2.064Zm0 1.215h-2.065c-.536 0-.992.373-1.12.875h4.306a1.162 1.162 0 0 0-1.12-.875Z"/>
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill={props.fill} d="M.102 0h24v24h-24z"/>
      </ClipPath>
    </Defs>
  </Svg>);
export default IconCategoryBusiness;
