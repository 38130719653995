import React, { useEffect, useRef, useState } from 'react';
import { Animated, StyleSheet, TouchableHighlight, } from 'react-native';
import Collapsible from 'utils/packages/rnCollapsible';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import IconArrow from 'design-system/icons/IconArrow';
import { LineSeparator } from 'design-system/LineSeparator';
import { SettingsSection, } from 'design-system/SettingsSection';
import useColors from 'hooks/useColors';
export const CollapsableSettingsSection = ({ title, style, children, collapsed, withBottomMargin, enabled = true, forceOpen, ...props }) => {
    const [collapser] = useState(new Animated.Value(0));
    const [startCollapse, setStartCollapse] = useState(collapsed);
    const [finishCollapse, setFinishCollapse] = useState(false);
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (collapsed && !isFirstRender.current) {
            setStartCollapse(false);
        }
        isFirstRender.current = false;
    }, [collapsed, forceOpen]);
    useEffect(() => {
        if (startCollapse === finishCollapse) {
            if (startCollapse) {
                Animated.timing(collapser, {
                    toValue: 0,
                    delay: 0,
                    duration: 200,
                    useNativeDriver: true,
                }).start(() => setFinishCollapse(false));
            }
            else {
                Animated.timing(collapser, {
                    toValue: 1,
                    delay: 0,
                    duration: 200,
                    useNativeDriver: true,
                }).start(() => setFinishCollapse(true));
            }
        }
    }, [collapser, finishCollapse, startCollapse]);
    const colors = useColors();
    return (<SettingsSection withBottomMargin={withBottomMargin} style={[styles.collBenefitInside, style]} {...props}>
        <TouchableHighlight style={styles.collBenefitHeader} underlayColor={colors.background.underlay} activeOpacity={1} disabled={!enabled} onPress={() => setStartCollapse(!startCollapse)}>
          <>
            <Text Text-16 flex>
              {title}
            </Text>
            {enabled && (<Animated.View style={{
                transform: [
                    {
                        rotate: collapser.interpolate({
                            inputRange: [0, 1],
                            outputRange: ['90deg', '270deg'],
                            extrapolate: 'clamp',
                        }),
                    },
                ],
            }}>
                <IconArrow />
              </Animated.View>)}
          </>
        </TouchableHighlight>
        <LineSeparator noMargin/>
        <Collapsible collapsed={startCollapse}>{children}</Collapsible>
      </SettingsSection>);
};
const styles = StyleSheet.create({
    collBenefitInside: {
        marginHorizontal: rem(16),
    },
    collBenefitHeader: {
        width: '100%',
        paddingVertical: rem(17.5),
        paddingLeft: rem(16),
        paddingRight: rem(16),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
