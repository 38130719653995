import _ from 'lodash';
import { REHYDRATE } from 'redux-persist';
import { SWITCHED_SPACE } from 'features/spaces/constants';
import { getTransactionsIdObj } from 'utils/getTransactions';
import { deduplicateWithKey } from 'utils/formatting';
import { ADD_TXN_TO_SUBSCRIPTION_BY_ID_SUCCESS, ADD_TXNS_TO_SUBSCRIPTION_SUCCESS, CANCEL_SUBSCRIPTIONS_SUCCESS, DELETE_TXN_FROM_SUBSCRIPTION_SUCCESS, } from 'features/subscriptions/actions/types';
import * as types from '../actions/types';
export const initialState = {
    isFetching: false,
    isFetchingSimilar: false,
    isFetchingLinked: false,
    isFetchingSearchTransactions: false,
    isRefreshing: false,
    transactionDeleted: null,
    transactionsSplit: [],
    transactionsArray: [],
    searchTransactions: [],
    categories: {},
    merchants: {},
    similarTxnMasterId: undefined,
    similarTransactionCurrentPage: undefined,
    similarTransactions: [],
    hasUnsplitTransactions: false,
    page: 1,
    pageCount: null,
    similarPage: 0,
    similarPageCount: null,
    sumAmount: 0,
    tags: [],
    lastAccount: null,
    merchantStats: null,
    to: undefined,
    from: undefined,
    // It is either 'ALL' or the id of the account selected
    transactionArrayType: undefined,
    accountIdsStr: undefined,
    showExcludedTransactions: undefined,
};
const sortTransactionByDate = (b, a) => new Date(a.customDate || a.bookingDate).getTime() -
    new Date(b.customDate || b.bookingDate).getTime();
const compactTransaction = (transaction) => ({
    ...transaction,
    categoryId: transaction.category.id,
    notes: transaction.notes || undefined,
    isPending: transaction.isPending || undefined,
    suggestedData: transaction.suggestedData || undefined,
});
const transactions = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE: {
            const transaction = action.payload?.transactions;
            if (transaction) {
                return {
                    ...initialState,
                    // moving to unpersisted transactions reducer
                    // @ts-expect-error
                    selected_transaction_receipts: [],
                };
            }
            return state;
        }
        case ADD_TXN_TO_SUBSCRIPTION_BY_ID_SUCCESS: {
            if (state.transactionCache) {
                const patchTransactionsArray = (arr) => arr.map((item) => {
                    if (item.id === action.extra.transactionId) {
                        return {
                            ...item,
                            subscriptionId: action.extra.subscriptionId,
                        };
                    }
                    return item;
                });
                return {
                    ...state,
                    transactionCache: {
                        ...state.transactionCache,
                        ...(state.transactionCache[action.extra.transactionId]
                            ? {
                                [action.extra.transactionId]: {
                                    ...state.transactionCache[action.extra.transactionId],
                                    subscriptionId: action.extra.subscriptionId,
                                },
                            }
                            : {}),
                    },
                    transactionsArray: patchTransactionsArray(state.transactionsArray),
                    searchTransactions: patchTransactionsArray(state.searchTransactions),
                };
            }
            return state;
        }
        case CANCEL_SUBSCRIPTIONS_SUCCESS: {
            const cache = state.transactionCache;
            if (!cache) {
                return state;
            }
            const newTransactionCache = Object.keys(cache).reduce((prev, txnId) => {
                const txn = cache[txnId];
                if (txn) {
                    const subscriptionId = 'subscriptionId' in txn ? txn.subscriptionId : null;
                    if (subscriptionId &&
                        subscriptionId === action.payload.subscriptionId) {
                        return {
                            ...prev,
                            [txnId]: {
                                ...txn,
                                subscription: null,
                                subscriptionId: null,
                            },
                        };
                    }
                    return {
                        ...prev,
                        [txnId]: txn,
                    };
                }
                return prev;
            }, {});
            return {
                ...state,
                transactionCache: newTransactionCache,
            };
        }
        case types.UPDATE_CATEGORY_SUCCESS:
        case types.CREATE_CATEGORY_SUCCESS:
        case types.SYNC_SUCCESS:
        case types.SYNC_ALL_SUCCESS:
            return {
                ...state,
                searchTransactions: [],
                categories: {},
                merchants: {},
            };
        case types.ADD_TRANSACTION_TO_CACHE:
        case types.GET_TRANSACTION_SUCCESS:
        case types.GET_ORIGINAL_TRANSACTION_SUCCESS:
        case types.LINKED_TRANSACTION_SUCCESS:
            return {
                ...state,
                transactionCache: {
                    ...state.transactionCache,
                    [action.payload.id]: action.payload,
                },
            };
        case types.GET_MERCHANT_STATS_SUCCESS:
            return { ...state, merchantStats: action.payload.spending };
        case types.RESET_TRANSACTION:
            return {
                ...state,
                merchantStats: null,
            };
        case types.IS_FETCHING_BANK_TRANSACTIONS:
            const { transactionArrayType, fromTo, showExcludedTransactions, accountIdsStr, } = action.payload;
            const { from, to } = fromTo || {};
            return Object.assign(state, initialState, {
                to,
                from,
                accountIdsStr,
                isFetching: true,
                transactionArrayType,
                showExcludedTransactions,
                transactionsArray: accountIdsStr === state.accountIdsStr &&
                    showExcludedTransactions === state.showExcludedTransactions &&
                    transactionArrayType === state.transactionArrayType &&
                    state.from === from &&
                    state.to === to
                    ? state.transactionsArray
                    : [],
                lastAccount: state.lastAccount,
            });
        case types.RESET_SIMILAR_TRANSACTIONS:
            return {
                ...state,
                similarPage: 0,
                similarPageCount: null,
                similarTransactions: [],
                similarTxnMasterId: undefined,
                similarTransactionCurrentPage: undefined,
            };
        case types.SIMILAR_TRANSACTIONS_REQUEST:
            return {
                ...state,
                isFetchingSimilar: true,
                similarTxnMasterId: action.extra.id,
                similarTransactionCurrentPage: action.extra.page,
            };
        case types.SIMILAR_TRANSACTIONS_SUCCESS:
            if (state.similarTransactionCurrentPage !== action.payload.paging.page ||
                state.similarTxnMasterId !== action.extra.id) {
                return state;
            }
            if (action.payload.paging.page === 1) {
                return {
                    ...state,
                    isFetchingSimilar: false,
                    similarPage: action.payload.paging.page,
                    similarTransactions: action.payload.transactions,
                    similarPageCount: action.payload.paging.pageCount,
                };
            }
            return {
                ...state,
                isFetchingSimilar: false,
                similarTransactions: deduplicateWithKey([...state.similarTransactions, ...action.payload.transactions], 'id').sort(sortTransactionByDate),
                similarPage: action.payload.paging.page,
                similarPageCount: action.payload.paging.pageCount,
            };
        case types.SIMILAR_TRANSACTIONS_FAILURE:
            return { ...state, isFetchingSimilar: false };
        case types.SEARCH_TRANSACTIONS_REQUEST:
            return {
                ...state,
                isFetchingSearchTransactions: true,
            };
        case types.SEARCH_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                searchTransactions: action.payload.transactions,
                categories: action.payload.categories,
                merchants: action.payload.merchants,
                isFetchingSearchTransactions: false,
            };
        case types.DELETE_TRANSACTION_SUCCESS: {
            const patchTransactionsArray = (arr) => arr.filter(({ id }) => id !== action.extra);
            return {
                ...state,
                transactionDeleted: action.extra,
                transactionsArray: patchTransactionsArray(state.transactionsArray),
                searchTransactions: patchTransactionsArray(state.searchTransactions),
            };
        }
        case types.CREATE_TRANSACTION_SUCCESS:
            return {
                ...state,
                searchTransactions: state.searchTransactions
                    .concat(compactTransaction(action.payload))
                    .sort(sortTransactionByDate),
                transactionsArray: state.transactionArrayType === 'All' ||
                    state.transactionArrayType === action.payload.accountId
                    ? [action.payload, ...state.transactionsArray].sort(sortTransactionByDate)
                    : state.transactionsArray,
            };
        case types.UNSPLIT_TRANSACTION_REQUEST:
            return { ...state, hasUnsplitTransactions: false };
        case types.UNSPLIT_TRANSACTION_SUCCESS: {
            const filterTransactionsArray = (arr) => arr.filter((transaction) => !('splitFromId' in transaction) ||
                transaction.splitFromId !== action.payload.id);
            return {
                ...state,
                hasUnsplitTransactions: true,
                transactionsArray: filterTransactionsArray(state.transactionsArray)
                    .concat(action.payload)
                    .sort(sortTransactionByDate),
                searchTransactions: filterTransactionsArray(state.searchTransactions)
                    .concat(compactTransaction(action.payload))
                    .sort(sortTransactionByDate),
            };
        }
        case types.SPLIT_TRANSACTION_REQUEST:
            return { ...state, transactionsSplit: [] };
        case types.SPLIT_TRANSACTION_SUCCESS: {
            const filterTransactionsArray = (arr) => arr.filter(({ id }) => id !== action.payload.transactions[0].splitFromId);
            return {
                ...state,
                transactionsSplit: action.payload.transactions,
                transactionsArray: filterTransactionsArray(state.transactionsArray)
                    .concat(action.payload.transactions)
                    .sort(sortTransactionByDate),
                searchTransactions: filterTransactionsArray(state.searchTransactions)
                    .concat(action.payload.transactions.map(compactTransaction))
                    .sort(sortTransactionByDate),
            };
        }
        case types.SET_TRANSACTIONS_REQUESTING:
        case types.TRANSACTIONS_REQUEST:
            return { ...state, isRefreshing: true };
        case types.TRANSACTIONS_SUCCESS: {
            if (action.extra.accountIdsStr === state.accountIdsStr &&
                state.showExcludedTransactions ===
                    action.extra.showExcludedTransactions &&
                state.transactionArrayType === (action.extra?.lastAccount || 'ALL') &&
                ((!state.from && !action.extra.from) ||
                    state.from === action.extra.from) &&
                ((!state.to && !action.extra.to) || state.to === action.extra.to)) {
                const newTransactionsArray = (action.payload.paging.page === 1
                    ? action.payload.transactions
                    : _.uniqBy([...state.transactionsArray, ...action.payload.transactions], (i) => i.id)).sort(sortTransactionByDate);
                return {
                    ...state,
                    sumAmount: action.payload.sumAmount,
                    transactionsArray: newTransactionsArray,
                    pageCount: action.payload.paging.pageCount,
                    page: action.payload.paging.page,
                    lastAccount: action.extra?.lastAccount,
                    isFetching: false,
                    isRefreshing: false,
                };
            }
            return state;
        }
        case types.TRANSACTIONS_FAILURE:
            return {
                ...state,
                isRefreshing: false,
            };
        case types.ON_SUBMIT_FEEDBACK_SUCCESS: {
            const patchTransactionsArray = (arr) => arr.map((item) => {
                if (action.extra.transaction.counterpartName === item.counterpartName) {
                    return { ...item, suggestedData: action.payload.suggestedData };
                }
                return item;
            });
            return {
                ...state,
                transactionsArray: patchTransactionsArray(state.transactionsArray),
                searchTransactions: patchTransactionsArray(state.searchTransactions),
            };
        }
        case types.GET_FEED_SUCCESS:
            return {
                ...state,
                transactionCache: {
                    ...state.transactionCache,
                    ...action.payload.latestTransactions?.reduce((prev, curr) => ({
                        ...prev,
                        [curr.id]: curr,
                    }), {}),
                },
            };
        case types.EDIT_TAGS_SUCCESS:
            const { data } = action.extra;
            const idObj = getTransactionsIdObj(data);
            const patchTransactionsArray = (arr) => arr.map((item) => idObj[item.id] ? { ...item, labels: action.extra.labels } : item);
            return {
                ...state,
                transactionCache: {
                    ...state.transactionCache,
                    ...action.extra.data.reduce((prev, curr) => ({
                        ...prev,
                        [curr]: state.transactionCache?.[curr]
                            ? {
                                ...state.transactionCache[curr],
                                labels: action.extra.labels,
                            }
                            : undefined,
                    }), {}),
                },
                transactionsArray: patchTransactionsArray(state.transactionsArray),
                searchTransactions: patchTransactionsArray(state.searchTransactions),
            };
        case types.EDIT_CATEGORY_SUCCESS: {
            const { data } = action.extra;
            const patchTransactionsArrayForEditCategory = (arr) => arr.map((item) => {
                if (data[item.id]) {
                    if ('category' in item) {
                        return {
                            ...item,
                            category: action.extra.category,
                        };
                    }
                    return {
                        ...item,
                        categoryId: action.extra.category.id,
                    };
                }
                return item;
            });
            const oldTxnCache = state.transactionCache;
            const newTransactionCache = oldTxnCache
                ? Object.keys(oldTxnCache).reduce((prev, txnId) => {
                    // @ts-ignore
                    if (data[txnId]) {
                        return {
                            ...prev,
                            [txnId]: {
                                ...oldTxnCache[txnId],
                                category: action.extra.category,
                            },
                        };
                    }
                    return {
                        ...prev,
                        [txnId]: oldTxnCache[txnId],
                    };
                }, {})
                : undefined;
            return {
                ...state,
                transactionCache: newTransactionCache,
                transactionsArray: patchTransactionsArrayForEditCategory(state.transactionsArray),
                searchTransactions: patchTransactionsArrayForEditCategory(state.searchTransactions),
            };
        }
        case types.GET_TAGS_SUCCESS:
            return { ...state, tags: action.payload.labels };
        case types.CHANGE_DATE_TRANSACTION_SUCCESS: {
            const patchTransactionsArray = (arr) => arr
                .map((item) => {
                if (action.extra.transaction.id === item.id) {
                    return { ...item, customDate: action.extra.customDate };
                }
                return item;
            })
                .sort(sortTransactionByDate);
            return {
                ...state,
                transactionCache: {
                    ...state.transactionCache,
                    [action.extra.transaction.id]: {
                        ...action.extra.transaction,
                        customDate: action.extra.customDate,
                    },
                },
                transactionsArray: patchTransactionsArray(state.transactionsArray),
                searchTransactions: patchTransactionsArray(state.searchTransactions),
            };
        }
        case types.RENAME_TRANSACTION_SUCCESS: {
            const { data } = action.extra;
            const idObj = getTransactionsIdObj(data);
            const patchTransactionsArray = (arr) => arr.map((item) => {
                if (idObj[item.id]) {
                    return { ...item, customName: action.extra.counterPartName };
                }
                return item;
            });
            return {
                ...state,
                transactionCache: {
                    ...state.transactionCache,
                    ...action.extra.data.reduce((prev, curr) => ({
                        ...prev,
                        [curr]: state.transactionCache?.[curr]
                            ? {
                                ...state.transactionCache[curr],
                                customName: action.extra.counterPartName,
                            }
                            : undefined,
                    }), {}),
                },
                transactionsArray: patchTransactionsArray(state.transactionsArray),
                searchTransactions: patchTransactionsArray(state.searchTransactions),
            };
        }
        case types.SAVE_NOTE_SUCCESS:
        case types.SET_TAGS_SUCCESS: {
            const existingLabels = state.tags.reduce((prev, curr) => ({
                ...prev,
                [curr.label]: true,
            }), {});
            const patchTransactionsArray = (arr) => arr.map((item) => {
                if (action.payload.id === item.id) {
                    return {
                        ...item,
                        notes: action.payload.notes,
                        labels: action.payload.labels,
                        customName: action.payload.customName,
                    };
                }
                return item;
            });
            return {
                ...state,
                transactionCache: {
                    ...state.transactionCache,
                    [action.payload.id]: action.payload,
                },
                transactionsArray: patchTransactionsArray(state.transactionsArray),
                searchTransactions: patchTransactionsArray(state.searchTransactions),
                tags: action.type === types.SET_TAGS_SUCCESS
                    ? state.tags.concat(action.payload.labels
                        .filter((label) => existingLabels[label] === undefined)
                        .map((label) => ({ label })))
                    : state.tags,
            };
        }
        case types.ADD_SUBSCRIPTIONS_SUCCESS:
        case ADD_TXNS_TO_SUBSCRIPTION_SUCCESS: {
            const txnIds = action.type === types.ADD_SUBSCRIPTIONS_SUCCESS
                ? [action.extra.baseTransactionId]
                : action.extra.ids;
            const subscription = action.type === types.ADD_SUBSCRIPTIONS_SUCCESS
                ? action.payload.subscription
                : action.payload;
            const patchTransactionsArray = (arr) => arr.map((item) => {
                if (txnIds.includes(item.id)) {
                    return {
                        ...item,
                        subscriptionId: subscription.id,
                    };
                }
                return item;
            });
            if (state.transactionCache) {
                return {
                    ...state,
                    transactionCache: {
                        ...state.transactionCache,
                        ...txnIds.reduce((prev, curr) => {
                            if (state.transactionCache?.[curr]) {
                                return {
                                    ...prev,
                                    [curr]: {
                                        ...state.transactionCache[curr],
                                        subscriptionId: subscription.id,
                                    },
                                };
                            }
                            return prev;
                        }, {}),
                    },
                    transactionsArray: patchTransactionsArray(state.transactionsArray),
                    searchTransactions: patchTransactionsArray(state.searchTransactions),
                };
            }
            return state;
        }
        case DELETE_TXN_FROM_SUBSCRIPTION_SUCCESS: {
            if (state.transactionCache) {
                const patchTransactionsArray = (arr) => arr.map((item) => {
                    if (action.extra.ids.includes(item.id)) {
                        return {
                            ...item,
                            subscriptionId: undefined,
                        };
                    }
                    return item;
                });
                return {
                    ...state,
                    transactionCache: {
                        ...state.transactionCache,
                        ...action.extra.ids.reduce((prev, curr) => {
                            if (state.transactionCache?.[curr]) {
                                return {
                                    ...prev,
                                    [curr]: {
                                        ...state.transactionCache[curr],
                                        subscriptionId: undefined,
                                    },
                                };
                            }
                            return prev;
                        }, {}),
                    },
                    transactionsArray: patchTransactionsArray(state.transactionsArray),
                    searchTransactions: patchTransactionsArray(state.searchTransactions),
                };
            }
            return state;
        }
        case types.SET_SPENDING_GROUP_INFO_SUCCESS: {
            if (state.transactionCache && action.originalTransactionId) {
                const patchTransactionsArray = (arr) => arr.map((item) => {
                    if (action.originalTransactionId === item.id) {
                        return {
                            ...item,
                            spendingGroupInfo: action.spendingGroupInfo,
                        };
                    }
                    return item;
                });
                return {
                    ...state,
                    transactionCache: {
                        ...state.transactionCache,
                        [action.originalTransactionId]: {
                            ...state.transactionCache[action.originalTransactionId],
                            spendingGroupInfo: action.spendingGroupInfo,
                        },
                    },
                    transactionsArray: patchTransactionsArray(state.transactionsArray),
                    searchTransactions: patchTransactionsArray(state.searchTransactions),
                };
            }
            return state;
        }
        case types.HIDE_SUCCESS:
        case types.CLOSED_CONNECTION_SUCCESS:
        case types.CHOOSE_RETAINED_CONNECTIONS_SUCCESS:
            return { ...state, transactionsArray: [] };
        case SWITCHED_SPACE:
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default transactions;
