import React, { useCallback } from 'react';
import Modal from 'design-system/Modal';
import PopUpMenuModal, { defaultPopUpWidth, } from 'design-system/PopUpMenuModal.web';
import { rem } from 'design-system/values';
import { isWeb } from '../../../constants';
import SubscriptionMenuModalContent from '../components/modal/SubscriptionMenuModalContent';
export const useSubscriptionMenu = (navigation) => {
    const showOptions = useCallback((subscription, data, touchViewRef) => {
        if (!subscription) {
            return;
        }
        if (isWeb) {
            if (touchViewRef) {
                touchViewRef.current?.measure((_, __, ___, ____, fx, fy) => {
                    PopUpMenuModal.show(<SubscriptionMenuModalContent mode={data} subscription={subscription} navigation={navigation}/>, {
                        fx: fx - defaultPopUpWidth,
                        fy: fy + rem(40),
                    });
                });
            }
        }
        else {
            Modal.show(<SubscriptionMenuModalContent mode={data} subscription={subscription} navigation={navigation}/>, {
                title: subscription.customName || subscription.merchantInfo?.displayName,
                containerStyle: {
                    paddingHorizontal: 0,
                },
                textStyle: {
                    paddingHorizontal: rem(16),
                },
            });
        }
    }, []);
    return [showOptions];
};
