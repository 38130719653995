import React, { memo, useCallback, useRef } from 'react';
import { Dimensions, Platform, View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import IconArrowTriangle from 'design-system/icons/IconArrowTriangle';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import Spacer from 'design-system/Spacer';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isWeb } from '../../../../constants';
// This is needed or Android doesn't measure
const noop = () => { };
const CategoryMenuButton = ({ idx, selected, onPressShowMenu, }) => {
    const containerRef = useRef(null);
    const { height: deviceHeight } = useAppFrameDimensions();
    const onButtonPressed = useCallback(() => {
        containerRef.current?.measure((_, __, ___, ____, fx, fy) => {
            const height = Platform.OS === 'ios' ? deviceHeight : Dimensions.get('screen').height;
            let marginTop = 30;
            if (fy + 280 > height)
                marginTop = -70;
            onPressShowMenu(idx, selected, { fx, fy, marginTop });
        });
    }, [deviceHeight, idx, onPressShowMenu, selected]);
    const styles = useStyles(styleSet);
    const colors = useColors();
    const { displayName } = selected;
    return (<View ref={containerRef} onLayout={noop}>
      <TouchableOpacity onPress={onButtonPressed} onCard hoverOnLight>
        <View style={styles.buttonCategory}>
          <View style={styles.imageContainer}>
            <IconTransactionCategory width={rem(24)} height={rem(24)} category={selected}/>
          </View>
          <Text Text-16 OffBlack numberOfLines={1}>
            {displayName}
          </Text>
          <Spacer w={16}/>
          <View style={isWeb && styles.iconContainer}>
            <IconArrowTriangle color={colors.elements.secondary}/>
          </View>
        </View>
      </TouchableOpacity>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    buttonCategory: {
        maxWidth: rem(200),
        flexDirection: 'row',
        alignItems: 'center',
        ...(isWeb && {
            borderWidth: rem(1),
            borderRadius: rem(8),
            paddingVertical: rem(8),
            paddingHorizontal: rem(16),
            borderColor: colors.borders.divider,
            backgroundColor: colors.background.dark,
        }),
    },
    iconContainer: {
        width: rem(7),
    },
    imageContainer: {
        height: rem(32),
        width: rem(32),
        borderRadius: rem(19),
        backgroundColor: colors.cards.onLight,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: rem(8),
    },
}));
export default memo(CategoryMenuButton);
