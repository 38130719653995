import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { Dimensions, Platform, View, } from 'react-native';
import Animated, { interpolate, runOnJS, useAnimatedScrollHandler, useAnimatedStyle, useSharedValue, } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import RNModal from 'utils/packages/rnModal';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useIsTablet from 'hooks/useIsTablet';
import ModalConfirmationContent from './ModalConfirmationContent';
import ModalHandle from './ModalHandle';
import Separator from './Separator';
import Text from './Text';
import { rem } from './values';
const strings = { error: 'Error', tryAgain: 'Try Again', ok: 'OK' };
const Modal = ({ top }) => {
    const { height, width } = useAppFrameDimensions();
    const isTablet = useIsTablet();
    const [state, setState] = useState({
        visible: false,
        content: null,
        title: '',
    });
    const styles = useStyles(styleSet);
    const close = useCallback(() => {
        setState((state) => ({
            ...state,
            visible: false,
        }));
    }, []);
    const onBackdropPress = useCallback(() => {
        state.options?.onBackdropPress?.();
        close();
    }, [close, state.options]);
    const onBackButtonPress = useCallback(() => {
        if (state.options?.nonDismissable) {
            // do not allow to close modal in this case
        }
        else {
            close();
        }
    }, [state.options, close]);
    const animatedScrollOffset = useSharedValue(0);
    const cleanup = useCallback(() => {
        if (Modal.onDidHideCallbacks) {
            const callback = Modal.onDidHideCallbacks[top ? 'top' : 'bottom'];
            if (typeof callback === 'function') {
                callback();
                Modal.onDidHideCallbacks[top ? 'top' : 'bottom'] = undefined;
            }
        }
        animatedScrollOffset.value = 0;
    }, [animatedScrollOffset, top]);
    useEffect(() => {
        if (Modal.setStateRefs === undefined)
            Modal.setStateRefs = {};
        Modal.setStateRefs[top ? 'top' : 'bottom'] = setState;
    }, [top]);
    const onPress = useCallback(() => {
        if (state.options?.onPress) {
            if (Modal.onDidHideCallbacks === undefined) {
                Modal.onDidHideCallbacks = {};
            }
            Modal.onDidHideCallbacks[top ? 'top' : 'bottom'] = state.options.onPress;
        }
        close();
    }, [state.options?.onPress, close, top]);
    const onPressCancel = useCallback(() => {
        if (state.options?.onPressCancel) {
            if (Modal.onDidHideCallbacks === undefined) {
                Modal.onDidHideCallbacks = {};
            }
            Modal.onDidHideCallbacks[top ? 'top' : 'bottom'] =
                state.options.onPressCancel;
        }
        close();
    }, [state.options?.onPressCancel, close, top]);
    const [scrollOffset, setScrollOffset] = useState(0);
    const handleOnScroll = (y) => {
        setScrollOffset(y);
    };
    const scrollRef = useRef(null);
    const handleScrollTo = (event) => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo(event);
        }
    };
    const { bottom } = useSafeAreaInsets();
    const scrollHandler = useAnimatedScrollHandler((handle) => {
        animatedScrollOffset.value = handle.contentOffset.y;
        runOnJS(handleOnScroll)(handle.contentOffset.y);
    });
    const scrollValueToUse = useMemo(() => state.options?.scrollable?.scrollValue
        ? state.options?.scrollable?.scrollValue
        : animatedScrollOffset, [animatedScrollOffset, state.options?.scrollable?.scrollValue]);
    const separator = useAnimatedStyle(() => ({
        opacity: interpolate(scrollValueToUse.value, [0, 40], [0, 1]),
    }), [scrollValueToUse]);
    const paddingBottom = useMarginBottom('paddingBottom');
    if (state.options && 'showConfirmation' in state.options) {
        return (<RNModal isVisible={state.visible} style={[
                styles.modal,
                isTablet &&
                    !state.options?.fullscreen && [{ width }, styles.modalTablet],
            ]} onBackdropPress={onBackdropPress} useNativeDriverForBackdrop coverScreen={false} swipeDirection="down" onSwipeComplete={close} onBackButtonPress={onBackButtonPress} onModalHide={cleanup} backdropTransitionOutTiming={0} deviceHeight={Dimensions.get('screen').height}>
        <ModalConfirmationContent options={state.options} containerStyle={[
                styles.errorContainer,
                isTablet && !state.options?.fullscreen && styles.containerTablet,
            ]} onPress={onPress} onPressCancel={onPressCancel} title={state.title} subtitle={state.subtitle}/>
      </RNModal>);
    }
    if (state.options && 'showError' in state.options) {
        return (<RNModal isVisible={state.visible} style={[
                styles.modal,
                isTablet &&
                    !state.options?.fullscreen && [{ width }, styles.modalTablet],
            ]} onBackdropPress={close} useNativeDriverForBackdrop coverScreen={false} swipeDirection="down" onSwipeComplete={close} onBackButtonPress={onBackButtonPress} onModalHide={cleanup} deviceHeight={Dimensions.get('screen').height}>
        <ModalConfirmationContent containerStyle={[
                styles.errorContainer,
                isTablet && !state.options?.fullscreen && styles.containerTablet,
            ]} onPress={onPress} title={state.title} subtitle={state.subtitle} options={{
                ...state.options,
                confirmButtonTitle: state.options.onPress
                    ? state.options.confirmButtonTitle || strings.tryAgain
                    : strings.ok,
            }}/>
      </RNModal>);
    }
    const { title } = state;
    let titleNode;
    if (typeof title === 'string') {
        if (state.title === '') {
            titleNode = null;
        }
        else {
            titleNode = (<Text Text-20 centered style={[
                    styles.title,
                    state.options?.increasedTopPadding && styles.increasedTopPadding,
                    state.options?.scrollable && styles.titleScrollable,
                    state.options?.textStyle,
                ]}>
          {state.title}
        </Text>);
        }
    }
    else {
        titleNode = title;
    }
    return (<RNModal isVisible={state.visible} style={[
            styles.modal,
            state.options?.fullscreen && styles.modalFullscreen,
            isTablet &&
                !state.options?.fullscreen && [{ width }, styles.modalTablet],
        ]} onBackdropPress={close} useNativeDriverForBackdrop coverScreen={false} swipeDirection={state.options?.nonDismissable ? undefined : 'down'} onSwipeComplete={close} onBackButtonPress={onBackButtonPress} onModalHide={cleanup} onModalShow={state.options?.onModalShow} animationIn={state.options?.animationIn} animationOut={state.options?.animationOut} hasBackdrop={!state.options?.noBackdrop} propagateSwipe={state.options?.propagateSwipe} useNativeDriver={state.options?.useNativeDriver} scrollOffset={state.options?.scrollable ? scrollOffset : undefined} scrollTo={state.options?.scrollable ? handleScrollTo : undefined} scrollOffsetMax={state.options?.scrollable?.maxHeight}>
      <View style={[
            styles.container,
            isTablet && !state.options?.fullscreen && styles.containerTablet,
            state.options?.fullscreen
                ? {
                    height: Platform.OS === 'ios'
                        ? height
                        : Dimensions.get('screen').height,
                }
                : [
                    styles.nonFullscreen,
                    state.options?.darkBg && styles.darkBg,
                    !isTablet && paddingBottom,
                ],
            state.options?.scrollable && styles.containerScrollable,
            state.options?.containerStyle,
        ]}>
        {titleNode}
        {state.options?.scrollable !== undefined ? (<View style={styles.scrollContainer}>
            {state.options?.scrollable.scrollValue ? (state.content) : (<Animated.ScrollView ref={scrollRef} onScroll={scrollHandler} style={{ maxHeight: state.options.scrollable.maxHeight }} scrollEventThrottle={8} contentContainerStyle={[
                    {
                        paddingBottom: state.options.scrollable.outsideContent
                            ? 0
                            : bottom || rem(16),
                    },
                ]}>
                {state.content}
              </Animated.ScrollView>)}
            {state.options.scrollable.outsideContent}
            <Animated.View style={[styles.separatorContainer, separator]}>
              <Separator />
            </Animated.View>
          </View>) : (state.content)}
        {!state.options?.hideHandle && !state.options?.fullscreen && (<ModalHandle />)}
      </View>
    </RNModal>);
};
Modal.minHideTime = { top: 0, bottom: 0 };
Modal.show = (content, options) => {
    if (Modal.setStateRefs) {
        if (options?.onDidHideCallback) {
            if (Modal.onDidHideCallbacks === undefined) {
                Modal.onDidHideCallbacks = {};
            }
            Modal.onDidHideCallbacks[options?.top ? 'top' : 'bottom'] =
                options.onDidHideCallback;
        }
        const setState = Modal.setStateRefs[options?.top ? 'top' : 'bottom'];
        if (setState) {
            setState({
                visible: true,
                title: options?.title ?? '',
                content,
                options,
            });
            if (options?.minShowTime) {
                Modal.minHideTime[options?.top ? 'top' : 'bottom'] =
                    new Date().getTime() + options.minShowTime;
            }
            else {
                Modal.minHideTime[options?.top ? 'top' : 'bottom'] = 0;
            }
        }
    }
};
Modal.showError = (message, onTryAgain, title, buttonText, onDidHideCallback) => {
    if (Modal.setStateRefs) {
        if (onDidHideCallback) {
            if (Modal.onDidHideCallbacks === undefined) {
                Modal.onDidHideCallbacks = {};
            }
            Modal.onDidHideCallbacks.top = onDidHideCallback;
        }
        const setState = Modal.setStateRefs.top;
        if (setState) {
            setState({
                visible: true,
                title: title || strings.error,
                subtitle: message,
                content: null,
                options: {
                    showError: true,
                    onPress: onTryAgain,
                    confirmButtonTitle: buttonText || strings.tryAgain,
                },
            });
        }
    }
};
Modal.showConfirmation = (title, subtitle, confirmButtonTitle, cancelButtonTitle, onConfirm, onPressCancel, onBackdropPress, brandReversedConfirm = false) => {
    if (Modal.setStateRefs) {
        const setState = Modal.setStateRefs.top;
        if (setState) {
            setState({
                visible: true,
                title,
                subtitle,
                content: null,
                options: {
                    showConfirmation: true,
                    onPress: onConfirm,
                    onPressCancel,
                    confirmButtonTitle,
                    cancelButtonTitle,
                    onBackdropPress,
                    brandReversedConfirm,
                },
            });
        }
    }
};
Modal.hide = (onDidHideCallback, options) => {
    if (Modal.setStateRefs) {
        if (onDidHideCallback) {
            if (Modal.onDidHideCallbacks === undefined) {
                Modal.onDidHideCallbacks = {};
            }
            Modal.onDidHideCallbacks[options?.top ? 'top' : 'bottom'] =
                onDidHideCallback;
        }
        const setState = Modal.setStateRefs[options?.top ? 'top' : 'bottom'];
        if (setState) {
            const minHideTime = Modal.minHideTime[options?.top ? 'top' : 'bottom'];
            const now = new Date().getTime();
            if (now < minHideTime) {
                setTimeout(() => {
                    setState((state) => ({ ...state, visible: false }));
                }, minHideTime - now);
            }
            else {
                setState((state) => ({ ...state, visible: false }));
            }
        }
    }
};
const styleSet = createStyleSheets((colors) => ({
    modal: {
        margin: 0,
        justifyContent: 'flex-end',
    },
    modalFullscreen: {
        flex: 1,
    },
    modalTablet: {
        alignSelf: 'center',
        justifyContent: 'center',
    },
    container: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    containerTablet: {
        bottom: undefined,
        borderRadius: rem(16),
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
    },
    containerScrollable: {
        position: 'relative',
        paddingHorizontal: 0,
        paddingBottom: 0,
    },
    nonFullscreen: {
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        paddingHorizontal: rem(16),
        paddingBottom: rem(16),
        backgroundColor: colors.cards.onDark,
        overflow: 'hidden',
    },
    errorContainer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderRadius: rem(25),
        paddingHorizontal: rem(16),
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        paddingBottom: rem(30),
        paddingTop: rem(40),
        alignItems: 'center',
        backgroundColor: colors.cards.onDark,
    },
    title: {
        marginTop: rem(40),
        color: colors.text.primary,
        alignSelf: 'center',
    },
    titleScrollable: {
        paddingBottom: rem(16),
    },
    increasedTopPadding: {
        marginTop: rem(60),
    },
    separatorContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
    scrollContainer: {
        overflow: 'hidden',
    },
    darkBg: {
        backgroundColor: colors.background.dark,
    },
}));
export default Modal;
