import React from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system/values';
import Text from 'design-system/Text';
import IconPlus from 'design-system/icons/IconPlus';
import useColors from 'hooks/useColors';
import IconCircle from 'design-system/icons/IconCircle';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { isWeb } from '../../../constants';
const strings = {
    title: 'Add new',
};
const MerchantBudgetAddRow = ({ onPress }) => {
    const colors = useColors();
    return (<TouchableHighlight onPress={onPress} underlayColor={colors.background.underlay} onCard hoverOnLight>
      <View style={styles.container}>
        <View style={styles.content}>
          <IconCircle width={44} height={44}>
            <IconPlus color={colors.elements.brand}/>
          </IconCircle>
          <View style={styles.innerContent}>
            <View style={styles.titleContainer}>
              <Text Text-16 style={styles.displayName}>
                {strings.title}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    container: {
        padding: rem(16),
        justifyContent: 'center',
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    innerContent: {
        flex: 1,
        marginStart: rem(16),
        flexDirection: 'column',
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
    },
    displayName: {
        paddingTop: rem(8),
        paddingEnd: rem(12),
    },
});
export default MerchantBudgetAddRow;
