import React, { memo, useCallback, useMemo } from 'react';
import { View, Platform } from 'react-native';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import Section from 'design-system/Section';
import Indicator from 'design-system/Indicator';
import { CardView } from 'design-system/CardView';
import IconPlus from 'design-system/icons/IconPlus';
import SectionHeader from 'design-system/SectionHeader';
import { SettingsItem } from 'design-system/SettingsItem';
import { TextButton, TextButtonLink } from 'design-system/TextButton';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { useSubscriptionScreenContext } from 'features/subscriptions/context/subscriptionScreenContext';
import RowSubscriptionTransaction from './RowSubscriptionTransaction';
import { isWeb } from '../../../../constants';
const SubscriptionPrediction = ({ date, onDone, onEdit, onPress, subscriptionId, subscription, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const { editing, hasData, isFetching } = useSubscriptionScreenContext();
    const navigation = useMainStackNavigation();
    const addMoreTxns = useCallback(() => {
        navigation.push('SubscriptionsSearch', {
            subscriptionId,
            empty: {
                title: 'Add a transaction',
                subtitle: 'Search through your transactions and select the one you want to include.\nIt works like magic!',
            },
        });
    }, [subscriptionId]);
    const renderNextPayment = useMemo(() => {
        if (!date) {
            return null;
        }
        return (<Section title="Next payment" style={styles.cardSectionHeader} button={<TextButtonLink text="Edit" onPress={onPress}/>}>
        <CardView noMarginTop noMarginBottom>
          <RowSubscriptionTransaction data={subscription}/>
        </CardView>
      </Section>);
    }, [date, onPress, styles.cardSectionHeader, subscription]);
    const pastSectionHeader = useMemo(() => isFetching && !hasData ? (<View style={styles.indicatorContainer}>
          <Indicator dark/>
        </View>) : (<>
          <SectionHeader horizontalMargins style={styles.cardSectionHeader} button={!editing && !hasData ? null : (<TextButton text={editing ? 'Done' : 'Edit'} onPress={editing ? onDone : onEdit}/>)}>
            Past payments
          </SectionHeader>
          <Spacer.H16 />
          {!editing && (<View style={[styles.addMoreTxn, !hasData && styles.completeAddMoreTxn]}>
              <SettingsItem onPress={addMoreTxns} title="Add more transactions" icon={<IconPlus color={colors.text.brand}/>}/>
            </View>)}
        </>), [
        isFetching,
        styles.indicatorContainer,
        styles.cardSectionHeader,
        styles.addMoreTxn,
        styles.completeAddMoreTxn,
        editing,
        hasData,
        onDone,
        onEdit,
        addMoreTxns,
        colors.text.brand,
    ]);
    return (<>
      {renderNextPayment}
      {pastSectionHeader}
    </>);
};
const styleSet = createStyleSheets((colors) => ({
    sectionHeader: {
        padding: rem(16),
        flexDirection: 'row',
        justifyContent: 'center',
    },
    textContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    amountContainer: {
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    accountRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    accountIcon: {
        width: rem(16),
        height: rem(16),
        marginEnd: rem(4),
        borderRadius: rem(8),
    },
    addMoreTxn: {
        marginHorizontal: rem(16),
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(Platform.OS !== 'android' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingTop: rem(8),
            paddingHorizontal: rem(8),
        }),
    },
    completeAddMoreTxn: {
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
        ...(isWeb && {
            paddingBottom: rem(8),
        }),
    },
    cardSectionHeader: {
        marginTop: rem(24),
        marginBottom: 0,
    },
    indicatorContainer: {
        marginTop: rem(16),
        alignItems: 'center',
    },
}));
export default memo(SubscriptionPrediction);
