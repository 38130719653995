import React from 'react';
import { forceRefreshAnalytics, getCategories, getFeed } from 'actions';
import { CONNECTIONS_REQUEST as CONNECTION_REQUEST_TYPE } from 'actions/types';
import { put, select, takeEvery, takeLatest, putResolve, } from 'typed-redux-saga/macro';
import { differenceInDays, parseISO } from 'date-fns';
import { setDeviceSettingsKey } from 'actions/deviceSettings';
import Modal from 'utils/packages/Modal';
import UltimateExpiredModalContent from 'features/spaces/components/UltimateExpiredModalContent';
import { switchedSpace, getSpaceInfo, getMySpaces, setSelectedSpace, } from '../actions';
import { SELECTED_SPACE, FETCH_SPACES } from '../constants';
import { selectPreviousSelectedSpace, selectSelectedSpaceData, selectSelectedSpace, selectHasSpaces, } from '../reducers/selectors';
const strings = {
    spaceReminderModal: {
        description: 'Only the accounts that you have in this space will be visible in Feed. You can switch Spaces anytime using the icon in the top-right corner.',
        dontShowAgain: "Don't show again",
        ok: 'OK',
    },
    bubbleIndicator: {
        space: 'Switch Spaces from here',
    },
};
function* refreshSpaceAccounts() {
    const space = yield* select(selectSelectedSpaceData);
    if (space && !space.isDefault) {
        yield* put(getSpaceInfo(space.id));
    }
}
function* changeSpace(action) {
    const id = action.payload;
    const previousSpace = yield* select(selectPreviousSelectedSpace);
    if (id !== previousSpace && id !== undefined) {
        yield* put(getSpaceInfo(id));
        yield* put(getFeed());
        yield* put(forceRefreshAnalytics());
        yield* put(getCategories());
        yield* put(switchedSpace());
    }
}
export function* fetchSpacesSaga() {
    try {
        const response = yield* putResolve(getMySpaces());
        if (response?.payload?.spaces) {
            const { spaces } = response.payload;
            const defaultSpace = spaces.find((space) => space.isDefault);
            const selection = yield* select(selectSelectedSpace);
            const selectedSpace = spaces.find((space) => space.id === selection);
            const hideSpaceReminderModal = yield* select((state) => state.deviceSettings.hideSpaceReminderModal);
            const hasSpaces = yield* select(selectHasSpaces);
            const lastSession = yield* select((state) => state.user.lastSession);
            if (selectedSpace &&
                !selectedSpace.isDefault &&
                !selectedSpace.isPremium) {
                Modal.show(<UltimateExpiredModalContent space={selectedSpace} onButtonPress={() => {
                        if (defaultSpace) {
                            put(setSelectedSpace(defaultSpace.id));
                        }
                    }}/>, {
                    nonDismissable: true,
                    title: 'Emma Ultimate has expired',
                    *onDidHideCallback() {
                        if (defaultSpace) {
                            yield* put(setSelectedSpace(defaultSpace.id));
                        }
                    },
                });
                return;
            }
            if (selection && !selectedSpace) {
                if (defaultSpace) {
                    yield* put(setSelectedSpace(defaultSpace.id));
                }
            }
            if (!hideSpaceReminderModal &&
                hasSpaces &&
                selectedSpace &&
                lastSession?.time &&
                differenceInDays(new Date(), parseISO(lastSession.time)) >= 7) {
                Modal.showConfirmation(`You are in ${selectedSpace.isDefault ? 'Primary' : selectedSpace.name} space`, strings.spaceReminderModal.description, strings.spaceReminderModal.dontShowAgain, strings.spaceReminderModal.ok, function* onConfirm() {
                    yield* put(setDeviceSettingsKey('hideSpaceReminderModal', true));
                    Modal.hide();
                }, () => {
                    Modal.hide();
                });
            }
        }
    }
    catch (error) {
        // noop
    }
}
function* spaceSaga() {
    log('[spaceSaga] starting...', false, 'cyan');
    yield* takeEvery(CONNECTION_REQUEST_TYPE, refreshSpaceAccounts);
    yield* takeLatest(SELECTED_SPACE, changeSpace);
    yield* takeLatest(FETCH_SPACES, fetchSpacesSaga);
}
export default spaceSaga;
