import React, { useMemo } from 'react';
import { View } from 'react-native';
import SectionHeader from 'design-system/SectionHeader';
import { rem } from 'design-system/values';
import Amount from 'design-system/Amount';
import { TextButtonLinkSeeAll } from 'design-system/TextButton';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import RowSubscription from 'features/subscriptions/components/row/RowSubscription';
import SwitchingButton from 'features/subscriptions/components/row/SwitchingButton';
import UpgradeButton from 'design-system/UpgradeButton';
import RowTransaction from './RowTransaction';
import { isWeb } from '../../../constants';
const TransactionRow = ({ item, openItem, onCompare, isEditing, isSelected, openSubscription, openSubscriptions, guessedHomeCountry, showUpgradeButton, onPressUpgradeForSearch, blurSubscriptionValues, style, rowStyle, isMultipleSelect, activeOpacity, }) => {
    const styles = useStyles(styleSet);
    const borderStyles = useMemo(() => [
        item.isFirstInSection && [
            styles.firstItem,
            !showUpgradeButton && styles.firstItemWebPadding,
        ],
        item.isLastInSection && [
            styles.lastItem,
            !showUpgradeButton && styles.lastItemWebPadding,
        ],
        !showUpgradeButton && styles.itemWebPadding,
    ], [
        item.isFirstInSection,
        item.isLastInSection,
        showUpgradeButton,
        styles.firstItem,
        styles.firstItemWebPadding,
        styles.itemWebPadding,
        styles.lastItem,
        styles.lastItemWebPadding,
    ]);
    if ('sectionTitle' in item) {
        return (<SectionHeader horizontalMargins style={[styles.sectionHeader, style]} button={item.hasSeeAll ? (<TextButtonLinkSeeAll onPress={openSubscriptions}/>) : (<Amount Secondary Numbers-16 value={{ value: item.totalAmount || 0, currency: item.currency }}/>)}>
        {item.sectionTitle}
      </SectionHeader>);
    }
    if (item && !item.id && item.subscriptionId)
        return null;
    const switchingButton = (<SwitchingButton data={
        // Add merchandInfo if coming from SearchTransactions
        !item.subscription && item && item.merchant
            ? { merchantInfo: item.merchant }
            : item.subscription} onOpenCompare={onCompare} guessedHomeCountry={guessedHomeCountry}/>);
    if ('sectionKey' in item && item?.sectionKey === 'Upcoming transactions') {
        return (<View style={[styles.item, borderStyles, style]}>
        <RowSubscription btnStyles={borderStyles} onPress={openSubscription} switchingButton={switchingButton} data={item} blurValues={blurSubscriptionValues}/>
      </View>);
    }
    const row = (<RowTransaction item={item} backgroundDark showUpgradeButton isMultipleSelect={isMultipleSelect} onPress={openItem} selected={isSelected} btnStyles={isWeb ? undefined : borderStyles} switchingButon={switchingButton} isEditingTransactions={isEditing} style={rowStyle} activeOpacity={activeOpacity}/>);
    return (<View style={[styles.item, borderStyles, style]}>
      {showUpgradeButton ? (<UpgradeButton onPress={onPressUpgradeForSearch}>
          <View style={styles.webPadding}>{row}</View>
        </UpgradeButton>) : (row)}
    </View>);
};
export default TransactionRow;
const styleSet = createStyleSheets((colors) => ({
    webPadding: {
        ...(isWeb && {
            padding: rem(8),
        }),
    },
    sectionHeader: {
        paddingTop: rem(24),
        paddingBottom: rem(16),
    },
    firstItem: {
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
    },
    firstItemWebPadding: {
        ...(isWeb && {
            paddingTop: rem(8),
        }),
    },
    lastItem: {
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
    },
    lastItemWebPadding: {
        ...(isWeb && {
            paddingBottom: rem(8),
        }),
    },
    item: {
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
    },
    itemWebPadding: {
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
}));
