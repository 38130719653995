import React, { memo } from 'react';
import { View } from 'react-native';
import Button from 'design-system/Button';
import Amount from 'design-system/Amount';
import Spacer from 'design-system/Spacer';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { RollingBudgetsTooltip } from './RollingBudgetsTooltip';
import { isWeb } from '../../../constants';
export const BudgetRollingAmountLabel = memo(({ rollingAccumulatedLimit, currency }) => {
    const styles = useStyles(styleSet);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'rollingBudgets',
    });
    return (<View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text Text-16>Rolling amount</Text>
          {!isWeb && (<>
              <Spacer w={10}/>
              <RollingBudgetsTooltip />
            </>)}
        </View>
        <Spacer.W8 />
        {isUnlockedForSpace ? (<Amount Numbers-16 value={{
                value: rollingAccumulatedLimit,
                currency,
            }}/>) : (<Button title="Upgrade" brandReversed square small onPress={onPressUpgrade}/>)}
      </View>);
});
const styleSet = createStyleSheets(() => ({
    container: {
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
        paddingBottom: rem(8),
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
    },
    titleContainer: { flexDirection: 'row', alignItems: 'center' },
}));
