import React, { memo } from 'react';
import Animated, { interpolateColor, useAnimatedStyle, } from 'react-native-reanimated';
import { TextInput, View } from 'react-native';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import Spacer from './Spacer';
import { rem } from './values';
import { SEARCH_BAR_HEIGHT } from './SearchBar';
import IconSearch from './icons/IconSearch';
const FakeSearchBar = ({ style, scrollY, placeholder = 'Search', }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const bgColorStyle = useAnimatedStyle(() => ({
        backgroundColor: !scrollY
            ? colors.cards.onDark
            : interpolateColor(scrollY.value, [0, 40], [
                colors.cards.onDark,
                colors.background.dark,
            ]),
    }), [colors]);
    return (<View pointerEvents="none" style={[styles.container, style]}>
      <Animated.View style={[styles.searchBar, scrollY && bgColorStyle]}>
        <IconSearch />
        <Spacer w={8}/>
        <TextInput placeholder={placeholder} style={styles.searchInput} selectionColor={colors.text.selection} placeholderTextColor={colors.text.placeholder}/>
      </Animated.View>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        marginTop: rem(8),
        paddingHorizontal: rem(16),
        marginBottom: rem(16),
    },
    searchBar: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: rem(12),
        height: SEARCH_BAR_HEIGHT,
        paddingHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
    },
    searchInput: {
        minWidth: 140,
        fontFamily: 'Montserrat-Light',
        fontSize: rem(16),
        color: colors.text.primary,
        padding: 0,
    },
}));
export default memo(FakeSearchBar);
