import { groupBy, sumBy } from 'lodash';
import { REHYDRATE } from 'redux-persist';
import { format, getWeek, getYear, parseISO } from 'date-fns';
import { formatToPeriods } from 'reducers/selectors';
import * as types from '../actions/types';
const initialState = {
    balances: {},
    investmentBalances: {},
    isValid: true,
    goals: [],
    loadedGoals: false,
};
const buildAverageArray = (arrayItems) => {
    if (!arrayItems) {
        return [];
    }
    const averageItems = Object.keys(arrayItems).map((key) => {
        const item = arrayItems[key];
        const displayStart = format(parseISO(item[item.length - 1].timestamp), 'do MMM yyyy');
        const displayEnd = format(parseISO(item[0].timestamp), 'do MMM yyyy');
        let averageBalance = sumBy(item, 'balance') / item.length;
        averageBalance = parseFloat(averageBalance.toFixed(2));
        return {
            timestamp: item[0].timestamp,
            balance: averageBalance,
            currency: item[0].currency,
            label: `${displayStart} - ${displayEnd}`, // eg: '01 Jan 2020 -  7 Jan 2020'
        };
    });
    return averageItems.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
};
const accounts = (state = initialState, action) => {
    let formatDaysData = null;
    let groupedDaysByWeek = null;
    let averageWeeks = null;
    let groupedDaysByMonth = null;
    let averageMonths = null;
    switch (action.type) {
        case REHYDRATE: {
            const { accounts } = action.payload || {};
            if (accounts) {
                return {
                    ...initialState,
                    ...accounts,
                };
            }
            return state;
        }
        case types.RESET_INVESTMENT_BALANCES: {
            return {
                ...state,
                investmentBalances: {},
            };
        }
        case types.GET_INVESTMENT_SUCCESS:
            const investmentHistory = { ...state.investmentBalances };
            if (!Object.keys(investmentHistory).length) {
                investmentHistory.periods = {};
            }
            investmentHistory.currency = action.payload.currency;
            const investmentBalancesPeriods = investmentHistory.periods;
            if (action.extra.stepSize === '1hour') {
                if (investmentBalancesPeriods) {
                    investmentBalancesPeriods.day = action.payload.history
                        .slice()
                        .reverse()
                        .map((day) => ({
                        ...day,
                        label: format(parseISO(day.timestamp), 'do MMM yyyy - h:mmaaa'), // eg: '01 Jan 2020 - 2:00pm'
                    }));
                }
                return {
                    ...state,
                    investmentBalances: investmentHistory,
                    isValid: true,
                };
            }
            const data = formatToPeriods(action.payload.history);
            // Select relevant weeks/months
            if (investmentBalancesPeriods) {
                investmentBalancesPeriods.lastupdated = data.lastUpdated;
                investmentBalancesPeriods.week = data.week;
                investmentBalancesPeriods.month = data.month;
                investmentBalancesPeriods.threemonths = data.threemonths;
                investmentBalancesPeriods.sixmonths = data.sixmonths;
                investmentBalancesPeriods.year = data.year;
            }
            return {
                ...state,
                investmentBalances: investmentHistory,
                isValid: true,
            };
        case types.GET_BALANCE_SUCCESS:
            if (action.extra.accountType === 'SAVINGS' ||
                action.extra.accountType === 'NETWORTH' ||
                action.extra.accountType === 'INVESTMENT') {
                // Clearing the old persisted data
                return {
                    ...state,
                    balances: {
                        ...state.balances,
                        [action.extra.accountType]: undefined,
                    },
                };
            }
            const balancesHistory = { ...state.balances };
            if (!balancesHistory[action.extra.accountType]) {
                balancesHistory[action.extra.accountType] = {
                    periods: {},
                };
            }
            balancesHistory[action.extra.accountType].currency =
                action.payload.currency;
            balancesHistory[action.extra.accountType].toProcess = true;
            const balancesPeriods = balancesHistory[action.extra.accountType].periods;
            if (action.extra.stepSize === '1hour') {
                balancesPeriods.day = action.payload.history
                    .slice()
                    .reverse()
                    .map((day) => ({
                    ...day,
                    label: format(parseISO(day.timestamp), 'do MMM yyyy - h:mmaaa'), // eg: '01 Jan 2020 - 2:00pm'
                }));
                return {
                    ...state,
                    balances: balancesHistory,
                    isValid: true,
                };
            }
            // Format data by Day
            formatDaysData = action.payload.history
                .slice(0, 7)
                .reverse()
                .map((day) => ({
                ...day,
                label: format(parseISO(day.timestamp), 'do MMM yyyy'), // eg: '01 Jan 2020'
            }));
            // Group data by Week
            groupedDaysByWeek = groupBy(action.payload.history, (item) => {
                const date = parseISO(item.timestamp);
                return `${getWeek(date)}${getYear(date)}`;
            });
            averageWeeks = buildAverageArray(groupedDaysByWeek);
            // Group data by month
            groupedDaysByMonth = groupBy(action.payload.history, (item) => {
                const d = new Date(item.timestamp);
                return `${d.getMonth()}${d.getFullYear()}`;
            });
            averageMonths = buildAverageArray(groupedDaysByMonth);
            // Select relevant weeks/months
            balancesPeriods.lastupdated = formatDaysData.slice(formatDaysData.length - 1);
            balancesPeriods.week = formatDaysData;
            balancesPeriods.month = averageWeeks.slice(averageWeeks.length - 5);
            balancesPeriods.threemonths = averageWeeks.slice(averageWeeks.length - 15);
            balancesPeriods.sixmonths = averageMonths.slice(averageMonths.length - 6);
            balancesPeriods.year = averageMonths.slice(averageMonths.length - 12);
            // Change all balances to negative values for LOAN
            if (action.extra.accountType === 'LOAN') {
                const reverseBalance = (item) => ({
                    ...item,
                    balance: item.balance * -1,
                });
                balancesPeriods.lastupdated =
                    balancesPeriods.lastupdated.map(reverseBalance);
                balancesPeriods.week = balancesPeriods.week.map(reverseBalance);
                balancesPeriods.month = balancesPeriods.month.map(reverseBalance);
                balancesPeriods.threemonths =
                    balancesPeriods.threemonths.map(reverseBalance);
                balancesPeriods.sixmonths =
                    balancesPeriods.sixmonths.map(reverseBalance);
                balancesPeriods.year = balancesPeriods.year.map(reverseBalance);
            }
            return {
                ...state,
                balances: balancesHistory,
                isValid: true,
                storageNeedReset: true,
            };
        case types.SET_BALANCE_STORAGE_RESET:
            return {
                ...state,
                storageNeedReset: false,
            };
        case types.SET_CONNECTIONS_STATUS:
        case types.HIDE_SUCCESS:
        case types.CLOSED_CONNECTION_SUCCESS:
        case types.DELETE_CONNECTION_SUCCESS:
            if ('payload' in action && action.payload === true) {
                return state;
            }
            return { ...state, isValid: false };
        case types.EDIT_ACCOUNT_SUCCESS: {
            if (!state.goals ||
                Array.isArray(state.goals) ||
                !state.goals[action.extra.id] ||
                action.extra.body.balance === undefined)
                return state;
            const goal = state.goals[action.extra.id];
            const percentageProgress = (action.extra.body.balance / goal.targetAmount) * 100;
            return {
                ...state,
                goals: {
                    ...state.goals,
                    [action.extra.id]: {
                        ...goal,
                        currentBalance: action.extra.body.balance,
                        percentageProgress: percentageProgress > 100 ? 100 : +percentageProgress.toFixed(2),
                    },
                },
                loadedGoals: false,
            };
        }
        case types.GET_ACCOUNT_GOALS_SUCCESS: {
            let newGoals = {};
            if (action.payload?.goals) {
                action.payload.goals.forEach((element) => {
                    newGoals[element.accountId] = { ...element };
                });
            }
            else {
                newGoals = state.goals;
            }
            return { ...state, loadedGoals: true, goals: newGoals };
        }
        case types.DELETE_GOAL_SUCCESS: {
            const newGoals = { ...state.goals };
            delete newGoals[action.extra.accountId];
            return {
                ...state,
                goals: newGoals,
            };
        }
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
export default accounts;
