import React, { useLayoutEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import Button from 'design-system/Button';
import EmptyComponent from 'design-system/EmptyComponent';
import SuccessView from 'design-system/SuccessView';
import useDisableAndroidBackButton from 'hooks/useDisableAndroidBackButton';
import StatusBar from 'design-system/StatusBar';
import { ButtonGroup } from 'design-system/ButtonGroup';
const strings = {
    success: 'Success',
    continue: 'Continue',
};
export const SuccessScreen = React.memo(({ route, navigation }) => {
    const subTitle = route.params?.subTitle;
    const title = route.params?.title || strings.success;
    const topTitle = route.params?.topTitle;
    const onContinue = route.params?.onContinue;
    const onSkip = route.params?.onSkip;
    const dontLockButton = route.params?.dontLockButton;
    const skipButtonText = route.params?.skipButtonText;
    const BtnPrefixComponent = route.params?.BtnPrefixComponent;
    const secondaryButtonText = route.params?.secondaryButtonText;
    const buttonText = route.params?.buttonText || strings.continue;
    const onSecondaryButtonPress = route.params?.onSecondaryButtonPress;
    const secondaryButtonLeftIcon = route.params?.secondaryButtonLeftIcon;
    useLayoutEffect(() => {
        navigation.setOptions({
            gestureEnabled: false,
            headerLeft: EmptyComponent,
        });
    }, []);
    const hasPressedContinue = React.useRef(false);
    const handlePress = React.useCallback(() => {
        if (dontLockButton) {
            return onContinue();
        }
        if (!hasPressedContinue.current) {
            hasPressedContinue.current = true;
            return onContinue();
        }
        return true;
    }, [dontLockButton, onContinue]);
    useDisableAndroidBackButton(handlePress);
    return (<View style={styles.container}>
        <StatusBar barStyle="dark-content" animated/>
        <SuccessView title={title} subTitle={subTitle} topTitle={topTitle}>
          <ButtonGroup type="floating">
            {BtnPrefixComponent && <BtnPrefixComponent />}
            {!!(onSkip && skipButtonText) && (<Button transparent onPress={onSkip} title={skipButtonText}/>)}
            {!!(secondaryButtonText && onSecondaryButtonPress) && (<Button title={secondaryButtonText} onPress={onSecondaryButtonPress} leftIcon={secondaryButtonLeftIcon} brandReversed/>)}
            <Button title={buttonText} onPress={handlePress} brand/>
          </ButtonGroup>
        </SuccessView>
      </View>);
});
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
