import * as React from 'react';
import Svg, { Path, G, Defs, ClipPath } from 'react-native-svg';
const IconCategoryHousing = (props) => (<Svg width={props.width} height={props.height} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path fill={props.fill} d="M10.64 19.957v-2.599c0-.663.541-1.201 1.21-1.201h2.443c.32 0 .628.127.855.352.227.225.355.53.355.85v2.598a1.03 1.03 0 0 0 .303.737c.195.196.462.306.74.306h1.666a2.941 2.941 0 0 0 2.077-.85c.551-.545.861-1.286.861-2.059v-7.404c0-.624-.279-1.217-.761-1.617l-5.67-4.496a2.633 2.633 0 0 0-3.356.06L5.822 9.07A2.103 2.103 0 0 0 5 10.687v7.397C5 19.694 6.315 21 7.938 21h1.628c.577 0 1.046-.462 1.05-1.035l.024-.008Z"/>
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill={props.fill} d="M.602 0h24v24h-24z"/>
      </ClipPath>
    </Defs>
  </Svg>);
export default IconCategoryHousing;
