import { Platform } from 'react-native';
import * as types from './types';
import { CALL_API } from '../middleware/api';
export const getBanks = () => ({
    [CALL_API]: {
        endpoint: 
        // eslint-disable-next-line operator-linebreak
        '/banks?types[]=BANK&types[]=BANK_NOT_SUPPORTED' +
            // eslint-disable-next-line operator-linebreak
            '&types[]=CREDIT_CARD&types[]=WALLET&types[]=CRYPTO_EXCHANGE' +
            // eslint-disable-next-line operator-linebreak
            '&types[]=CRYPTO_WALLET&types[]=PENSION&types[]=INVESTMENT' +
            // eslint-disable-next-line operator-linebreak
            '&login_types[]=OAUTH2&login_types[]=NONE&login_types[]=APIKEY' +
            // eslint-disable-next-line operator-linebreak
            '&login_types[]=NOT_SUPPORTED' +
            // eslint-disable-next-line operator-linebreak
            '&login_types[]=PLAID_LINK&withChildEntries=true' +
            '&hasPersonalBanking=true&hasBusinessBanking=true',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [types.BANKS_REQUEST, types.BANKS_SUCCESS, types.BANKS_FAILURE],
    },
});
export const loginWithCredentials = (id, body, muteAlert = false) => ({
    [CALL_API]: {
        endpoint: `/banks/${id}/login-with-credentials`,
        method: 'POST',
        body: JSON.stringify(body),
        muteAlert,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.LOGIN_BANK_REQUEST,
            types.LOGIN_BANK_SUCCESS,
            types.LOGIN_BANK_FAILURE,
        ],
    },
});
export const getPlaidLink = () => ({
    [CALL_API]: {
        // Using Platform.OS gets us a link to the native plaid SDK
        // We used to use 'web' here and open a webview
        endpoint: `/plaid/link?platform=${Platform.OS}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.PLAID_LINK_REQUEST,
            types.PLAID_LINK_SUCCESS,
            types.PLAID_LINK_FAILURE,
        ],
        muteAlert: false,
    },
});
export const linkPlaidConnection = (data) => async (dispatch, getState) => {
    const { linkBank } = getState();
    if (!linkBank) {
        return false;
    }
    const { plaid_details } = linkBank;
    if (!plaid_details) {
        return false;
    }
    const { state } = plaid_details;
    const body = { ...data, state };
    return dispatch({
        [CALL_API]: {
            endpoint: '/plaid/redirect',
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: [
                types.LINK_PLAID_CONNECTION_REQUEST,
                types.LINK_PLAID_CONNECTION_SUCCESS,
                types.LINK_PLAID_CONNECTION_FAILURE,
            ],
        },
    });
};
export const getLinkToBank = (data) => ({
    [CALL_API]: {
        endpoint: `/banks/${data}/auth-link?supportAppLink=true`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [types.LINK_REQUEST, types.LINK_SUCCESS, types.LINK_FAILURE],
        muteAlert: false,
    },
});
export const getRenewAuth = (id) => ({
    [CALL_API]: {
        // Using Platform.OS gets us a link to the native plaid SDK
        // We used to use 'web' here and open a webview
        endpoint: `/bank-connections/${id}/renew-auth?supportAppLink=true&platform=${Platform.OS}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [types.LINK_REQUEST, types.LINK_SUCCESS, types.LINK_FAILURE],
        muteAlert: false,
    },
});
