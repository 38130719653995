import * as RNLocalize from 'react-native-localize';
import i18n from '../../../i18n';
const locales = RNLocalize.getLocales();
i18n.locale = locales[0].languageTag;
export const FREQUENCY = {
    1: 'Daily',
    7: 'Weekly',
    14: i18n.t('fortnightly'),
    28: 'Every 4 weeks',
    30: 'Monthly',
    60: '2 months',
    90: 'Quarterly',
    180: '6 months',
    360: 'Yearly',
};
