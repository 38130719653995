export var RentReportingFrequency;
(function (RentReportingFrequency) {
    RentReportingFrequency["WEEKLY"] = "WEEKLY";
    RentReportingFrequency["FORTNIGHTLY"] = "FORTNIGHTLY";
    RentReportingFrequency["MONTHLY"] = "MONTHLY";
    RentReportingFrequency["QUARTERLY"] = "QUARTERLY";
    RentReportingFrequency["BIANNUALLY"] = "BIANNUALLY";
    RentReportingFrequency["ANNUALLY"] = "ANNUALLY";
    RentReportingFrequency["PERIODIC"] = "PERIODIC";
})(RentReportingFrequency || (RentReportingFrequency = {}));
