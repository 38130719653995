import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import { rem } from 'design-system/values';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import { isWeb } from '../../../constants';
const BudgetingGraphLabel = ({ title, color, amount, style, hasIndicator = true, onPress, }) => (<TouchableOpacity onCard hoverOnLight disabled={!onPress} onPress={onPress} style={[styles.row, style]}>
    <View style={styles.label}>
      {hasIndicator && (<View style={[styles.indicator, { backgroundColor: color }]}/>)}
      <Text Text-16 style={styles.text}>
        {title}
      </Text>
    </View>
    <Amount Numbers-16 value={{ value: amount.amount, currency: amount.currency }}/>
  </TouchableOpacity>);
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...(isWeb && {
            paddingVertical: rem(8),
            paddingHorizontal: rem(8),
        }),
    },
    text: {
        paddingHorizontal: rem(8),
        paddingVertical: rem(4),
    },
    indicator: {
        width: rem(8),
        height: rem(8),
        borderRadius: rem(4),
    },
    label: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});
export default BudgetingGraphLabel;
