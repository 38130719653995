import { useCallback } from 'react';
import useRequiredBenefitCallback from 'features/premium/hooks/useRequiredBenefitCallback';
import { useAppStore } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
export const useSmartRulesNavigation = () => {
    const { navigate } = useMainStackNavigation();
    const store = useAppStore();
    const startCreateFlow = useRequiredBenefitCallback(useCallback(() => {
        navigate('SmartRuleName');
    }, []), 'smartRules');
    const onPressSmartRules = useCallback((isCreateFlow) => {
        const { didViewSmartRulesIntro } = store.getState().deviceSettings;
        const hasRule = store.getState().smartRules.rules?.length;
        if (didViewSmartRulesIntro || hasRule) {
            if (isCreateFlow === true) {
                startCreateFlow();
                return;
            }
            navigate('SmartRules');
        }
        else {
            navigate('SmartRuleIntro');
        }
    }, [startCreateFlow]);
    return { onPressSmartRules, startCreateFlow };
};
