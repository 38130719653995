import * as types from '../actions/types';
export const initialState = {
    transactionsReceipts: {},
};
export const getReceiptTransactionId = (transactionId, isGroup) => isGroup ? `group_transaction_${transactionId}` : transactionId.toString();
const unpersistedTransactions = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_RECEIPTS_SUCCESS: {
            const { payload, extra: { isGroup, transactionId }, } = action;
            const id = getReceiptTransactionId(transactionId, isGroup);
            const transactionsReceipts = {
                ...state.transactionsReceipts,
                [id]: payload.map((r) => {
                    const cachedReceipt = state.transactionsReceipts[id]?.find((receipt) => receipt.id === r.id);
                    const url = r.url?.split('?')[0];
                    const extension = url.split('.').pop();
                    return {
                        ...cachedReceipt,
                        ...r,
                        url: r.url?.split('?')[0],
                        originalUrl: r.url,
                        fileName: r.fileName || `receipt-${r.id}.${extension}`,
                    };
                }),
            };
            return { ...state, transactionsReceipts };
        }
        case types.UPLOAD_RECEIPT_REQUEST: {
            const { transactionId, files, isGroup } = action.extra;
            const now = Date.now();
            const id = getReceiptTransactionId(transactionId, isGroup);
            return {
                ...state,
                transactionsReceipts: {
                    ...state.transactionsReceipts,
                    [id]: [
                        ...state.transactionsReceipts[id],
                        ...files.map(({ name, uri }, i) => ({
                            id: now + i,
                            fileUri: uri,
                            fileName: name,
                            isUploading: true,
                        })),
                    ],
                },
            };
        }
        case types.UPLOAD_RECEIPT_SUCCESS: {
            const { transactionId, files, isGroup } = action.extra;
            if (!action.payload)
                return state;
            const id = getReceiptTransactionId(transactionId, isGroup);
            const transactionsReceipts = {
                ...state.transactionsReceipts,
                [id]: [
                    ...state.transactionsReceipts[id].slice(0, -files.length),
                    ...action.payload.map((r, i) => ({
                        ...state.transactionsReceipts[id][state.transactionsReceipts[id].length - files.length + i],
                        ...r,
                        url: r.url?.split('?')[0],
                        originalUrl: r.url,
                        isUploading: false,
                    })),
                ],
            };
            return { ...state, transactionsReceipts };
        }
        case types.UPLOAD_RECEIPT_FAILURE: {
            const { transactionId, files, isGroup } = action.extra;
            const id = getReceiptTransactionId(transactionId, isGroup);
            return {
                ...state,
                transactionsReceipts: {
                    ...state.transactionsReceipts,
                    [id]: state.transactionsReceipts[id].slice(0, -files.length),
                },
            };
        }
        case types.DELETE_RECEIPT_REQUEST: {
            const { receipt, transactionId, isGroup } = action.extra;
            const id = getReceiptTransactionId(transactionId, isGroup);
            return {
                ...state,
                transactionsReceipts: {
                    ...state.transactionsReceipts,
                    [id]: state.transactionsReceipts[id].filter((r) => r.id !== receipt.id),
                },
            };
        }
        case types.DELETE_RECEIPT_FAILURE: {
            const { receipt, transactionId, index, isGroup } = action.extra;
            const id = getReceiptTransactionId(transactionId, isGroup);
            return {
                ...state,
                transactionsReceipts: {
                    ...state.transactionsReceipts,
                    [id]: state.transactionsReceipts[id].toSpliced(index, 0, receipt),
                },
            };
        }
        default:
            return state;
    }
};
export default unpersistedTransactions;
