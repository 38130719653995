import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, } from 'react';
import { parseISO, format, isFuture, sub, add } from 'date-fns';
import { View } from 'react-native';
import Animated from 'react-native-reanimated';
import { CommonActions } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import Button, { HEIGHT } from 'design-system/Button';
import Indicator from 'design-system/Indicator';
import { AppView } from 'design-system/AppView';
import ListEmptyView from 'design-system/ListEmptyView';
import StackHeader from 'design-system/hoc/StackHeader';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { getTransactionsFlatlistData, } from 'utils/getTransactions';
import { selectBudgetingPosition, selectMonthlyTotals, selectMonthlyTotalsFilteredWithFallbackMonths, } from 'features/analytics/selectors';
import Amplitude from 'utils/amplitude';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useEstimatedListSize from 'hooks/useEstimatedListSize';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { mapStepToDateFnsUnit, mapStepToStartOf, } from 'features/analytics/hooks/useOpenCategory';
import useSyncMonthlyExpenses from 'features/analytics/hooks/useSyncMonthlyExpenses';
import TransactionsHeader from 'features/transactions/components/TransactionsHeader';
import useAdvancedEditTransactions from 'features/transactions/hooks/useAdvancedEditTransactions';
import { addTransactionToCache } from 'actions';
import useHeaderHeight from 'hooks/useHeaderHeight';
import { selectMerchantBudgets, makeSelectCategoryTotalById, makeSelectMerchantTotalById, } from '../selectors';
import { getBudgets } from '../actions';
import { isWeb } from '../../../constants';
import CategoryEditModal from '../components/CategoryEditModal';
import { getMerchantBudgetById } from '../utils/getMerchantBudget';
import MonthlyCategoryHeader from '../components/MonthlyCategoryHeader';
import MonthlyCategoryTransactionRow from '../components/MonthlyCategoryTransactionRow';
const keyExtractor = (item) => item.id.toString();
const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);
const estimatedItemSize = rem(98);
const MonthlyCategoryScreen = withAnimatedNavigationHeader(({ navigation, route, scrollHandler }) => {
    const navRightBtnRef = useRef(null);
    const styles = useStyles(styleSet);
    const position = useAppSelector(selectBudgetingPosition);
    const unfilteredMonthlyTotals = useAppSelector(selectMonthlyTotals);
    const filteredMonthlyTotals = useAppSelector(selectMonthlyTotalsFilteredWithFallbackMonths);
    const data = useMemo(() => {
        if (route.params?.to &&
            route.params?.from &&
            (route.params?.merchantId || route.params?.categoryId)) {
            const { to, from } = route.params;
            let toDate = parseISO(to);
            let fromDate = parseISO(from);
            const monthlyTotals = filteredMonthlyTotals?.results || unfilteredMonthlyTotals;
            const unit = mapStepToDateFnsUnit(filteredMonthlyTotals?.step);
            const startOfUnit = mapStepToStartOf(filteredMonthlyTotals?.step);
            if (filteredMonthlyTotals?.step !== 'custom') {
                if (position === 0) {
                    fromDate = sub(startOfUnit(fromDate), { [unit]: 6 });
                }
                else {
                    toDate = add(startOfUnit(toDate), { [unit]: 3 });
                    fromDate = sub(startOfUnit(fromDate), { [unit]: 3 });
                    if (isFuture(add(parseISO(to), { [unit]: 3 }))) {
                        toDate = parseISO(monthlyTotals[0].to);
                    }
                }
            }
            return {
                step: route.params?.step,
                monthEndDate: route.params.to,
                to: format(toDate, 'yyyy-MM-dd'),
                monthStartDate: route.params.from,
                from: format(fromDate, 'yyyy-MM-dd'),
                fromCurrentMonth: route.params.from,
                merchantId: route.params?.merchantId,
                categoryId: route.params?.categoryId,
            };
        }
        return undefined;
    }, [
        filteredMonthlyTotals?.results,
        filteredMonthlyTotals?.step,
        position,
        route.params,
        unfilteredMonthlyTotals,
    ]);
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const isFetchingCategoryExpenses = useAppSelector((store) => store.expenses.isFetchingCategoryExpenses);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getBudgets());
    }, []);
    const [, categoryExpenses] = useSyncMonthlyExpenses(data);
    const selectCategoryTotalById = useMemo(makeSelectCategoryTotalById, []);
    const selectMerchantTotalById = useMemo(makeSelectMerchantTotalById, []);
    const category = useAppSelector((store) => {
        if (data?.categoryId) {
            return selectCategoryTotalById?.(store, data.categoryId);
        }
        if (data?.merchantId) {
            return selectMerchantTotalById?.(store, data.merchantId);
        }
        return undefined;
    });
    useEffect(() => {
        if (!category && isWeb) {
            navigation.dispatch(CommonActions.reset({
                index: 0,
                routes: [{ name: 'Budgeting' }],
            }));
        }
    }, [category]);
    const merchantBudgets = useAppSelector(selectMerchantBudgets);
    const handleEditCategory = useCallback(() => {
        if (data?.categoryId) {
            navigation.navigate('Budgets', {
                categoryId: data.categoryId,
            });
        }
        else if (data?.merchantId) {
            // For merchants we get the latest merchant budget data, as it may have changed if this is a past period
            const merchantBudget = getMerchantBudgetById(merchantBudgets, data.merchantId);
            const monthlyMerchant = category;
            navigation.navigate('EditMerchantBudget', {
                id: data.merchantId ?? monthlyMerchant?.displayName,
                displayName: merchantBudget?.displayName ?? monthlyMerchant?.displayName,
                color: merchantBudget?.color ?? monthlyMerchant.color,
                budgetLimit: merchantBudget?.totalLimit ?? monthlyMerchant.budgetLimit,
                currency: merchantBudget?.currency ?? monthlyMerchant.currency,
                iconUrl: monthlyMerchant.iconUrl,
            });
        }
    }, [category, data?.categoryId, data?.merchantId, merchantBudgets]);
    const { selected, isEditing, selectedObj, onEdit, onSelected, openEditModal, } = useAdvancedEditTransactions(navigation);
    const flashListData = useMemo(() => isFetchingCategoryExpenses
        ? []
        : getTransactionsFlatlistData(categoryExpenses, false, true, true), [categoryExpenses, isFetchingCategoryExpenses]);
    const onPressEditButton = useCallback(() => {
        if (isEditing) {
            onEdit(false);
            return;
        }
        if (route.params?.isEditable) {
            if (flashListData.length === 0) {
                handleEditCategory();
            }
            else {
                CategoryEditModal.show(handleEditCategory, onEdit, navRightBtnRef);
            }
        }
        else {
            onEdit(true);
        }
    }, [
        flashListData.length,
        handleEditCategory,
        isEditing,
        onEdit,
        route.params?.isEditable,
    ]);
    useLayoutEffect(() => {
        if (category) {
            navigation.setOptions({
                ...(route.params?.isEditable && {
                    headerRight: () => (<View ref={navRightBtnRef}>
                <TransactionsHeader isEditing={isEditing} onPressEdit={onPressEditButton}/>
              </View>),
                }),
                headerTitle: () => (<StackHeader width={250} title={category?.displayName}/>),
                title: category.displayName,
            });
        }
    }, [category, isEditing, onPressEditButton, route.params?.isEditable]);
    const onCompare = useCallback((routeWithParams) => {
        navigation.navigate(routeWithParams);
        Amplitude.logEvent('Category.openCompare', {
            type: routeWithParams.name,
        });
    }, []);
    const openItem = useCallback((data) => {
        const { isPending } = data;
        if (isEditing) {
            if (isPending)
                return;
            onSelected(data);
            return;
        }
        dispatch(addTransactionToCache(data));
        navigation.navigate('Item', { id: data.id });
        Amplitude.logEvent('Category.OpenTransaction');
    }, [isEditing, onSelected]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const contentContainerStyle = useMarginBottom('paddingBottom', isEditing ? HEIGHT + rem(16) : 0, paddingHorizontalStyle);
    const ListHeaderComponent = useMemo(() => category &&
        data?.monthStartDate &&
        data?.monthEndDate &&
        (data?.categoryId || data?.merchantId) ? (<MonthlyCategoryHeader data={category} periodStartDate={data.monthStartDate} periodEndDate={data.monthEndDate} onPressSetup={handleEditCategory} type={data?.categoryId ? 'category' : 'merchant'}/>) : null, [
        category,
        data?.categoryId,
        data?.merchantId,
        data?.monthEndDate,
        data?.monthStartDate,
        handleEditCategory,
    ]);
    const renderItem = useCallback(({ item }) => (<MonthlyCategoryTransactionRow item={item} onPress={openItem} onCompare={onCompare} guessesHomeCountry={guessedHomeCountry} isEditingTransactions={isEditing} selected={!!selectedObj[item.id]}/>), [guessedHomeCountry, isEditing, onCompare, openItem, selectedObj]);
    const estimatedListSize = useEstimatedListSize(flashListData);
    const headerHeight = useHeaderHeight();
    const indicatorStyle = useMemo(() => ({ marginTop: headerHeight * 2 }), [headerHeight]);
    const ListEmptyComponent = useMemo(() => {
        if (isFetchingCategoryExpenses) {
            return <Indicator dark style={indicatorStyle}/>;
        }
        return (<ListEmptyView happy onCard style={styles.empty} title="There are no transactions"/>);
    }, [indicatorStyle, isFetchingCategoryExpenses, styles.empty]);
    const hasEditTransaction = isEditing && selected.length !== 0;
    const extraData = useMemo(() => [isEditing, selectedObj], [isEditing, selectedObj]);
    const baseProps = {
        data: flashListData,
        renderItem,
        scrollEventThrottle: 16,
        onScroll: scrollHandler,
        onEndReachedThreshold: 0.8,
        keyExtractor,
        ListEmptyComponent,
        ListHeaderComponent,
        contentContainerStyle,
        scrollIndicatorInsets: SCROLL_INDICATOR_INSETS,
        extraData,
    };
    return (<AppView style={styles.content} withFrame={false}>
        {isWeb ? (<Animated.FlatList {...baseProps}/>) : (<AnimatedFlashList {...baseProps} estimatedListSize={estimatedListSize} estimatedItemSize={estimatedItemSize}/>)}

        {hasEditTransaction && (<Button brand floating title={`Edit (${selected.length}) Transactions`} onPress={openEditModal}/>)}
      </AppView>);
}, ({ colors }) => ({
    hasCustomTitle: true,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styleSet = createStyleSheets(() => ({
    content: {
        flex: 1,
    },
    empty: {
        marginTop: rem(16),
    },
}));
export default MonthlyCategoryScreen;
