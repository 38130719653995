import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { rem, SettingsItemNavigate, SettingsSection } from 'design-system';
import { IconBank, IconHome } from 'design-system/icons';
import { IconBankCard } from 'design-system/icons/IconBankCard';
import IconCar from 'design-system/icons/IconCar';
import { IconMoneyCheckPenSolid } from 'design-system/icons/IconMoneyCheckPenSolid';
import useLoans from 'features/feed/components/save/useLoans';
import Links from 'utils/links';
import { isWeb } from '../../../constants';
const LoanItemsList = () => {
    const loans = useLoans();
    const onPressLoans = useCallback(() => {
        loans('LOAN');
    }, [loans]);
    const onPressCars = useCallback(() => {
        loans('CAR_FINANCE');
    }, [loans]);
    const onPressDebtConsolidation = useCallback(() => {
        loans('DEBT');
    }, [loans]);
    const onPressHomeImprovements = useCallback(() => {
        loans('HOME_IMPROVEMENTS');
    }, [loans]);
    const onPressCreditCard = useCallback(() => {
        loans('CREDIT_CARD');
    }, [loans]);
    const goToLoans = useCallback(() => {
        Links.openKnownLink('loans');
    }, []);
    const goToCreditCards = useCallback(() => {
        Links.openKnownLink('creditCards');
    }, []);
    return (<SettingsSection noMarginTop style={styles.listSection}>
      <SettingsItemNavigate titleFirst icon={<IconBank gradientKey="green"/>} title="Loans" description="Browse short and long term loans" onPress={isWeb ? goToLoans : onPressLoans}/>
      <SettingsItemNavigate titleFirst icon={<IconCar gradientKey="darkPurple"/>} title="Car finance" description="Pay for your car in installments" onPress={isWeb ? goToLoans : onPressCars}/>
      <SettingsItemNavigate titleFirst icon={<IconHome gradientKey="lightPink"/>} title="Home improvements" description="Pay for your next renovation" onPress={isWeb ? goToLoans : onPressHomeImprovements}/>
      <SettingsItemNavigate titleFirst icon={<IconMoneyCheckPenSolid gradientKey="orange"/>} title="Debt consolidation" description="Streamline your debts" onPress={isWeb ? goToLoans : onPressDebtConsolidation}/>
      <SettingsItemNavigate titleFirst title="Credit cards" onPress={isWeb ? goToCreditCards : onPressCreditCard} description="Find the right card for you" icon={<IconBankCard gradientKey="darkPink"/>}/>
    </SettingsSection>);
};
const styles = StyleSheet.create({
    listSection: {
        marginTop: rem(8),
        marginBottom: rem(24),
    },
});
export default LoanItemsList;
