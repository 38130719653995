import React, { useEffect, useMemo, memo } from 'react';
import Animated, { withTiming, useSharedValue, useAnimatedStyle, } from 'react-native-reanimated';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isWeb } from '../../constants';
const eachCharacterWidth = rem(10);
const horizontalPadding = rem(8 * 2);
const AnimatedMarker = ({ periods, viewWidth, selectedPeriod, isInTab = false, backgroundColor, paddingHorizontal = 0, }) => {
    const styles = useStyles(styleSet);
    const periodIndex = useMemo(() => periods.findIndex((period) => period === selectedPeriod) || 0, [periods, selectedPeriod]);
    const { width } = useAppFrameDimensions(isWeb || isInTab);
    const divWidth = useMemo(() => viewWidth || (width - 2 * paddingHorizontal) / periods.length, [paddingHorizontal, periods.length, viewWidth, width]);
    const left = useSharedValue(periodIndex);
    useEffect(() => {
        left.value = withTiming(periodIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodIndex]);
    const animatedStyles = useAnimatedStyle(() => ({
        left: left.value * divWidth,
    }), [divWidth, left]);
    const markerContainerStyle = useMemo(() => [{ width: divWidth }, animatedStyles, styles.absView], [animatedStyles, divWidth, styles.absView]);
    const markerAnimatedStyles = useAnimatedStyle(() => ({
        width: selectedPeriod.length * eachCharacterWidth + horizontalPadding,
    }), [selectedPeriod.length]);
    const markerStyles = useMemo(() => [
        styles.marker,
        markerAnimatedStyles,
        !!backgroundColor && { backgroundColor },
    ], [backgroundColor, markerAnimatedStyles, styles.marker]);
    return (<Animated.View style={markerContainerStyle}>
      <Animated.View style={markerStyles}/>
    </Animated.View>);
};
export default memo(AnimatedMarker);
const styleSet = createStyleSheets((colors) => ({
    absView: {
        top: 0,
        bottom: 0,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
    },
    marker: {
        height: rem(25),
        borderRadius: rem(8),
        backgroundColor: colors.buttons.brand,
    },
}));
