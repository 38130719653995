import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';
import { createNonMemoizedSelector } from 'store/selectors';
import { Tier } from 'features/premium/entities';
import { selectUserTier } from 'features/premium/selectors/tier';
import { selectIsDefaultSpace } from 'features/spaces/reducers/selectors';
import checkFeatureFlag from 'utils/featureFlags';
import { getActivitiesSectionedFlashlist } from 'utils/formatting/activities';
import { assertUnreachable } from 'utils/types';
import * as debug from '../debug';
import { SavingsPlanRuleType, } from '../types';
export const selectPots = (state) => debug.useFakeData || state.pots.pots;
export const selectUserCreatedPot = createSelector((state) => state.pots.pots, (pots) => {
    if (pots.length) {
        const pot = pots.find((pot) => !pot.type.startsWith('ACCUMULATED_INTEREST'));
        if (pot) {
            return pot;
        }
    }
    return undefined;
});
export const selectUserCreatedPots = createSelector((state) => state.pots.pots, (pots) => {
    if (pots.length) {
        return pots.filter((pot) => !pot.type.startsWith('ACCUMULATED_INTEREST'));
    }
    return [];
});
export const selectPendingTopupsFlag = (state) => Boolean(debug.emulatePendingTopups || state.pots.pendingTopupsFlag);
export const selectCashWithdrawableBalance = (state) => state.pots.cashWithdrawable;
export const selectPotsStatuses = createSelector((state) => state.pots.account?.status, (potsAccountStatus) => ({
    status: potsAccountStatus,
}));
export const selectActivePotInterest = createSelector([
    (_state, potProductId) => potProductId,
    selectUserTier,
    (store) => store.pots.interestPotProductsTierRateMap,
], (potProductId, activeTier, interestPotProductsTierRateMap) => activeTier && potProductId ? interestPotProductsTierRateMap?.[potProductId]?.[activeTier] : undefined);
/**
 * Selects activeRate, tierRateMap and hasRateUpgrade
 *
 * on the basis of pot product
 *
 * or
 *
 * on the basis of highest interest rates
 */
export const selectInterestInfoForProductId = createSelector([
    (_state, potProductId) => potProductId,
    (store) => store.pots.interestPotProductsTierRateMap,
    (store) => store.pots.interestPotsTierRateMap,
    selectUserTier,
], (potProductId, interestPotProductsTierRateMap, maxInterestRatesMap, userTier) => {
    if (potProductId) {
        const tierRateMap = interestPotProductsTierRateMap?.[potProductId];
        const ultimateRate = tierRateMap?.[Tier.ultimate];
        const userRate = tierRateMap?.[userTier];
        return {
            tierRateMap,
            activeRate: userRate,
            hasRateUpgrade: ultimateRate && userRate ? Number(ultimateRate) > Number(userRate) : false,
        };
    }
    const ultimateRate = maxInterestRatesMap?.[Tier.ultimate];
    const userRate = maxInterestRatesMap?.[userTier];
    return {
        tierRateMap: maxInterestRatesMap,
        activeRate: maxInterestRatesMap?.[userTier],
        hasRateUpgrade: ultimateRate && userRate ? Number(ultimateRate) > Number(userRate) : false,
    };
});
export const selectTransferPotsFromPotType = createSelector([
    (_state, type, productId) => ({
        type,
        productId,
    }),
    (state) => state.pots.pots,
], ({ type, productId }, pots) => {
    if (type === 'BASIC' || type === 'INTEREST' || type === 'INTEREST_NOTICE') {
        return pots.filter((pot) => pot.type === type && pot.productId === productId);
    }
    if (type === 'ACCUMULATED_INTEREST') {
        return pots.filter((pot) => (pot.type === 'ACCUMULATED_INTEREST' || pot.type === 'INTEREST') && pot.productId === productId);
    }
    if (type === 'ACCUMULATED_INTEREST_NOTICE') {
        return pots.filter((pot) => (pot.type === 'ACCUMULATED_INTEREST_NOTICE' || pot.type === 'INTEREST_NOTICE') && pot.productId === productId);
    }
    assertUnreachable(type);
    return [];
});
/**
 * Selects length of pots of type BASIC
 */
export const selectHasMoreBasicPots = createSelector((store) => store.pots.pots, (pots) => pots.filter((pot) => pot.type === 'BASIC').length > 1);
/**
 * Selects length of pots of type INTEREST as well as ACCUMULATED_INTEREST
 */
export const selectHasMoreAllInterestPots = createSelector([(_state, productId) => productId, (store) => store.pots.pots], (productId, pots) => pots.filter((pot) => productId === pot.productId && (pot.type === 'INTEREST' || pot.type === 'ACCUMULATED_INTEREST')).length > 1);
/**
 * Selects length of pots of type INTEREST as well as ACCUMULATED_INTEREST
 */
export const selectHasMoreAllNoticeInterestPots = createSelector([(_state, productId) => productId, (store) => store.pots.pots], (productId, pots) => pots.filter((pot) => productId === pot.productId && (pot.type === 'INTEREST_NOTICE' || pot.type === 'ACCUMULATED_INTEREST_NOTICE')).length > 1);
/**
 * Selects length of pots of type INTEREST
 */
export const selectHasMoreInterestPots = createSelector([(_state, productId) => productId, (store) => store.pots.pots], (productId, pots) => pots.filter((pot) => pot.productId === productId && pot.type === 'INTEREST').length > 1);
/**
 * Selects length of pots of type INTEREST_NOTICE
 */
export const selectHasMoreNoticeInterestPots = createSelector([(_state, productId) => productId, (store) => store.pots.pots], (productId, pots) => pots.filter((pot) => pot.productId === productId && pot.type === 'INTEREST_NOTICE').length > 1);
/**
 * Selects the maximum pot rate which is in Tier,ultimate
 */
export const selectMaximumInterest = createSelector((store) => store.pots.interestPotsTierRateMap, (tierRateMap) => tierRateMap?.[Tier.ultimate]);
export const selectUserTierMaxPotInterest = createSelector((store) => store.pots.interestPotsTierRateMap, selectUserTier, (tierRateMap, userTier) => {
    if (userTier && tierRateMap?.[userTier]) {
        return tierRateMap[userTier];
    }
    return '';
});
export const selectHasInterestPots = createSelector((store) => store.pots.pots, (pots) => pots.find((pot) => pot.type === 'INTEREST' ||
    pot.type === 'ACCUMULATED_INTEREST' ||
    pot.type === 'INTEREST_NOTICE' ||
    pot.type === 'ACCUMULATED_INTEREST_NOTICE'));
export const selectHasPots = createSelector((store) => store.pots.pots, (pots) => !!pots.length);
export const selectShouldShowOpenPotsNotificationBanner = createSelector([
    (_state, type) => type,
    (state) => state.deviceSettings.didCloseOpenPotsCTAFromAnalytics,
    (state) => state.deviceSettings.didCloseOpenPotsCTAFromBudgeting,
    (state) => state.deviceSettings.didCloseOpenPotsCTAFromTransactions,
    (state) => state.deviceSettings.didCloseOpenPotsCTAFromPots,
], (type, didCloseOpenPotsCTAFromAnalytics, didCloseOpenPotsCTAFromBudgeting, didCloseOpenPotsCTAFromTransactions, didCloseOpenPotsCTAFromPots) => {
    switch (type) {
        case 'Analytics':
            return didCloseOpenPotsCTAFromAnalytics;
        case 'Budgeting':
            return didCloseOpenPotsCTAFromBudgeting;
        case 'Transactions':
            return didCloseOpenPotsCTAFromTransactions;
        case 'Pots':
            return didCloseOpenPotsCTAFromPots;
        default:
            return false;
    }
});
export const selectHasInterestPotProduct = createSelector((store) => store.pots.products, (products) => {
    const interestProduct = products?.find((product) => product.type === 'INTEREST' || product.type === 'INTEREST_NOTICE');
    return !!interestProduct;
});
export const selectPotsTotalBalance = createSelector(selectIsDefaultSpace, (store) => store.pots.pots, (isDefaultSpace, pots) => {
    if (!isDefaultSpace) {
        return {
            value: 0,
            currency: 'GBP',
        };
    }
    return {
        value: pots.reduce((prev, curr) => prev + curr.balance.value, 0),
        currency: 'GBP',
    };
});
export const selectPotProductFromProductId = createSelector([(_state, productId) => productId, (state) => state.pots.products], (productId, potProducts) => {
    if (productId) {
        return potProducts?.find((product) => product.id === productId);
    }
    return undefined;
});
export const selectIsPotsAccountActive = (state) => state.pots.account?.status === 'ACTIVE';
export const selectIsPotsAccountNotStarted = (state) => state.pots.account?.status === 'NOT_STARTED';
export const selectPotProductWithTheHighestInterest = createSelector([(store) => store.pots.productIdWithTheHighestInterest, (store) => store.pots.products], (productIdWithTheHighestInterest, potProducts) => {
    if (productIdWithTheHighestInterest) {
        return potProducts?.find((product) => product.id === productIdWithTheHighestInterest);
    }
    return undefined;
});
export const selectShouldForceShowPotsOnb = createSelector((store) => store.deviceSettings.hasTriedSettingsUpPots, (store) => store.pots.hasCreatedPot, (state) => state.pots.account?.status, (hasTriedSettingsUpPots, hasCreatedPot, potsAccStatus) => !hasTriedSettingsUpPots && !hasCreatedPot && potsAccStatus !== 'NOT_STARTED');
export const selectLatestUpcomingPayment = createSelector((store) => store.unpersistedPotsReducer.upcomingAutosavePayments, (upcomingAutosavePayments) => {
    if (debug.showFakeUpcomingAutosavePayments) {
        return debug.fakeUpcomingAutosavePayments?.[0];
    }
    if (upcomingAutosavePayments?.[0]) {
        return upcomingAutosavePayments[0];
    }
    return undefined;
}, {
    memoizeOptions: {
        resultEqualityCheck: (a, b) => isEqual(a, b),
    },
});
export const selectLatestAutosaveActivity = createSelector((store) => store.unpersistedPotsReducer.autoSaveActivities, (autoSaveActivities) => {
    if (debug.showFakeAutosaveActivities) {
        return debug.fakeAutosaveActivities?.[0];
    }
    if (autoSaveActivities?.[0]) {
        return autoSaveActivities[0];
    }
    return undefined;
}, {
    memoizeOptions: {
        resultEqualityCheck: (a, b) => isEqual(a, b),
    },
});
export const selectAutosaveActivityToDisplayInAutosave = createNonMemoizedSelector(selectLatestUpcomingPayment, selectLatestAutosaveActivity, (upcomingAutosavePayments, autoSaveActivities) => {
    if (upcomingAutosavePayments) {
        return upcomingAutosavePayments;
    }
    if (autoSaveActivities) {
        return autoSaveActivities;
    }
    return undefined;
});
export const selectAutosaveActivitySections = createSelector(selectLatestUpcomingPayment, (state) => debug.showFakeAutosaveActivities ? debug.fakeAutosaveActivities : state.unpersistedPotsReducer.autoSaveActivities, (latestUpcomingPayment, activities) => {
    const activitySections = activities?.length ? getActivitiesSectionedFlashlist(activities, 'timestamp') : [];
    const upcomingSections = latestUpcomingPayment
        ? [
            {
                id: 'Upcoming',
                sectionTitle: 'Upcoming',
            },
            latestUpcomingPayment,
        ]
        : [];
    return [...upcomingSections, ...activitySections];
});
export const selectAutosaveActivitiesInitialLoading = createSelector((state) => state.unpersistedPotsReducer.fetchingAutosaveActivities, (state) => state.unpersistedPotsReducer.autosaveActivitiesPaginationToken, (requesting, paginationToken) => !!(requesting && paginationToken === undefined));
export const selectPotIdPotInfoMap = createSelector(selectPots, (pots) => pots.reduce((accumulator, currentValue) => ({ ...accumulator, [currentValue.id]: currentValue }), {}));
export const selectShouldShowAutosave = createSelector((store) => checkFeatureFlag(store.utils.featureFlags, 'automated_savings_v2'), (store) => store.pots.savingsPlan, (state) => state.user.user.guessedHomeCountry === 'GB', (hasAutosaveFlag, savingsPlan, isGBUser) => isGBUser && !!(savingsPlan?.mandateStatus || hasAutosaveFlag));
export const isSortCodeSame = (sortCode1, sortCode2) => {
    if (sortCode1 && sortCode2) {
        return sortCode1.replace(/[^a-zA-Z0-9]/g, '') === sortCode2.replace(/[^a-zA-Z0-9]/g, '');
    }
    return false;
};
/**
 * selectShouldShowAutosaveCTAinPotsScreen should return true if user has working autosave and can see autosave
 * This selector basically checks if the user has setup autosave with feature flag
 */
export const selectShouldShowAutosaveCTAinPotsScreen = createSelector(selectShouldShowAutosave, (store) => store.pots.savingsPlan, (shouldShowAutosave, savingsPlan) => {
    if (shouldShowAutosave && savingsPlan?.mandateStatus) {
        return true;
    }
    return false;
});
/**
 * selectShouldShowAutosaveCTAinSuccessScreens should return true if user has not setup autosave and can see autosave
 * This selector basically checks if the user has not setup autosave with feature flag
 */
export const selectShouldShowAutosaveCTAinSuccessScreens = createSelector(selectShouldShowAutosave, (store) => store.pots.savingsPlan, (shouldShowAutosave, savingsPlan) => {
    if (shouldShowAutosave && !savingsPlan?.mandateStatus) {
        return true;
    }
    return false;
});
export const selectAutosaveRulesArr = createSelector((store) => store.pots.savingsPlan, (savingsPlan) => {
    const rules = [];
    const { customAmount, automated, roundUpMultiplier } = savingsPlan || {};
    if (automated) {
        rules.push({
            type: SavingsPlanRuleType.AUTOMATED,
        });
    }
    if (customAmount) {
        rules.push({
            amount: customAmount,
            type: SavingsPlanRuleType.CUSTOM,
        });
    }
    if (roundUpMultiplier) {
        rules.push({
            roundUpMultiplier,
            type: SavingsPlanRuleType.ROUND_UP,
        });
    }
    return rules;
});
export const selectAutosaveFees = createSelector((store) => store.pots.savingsPlanTierFees, selectUserTier, (savingsPlanTierFees, tier) => savingsPlanTierFees?.[tier]);
export const selectForceTransferAndAutosaveInOnboarding = createSelector((store) => store.utils.featureFlags, (featureFlags) => checkFeatureFlag(featureFlags, 'force_transfer_and_autosave_in_save_onboarding_funnel'));
