import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconChat = ({ alt, ...props }) => {
    const id = useId();
    if (alt) {
        return (<IconWithGradient id={id} {...props} defaultGradientKey="brandGradient">
        <Path fill={`url(#gradient_${id})`} d="M12.0002 2.00003C17.5232 2.00003 22.0001 6.47703 22.0001 12C22.0001 17.523 17.5232 22 12.0002 22C10.3824 22.0022 8.78844 21.6102 7.35613 20.8581L3.06613 21.975C2.92279 22.0124 2.77217 22.0116 2.62922 21.9728C2.48627 21.9339 2.35593 21.8585 2.25115 21.7538C2.14635 21.6491 2.07073 21.5188 2.03179 21.3759C1.99285 21.2331 1.99194 21.0824 2.02913 20.9391L3.14513 16.65C2.39093 15.2163 1.9979 13.6201 2.00013 12C2.00013 6.47703 6.47713 2.00003 12.0002 2.00003ZM13.2521 13H8.75012L8.64812 13.0071C8.46862 13.0317 8.30412 13.1206 8.18504 13.2572C8.06596 13.3937 8.00036 13.5688 8.00036 13.75C8.00036 13.9312 8.06596 14.1063 8.18504 14.2429C8.30412 14.3795 8.46862 14.4683 8.64812 14.493L8.75012 14.5H13.2521L13.3532 14.493C13.5326 14.4683 13.6971 14.3795 13.8162 14.2429C13.9353 14.1063 14.0009 13.9312 14.0009 13.75C14.0009 13.5688 13.9353 13.3937 13.8162 13.2572C13.6971 13.1206 13.5326 13.0317 13.3532 13.0071L13.2521 13ZM15.2501 9.50006H8.75012L8.64812 9.507C8.46862 9.53177 8.30412 9.62057 8.18504 9.7572C8.06596 9.89374 8.00036 10.0689 8.00036 10.2501C8.00036 10.4313 8.06596 10.6063 8.18504 10.7429C8.30412 10.8795 8.46862 10.9683 8.64812 10.993L8.75012 11.0001H15.2501L15.3521 10.993C15.5317 10.9683 15.6962 10.8795 15.8152 10.7429C15.9343 10.6063 15.9999 10.4313 15.9999 10.2501C15.9999 10.0689 15.9343 9.89374 15.8152 9.7572C15.6962 9.62057 15.5317 9.53177 15.3521 9.507L15.2501 9.50006Z"/>
      </IconWithGradient>);
    }
    return (<IconWithGradient id={id} {...props} defaultGradientKey="green">
      <Path fillRule="evenodd" clipRule="evenodd" d="M2 12.015C2 6.74712 6.21 2 12.02 2C17.7 2 22 6.65699 22 11.985C22 18.1642 16.96 22 12 22C10.36 22 8.54 21.5593 7.08 20.698C6.57 20.3876 6.14 20.1572 5.59 20.3375L3.57 20.9384C3.06 21.0986 2.6 20.698 2.75 20.1572L3.42 17.9139C3.53 17.6034 3.51 17.2729 3.35 17.0125C2.49 15.4301 2 13.6975 2 12.015ZM10.7 12.015C10.7 12.7261 11.27 13.2969 11.98 13.307C12.69 13.307 13.26 12.7261 13.26 12.025C13.26 11.314 12.69 10.7431 11.98 10.7431C11.28 10.7331 10.7 11.314 10.7 12.015ZM15.31 12.025C15.31 12.7261 15.88 13.307 16.59 13.307C17.3 13.307 17.87 12.7261 17.87 12.025C17.87 11.314 17.3 10.7431 16.59 10.7431C15.88 10.7431 15.31 11.314 15.31 12.025ZM7.37 13.307C6.67 13.307 6.09 12.7261 6.09 12.025C6.09 11.314 6.66 10.7431 7.37 10.7431C8.08 10.7431 8.65 11.314 8.65 12.025C8.65 12.7261 8.08 13.2969 7.37 13.307Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
