import React from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system/values';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isWeb } from '../constants';
export const ButtonGroup = ({ children, type = 'default', margins = true, style, isInIOS13Modal, }) => {
    // 1 transparent button followed by another button requires less padding
    const childArr = React.Children.toArray(children);
    const buttons = React.Children.map(children, (buttonNode, index) => {
        const prev = childArr[index - 1];
        return buttonNode != null && index > 0 ? (<View style={React.isValidElement(prev) &&
                (prev.props.transparent === true ||
                    prev.props.transparentReversed === true)
                ? styles.buttonContainerTransparent
                : styles.buttonContainer}>
        {buttonNode}
      </View>) : (buttonNode);
    });
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb, isInIOS13Modal);
    const bottom = useMarginBottom('bottom', undefined, styles.floating);
    return (<View style={[
            styles.container,
            type === 'floating' && bottom,
            ...(margins && type === 'floating'
                ? [styles.containerFloating, paddingHorizontalStyle]
                : []),
            margins && type === 'default' ? styles.containerDefault : undefined,
            style,
        ]}>
      {buttons}
    </View>);
};
const styles = StyleSheet.create({
    container: {
        paddingTop: rem(8),
    },
    containerDefault: {
        marginLeft: rem(16),
        marginRight: rem(16),
    },
    containerFloating: {
        paddingTop: rem(8),
        left: rem(16),
        right: rem(16),
    },
    buttonContainer: {
        paddingTop: rem(8),
    },
    buttonContainerTransparent: {
        paddingTop: rem(0),
    },
    floating: {
        position: 'absolute',
    },
});
