import React, { memo, useCallback, useMemo } from 'react';
import { Platform, View } from 'react-native';
import { rem } from 'design-system/values';
import Squircle from 'design-system/Squircle';
import { IconChat, IconSearch, IconEdit } from 'design-system/icons';
import TouchableBounce from 'design-system/TouchableScale';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import IconChatFloating from './icons/IconChatFloating';
const FloatingButton = ({ top, onPress, style = {}, forceShadow, ...props }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const renderIcon = useCallback(() => {
        if ('Search' in props) {
            return (<Squircle width={rem(46)} height={rem(46)} color={colors.background.contrast}>
          <IconSearch alt/>
        </Squircle>);
        }
        if ('Chat' in props) {
            return <IconChat alt color={colors.text.textOnBrandLight}/>;
        }
        if ('ChatPrimary' in props) {
            return <IconChatFloating />;
        }
        if ('Edit' in props) {
            return (<Squircle width={rem(46)} height={rem(46)} color={colors.background.brand}>
          <IconEdit color={colors.elements.white}/>
        </Squircle>);
        }
        return null;
    }, [
        colors.background.brand,
        colors.background.contrast,
        colors.elements.white,
        colors.text.textOnBrandLight,
        props,
    ]);
    const outerSquircleStyles = useMemo(() => [styles.outerSquircle, forceShadow && styles.forceShadow], [forceShadow, styles.forceShadow, styles.outerSquircle]);
    const { top: paddingTop } = useSafeAreaInsets();
    return (<View pointerEvents="box-none" style={[
            styles.floatingSearch,
            top
                ? [styles.top, { paddingTop: paddingTop + rem(16) }]
                : styles.bottom,
            style,
        ]}>
      <TouchableBounce onPress={onPress} finalScaleValue={0.95}>
        <Squircle width={rem(56)} height={rem(56)} style={outerSquircleStyles} color={'Chat' in props
            ? colors.buttons.brandLight
            : colors.elements.floatingWhite}>
          {renderIcon()}
        </Squircle>
      </TouchableBounce>
    </View>);
};
// need to take in a bigger area for android shadow
export const floatingButtonArea = rem(88);
const styleSet = createStyleSheets((colors) => ({
    top: {
        top: 0,
    },
    bottom: {
        bottom: 0,
    },
    floatingSearch: {
        right: 0,
        zIndex: 1,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        width: floatingButtonArea,
        height: floatingButtonArea,
    },
    outerSquircle: {
        ...colors.shadows.floatingSquircle,
        ...Platform.select({
            android: {
                width: rem(55),
                height: rem(55),
                borderRadius: rem(20),
            },
        }),
    },
    // This is used when we have white background in night as well as day mode
    forceShadow: {
        ...Platform.select({
            ios: {
                shadowColor: '#6b73a04d',
                shadowOpacity: 1,
                shadowOffset: { width: 0, height: 20 },
                shadowRadius: 20,
            },
            android: {
                elevation: 10,
            },
        }),
    },
}));
export default memo(FloatingButton);
