import React, { useId } from 'react';
import { View } from 'react-native';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
const dimensions = {
    large: {
        radius: rem(252),
        strokeWidthInner: 3,
        strokeWidthOuter: 5,
    },
    medium: {
        radius: rem(156),
        strokeWidthInner: 3.5,
        strokeWidthOuter: 5.5,
    },
    small: {
        radius: rem(140),
        strokeWidthInner: 4,
        strokeWidthOuter: 7,
    },
};
const BudgetingCircleChart = ({ onLight, committedPercent, spendingPercent, large, medium, gradientColors, backgroundColor, }) => {
    const colors = useColors();
    const committedSpendingGradient = colors.gradients.darkPink.colors;
    const spendingGradient = gradientColors ?? colors.gradients.ultimate.colors;
    let sizeKey = 'small';
    if (large) {
        sizeKey = 'large';
    }
    else if (medium) {
        sizeKey = 'medium';
    }
    const size = dimensions[sizeKey];
    const styles = useStyles(styleSet);
    const id = useId();
    return (<View>
      <Svg width={size.radius} height={size.radius} viewBox=" 0 0 42 42">
        <Path stroke={onLight
            ? colors.cards.onLight
            : backgroundColor ?? colors.background.dark} strokeWidth={size.strokeWidthInner} d="M21 5
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" fill="transparent"/>
        {!!committedPercent && committedPercent !== 0 && (<Path stroke={`url(#gradient_a_${id})`} strokeWidth={size.strokeWidthOuter} strokeLinecap="round" strokeDasharray={`${committedPercent}, 100`} d="M21 5
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" fill="transparent"/>)}
        <Defs>
          <LinearGradient id={`gradient_a_${id}`} gradientUnits="objectBoundingBox">
            <Stop stopColor={committedSpendingGradient[0]}/>
            <Stop offset="1" stopColor={committedSpendingGradient[1]}/>
          </LinearGradient>
        </Defs>
      </Svg>
      <Svg width={size.radius} height={size.radius} viewBox=" 0 0 42 42" style={styles.shadow}>
        {spendingPercent !== 0 && (<Path stroke={`url(#gradient_b_${id})`} strokeWidth={size.strokeWidthOuter} strokeLinecap="round" strokeDasharray={`${spendingPercent}, 100`} d="M21 5
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" fill="transparent"/>)}
        <Defs>
          <LinearGradient id={`gradient_b_${id}`} gradientUnits="objectBoundingBox">
            <Stop stopColor={spendingGradient[0]}/>
            <Stop offset="1" stopColor={spendingGradient[1]}/>
          </LinearGradient>
        </Defs>
      </Svg>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    shadow: {
        position: 'absolute',
        ...colors.shadows.budgetingChartShadow,
    },
}));
export default BudgetingCircleChart;
