import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { logQuest } from 'features/quests/actions';
import useBillReminders from 'hooks/useBillReminders';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import { getSubscriptions } from '../actions';
import Links from '../../../utils/links';
import Amplitude from '../../../utils/amplitude';
import { selectOrderedActiveSubscriptions } from '../selectors';
import SubscriptionSection from '../components/SubscriptionSection';
import { isWeb } from '../../../constants';
import useOpenSubscription from '../hooks/useOpenSubscription';
const openInfo = () => {
    const url = 'https://help.emma-app.com/en/category/subscriptions-1xrbcm4/';
    Links.openLink(url);
};
const SubscriptionsScreen = withAnimatedNavigationHeader(({ navigation, onScroll, route }) => {
    const transactionId = route.params?.data?.id;
    const dispatch = useAppDispatch();
    const downloaded = useAppSelector((store) => store.subscriptions.subscriptions.downloaded);
    const isFetching = useAppSelector((store) => store.subscriptions.isFetchingSubscriptions);
    const activeSubscriptions = useAppSelector(selectOrderedActiveSubscriptions);
    const openSearch = useCallback(() => {
        navigation.navigate('SubscriptionsSearch', {
            empty: {
                title: 'Add a recurring payment',
                subtitle: 'Search through your transactions and select a recurring payment.\nIt works like magic!',
            },
        });
    }, []);
    useLayoutEffect(() => {
        navigation.setOptions({
            title: transactionId ? '' : 'Recurring payments',
            headerRight: transactionId
                ? undefined
                : () => (<TouchableOpacity onCard hoverOnDark onPress={openSearch} style={styles.headerRightBtn}>
                  <Text Text-16>Add</Text>
                </TouchableOpacity>),
        });
    }, [openSearch, transactionId]);
    useEffect(() => {
        dispatch(getSubscriptions());
        dispatch(logQuest('SUBSCRIPTIONS_OPENED'));
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { billRemindersOn, setBillRemindersOn } = useBillReminders();
    const onOpenCompare = useCallback((routeWithParams) => {
        navigation.navigate(routeWithParams);
        Amplitude.logEvent('Subscriptions.openCompare', {
            type: routeWithParams.name,
        });
    }, []);
    const openSubscription = useOpenSubscription();
    const fetching = isFetching && !downloaded;
    return (<AppView withFrame={false}>
          <View style={styles.container}>
            <StatusBar barStyle="dark-content" animated/>
            <SubscriptionSection openInfo={openInfo} onScroll={onScroll} fetching={fetching} openSearch={openSearch} data={activeSubscriptions} onOpenCompare={onOpenCompare} billRemindersOn={billRemindersOn} onOpenSubscription={openSubscription} setBillRemindersOn={setBillRemindersOn} transactionId={transactionId}/>
          </View>
        </AppView>);
}, ({ colors }) => ({
    hasCustomTitle: true,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    headerRightBtn: {
        ...(isWeb && {
            marginRight: rem(16),
            paddingVertical: rem(8),
            paddingHorizontal: rem(16),
        }),
    },
});
export default SubscriptionsScreen;
