import React from 'react';
import { Text, View } from 'react-native';
import { rem } from 'design-system';
import { Avatar } from 'design-system/avatar';
import Logo from 'design-system/Logo';
import IconCategoryExcluded from 'features/transactions/icons/categories/IconCategoryExcluded';
import { extractImage } from 'utils/imageHelpers';
import createStyleSheets from 'utils/createStyleSheets';
import useColors from 'hooks/useColors';
import useColorScheme from 'hooks/useColorScheme';
import useStyles from 'hooks/useStyles';
import Squircle from './Squircle';
const fallbackSourceLight = require('./img/bank-icon.png');
const fallbackSourceDark = require('./img/bank-icon-dark.png');
export const CIRCLE_SIZE = 44;
export const CIRCLE_BORDER_SIZE = 1;
const LogoBankAccount = ({ data, onCard, light, showHidden = false, R56, }) => {
    const colors = useColors();
    const colorScheme = useColorScheme();
    const styles = useStyles(styleSet);
    if ('emoji' in data && data.emoji) {
        return (<View style={styles.fallbackContainerWrapper}>
        <View style={[
                styles.container,
                onCard && styles.onCard,
                light && styles.light,
            ]}>
          <Text style={styles.emoji}>{data.emoji}</Text>
        </View>
      </View>);
    }
    if (showHidden && 'isHidden' in data && data.isHidden) {
        return (<Squircle color={onCard ? colors.background.dark : colors.background.light} width={rem(CIRCLE_SIZE)} height={rem(CIRCLE_SIZE)}>
        <IconCategoryExcluded width={rem(24)} height={rem(24)} fill={colors.text.secondary} viewBox="0 0 24 24"/>
      </Squircle>);
    }
    if (data.isManual && !data.customIconUrl) {
        return (<Avatar name={data.name} color={colors.background.brandLight} size={rem(CIRCLE_SIZE)} borderWidth={CIRCLE_BORDER_SIZE} fontColor={colors.text.textOnBrand} style={styles.avatar} medium/>);
    }
    const source = extractImage(data, showHidden);
    const uri = source && typeof source === 'object' ? source.uri : null;
    const fallbackSource = colorScheme === 'dark' ? fallbackSourceDark : fallbackSourceLight;
    const size = R56 ? { R56: true } : { R44: true };
    return (<Logo {...size} uri={uri} fallbackSource={fallbackSource} transparent/>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        width: rem(CIRCLE_SIZE),
        height: rem(CIRCLE_SIZE),
        borderRadius: rem(CIRCLE_SIZE / 2),
        backgroundColor: colors.cards.onLight,
        justifyContent: 'center',
        alignItems: 'center',
    },
    emojiRoundedContainer: {
        borderWidth: CIRCLE_BORDER_SIZE,
        borderColor: colors.borders.logoStroke,
        margin: CIRCLE_BORDER_SIZE,
    },
    onCard: {
        backgroundColor: colors.background.dark,
    },
    light: {
        backgroundColor: colors.background.light,
    },
    emoji: {
        color: 'black',
        fontSize: rem(25),
        fontFamily: 'System',
        textAlign: 'center',
    },
    fallbackContainerWrapper: {
        width: rem(CIRCLE_SIZE + CIRCLE_BORDER_SIZE * 2),
        height: rem(CIRCLE_SIZE + CIRCLE_BORDER_SIZE * 2),
        borderRadius: rem(CIRCLE_SIZE / 2 + CIRCLE_BORDER_SIZE),
        borderWidth: CIRCLE_BORDER_SIZE,
        borderColor: colors.borders.logoStroke,
    },
    avatar: {
        borderColor: colors.borders.iconStroke,
    },
}));
export default LogoBankAccount;
