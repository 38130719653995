import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { setDeviceSettingsKey } from 'actions/deviceSettings';
import NotificationBanner from 'design-system/NotificationBanner';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useSmartRulesNavigation } from 'hooks/useSmartRulesNavigation';
import { selectActiveSmartRulesByAccountId } from 'features/smart-rules/selectors';
import Section from 'design-system/Section';
import { CardView, rem, SettingsItemNavigate, TextButtonLink, } from 'design-system';
import { IconSmartRule } from 'design-system/icons';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { selectUserId } from 'reducers/selectors';
const SmartRuleSection = ({ account }) => {
    const { onPressSmartRules } = useSmartRulesNavigation();
    const dispatch = useAppDispatch();
    const navigation = useMainStackNavigation();
    const userId = useAppSelector(selectUserId);
    const didCloseSmartRulesCard = useAppSelector((store) => store.deviceSettings.didCloseSmartRulesCard);
    const smartRules = useAppSelector((store) => selectActiveSmartRulesByAccountId(store, account.id));
    const onCancel = useCallback(() => {
        dispatch(setDeviceSettingsKey('didCloseSmartRulesCard', true));
    }, []);
    if (smartRules.length) {
        return (<Section title="Smart rules" button={<TextButtonLink text="Manage" onPress={() => onPressSmartRules(false)}/>} style={styles.section}>
        <CardView noMarginTop noMarginBottom>
          {smartRules.map(({ id, ruleName }) => (<SettingsItemNavigate key={id} title={ruleName} icon={<IconSmartRule />} onPress={() => navigation.navigate('SmartRuleDetails', { id })}/>))}
        </CardView>
      </Section>);
    }
    const isCurrentUsersAccount = account.userId === userId;
    if (didCloseSmartRulesCard || !isCurrentUsersAccount)
        return null;
    return (<NotificationBanner noMarginBottom noMarginTop={false} title="Set a Smart Rule" cancelButtonTitle="Dismiss" positiveButtonTitle="    Create    " onPositiveBtnPress={() => onPressSmartRules(true)} onCancel={onCancel} description="Whenever you transfer money from a linked account to an offline one, Emma can update the balance for you"/>);
};
const styles = StyleSheet.create({
    section: {
        marginTop: rem(24),
        marginBottom: 0,
    },
});
export default SmartRuleSection;
