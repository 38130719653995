import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
const IconMerchant = ({ width = 24, height = 24, ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} width={width} height={height} defaultGradientKey="apricot" {...props}>
      <Path fill={`url(#gradient_${id})`} fillRule="evenodd" d="M17.5 3a2 2 0 0 1 1.6.8l2.688 3.584a1 1 0 0 1 .204.616H22v1a4 4 0 0 1-1 2.646V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7.354A4 4 0 0 1 2 9V8h.008a1 1 0 0 1 .204-.616L4.9 3.8A2 2 0 0 1 6.5 3h11ZM20 9h-4a2 2 0 0 0 3.995.15L20 9Zm-6 0h-4a2 2 0 0 0 3.995.15L14 9ZM8 9H4a2 2 0 0 0 3.995.15L8 9Z" clipRule="evenodd"/>
    </IconWithGradient>);
};
export default IconMerchant;
