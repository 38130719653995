import React from 'react';
import { View } from 'react-native';
import useColors from 'hooks/useColors';
import useIsTablet from 'hooks/useIsTablet';
import Button from './Button';
import Spacer from './Spacer';
import Text from './Text';
import ModalHandle from './ModalHandle';
import { isWeb } from '../constants';
const ModalConfirmationContent = ({ title, subtitle, options, onPress, onPressCancel, containerStyle, }) => {
    const colors = useColors();
    const isTablet = useIsTablet();
    return (<View style={[
            containerStyle,
            {
                backgroundColor: options?.lightBg
                    ? colors.cards.onLight
                    : colors.cards.onDark,
            },
        ]}>
      {!options?.hideHandle && !options?.fullscreen && <ModalHandle />}
      <Text Text-20 centered>
        {title}
      </Text>
      <Spacer h={16}/>
      <Text TextThin-14 Gray centered>
        {subtitle}
      </Text>
      <Spacer h={32}/>
      <Button {...(options.brandReversedConfirm ||
        options.cancelButtonTitle !== undefined
        ? { brandReversed: true }
        : { brand: true })} title={options.confirmButtonTitle ?? 'OK'} onPress={onPress}/>
      {options.cancelButtonTitle !== undefined ? (<>
          <Spacer h={8}/>
          <Button brand onPress={onPressCancel} title={options.cancelButtonTitle}/>
        </>) : null}
      {!isTablet && !isWeb && <Spacer h={16}/>}
    </View>);
};
export default ModalConfirmationContent;
