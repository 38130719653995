import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, LayoutAnimation } from 'react-native';
import { DraggableScrollContainer, DraggableList, } from 'utils/packages/DraggableFlatList';
import { AppView } from 'design-system/AppView';
import StatusBar from 'design-system/StatusBar';
import { SettingsSection } from 'design-system/SettingsSection';
import { SettingsItemSwitch } from 'design-system/SettingsItem';
import Text from 'design-system/Text';
import { CardView } from 'design-system/CardView';
import { rem, SCREEN_BOTTOM_PADDING } from 'design-system/values';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import { IconBoldSubtract } from 'design-system/icons/IconBoldSubtract';
import { IconBoldAdd } from 'design-system/icons/IconBoldAdd';
import { triggerReleaseHapticFeedback } from 'utils/hapticFeedback';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { selectAllCurrentAndCreditCardAccounts } from 'reducers/selectors';
import useColors from 'hooks/useColors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { useAppSelector } from 'store/hooks';
import useAccountCallbacks from 'hooks/useAccountCallbacks';
import RowAccount from 'features/accounts/components/RowAccount';
import { useNotificationsPreferences } from '../hooks/useNotificationsPreferences';
import { isWeb } from '../../../../constants';
const keyExtractor = (item) => item.item.id.toString();
const KEY = 'alerts.daily_balance.push';
const strings = {
    byAccount: {
        title: 'Daily balances',
        description: 'Receive your selected balances in a daily notification',
        activeDescription: 'You’ll receive balance notifications for the accounts below. Drag to rearrange the order',
        activeDescriptionWeb: 'You’ll receive balance notifications for the accounts below',
        inActiveDescription: 'Balance notifications won’t be sent for the accounts below',
    },
    buttonTitle: 'Save',
};
const createInitialAccountsState = (accounts) => {
    const enabledAccounts = accounts.map((account) => ({
        item: account,
        enabled: true,
    }));
    const accountIds = accounts.map((account) => account.id);
    return {
        enabledAccounts,
        accountIds,
    };
};
const DailyBalanceNotifications = withAnimatedNavigationHeader(({ scrollHandler }) => {
    const colors = useColors();
    const allCurrentAndCreditCardAccounts = useAppSelector(selectAllCurrentAndCreditCardAccounts);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', SCREEN_BOTTOM_PADDING, paddingHorizontalStyle);
    const { updatePreferences, updateBalancePreference, balancePreference, pushNormalizedNotificationPreferences: notificationPreferences, } = useNotificationsPreferences();
    const { onOpenLogins } = useAccountCallbacks(false);
    const [byAccountEnabled, setByAccountEnabled] = useState(balancePreference.value);
    const [enabledAccounts, setEnabledAccounts] = useState([]);
    const [disabledAccounts, setDisabledAccounts] = useState([]);
    useEffect(() => {
        const { value, info } = balancePreference;
        // Initialize byAccountEnabled from API value
        setByAccountEnabled(!!value);
        if (value) {
            if (!info) {
                // If notifications are on but no account configuration exists
                const { enabledAccounts, accountIds } = createInitialAccountsState(allCurrentAndCreditCardAccounts.array);
                updateBalancePreference(accountIds, []);
                setEnabledAccounts(enabledAccounts);
                setDisabledAccounts([]);
            }
            else {
                // Handle existing configuration
                const { enabledAccountIds, disabledAccountIds } = info;
                const enabledAccounts = enabledAccountIds.reduce((acc, id) => {
                    const account = allCurrentAndCreditCardAccounts.array.find((account) => account.id === id);
                    if (account) {
                        return [
                            ...acc,
                            {
                                item: account,
                                enabled: true,
                            },
                        ];
                    }
                    return acc;
                }, []);
                const disabledAccounts = disabledAccountIds.reduce((acc, id) => {
                    const account = allCurrentAndCreditCardAccounts.array.find((account) => account.id === id);
                    if (account) {
                        return [
                            ...acc,
                            {
                                item: account,
                                enabled: false,
                            },
                        ];
                    }
                    return acc;
                }, []);
                setEnabledAccounts(enabledAccounts);
                setDisabledAccounts(disabledAccounts);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleToggleChange = useCallback((newValue) => {
        setByAccountEnabled(newValue);
        updatePreferences({ ...notificationPreferences, [KEY]: newValue });
        if (newValue) {
            if (!balancePreference.info) {
                // If no configuration exists, initialize with all accounts enabled
                const { enabledAccounts } = createInitialAccountsState(allCurrentAndCreditCardAccounts.array);
                setEnabledAccounts(enabledAccounts);
                setDisabledAccounts([]);
                updateBalancePreference(enabledAccounts.map((account) => account.item.id), []);
            }
            else {
                // Use existing configuration from API
                const { enabledAccountIds, disabledAccountIds } = balancePreference.info;
                const enabledAccounts = allCurrentAndCreditCardAccounts.array
                    .filter((account) => enabledAccountIds.includes(account.id))
                    .map((account) => ({
                    item: account,
                    enabled: true,
                }));
                const disabledAccounts = allCurrentAndCreditCardAccounts.array
                    .filter((account) => disabledAccountIds.includes(account.id))
                    .map((account) => ({
                    item: account,
                    enabled: false,
                }));
                setEnabledAccounts(enabledAccounts);
                setDisabledAccounts(disabledAccounts);
                updateBalancePreference(enabledAccounts.map((account) => account.item.id), disabledAccounts.map((account) => account.item.id));
            }
        }
    }, [
        allCurrentAndCreditCardAccounts.array,
        balancePreference.info,
        notificationPreferences,
        updateBalancePreference,
        updatePreferences,
    ]);
    const onRelease = useCallback(() => {
        triggerReleaseHapticFeedback();
    }, []);
    const onEnabledDragEnd = useCallback((data) => {
        updateBalancePreference(data.data.map((account) => account.item.id), disabledAccounts.map((account) => account.item.id));
        setEnabledAccounts(data.data);
    }, [disabledAccounts, updateBalancePreference]);
    const onDisabledDragEnd = useCallback((data) => {
        updateBalancePreference(enabledAccounts.map((account) => account.item.id), data.data.map((account) => account.item.id));
        setDisabledAccounts(data.data);
    }, [enabledAccounts, updateBalancePreference]);
    const handleAccountToggle = useCallback((item) => {
        if (item.enabled) {
            // Prevent disabling if this is the last enabled account
            if (enabledAccounts.length <= 1) {
                return;
            }
            const updatedEnabledAccounts = enabledAccounts.filter((account) => account.item.id !== item.item.id);
            const updatedItem = { ...item, enabled: false };
            const updatedDisabledAccounts = [...disabledAccounts, updatedItem];
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            updateBalancePreference(updatedEnabledAccounts.map((account) => account.item.id), updatedDisabledAccounts.map((account) => account.item.id));
            setEnabledAccounts(updatedEnabledAccounts);
            setDisabledAccounts(updatedDisabledAccounts);
        }
        else {
            const updatedDisabledAccounts = disabledAccounts.filter((account) => account.item.id !== item.item.id);
            const updatedItem = { ...item, enabled: true };
            const updatedEnabledAccounts = [...enabledAccounts, updatedItem];
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            updateBalancePreference(updatedEnabledAccounts.map((account) => account.item.id), updatedDisabledAccounts.map((account) => account.item.id));
            setEnabledAccounts(updatedEnabledAccounts);
            setDisabledAccounts(updatedDisabledAccounts);
        }
    }, [enabledAccounts, disabledAccounts, updateBalancePreference]);
    const renderItem = useCallback(({ item, drag }) => {
        let icon = null;
        if (!item.enabled) {
            icon = <IconBoldAdd color={colors.elements.positive}/>;
        }
        else if (enabledAccounts.length > 1) {
            icon = <IconBoldSubtract color={colors.elements.negative}/>;
        }
        return (<TouchableHighlight hoverOnLight underlayColor={colors.background.underlay} style={styles.accountRowContainer} onPress={() => handleAccountToggle(item)} onLongPress={drag}>
              <>
                {!!icon && <View style={styles.subtractButton}>{icon}</View>}
                <View style={styles.accountRowWrapper}>
                  <RowAccount data={item.item} onOpenLogins={onOpenLogins} disabledOpacity style={styles.rowAccount}/>
                </View>
              </>
            </TouchableHighlight>);
    }, [
        colors.background.underlay,
        colors.elements.negative,
        colors.elements.positive,
        enabledAccounts.length,
        handleAccountToggle,
        onOpenLogins,
    ]);
    return (<AppView withFrame={false}>
          <StatusBar barStyle="dark-content" animated/>
          <DraggableScrollContainer scrollEventThrottle={16} 
    // @ts-ignore
    onScrollOffsetChange={scrollHandler} contentContainerStyle={paddingBottom}>
            <SettingsSection>
              <SettingsItemSwitch title={strings.byAccount.title} description={strings.byAccount.description} value={byAccountEnabled} onValueChange={handleToggleChange}/>
            </SettingsSection>

            {byAccountEnabled && (<>
                <View style={styles.activeDescriptionContainer}>
                  <Text TextThin-16 Secondary>
                    {isWeb
                ? strings.byAccount.activeDescriptionWeb
                : strings.byAccount.activeDescription}
                  </Text>
                </View>

                {!!enabledAccounts.length && (<CardView>
                    <DraggableList data={enabledAccounts} onDragEnd={onEnabledDragEnd} scrollEnabled={false} onRelease={onRelease} renderItem={renderItem} keyExtractor={keyExtractor}/>
                  </CardView>)}

                {!!disabledAccounts.length && (<>
                    <View style={styles.inactiveDescriptionContainer}>
                      <Text TextThin-16 Secondary>
                        {strings.byAccount.inActiveDescription}
                      </Text>
                    </View>
                    <CardView>
                      <DraggableList data={disabledAccounts} onDragEnd={onDisabledDragEnd} scrollEnabled={false} onRelease={onRelease} renderItem={renderItem} keyExtractor={keyExtractor}/>
                    </CardView>
                  </>)}
              </>)}
          </DraggableScrollContainer>
        </AppView>);
}, ({ colors }) => ({
    title: 'Daily balances',
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
export default DailyBalanceNotifications;
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    rowContent: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: rem(16),
    },
    accountRowContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: rem(16),
        ...(isWeb && {
            margin: rem(8),
            borderRadius: rem(8),
            paddingHorizontal: rem(8),
        }),
    },
    subtractButton: {
        marginRight: rem(16),
        alignItems: 'center',
        justifyContent: 'center',
    },
    accountRowWrapper: {
        flex: 1,
    },
    activeDescriptionContainer: {
        marginHorizontal: rem(16),
        marginTop: rem(16),
    },
    inactiveDescriptionContainer: {
        marginHorizontal: rem(16),
    },
    rowAccount: {
        ...(isWeb && {
            paddingRight: 0,
        }),
    },
});
