import React, { useMemo } from 'react';
import { StyleSheet, Text as RNText, } from 'react-native';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assertUnreachable(_x) {
    // we should catch this on typecheck stage
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assertDefault(_x) {
    // we should catch this on typecheck stage
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assertLeft(_x) {
    // we should catch this on typecheck stage
}
/**
 * WARNING: use `Text-18` it only for navigation titles!
 */
const Text = ({ children, ...props }) => {
    const colorStyles = useStyles(colorStyleSet);
    const style = useMemo(() => {
        const result = [];
        const fontSizeVariant = props;
        if ('Text-48' in fontSizeVariant) {
            result.push(styles.Text_48);
        }
        else if ('Text-32' in fontSizeVariant) {
            result.push(styles.Text_32);
        }
        else if ('Text-26' in fontSizeVariant) {
            result.push(styles.Text_26);
        }
        else if ('Text-24' in fontSizeVariant) {
            result.push(styles.Text_24);
        }
        else if ('Text-20' in fontSizeVariant) {
            result.push(styles.Text_20);
        }
        else if ('Text-18' in fontSizeVariant) {
            result.push(styles.Text_18);
        }
        else if ('Text-16' in fontSizeVariant) {
            result.push(styles.Text_16);
        }
        else if ('Text-14' in fontSizeVariant) {
            result.push(styles.Text_14);
        }
        else if ('Text-12' in fontSizeVariant) {
            result.push(styles.Text_12);
        }
        else if ('Text-10' in fontSizeVariant) {
            result.push(styles.Text_10);
        }
        else if ('TextThin-32' in fontSizeVariant) {
            result.push(styles.TextThin_32);
        }
        else if ('TextThin-24' in fontSizeVariant) {
            result.push(styles.TextThin_24);
        }
        else if ('TextThin-20' in fontSizeVariant) {
            result.push(styles.TextThin_20);
        }
        else if ('TextThin-16' in fontSizeVariant) {
            result.push(styles.TextThin_16);
        }
        else if ('TextThin-14' in fontSizeVariant) {
            result.push(styles.TextThin_14);
        }
        else if ('TextThin-12' in fontSizeVariant) {
            result.push(styles.TextThin_12);
        }
        else if ('Numbers-72' in fontSizeVariant) {
            result.push(styles.Numbers_72);
        }
        else if ('Numbers-100' in fontSizeVariant) {
            result.push(styles.Numbers_100);
        }
        else if ('Numbers-56' in fontSizeVariant) {
            result.push(styles.Numbers_56);
        }
        else if ('Numbers-64' in fontSizeVariant) {
            result.push(styles.Numbers_64);
        }
        else if ('Numbers-48' in fontSizeVariant) {
            result.push(styles.Numbers_48);
        }
        else if ('Numbers-36' in fontSizeVariant) {
            result.push(styles.Numbers_36);
        }
        else if ('Numbers-32' in fontSizeVariant) {
            result.push(styles.Numbers_32);
        }
        else if ('Numbers-26' in fontSizeVariant) {
            result.push(styles.Numbers_26);
        }
        else if ('Numbers-24' in fontSizeVariant) {
            result.push(styles.Numbers_24);
        }
        else if ('Numbers-20' in fontSizeVariant) {
            result.push(styles.Numbers_20);
        }
        else if ('Numbers-16' in fontSizeVariant) {
            result.push(styles.Numbers_16);
        }
        else if ('Numbers-14' in fontSizeVariant) {
            result.push(styles.Numbers_14);
        }
        else if ('Numbers-12' in fontSizeVariant) {
            result.push(styles.Numbers_12);
        }
        else if ('Numbers-10' in fontSizeVariant) {
            result.push(styles.Numbers_10);
        }
        else if ('NumbersThin-20' in fontSizeVariant) {
            result.push(styles.NumbersThin_20);
        }
        else if ('NumbersThin-16' in fontSizeVariant) {
            result.push(styles.NumbersThin_16);
        }
        else if ('NumbersThin-14' in fontSizeVariant) {
            result.push(styles.NumbersThin_14);
        }
        else if ('NumbersThin-12' in fontSizeVariant) {
            result.push(styles.NumbersThin_12);
        }
        else if ('NumbersThin-10' in fontSizeVariant) {
            result.push(styles.NumbersThin_10);
        }
        else {
            assertUnreachable(fontSizeVariant);
        }
        const color = props;
        if ('Primary' in color) {
            result.push(colorStyles.Primary);
        }
        else if ('Secondary' in color) {
            result.push(colorStyles.Secondary);
        }
        else if ('Gray' in color) {
            result.push(colorStyles.Gray);
        }
        else if ('Gray-50' in color) {
            result.push(colorStyles.Gray_50);
        }
        else if ('Gray-70' in color) {
            result.push(colorStyles.Gray_70);
        }
        else if ('White' in color) {
            result.push(colorStyles.White);
        }
        else if ('White-80' in color) {
            result.push(colorStyles.White_80);
        }
        else if ('White-20' in color) {
            result.push(colorStyles.White_20);
        }
        else if ('White-10' in color) {
            result.push(colorStyles.White_10);
        }
        else if ('Red' in color) {
            result.push(colorStyles.Red);
        }
        else if ('Green' in color) {
            result.push(colorStyles.Green);
        }
        else if ('Positive' in color) {
            result.push(colorStyles.Positive);
        }
        else if ('Blue' in color) {
            result.push(colorStyles.Blue);
        }
        else if ('Debt' in color) {
            result.push(colorStyles.Debt);
        }
        else if ('OnBrand' in color) {
            result.push(colorStyles.OnBrand);
        }
        else if ('OnPrimary' in color) {
            result.push(colorStyles.OnPrimary);
        }
        else if ('color' in color) {
            result.push({ color: color.color });
        }
        else {
            assertDefault(color);
            result.push(colorStyles.DefaultColor);
        }
        if (props.flex) {
            result.push(styles.flex);
        }
        const align = props;
        if ('centered' in align) {
            if (align.centered) {
                result.push(styles.centered);
            }
        }
        else if ('right' in align) {
            if (align.right) {
                result.push(styles.right);
            }
        }
        else {
            assertLeft(align);
            // text is always aligned left by default, no need to specify any style prop
        }
        if (props.underline) {
            result.push(styles.underline);
        }
        return result;
        // @ts-ignore have no idea why typescript fails on this line
    }, [colorStyles, props]);
    const { style: extraStyle, ...otherTextProps } = {
        ...props,
        flex: undefined,
        right: undefined,
        left: undefined,
        centered: undefined,
        underline: undefined,
    };
    return (<RNText style={[extraStyle, style]} {...otherTextProps}>
      {children}
    </RNText>);
};
// any font above 16 is considered as large header
const largeHeaderLineHeightCoefficient = 1.2;
export const lineHeight = {
    Numbers_14: 21,
    Numbers_20: 30,
    Text_12: 18,
    Text_14: 21,
    Text_16: 24,
    Text_18: 18 * largeHeaderLineHeightCoefficient,
    Text_20: 20 * largeHeaderLineHeightCoefficient,
    Text_24: 24 * largeHeaderLineHeightCoefficient,
    Text_26: 26 * largeHeaderLineHeightCoefficient,
    Text_32: 32 * largeHeaderLineHeightCoefficient,
    Text_48: 48 * largeHeaderLineHeightCoefficient,
    TextThin_14: 21,
    TextThin_16: 24,
    TextThin_20: 20 * largeHeaderLineHeightCoefficient,
};
export const styles = StyleSheet.create({
    /* font styles */
    Text_48: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(48),
        lineHeight: rem(lineHeight.Text_48),
    },
    Text_32: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(32),
        lineHeight: rem(lineHeight.Text_32),
    },
    Text_26: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(26),
        lineHeight: rem(lineHeight.Text_26),
    },
    Text_24: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(24),
        lineHeight: rem(lineHeight.Text_24),
    },
    Text_20: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(20),
        lineHeight: rem(lineHeight.Text_20),
    },
    Text_18: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(18),
        lineHeight: rem(lineHeight.Text_18),
    },
    Text_16: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(16),
        lineHeight: rem(lineHeight.Text_16),
    },
    Text_14: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(14),
        lineHeight: rem(lineHeight.Text_14),
    },
    Text_12: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(12),
        lineHeight: rem(lineHeight.Text_12),
    },
    Text_10: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(10),
        lineHeight: rem(15),
    },
    TextThin_32: {
        fontFamily: 'Montserrat-Light',
        fontSize: rem(32),
        lineHeight: rem(lineHeight.Text_32),
    },
    TextThin_24: {
        fontFamily: 'Montserrat-Light',
        fontSize: rem(24),
        lineHeight: rem(lineHeight.Text_24),
    },
    TextThin_20: {
        fontFamily: 'Montserrat-Light',
        fontSize: rem(20),
        lineHeight: rem(lineHeight.TextThin_20),
    },
    TextThin_16: {
        fontFamily: 'Montserrat-Light',
        fontSize: rem(16),
        lineHeight: rem(lineHeight.TextThin_16),
    },
    TextThin_14: {
        fontFamily: 'Montserrat-Light',
        fontSize: rem(14),
        lineHeight: rem(lineHeight.TextThin_14),
    },
    TextThin_12: {
        fontFamily: 'Montserrat-Light',
        fontSize: rem(12),
        lineHeight: rem(18),
    },
    Numbers_72: {
        fontFamily: 'Nunito-ExtraBold',
        fontSize: rem(72),
        lineHeight: rem(108),
    },
    Numbers_100: {
        fontFamily: 'Nunito-ExtraBold',
        fontSize: rem(100),
        lineHeight: rem(130),
    },
    Numbers_64: {
        fontFamily: 'Nunito-ExtraBold',
        fontSize: rem(64),
        lineHeight: rem(96),
    },
    Numbers_56: {
        fontFamily: 'Nunito-ExtraBold',
        fontSize: rem(56),
        lineHeight: rem(64),
    },
    Numbers_48: {
        fontFamily: 'Nunito-ExtraBold',
        fontSize: rem(48),
        lineHeight: rem(72),
    },
    Numbers_36: {
        fontFamily: 'Nunito-ExtraBold',
        fontSize: rem(36),
        lineHeight: rem(54),
    },
    Numbers_32: {
        fontFamily: 'Nunito-ExtraBold',
        fontSize: rem(32),
        lineHeight: rem(48),
    },
    Numbers_26: {
        fontFamily: 'Nunito-ExtraBold',
        fontSize: rem(26),
        lineHeight: rem(39),
    },
    Numbers_24: {
        fontFamily: 'Nunito-ExtraBold',
        fontSize: rem(24),
        lineHeight: rem(32),
    },
    Numbers_20: {
        fontFamily: 'Nunito-Bold',
        fontSize: rem(20),
        lineHeight: rem(lineHeight.Numbers_20),
    },
    // This is exported
    // eslint-disable-next-line react-native/no-unused-styles
    Numbers_18: {
        fontFamily: 'Nunito-Bold',
        fontSize: rem(18),
        lineHeight: rem(24),
    },
    Numbers_16: {
        fontFamily: 'Nunito-Bold',
        fontSize: rem(16),
        lineHeight: rem(24),
    },
    Numbers_14: {
        fontFamily: 'Nunito-Bold',
        fontSize: rem(14),
        lineHeight: rem(lineHeight.Numbers_14),
    },
    Numbers_12: {
        fontFamily: 'Nunito-Bold',
        fontSize: rem(12),
        lineHeight: rem(18),
    },
    Numbers_10: {
        fontFamily: 'Nunito-Bold',
        fontSize: rem(10),
        lineHeight: rem(15),
    },
    NumbersThin_20: {
        fontFamily: 'Nunito-Regular',
        fontSize: rem(20),
        lineHeight: rem(30),
    },
    NumbersThin_16: {
        fontFamily: 'Nunito-Regular',
        fontSize: rem(16),
        lineHeight: rem(24),
    },
    NumbersThin_14: {
        fontFamily: 'Nunito-Regular',
        fontSize: rem(14),
        lineHeight: rem(21),
    },
    NumbersThin_12: {
        fontFamily: 'Nunito-Regular',
        fontSize: rem(12),
        lineHeight: rem(18),
    },
    NumbersThin_10: {
        fontFamily: 'Nunito-Regular',
        fontSize: rem(10),
        lineHeight: rem(15),
    },
    /* layout and other styles */
    flex: {
        flex: 1,
        flexGrow: 1,
    },
    centered: { textAlign: 'center' },
    right: { textAlign: 'right' },
    underline: { textDecorationLine: 'underline' },
});
const colorStyleSet = createStyleSheets((colors) => ({
    DefaultColor: { color: colors.text.primary },
    Primary: { color: colors.text.brand },
    OnBrand: { color: colors.text.textOnBrand },
    OnPrimary: { color: colors.text.onPrimary },
    Secondary: { color: colors.text.secondary },
    Gray: { color: colors.text.secondary },
    Gray_50: { color: colors.text.tertiary },
    Gray_70: { color: colors.text.tertiary },
    White: { color: colors.text.white },
    White_80: { color: colors.text.textOnBrand },
    White_20: { color: colors.text.textOnBrand },
    White_10: { color: colors.text.textOnBrand },
    Red: { color: colors.text.negative },
    Green: { color: colors.text.positive },
    Positive: { color: colors.status.positive },
    Blue: { color: colors.text.income },
    Debt: { color: colors.text.negative },
}));
export default Text;
