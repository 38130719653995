import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { subMonths, parseISO, isBefore } from 'date-fns';
import { View } from 'react-native';
import Animated, { Easing, withDelay, withTiming, useAnimatedStyle, useAnimatedScrollHandler, } from 'react-native-reanimated';
import { FlashList } from '@shopify/flash-list';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import { useCategories, isDefaultCategory, useCustomCategoryColorsMap, } from 'hooks/useCategory';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import useColorScheme from 'hooks/useColorScheme';
import { setAnalyticsPosition } from 'actions/expenses';
import { useMarginBottom } from 'hooks/useMarginBottom';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useEstimatedListSize from 'hooks/useEstimatedListSize';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { fetchCommittedForCurrentPaydayRange } from 'features/subscriptions/actions';
import { useOpenCategory } from '../hooks/useOpenCategory';
import useSelectedCategory from '../hooks/useSelectedCategory';
import { selectChartType, selectMonthlyTotals, selectAnalyticsPosition, selecAnalyticstMerchantSections, selectAnalyticsCategorySections, selectAnalyticsCategoryCurrentLoadingState, selectAnalyticsMerchantCurrentLoadingState, selectMonthlyTotalsFilteredWithFallbackMonths, } from '../selectors';
import { ChartType } from '../types';
import AnalyticsSectionAddCategory, { ADD_CATEGORY_ROW_HEIGHT, } from './list/AnalyticsSectionAddCategory';
import AnalyticsSectionHeader from './list/AnalyticsSectionHeader';
import AnalyticsSectionListEmpty from './list/AnalyticsSectionListEmpty';
import AnalyticsSectionFooter from './list/AnalyticsSectionListFooter';
import AnalyticsSectionListHeader from './list/AnalyticsSectionListHeader';
import RowCategory from './row/RowCategory';
import RowMerchant from './row/RowMerchant';
import { isWeb } from '../../../constants';
const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);
function withAnimation(toValue) {
    'worklet';
    return withTiming(toValue, {
        duration: 300,
        easing: Easing.inOut(Easing.cubic),
    });
}
const getItemType = (item) => item.type;
const keyExtractor = (item) => {
    switch (item.type) {
        case 'footer':
        case 'add-category':
            return item.type;
        case 'header':
            return item.data.title;
        default:
            return item.data.id.toString();
    }
};
const MARKER_HEIGHT = rem(77);
// This used to be called 'Categories' in the old app
const AnalyticsList = ({ scrollY }) => {
    const unfilteredMonthlyTotals = useAppSelector(selectMonthlyTotals);
    const filteredMonthlyTotals = useAppSelector(selectMonthlyTotalsFilteredWithFallbackMonths);
    const monthlyTotals = filteredMonthlyTotals?.results || unfilteredMonthlyTotals;
    const position = useAppSelector(selectAnalyticsPosition);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchCommittedForCurrentPaydayRange());
    }, []);
    const openCategory = useOpenCategory(monthlyTotals, position, 'Category', filteredMonthlyTotals?.step, filteredMonthlyTotals?.accountIds);
    useEffect(() => () => {
        dispatch(setAnalyticsPosition(0, 0, true));
    }, []);
    const scrollHandler = useAnimatedScrollHandler((handle) => {
        // eslint-disable-next-line no-param-reassign
        scrollY.value = handle.contentOffset.y;
    }, []);
    const chartTypeToShow = useAppSelector(selectChartType);
    const withMarker = chartTypeToShow === ChartType.pie;
    const [selected, setSelection] = useState(0);
    const categories = useCategories();
    const customCategoryColorsMap = useCustomCategoryColorsMap();
    const styles = useStyles(styleSet);
    const { selectedCategoryId } = useSelectedCategory();
    const categorySections = useAppSelector(selectAnalyticsCategorySections);
    const merchantSections = useAppSelector(selecAnalyticstMerchantSections);
    const categoriesLoading = useAppSelector(selectAnalyticsCategoryCurrentLoadingState);
    const merchantsLoading = useAppSelector(selectAnalyticsMerchantCurrentLoadingState);
    const categoryKeyIndexMap = useMemo(() => {
        const expenses = categorySections.filter((s) => s.type === 'category');
        return expenses.reduce((result, exp, index) => {
            if (exp.type === 'category') {
                const category = exp.data;
                const backgroundColor = exp.data
                    ? categories[category.id]?.color ||
                        customCategoryColorsMap[category.color]?.[0] ||
                        category.color
                    : undefined;
                return {
                    ...result,
                    [exp.data.id]: {
                        index,
                        backgroundColor,
                    },
                };
            }
            return result;
        }, {});
    }, [categories, categorySections, customCategoryColorsMap]);
    const colors = useColors();
    const [headerHeight, setHeaderHeight] = useState(0);
    const listStartsAt = useMemo(() => {
        let firstRowHeight = 0;
        if (categorySections.length) {
            if (categorySections[0].type === 'header') {
                firstRowHeight = rem(40);
            }
            else if (categorySections[0].type === 'add-category') {
                firstRowHeight = ADD_CATEGORY_ROW_HEIGHT;
            }
        }
        return headerHeight > 0 ? headerHeight + firstRowHeight : 0;
    }, [categorySections, headerHeight]);
    const markerAnimatedStyle = useAnimatedStyle(() => {
        const categoryRowData = selectedCategoryId.value
            ? categoryKeyIndexMap[selectedCategoryId.value]
            : undefined;
        return {
            transform: [
                {
                    translateY: withAnimation((categoryRowData?.index || 0) * MARKER_HEIGHT),
                },
            ],
            top: listStartsAt - scrollY.value,
            backgroundColor: withAnimation(categoryRowData?.backgroundColor || colors.elements.white),
            opacity: categoryRowData && withMarker ? withDelay(100, withTiming(1)) : 0,
        };
    }, [
        selectedCategoryId.value,
        categoryKeyIndexMap,
        listStartsAt,
        scrollY.value,
        colors.elements.white,
        withMarker,
    ]);
    const onLayout = useCallback((event) => {
        if (event.nativeEvent.layout.height > headerHeight && withMarker) {
            setHeaderHeight(event.nativeEvent.layout.height);
        }
    }, [withMarker, headerHeight]);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'customCategories',
    });
    const { isUnlockedForSpace: isPreviousPeriodsUnlockedForSpace, onPressUpgrade: onPressUpgradeForSearch, } = useShouldShowUpgrade({
        benefitId: 'search',
    });
    const twoMonthsAgo = useMemo(() => subMonths(new Date(), 2), []);
    const positionToBlurFrom = useMemo(() => {
        if (isPreviousPeriodsUnlockedForSpace) {
            return undefined;
        }
        const index = monthlyTotals.findIndex((item) => isBefore(parseISO(item.from), twoMonthsAgo));
        if (index > -1) {
            return index;
        }
        return undefined;
    }, [isPreviousPeriodsUnlockedForSpace, monthlyTotals, twoMonthsAgo]);
    const { paddingHorizontalStyle, paddingHorizontal } = useAppFrameDimensions(isWeb);
    const renderItem = useCallback(({ item }) => {
        const blurValue = positionToBlurFrom
            ? position >= positionToBlurFrom
            : false;
        switch (item.type) {
            case 'merchant':
                return (<View style={styles.rowContainer}>
              <RowMerchant merchant={item.data} onPress={openCategory} blurValue={blurValue}/>
            </View>);
            case 'category':
                return (<View style={styles.rowContainer}>
              <RowCategory category={item.data} totalSpending={item.totalSpending} chartTypeToShow={chartTypeToShow || ChartType.bar} categories={categories} customCategoryColorsMap={customCategoryColorsMap} onPress={openCategory} onPressUpgrade={!isUnlockedForSpace && !isDefaultCategory(item.data.id)
                        ? onPressUpgrade
                        : undefined} selectable={withMarker} selectedCategoryId={selectedCategoryId} blurValue={blurValue}/>
            </View>);
            case 'add-category': {
                return (<View style={styles.rowContainer}>
              <AnalyticsSectionAddCategory />
            </View>);
            }
            case 'header':
                return (<View style={styles.rowContainer}>
              <AnalyticsSectionHeader title={item.data.title} amount={item.data.amount} currency={item.data.currency}/>
            </View>);
            case 'footer':
                return <AnalyticsSectionFooter />;
            default:
                return null;
        }
    }, [
        categories,
        chartTypeToShow,
        customCategoryColorsMap,
        isUnlockedForSpace,
        onPressUpgrade,
        openCategory,
        position,
        positionToBlurFrom,
        selectedCategoryId,
        styles.rowContainer,
        withMarker,
    ]);
    const renderItemWrapper = useCallback(({ item }) => (<View style={paddingHorizontalStyle}>{renderItem({ item })}</View>), [paddingHorizontalStyle, renderItem]);
    const renderListHeader = useMemo(() => (<View>
        <AnalyticsSectionListHeader chartTypeToShow={chartTypeToShow || ChartType.bar} filteredMonthlyTotals={filteredMonthlyTotals} monthlyTotals={monthlyTotals} position={position} positionToBlurFrom={positionToBlurFrom} setSelection={setSelection} onLayout={onLayout} contentContainerStyle={paddingHorizontalStyle} onPressUpgradeForSearch={onPressUpgradeForSearch}/>
      </View>), [
        chartTypeToShow,
        filteredMonthlyTotals,
        monthlyTotals,
        onLayout,
        onPressUpgradeForSearch,
        paddingHorizontalStyle,
        position,
        positionToBlurFrom,
    ]);
    const emptyComponent = useMemo(() => (<View style={paddingHorizontalStyle}>
        <AnalyticsSectionListEmpty isFetching={selected === 0 ? categoriesLoading : merchantsLoading}/>
      </View>), [categoriesLoading, merchantsLoading, paddingHorizontalStyle, selected]);
    const paddingBottom = useMarginBottom('paddingBottom', undefined);
    const colorScheme = useColorScheme();
    const estimatedListSize = useEstimatedListSize(categorySections);
    const baseProps = {
        keyExtractor,
        onScroll: scrollHandler,
        scrollEventThrottle: 16,
        renderItem: renderItemWrapper,
        ListEmptyComponent: emptyComponent,
        contentContainerStyle: paddingBottom,
        ListHeaderComponent: renderListHeader,
        scrollIndicatorInsets: SCROLL_INDICATOR_INSETS,
        data: selected === 0 ? categorySections : merchantSections,
    };
    return (<View style={styles.container}>
      {isWeb ? (<Animated.FlatList {...baseProps}/>) : (<AnimatedFlashList {...baseProps} estimatedItemSize={74} getItemType={getItemType} estimatedListSize={estimatedListSize} extraData={`${withMarker}.${selectedCategoryId.value}.${colorScheme}${paddingHorizontal}`}/>)}
      {withMarker && listStartsAt > 0 && selected === 0 ? (<Animated.View style={[
                styles.marker,
                { marginLeft: (paddingHorizontal || 0) + rem(16) },
                markerAnimatedStyle,
            ]}/>) : null}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
    },
    rowContainer: {
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    marker: {
        height: MARKER_HEIGHT,
        position: 'absolute',
        width: rem(4),
        marginLeft: rem(16),
    },
}));
export default memo(AnalyticsList);
