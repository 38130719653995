import React, { memo } from 'react';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import { View } from 'react-native';
import createStyleSheets from 'utils/createStyleSheets';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { rem } from './values';
const BottomPanel = ({ style, children, }) => {
    const { paddingHorizontal } = useAppFrameDimensions();
    const paddingBottom = useMarginBottom('paddingBottom');
    const styles = useStyles(styleSet);
    return (<View style={[
            styles.container,
            paddingBottom,
            {
                paddingHorizontal: paddingHorizontal + rem(16),
            },
            style,
        ]}>
      {children}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        paddingTop: rem(16),
        borderTopColor: colors.borders.divider,
        borderTopWidth: rem(1),
        backgroundColor: colors.cards.onDark,
    },
}));
export default memo(BottomPanel);
