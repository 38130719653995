import React from 'react';
import { ClipPath, Defs, G, LinearGradient, Path, Rect, Stop, } from 'react-native-svg';
import { Icon } from 'design-system/icons/Icon';
import { useGradientColors } from 'hooks/useColors';
export const IconMoneyCheckPenSolid = ({ color, gradientKey = 'darkPurple', ...props }) => {
    const gradientColors = useGradientColors(gradientKey, (color ? [color, color] : props.gradientColors));
    return (<Icon {...props}>
      <G clipPath="url(#clip0_4828_6358)">
        <Path d="M17.5749 6.42505C18.5999 6.42505 19.4333 7.25696 19.4333 8.28341V10.1592C18.9919 10.2173 18.568 10.4147 18.2311 10.7545L12.9667 16.0159C12.7286 16.254 12.5602 16.5531 12.4789 16.8812L12.3047 17.5752H4.56637C3.53992 17.5752 2.70801 16.7419 2.70801 15.7169V8.28341C2.70801 7.25696 3.53992 6.42505 4.56637 6.42505H17.5749ZM5.96014 12.9293C5.70462 12.9293 5.49555 13.1384 5.49555 13.3939C5.49555 13.6494 5.70462 13.8585 5.96014 13.8585H12.4644C12.7199 13.8585 12.929 13.6494 12.929 13.3939C12.929 13.1384 12.7199 12.9293 12.4644 12.9293H5.96014ZM5.96014 11.071H15.252C15.5075 11.071 15.7165 10.8619 15.7165 10.6064C15.7165 10.3508 15.5075 10.1418 15.252 10.1418H5.96014C5.70462 10.1418 5.49555 10.3508 5.49555 10.6064C5.49555 10.8619 5.70462 11.071 5.96014 11.071ZM20.9461 11.8288C21.402 12.2818 21.402 13.0164 20.9461 13.4723L20.0924 14.326L18.0337 12.2644L18.8874 11.4107C19.3403 10.9577 20.075 10.9577 20.5309 11.4107L20.9461 11.8288ZM13.623 16.6489L17.3745 12.9235L19.4362 14.9822L15.6846 18.7338C15.5655 18.8528 15.4146 18.9371 15.252 18.9777L13.5068 19.4162C13.3471 19.4539 13.1816 19.4074 13.0655 19.2942C12.9493 19.1781 12.9029 19.0096 12.9435 18.8267L13.3791 17.1048C13.4197 16.9422 13.5039 16.7941 13.623 16.6489Z" fill="url(#paint0_linear_4828_6358)"/>
      </G>
      <Defs>
        <LinearGradient id="paint0_linear_4828_6358" x1="11.998" y1="6.42505" x2="11.998" y2="19.4291" gradientUnits="userSpaceOnUse">
          <Stop stopColor={gradientColors[0]}/>
          <Stop offset="1" stopColor={gradientColors[1]}/>
        </LinearGradient>
        <ClipPath id="clip0_4828_6358">
          <Rect width="18.5836" height="14.8669" fill="white" transform="translate(2.70801 4.56665)"/>
        </ClipPath>
      </Defs>
    </Icon>);
};
