import React, { memo, useCallback, useMemo } from 'react';
import { View, StyleSheet, FlatList, } from 'react-native';
import Animated from 'react-native-reanimated';
import SectionHeader from 'design-system/SectionHeader';
import { TextButtonLink } from 'design-system/TextButton';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import { useCategories } from 'hooks/useCategory';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import { useAppSelector } from 'store/hooks';
import { selectAllCategories } from 'reducers/selectors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useIsTablet from 'hooks/useIsTablet';
import EditCategoryRow from './EditCategoryRow';
import { defaultCategories, isDesktopOrWeb, isWeb, } from '../../../../constants';
const custom = [{ id: 'custom1' }];
const keyExtractor = (item) => item[0].id;
const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);
const splitIntoRows = (array) => array.reduce((curr, item, index) => {
    const chunkIndex = Math.floor(index / 4);
    const newArray = curr;
    if (!newArray[chunkIndex]) {
        newArray[chunkIndex] = []; // new row
    }
    newArray[chunkIndex].push(item);
    return newArray;
}, []);
const EditCategoryListNew = ({ scrollHandler, contentContainerStyle, selectedCategory, onPressCategory, onLongPressCategory, onPressManage, }) => {
    const allCategories = useAppSelector(selectAllCategories);
    const categories = useCategories();
    const { width } = useAppFrameDimensions(isWeb);
    const isTablet = useIsTablet();
    const renderRow = useCallback(({ item: row }) => (<View style={styles.row}>
        {row.map((item) => {
            const category = categories[item.id] || item;
            const id = selectedCategory?.id != null ? selectedCategory.id : null;
            return (<EditCategoryRow key={item.id} onLongPress={onLongPressCategory} isDefaultCategory={defaultCategories.includes(item.id)} onPress={onPressCategory} item={category} selected={id === category.id}/>);
        })}
        {Array.from({ length: 4 - row.length }).map((_, i) => (<View key={i} style={{
                width: isDesktopOrWeb || isTablet ? width / 4 : rem(76),
            }}/>))}
      </View>), [
        categories,
        isTablet,
        onLongPressCategory,
        onPressCategory,
        selectedCategory?.id,
        width,
    ]);
    const renderHeader = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    () => (<>
        <ScreenInfoHeader title="Pick a category"/>
        <SectionHeader style={styles.sectionHeader} largeVerticalMargins={isWeb} button={onPressManage && (<TextButtonLink text="Manage" onPress={onPressManage}/>)}>
          Categories
        </SectionHeader>
      </>), [onPressManage]);
    const fallbackCategories = useMemo(() => {
        const arr = Object.values(categories);
        // remove custom1 from the list
        arr.pop();
        return arr;
    }, [categories]);
    const data = useMemo(() => splitIntoRows(
    // We can use default categories from the local record if the array has failed to load
    custom.concat(allCategories?.length ? allCategories : fallbackCategories)), [allCategories, fallbackCategories]);
    return (<AnimatedFlatList renderItem={renderRow} scrollEventThrottle={16} onScroll={scrollHandler} keyExtractor={keyExtractor} data={data} ListHeaderComponent={renderHeader} contentContainerStyle={contentContainerStyle} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS}/>);
};
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginHorizontal: rem(16),
        marginBottom: rem(16),
    },
    sectionHeader: {
        marginTop: rem(12),
        marginHorizontal: rem(16),
        marginBottom: rem(24),
    },
});
export default memo(EditCategoryListNew);
