import { LOGGED_OUT } from 'actions/types';
import * as types from './actions';
const initialState = {
    rules: [],
};
// Infer the type and add default name to support old smart rules
const inferDefaults = (rule) => {
    const { type, minAmount, maxAmount } = rule.filter.criteria;
    if (type) {
        return { ...rule, ruleName: rule.ruleName || 'My smart rule' };
    }
    let inferredType;
    if (!minAmount && !maxAmount) {
        inferredType = 'both';
    }
    else if ((minAmount ?? 0) < 0 || (maxAmount ?? 0) < 0) {
        inferredType = 'spending';
    }
    else {
        inferredType = 'income';
    }
    return {
        ...rule,
        ruleName: rule.ruleName || 'My smart rule',
        filter: {
            ...rule.filter,
            criteria: {
                ...rule.filter.criteria,
                type: inferredType,
            },
        },
    };
};
export const smartRules = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SMART_RULES_SUCCESS:
            const rules = action.payload.rules.map(inferDefaults);
            return { ...state, rules };
        case types.CREATE_SMART_RULE_SUCCESS:
            return { ...state, rules: [action.payload.rule, ...state.rules] };
        case types.DELETE_SMART_RULE_SUCCESS:
            return {
                ...state,
                rules: state.rules.filter(({ id }) => id !== action.extra),
            };
        case types.EDIT_SMART_RULE_SUCCESS:
            return {
                ...state,
                rules: state.rules.map((rule) => rule.id === action.payload.rule.id
                    ? inferDefaults(action.payload.rule)
                    : rule),
            };
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
