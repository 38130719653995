import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, { useAnimatedRef, useAnimatedScrollHandler, useSharedValue, } from 'react-native-reanimated';
import { useScrollToTop } from '@react-navigation/native';
import { AppView } from 'design-system/AppView';
import { NavigationHeader } from 'design-system/NavigationHeader';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useIsTablet from 'hooks/useIsTablet';
import useStatusBar from 'hooks/useStatusBar';
import AvatarFeed from 'features/feed/components/avatar-feed/AvatarFeed';
import RentReportingSection from 'features/feed/components/rent-reporting/RentReportingSection';
import { isWeb } from '../../constants';
import ImproveCreditScoreCard from './components/ImproveCreditScoreCard';
import LoanItemsList from './components/LoanItemsList';
const BorrowTab = () => {
    const isTablet = useIsTablet();
    const scrollY = useSharedValue(0);
    const onScroll = useAnimatedScrollHandler((e) => {
        scrollY.value = e.contentOffset.y;
    });
    useStatusBar('dark-content', false);
    const scrollViewRef = useAnimatedRef();
    useScrollToTop(scrollViewRef);
    const { paddingHorizontalStyle } = useAppFrameDimensions(true);
    const headerLeft = useMemo(() => !isTablet && !isWeb ? (<View style={styles.avatar}>
          <AvatarFeed />
        </View>) : null, [isTablet]);
    return (<AppView withFrame={false}>
      <NavigationHeader title="Borrow" scrollY={scrollY} leftContent={headerLeft} isInTab/>
      <Animated.ScrollView ref={scrollViewRef} onScroll={onScroll} scrollEventThrottle={16} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS} contentContainerStyle={paddingHorizontalStyle}>
        <LoanItemsList />
        <RentReportingSection />
        {!isWeb && <ImproveCreditScoreCard />}
      </Animated.ScrollView>
    </AppView>);
};
const styles = StyleSheet.create({
    avatar: {
        position: 'absolute',
        left: rem(16),
    },
});
export default BorrowTab;
