import React from 'react';
import { View, StyleSheet } from 'react-native';
import Indicator from 'design-system/Indicator';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
/**
 * This component is used as loader in ListEmptyComponent
 */
const ListLoader = ({ smallMargin }) => {
    const { height } = useAppFrameDimensions();
    return (<View style={[
            styles.container,
            {
                marginTop: (smallMargin ? 0.3 : 0.4) * height,
            },
        ]}>
      <Indicator dark/>
    </View>);
};
export default React.memo(ListLoader);
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },
});
