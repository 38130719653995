import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, { interpolate, useAnimatedStyle } from 'react-native-reanimated';
import { Spacer, rem } from 'design-system';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDefaultHeaderHeight } from 'hooks/useHeaderHeight';
import { selectFeatureFlag } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import TransactionLogo from '../TransactionLogo';
import TransactionHeaderText, { useTransactionHeaderTextHeight } from './TransactionHeaderText';
import { TransactionBankLogo } from '../TransactionBankLogo';
const logoMarginBottom = 16;
const titleButtonSpacing = 16;
const logoSize = 70;
const TransactionHeader = ({ scrollY, transaction, showFirstUpgradeButton, showRenameUpgradeButton, useCustomName, onPressUpgrade, headerScrollOffset, customLogo, }) => {
    const bankLogoTransaction = useAppSelector((store) => selectFeatureFlag(store, 'bank_logo_transaction')).value;
    const { top } = useSafeAreaInsets();
    const navHeight = useDefaultHeaderHeight();
    const logoStyle = useAnimatedStyle(() => ({
        opacity: interpolate(scrollY.value, [0, headerScrollOffset / 2], [1, 0], 'clamp'),
    }), []);
    const translateStyles = useAnimatedStyle(() => ({
        transform: [
            {
                translateY: interpolate(scrollY.value, [0, headerScrollOffset, headerScrollOffset + 1], [0, -headerScrollOffset, -headerScrollOffset]),
            },
        ],
    }), [headerScrollOffset]);
    return (<Animated.View style={[styles.absoluteContainer, translateStyles]}>
      <View pointerEvents="box-none" style={{ height: navHeight, paddingTop: top }}/>
      <Spacer h={8}/>
      <Animated.View style={logoStyle}>
        {customLogo || <TransactionLogo border light transaction={transaction} R70/>}
        {bankLogoTransaction && 'accountId' in transaction && <TransactionBankLogo id={transaction.accountId}/>}
      </Animated.View>
      <Spacer h={logoMarginBottom}/>
      <TransactionHeaderText transaction={transaction} showFirstUpgradeButton={showFirstUpgradeButton} showRenameUpgradeButton={showRenameUpgradeButton} onPressUpgrade={onPressUpgrade} useCustomName={useCustomName}/>
    </Animated.View>);
};
const styles = StyleSheet.create({
    absoluteContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        backgroundColor: 'transparent',
    },
});
export const useTransactionHeaderHeight = (transaction, showRenameUpgradeButton, showFirstUpgradeButton) => {
    const navHeaderHeight = useDefaultHeaderHeight();
    const [transactionHeaderTextHeight] = useTransactionHeaderTextHeight(transaction, showRenameUpgradeButton, showFirstUpgradeButton);
    const { top } = useSafeAreaInsets();
    const viewHeight = rem(logoMarginBottom) + rem(logoSize) + transactionHeaderTextHeight + titleButtonSpacing + rem(16);
    return [
        navHeaderHeight + viewHeight,
        transactionHeaderTextHeight + rem(8),
        rem(logoSize) + navHeaderHeight - rem(12),
        rem(logoSize) + navHeaderHeight + rem(logoMarginBottom) + rem(8) - top,
    ];
};
export const useTransactionHeaderUpgradeButtons = (transaction) => {
    const { isUnlockedForSpace: isSplitUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        benefitId: 'split',
    });
    const { isUnlockedForSpace: isRenameUnlockedForSpace } = useShouldShowUpgrade({
        benefitId: 'renameTransactions',
    });
    const { isUnlockedForSpace: isOfflineTransactionsUnlockedForSpace } = useShouldShowUpgrade({
        benefitId: 'offlineAccounts',
    });
    const showSplitUpgradeButton = !!transaction && 'splitFromId' in transaction && transaction.splitFromId != null && !isSplitUnlockedForSpace;
    const showOfflineTransactionsUpgradeButton = !!transaction &&
        'provider' in transaction &&
        transaction.provider === 'MANUAL' &&
        !isOfflineTransactionsUnlockedForSpace;
    const showRenameUpgradeButton = !isRenameUnlockedForSpace && !showSplitUpgradeButton && !!transaction && transaction.customName != null;
    const useCustomName = isRenameUnlockedForSpace && !!transaction && transaction.customName != null;
    return {
        showFirstUpgradeButton: showSplitUpgradeButton || showOfflineTransactionsUpgradeButton,
        showRenameUpgradeButton,
        useCustomName,
        onPressUpgrade,
    };
};
export default memo(TransactionHeader);
