import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconUser = ({ ...props }) => {
    const id = useId();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="turquoise">
      <Path d="M12 3.5C9.56586 3.5 7.59259 5.45716 7.59259 7.87143C7.59259 10.2857 9.56586 12.2429 12 12.2429C14.4341 12.2429 16.4074 10.2857 16.4074 7.87143C16.4074 5.45716 14.4341 3.5 12 3.5Z" fill={`url(#gradient_${id})`}/>
      <Path d="M14.601 14.1877C12.8779 13.9149 11.1221 13.9149 9.39904 14.1877L9.21435 14.2169C6.78647 14.6012 5 16.6783 5 19.1168C5 20.433 6.07576 21.5 7.40278 21.5H16.5972C17.9242 21.5 19 20.433 19 19.1168C19 16.6783 17.2135 14.6012 14.7857 14.2169L14.601 14.1877Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
