import { CALL_API } from '../middleware/api';
import * as types from './types';
export const getGroups = () => ({
    [CALL_API]: {
        endpoint: '/spending-groups?withNetBalance=true&withUserIds=true',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_GROUPS_REQUEST,
            types.GET_GROUPS_SUCCESS,
            types.GET_GROUPS_FAILURE,
        ],
    },
});
export const getGroupById = (groupId) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_GROUP_BY_ID_REQUEST,
            types.GET_GROUP_BY_ID_SUCCESS,
            types.GET_GROUP_BY_ID_FAILURE,
        ],
    },
});
export const uploadGroupImage = (image, groupId) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}/image`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        body: image,
        types: [
            types.UPLOAD_GROUP_IMAGE_REQUEST,
            types.UPLOAD_GROUP_IMAGE_SUCCESS,
            types.UPLOAD_GROUP_IMAGE_FAILURE,
        ],
    },
});
export const deleteGroupImage = (groupId, iconUrl) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}/image`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        extra: { groupId, iconUrl },
        types: [
            types.DELETE_GROUP_IMAGE_REQUEST,
            types.DELETE_GROUP_IMAGE_SUCCESS,
            types.DELETE_GROUP_IMAGE_FAILURE,
        ],
    },
});
export const saveGroupTransaction = (spendingGroupID, body) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${spendingGroupID}/transactions`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        types: [
            types.SAVE_GROUP_TRANSACTION_REQUEST,
            types.SAVE_GROUP_TRANSACTION_SUCCESS,
            types.SAVE_GROUP_TRANSACTION_FAILURE,
        ],
    },
});
export const patchGroupTransaction = (spendingGroupID, transactionId, body) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${spendingGroupID}/transactions/${transactionId}`,
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        extra: { groupId: spendingGroupID, transactionId },
        types: [
            types.PATCH_GROUP_TRANSACTION_REQUEST,
            types.PATCH_GROUP_TRANSACTION_SUCCESS,
            types.PATCH_GROUP_TRANSACTION_FAILURE,
        ],
    },
});
export const importGroupTransactions = (spendingGroupID, transactionIds, split, isSettlement = false) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${spendingGroupID}/import-transactions`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ transactionIds, split, isSettlement }),
        types: [
            types.IMPORT_GROUP_TRANSACTIONS_REQUEST,
            types.IMPORT_GROUP_TRANSACTIONS_SUCCESS,
            types.IMPORT_GROUP_TRANSACTIONS_FAILURE,
        ],
    },
});
export const deleteGroupTransaction = (groupId, transactionId) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}/transactions/${transactionId}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.DELETE_GROUP_TRANSACTION_REQUEST,
            types.DELETE_GROUP_TRANSACTION_SUCCESS,
            types.DELETE_GROUP_TRANSACTION_FAILURE,
        ],
    },
});
export const createGroup = (name) => ({
    [CALL_API]: {
        endpoint: '/spending-groups',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name }),
        types: [
            types.CREATE_GROUP_REQUEST,
            types.CREATE_GROUP_SUCCESS,
            types.CREATE_GROUP_FAILURE,
        ],
    },
});
export const renameGroup = (groupId, oldName, newName) => {
    const body = { name: newName };
    return {
        [CALL_API]: {
            endpoint: `/spending-groups/${groupId}`,
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            extra: { groupId, oldName, newName },
            types: [
                types.RENAME_GROUP_REQUEST,
                types.RENAME_GROUP_SUCCESS,
                types.RENAME_GROUP_FAILURE,
            ],
        },
    };
};
export const deleteGroup = (groupId) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: groupId,
        types: [
            types.DELETE_GROUP_REQUEST,
            types.DELETE_GROUP_SUCCESS,
            types.DELETE_GROUP_FAILURE,
        ],
    },
});
export const deleteGroupUser = (groupId, userId) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}/members/${userId}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: {
            groupId,
            userId,
        },
        types: [
            types.DELETE_GROUP_USER_REQUEST,
            types.DELETE_GROUP_USER_SUCCESS,
            types.DELETE_GROUP_USER_FAILURE,
        ],
    },
});
export const leaveGroup = (groupId) => ({
    type: types.LEAVE_GROUP_SUCCESS,
    extra: groupId,
});
export const addPeopleToGroup = (id, handles) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${id}/add-members`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ handles }),
        types: [
            types.ADD_PEOPLE_TO_GROUP_REQUEST,
            types.ADD_PEOPLE_TO_GROUP_SUCCESS,
            types.ADD_PEOPLE_TO_GROUP_FAILURE,
        ],
    },
});
export const joinGroup = (accessCode) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/join/${accessCode}`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.JOIN_GROUP_REQUEST,
            types.JOIN_GROUP_SUCCESS,
            types.JOIN_GROUP_FAILURE,
        ],
    },
});
export const sendGroupSettlementReminder = (groupId, debtorUserHandle) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}/settlement-reminder`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ debtorUserHandle }),
        types: [
            types.SEND_GROUP_SETTLEMENT_REMINDER_REQUEST,
            types.SEND_GROUP_SETTLEMENT_REMINDER_SUCCESS,
            types.SEND_GROUP_SETTLEMENT_REMINDER_FAILURE,
        ],
    },
});
export const getGroupTransactionReceipts = (groupId, transactionId) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}/transactions/${transactionId}/receipts`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: { isGroup: true, transactionId },
        types: [
            types.GET_RECEIPTS_REQUEST,
            types.GET_RECEIPTS_SUCCESS,
            types.GET_RECEIPTS_FAILURE,
        ],
    },
});
export const uploadGroupTransactionReceipts = (groupId, transactionId, body, files) => ({
    [CALL_API]: {
        endpoint: `/spending-groups/${groupId}/transactions/${transactionId}/receipts`,
        method: 'POST',
        body,
        extra: { isGroup: true, transactionId, files },
        types: [
            types.UPLOAD_RECEIPT_REQUEST,
            types.UPLOAD_RECEIPT_SUCCESS,
            types.UPLOAD_RECEIPT_FAILURE,
        ],
    },
});
