import React from 'react';
import { View } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import { CardView } from 'design-system/CardView';
import { rem } from 'design-system/values';
import BlurAmountView from 'design-system/BlurAmountView';
import IconCircle from 'design-system/icons/IconCircle';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const CategoryValueCard = ({ value, icon, children, blurValue, }) => {
    const styles = useStyles(styleSet);
    return (<CardView style={styles.card} noMarginTop withPadding>
      <View style={styles.row}>
        <IconCircle width={44} height={44} style={styles.icon}>
          {icon}
        </IconCircle>
        <View>
          {value ? (<BlurAmountView enabled={blurValue}>
              <Amount Numbers-16 showFraction={false} value={{ value: value.amount, currency: value.currency }}/>
            </BlurAmountView>) : (<Text Numbers-16>-</Text>)}
          <Text TextThin-14 Gray>
            {children}
          </Text>
        </View>
      </View>
    </CardView>);
};
const styleSet = createStyleSheets((colors) => ({
    card: {
        flex: 1,
        padding: rem(16),
        overflow: 'visible',
        marginHorizontal: rem(4),
        marginBottom: rem(8),
        ...colors.shadows.card,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        marginRight: rem(16),
    },
}));
export default CategoryValueCard;
