import React, { useCallback } from 'react';
import { rem } from 'design-system/values';
import { Button } from 'design-system/Button';
import IconPlus from 'design-system/icons/IconPlus';
import useColors from 'hooks/useColors';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { isWeb } from '../../../../constants';
import { RentReportingUnavailableModal } from './RentReportingUnavailableModal.web';
const RentReportingStartButton = () => {
    const navigation = useMainStackNavigation();
    const colors = useColors();
    const onPressStartReporting = useCallback(() => {
        if (isWeb) {
            RentReportingUnavailableModal.show();
        }
        else {
            navigation.navigate('RentReportingOnboarding');
        }
    }, []);
    return (<Button onPress={onPressStartReporting} square brandLight title="Start reporting" leftIcon={<IconPlus width={rem(12)} height={rem(12)} color={colors.elements.brand}/>}/>);
};
export default RentReportingStartButton;
