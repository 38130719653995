import { CALL_API } from '../middleware/api';
import * as types from './types';
export const deleteTransaction = (id) => ({
    [CALL_API]: {
        endpoint: `/transactions/${id}?updateAccountBalance=true`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: id,
        types: [
            types.DELETE_TRANSACTION_REQUEST,
            types.DELETE_TRANSACTION_SUCCESS,
            types.DELETE_TRANSACTION_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const createTransaction = (body) => ({
    [CALL_API]: {
        endpoint: '/transactions/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.CREATE_TRANSACTION_REQUEST,
            types.CREATE_TRANSACTION_SUCCESS,
            types.CREATE_TRANSACTION_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const splitTransactions = (id, data) => ({
    [CALL_API]: {
        endpoint: `/transactions/${id}/split`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SPLIT_TRANSACTION_REQUEST,
            types.SPLIT_TRANSACTION_SUCCESS,
            types.SPLIT_TRANSACTION_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const unsplitTransaction = (id) => ({
    [CALL_API]: {
        endpoint: `/transactions/${id}/unsplit`,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.UNSPLIT_TRANSACTION_REQUEST,
            types.UNSPLIT_TRANSACTION_SUCCESS,
            types.UNSPLIT_TRANSACTION_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const getTransactionReceipts = (id) => ({
    [CALL_API]: {
        endpoint: `/transactions/${id}/receipts`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: { transactionId: id },
        types: [
            types.GET_RECEIPTS_REQUEST,
            types.GET_RECEIPTS_SUCCESS,
            types.GET_RECEIPTS_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const uploadTransactionReceipts = (id, body, files) => ({
    [CALL_API]: {
        endpoint: `/transactions/${id}/receipts`,
        method: 'POST',
        body,
        extra: {
            transactionId: id,
            files,
        },
        types: [
            types.UPLOAD_RECEIPT_REQUEST,
            types.UPLOAD_RECEIPT_SUCCESS,
            types.UPLOAD_RECEIPT_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const deleteReceipt = (receipt, transactionId, index, isGroup) => ({
    [CALL_API]: {
        endpoint: `/receipts/${receipt.id}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: { receipt, transactionId, index, isGroup },
        types: [
            types.DELETE_RECEIPT_REQUEST,
            types.DELETE_RECEIPT_SUCCESS,
            types.DELETE_RECEIPT_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const getMerchantStats = (id) => ({
    [CALL_API]: {
        endpoint: `/analytics/merchants/${id}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_MERCHANT_STATS_REQUEST,
            types.GET_MERCHANT_STATS_SUCCESS,
            types.GET_MERCHANT_STATS_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const onSubmitFeedback = (id, twitterUsername, transaction) => ({
    [CALL_API]: {
        extra: { id, transaction },
        endpoint: `/transactions/${id}/suggest-merchant`,
        method: 'POST',
        body: JSON.stringify({
            twitterUsername,
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.ON_SUBMIT_FEEDBACK_REQUEST,
            types.ON_SUBMIT_FEEDBACK_SUCCESS,
            types.ON_SUBMIT_FEEDBACK_FAILURE,
        ],
    },
});
// We call this whenever we navigate to the transaction detail screen from a place that doesn't use the transactions cache
export const addTransactionToCache = (transaction) => ({
    type: types.ADD_TRANSACTION_TO_CACHE,
    payload: transaction,
});
export const getTransaction = (id) => ({
    [CALL_API]: {
        endpoint: `/transactions/${id}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_TRANSACTION_REQUEST,
            types.GET_TRANSACTION_SUCCESS,
            types.GET_TRANSACTION_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const changeTransactionDate = (transaction, customDate) => {
    const body = [];
    const { id } = transaction;
    body.push({ id, customDate });
    return {
        [CALL_API]: {
            endpoint: '/transactions/',
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            extra: { transaction, customDate },
            addSpaceIdHeader: true,
            types: [
                types.CHANGE_DATE_TRANSACTION_REQUEST,
                types.CHANGE_DATE_TRANSACTION_SUCCESS,
                types.CHANGE_DATE_TRANSACTION_FAILURE,
            ],
        },
    };
};
export const renameTransaction = (data, counterPartName) => {
    const body = [];
    const numberData = [];
    for (let index = 0; index < data.length; index++) {
        const numberId = data[index];
        numberData.push(numberId);
        body.push({ id: numberId, customName: counterPartName });
    }
    return {
        [CALL_API]: {
            endpoint: '/transactions/',
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            extra: { data: numberData, counterPartName },
            types: [
                types.RENAME_TRANSACTION_REQUEST,
                types.RENAME_TRANSACTION_SUCCESS,
                types.RENAME_TRANSACTION_FAILURE,
            ],
            addSpaceIdHeader: true,
        },
    };
};
export const setTags = (id, tags) => {
    const body = { labels: tags };
    return {
        [CALL_API]: {
            endpoint: `/transactions/${id}/edit`,
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: [
                types.SET_TAGS_REQUEST,
                types.SET_TAGS_SUCCESS,
                types.SET_TAGS_FAILURE,
            ],
            addSpaceIdHeader: true,
        },
    };
};
export const getTags = () => ({
    [CALL_API]: {
        endpoint: '/labels',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_TAGS_REQUEST,
            types.GET_TAGS_SUCCESS,
            types.GET_TAGS_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const saveNote = (id, data) => {
    const body = { notes: data };
    return {
        [CALL_API]: {
            endpoint: `/transactions/${id}/edit`,
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: [
                types.SAVE_NOTE_REQUEST,
                types.SAVE_NOTE_SUCCESS,
                types.SAVE_NOTE_FAILURE,
            ],
            addSpaceIdHeader: true,
        },
    };
};
export const resetTransaction = () => ({
    type: types.RESET_TRANSACTION,
});
export const setTransactionsRequesting = {
    type: types.SET_TRANSACTIONS_REQUESTING,
};
export const getOriginalSplit = (id) => ({
    [CALL_API]: {
        endpoint: `/transactions/${id}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.GET_ORIGINAL_TRANSACTION_REQUEST,
            types.GET_ORIGINAL_TRANSACTION_SUCCESS,
            types.GET_ORIGINAL_TRANSACTION_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const getLinkedTransaction = (id) => ({
    [CALL_API]: {
        endpoint: `/transactions/${id}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.LINKED_TRANSACTION_REQUEST,
            types.LINKED_TRANSACTION_SUCCESS,
            types.LINKED_TRANSACTION_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const isFetchingBankTransactions = (transactionArrayType, fromTo, showExcludedTransactions, accountIdsStr) => ({
    type: types.IS_FETCHING_BANK_TRANSACTIONS,
    payload: {
        fromTo,
        transactionArrayType,
        accountIdsStr,
        showExcludedTransactions: showExcludedTransactions ?? true,
    },
});
export const getSearchTransactions = () => ({
    [CALL_API]: {
        endpoint: '/transactions-compact',
        method: 'GET',
        types: [
            types.SEARCH_TRANSACTIONS_REQUEST,
            types.SEARCH_TRANSACTIONS_SUCCESS,
            types.SEARCH_TRANSACTIONS_FAILURE,
        ],
        addSpaceIdHeader: true,
    },
});
export const getTransactions = ({ data, type, categoryId, from, to, label, page, key, merchantId, hasInternalTransactions, accountIdsStr, }) => {
    let account = `accountIds[]=${data}`;
    if (accountIdsStr) {
        account = accountIdsStr;
    }
    else if (!data) {
        account = '';
    }
    let typeString = '';
    if (type)
        typeString = `&type=${type}`;
    let category = '';
    if (categoryId)
        category = `&categoryId=${categoryId}`;
    let period = '';
    if (from)
        period = `&dateFrom=${from}&dateTo=${to}`;
    let labelArray = '';
    if (label)
        labelArray = `labels[]=${label}`;
    let merchant = '';
    if (merchantId)
        merchant = `&merchantId=${merchantId}`;
    const internalTransactions = hasInternalTransactions
        ? `&withoutInternal=${hasInternalTransactions}`
        : '';
    const paging = `&page=${page}&perPage=60`;
    return {
        [CALL_API]: {
            endpoint: `/transactions?${labelArray}${account}${paging}${typeString}${category}${period}${merchant}${internalTransactions}`,
            method: 'GET',
            extra: {
                to,
                key,
                from,
                accountIdsStr,
                lastAccount: data,
                showExcludedTransactions: !hasInternalTransactions,
            },
            types: [
                types.TRANSACTIONS_REQUEST,
                types.TRANSACTIONS_SUCCESS,
                types.TRANSACTIONS_FAILURE,
            ],
            addSpaceIdHeader: true,
        },
    };
};
export const resetSimilarTransactions = () => ({
    type: types.RESET_SIMILAR_TRANSACTIONS,
});
export const getSimilarTransactions = ({ id, page = 1, perPage = 25, excludeCategoryId, }) => ({
    [CALL_API]: {
        endpoint: `/transactions/${id}/similar?page=${page}&perPage=${perPage}${excludeCategoryId ? `&excludeCategoryId=${excludeCategoryId}` : ''}`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.SIMILAR_TRANSACTIONS_REQUEST,
            types.SIMILAR_TRANSACTIONS_SUCCESS,
            types.SIMILAR_TRANSACTIONS_FAILURE,
        ],
        extra: {
            id,
            page,
        },
        addSpaceIdHeader: true,
    },
});
export const editTagsTransactions = (data, tags) => {
    const body = [];
    const numberData = [];
    for (let index = 0; index < data.length; index++) {
        const numberId = data[index];
        numberData.push(String(numberId));
        body.push({ id: numberId, labels: tags });
    }
    return {
        [CALL_API]: {
            endpoint: '/transactions/',
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            extra: { data: numberData, labels: tags },
            types: [
                types.EDIT_TAGS_REQUEST,
                types.EDIT_TAGS_SUCCESS,
                types.EDIT_TAGS_FAILURE,
            ],
            addSpaceIdHeader: true,
        },
    };
};
export const editTransactions = (data, category) => {
    const body = [];
    Object.keys(data).forEach((key) => {
        body.push({ id: key, categoryId: category.id });
    });
    return {
        [CALL_API]: {
            endpoint: '/transactions/',
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            extra: { data, category },
            types: [
                types.EDIT_CATEGORY_REQUEST,
                types.EDIT_CATEGORY_SUCCESS,
                types.EDIT_CATEGORY_FAILURE,
            ],
            addSpaceIdHeader: true,
        },
    };
};
export const setSpendingGroupInfo = (originalTransactionId, spendingGroupInfo) => ({
    type: types.SET_SPENDING_GROUP_INFO_SUCCESS,
    originalTransactionId,
    spendingGroupInfo,
});
