import React, { memo, useCallback, useState } from 'react';
import { TextInput, TouchableOpacity, View } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import { useCategoryColors } from 'hooks/useCategory';
import IconClose from './icons/IconClose';
import { getFallbackColor } from './colors';
import { isWeb } from '../../../../constants';
const HIT_SLOP_REMOVE = { top: 10, bottom: 10, left: 10, right: 10 };
const SearchHeaderItemBadge = ({ item, amountLowerValue, amountUpperValue, refAmountLowerInput, refAmountUpperInput, onToggleSelectedFilter, onRemoveItem, onChangeAmountLower, onChangeAmountUpper, }) => {
    const handleToggleSelectedFilter = useCallback(() => {
        onToggleSelectedFilter(item);
    }, [item, onToggleSelectedFilter]);
    const handleRemoveItem = useCallback(() => {
        onRemoveItem(item);
    }, [item, onRemoveItem]);
    const colors = useColors();
    const styles = useStyles(styleSet);
    const categoryColors = useCategoryColors(item);
    const [inputWidth, setInputWidth] = useState(5);
    return (<TouchableOpacity style={[
            styles.container,
            {
                backgroundColor: item.color ? categoryColors : colors.gradients[getFallbackColor(item.filterType)].colors[0],
            },
        ]} onPress={handleToggleSelectedFilter}>
      <Text Text-14 OnPrimary>
        {item.badgeText}
      </Text>
      {item.filterType === 'AMOUNT_UPPER' && (<View pointerEvents="none">
          <TextInput autoFocus ref={refAmountUpperInput} style={[styles.badgeInputText, isWeb && { width: inputWidth }]} value={amountUpperValue} onChangeText={(text) => {
                onChangeAmountUpper?.(text);
                if (isWeb) {
                    setInputWidth(5 + text.length * 8);
                }
            }} keyboardType="numeric" caretHidden={!isWeb} pointerEvents="none"/>
        </View>)}
      {item.filterType === 'AMOUNT_LOWER' && (<View pointerEvents="none">
          <TextInput autoFocus ref={refAmountLowerInput} style={[styles.badgeInputText, isWeb && { width: inputWidth }]} value={amountLowerValue} onChangeText={(text) => {
                onChangeAmountLower?.(text);
                if (isWeb) {
                    setInputWidth(5 + text.length * 8);
                }
            }} keyboardType="numeric" caretHidden={!isWeb} pointerEvents="none"/>
        </View>)}
      <Spacer w={4}/>
      <TouchableOpacity onPress={handleRemoveItem} hitSlop={HIT_SLOP_REMOVE}>
        <IconClose size={16} color={colors.text.onPrimary}/>
      </TouchableOpacity>
    </TouchableOpacity>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: rem(29 / 2),
        height: rem(29),
        paddingHorizontal: rem(16),
        marginRight: rem(8),
    },
    badgeInputText: {
        fontFamily: 'Montserrat-Regular',
        padding: 0,
        minWidth: 20,
        color: colors.text.onPrimary,
        fontSize: rem(14),
        ...(isWeb && {
            width: 5,
            caretColor: colors.text.onPrimary,
        }),
    },
}));
export default memo(SearchHeaderItemBadge);
