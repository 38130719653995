import React from 'react';
import { View } from 'react-native';
import { rem } from 'design-system';
import { CIRCLE_SIZE, CIRCLE_BORDER_SIZE } from 'design-system/LogoBankAccount';
import { IconEmma } from 'design-system/icons';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const IconEmmaWallet = ({ onCard }) => {
    const styles = useStyles(styleSet);
    return (<View style={styles.wrapper}>
      <View style={[
            styles.container,
            onCard && styles.onCard, // in dark mode, when shown on a card, should have darker background
        ]}>
        <IconEmma gradientKey="ultimate"/>
      </View>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    wrapper: {
        borderRadius: rem(CIRCLE_SIZE / 2 + CIRCLE_BORDER_SIZE),
        width: rem(CIRCLE_SIZE + CIRCLE_BORDER_SIZE * 2),
        height: rem(CIRCLE_SIZE + CIRCLE_BORDER_SIZE * 2),
        borderWidth: CIRCLE_BORDER_SIZE,
        borderColor: colors.borders.logoStroke,
    },
    container: {
        alignItems: 'center',
        borderRadius: rem(22),
        width: rem(CIRCLE_SIZE),
        justifyContent: 'center',
        height: rem(CIRCLE_SIZE),
        backgroundColor: colors.cards.onLight,
    },
    onCard: {
        backgroundColor: colors.background.dark,
    },
}));
export default IconEmmaWallet;
