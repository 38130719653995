import investReducers from 'features/invest/reducers';
import pots from 'features/pots/reducers/pots';
import unpersistedPotsReducer from 'features/pots/reducers/unpersistedPotsReducer';
import { myLimits } from 'features/settings/plan-overview/reducers/myLimits';
import { spaces } from 'features/spaces/reducers';
import { referrals } from 'features/inviteFriend/reducers';
import { contacts } from 'features/contacts/reducers';
import { iapSubscriptions, stripe } from 'features/premium/reducers';
import subscriptions from 'features/subscriptions/reducers';
import notifications from 'features/notifications/reducers';
import analytics from 'features/analytics/reducers';
import signInNavigation from 'login/reducers/signinNavigation';
import { promotionsReducer } from 'features/promotions/reducers';
import { smartRules } from 'features/smart-rules/reducers';
import rentReporting from 'features/rent-reporting/reducers';
import budgeting from 'features/budgeting/reducers';
import paymentLimits from 'features/pay/reducers/paymentLimits';
import preApprovedTransfers from 'features/pre-approved-transfers/reducers';
import feed from './feed';
import groups from './groups';
import accounts from './accounts';
import user from './user';
import linkBank from './linkBank';
import transactions from './transactions';
import utils from './utils';
import error from './error';
import expenses from './expenses';
import quiz from './quiz';
import rewards from './rewards';
import quests from '../features/quests/reducers';
import reports from './reports';
import switching from './switching';
import recommendations from './recommendations';
import dataProtection from './dataProtection';
import payments from './payments';
import pendingPayments from './pendingPayments';
import thankedPayment from './thankedPayment';
import remindedRequest from './remindedRequest';
import beneficiaries from './beneficiaries';
import sharedBills from './sharedBills';
import eventBus from './eventBus';
import signIn from './signin';
import signInWeb from './signinWeb';
import { network } from './network';
import deviceSettings from './deviceSettings';
import sessionSettings from './sessionSettings';
import balanceHistory from './balanceHistory';
import emmaWallet from './emmaWallet';
import billingHistory from '../features/premium/reducers/billingHistory';
import unpersistedUtils from './unpersistedUtils';
import addressReducer from './address';
import walkthrough from './walkthrough';
import unpersistedTransactions from './unpersistedTransactions';
export default {
    feed,
    groups,
    accounts,
    user,
    linkBank,
    transactions,
    unpersistedTransactions,
    utils,
    error,
    expenses,
    subscriptions,
    quiz,
    rewards,
    quests,
    reports,
    switching,
    recommendations,
    notifications,
    dataProtection,
    payments,
    pendingPayments,
    thankedPayment,
    remindedRequest,
    spaces,
    myLimits,
    beneficiaries,
    sharedBills,
    eventBus,
    unpersistedUtils,
    ...investReducers,
    network,
    signIn,
    signInWeb,
    contacts,
    pots,
    addressReducer,
    stripe,
    balanceHistory,
    emmaWallet,
    billingHistory,
    unpersistedPotsReducer,
    iapSubscriptions,
    deviceSettings,
    sessionSettings,
    analytics,
    promotionsState: promotionsReducer,
    signInNavigation,
    smartRules,
    rentReporting,
    budgeting,
    paymentLimits,
    referrals,
    preApprovedTransfers,
    walkthrough,
};
