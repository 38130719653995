import { Platform } from 'react-native';
import { pick } from 'utils/packages/documentPicker';
import { launchCamera, launchImageLibrary, } from 'utils/packages/imagePicker';
import RNBlobUtil from 'utils/packages/bloblUtil';
import Share from 'utils/packages/rnShare';
export const keyExtractor = (item) => item.id?.toString();
const getFile = async (url, name, opts) => {
    const saveDir = Platform.OS === 'ios'
        ? RNBlobUtil.fs.dirs.DocumentDir
        : RNBlobUtil.fs.dirs.DownloadDir;
    const config = {
        fileCache: true,
        path: `${saveDir}/${name}`,
        ...opts,
        addAndroidDownloads: {
            useDownloadManager: true,
            notification: true,
            mediaScannable: true,
            title: name,
            path: `${saveDir}/${name}`,
            mime: name.endsWith('.pdf') ? 'application/pdf' : 'image/jpeg',
            ...opts?.addAndroidDownloads,
        },
    };
    const response = await RNBlobUtil.config(config).fetch('GET', url);
    return response;
};
export const shareFile = async (url, fileName) => {
    const opts = { addAndroidDownloads: { notification: false } };
    const path = (await getFile(url, fileName, opts)).path();
    const shareOptionsUrl = {
        url: `file://${path}`,
    };
    try {
        await Share.open(shareOptionsUrl);
    }
    catch {
        // noop
    }
    await RNBlobUtil.fs.unlink(path);
};
export const saveFileAndroid = async (url, fileName) => {
    if (Platform.OS !== 'android')
        return;
    const path = `/storage/emulated/0/Download/${fileName}`;
    await getFile(url, fileName, { addAndroidDownloads: { path } });
};
export const openImageLibrary = async (callback) => {
    try {
        const response = await launchImageLibrary({
            mediaType: 'photo',
            selectionLimit: 0,
        });
        if (response.assets && response.assets.length) {
            callback(response.assets);
        }
    }
    catch {
        // noop
    }
};
export const openCamera = async (callback) => {
    try {
        const response = await launchCamera({
            saveToPhotos: false,
            mediaType: 'photo',
        });
        if (response.assets && response.assets.length) {
            callback(response.assets);
        }
    }
    catch {
        // noop
    }
};
export const openDocumentPicker = async (callback) => {
    try {
        const response = await pick({
            mode: 'open',
            allowMultiSelection: true,
            type: ['com.adobe.pdf', 'application/pdf'],
            allowVirtualFiles: true,
            presentationStyle: 'pageSheet',
        });
        if (response.length) {
            if (Platform.OS === 'android') {
                // convert content:// to file://
                const files = await Promise.all(response.map(async (file) => {
                    const tempPath = `${RNBlobUtil.fs.dirs.CacheDir}/${file.name}`;
                    await RNBlobUtil.MediaCollection.copyToInternal(file.uri, tempPath);
                    return { ...file, uri: `file://${tempPath}`, fileName: file.name };
                }));
                callback(files);
            }
            else {
                callback(response.map((file) => ({ ...file, fileName: file.name })));
            }
        }
    }
    catch (e) {
        // noop
    }
};
