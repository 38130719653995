import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Animated, Platform, TextInput as RNTextInput, View } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import { AppView } from 'design-system/AppView';
import Button from 'design-system/Button';
import MoneyInputField from 'design-system/MoneyInputField';
import ShakeContainer from 'design-system/ShakeContainer';
import Spacer from 'design-system/Spacer';
import StatusBar from 'design-system/StatusBar';
import Text from 'design-system/Text';
import { IconArrow } from 'design-system/icons/IconArrow';
import IconArrowTriangle from 'design-system/icons/IconArrowTriangle';
import PopUpMenuModal from 'design-system/PopUpMenuModal.web';
import { hitSlop, rem } from 'design-system/values';
import KeyboardAvoidingView from 'design-system/KeyboardAvoidingView';
import Squircle from 'design-system/Squircle';
import { CardView } from 'design-system/CardView';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import { DEFAULT_MAX_AMOUNT, formatAmountText, getInitialScale, getScale, } from 'utils/formatting';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppSelector } from 'store/hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { blur, focus, useAutoFocusInput } from 'hooks/useAutoFocusInput';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import { selectAccountById, selectDefaultCurrency } from 'reducers/selectors';
import { Sign } from 'utils/types/navigation';
import { isWeb } from '../../../constants';
import PickCurrency from '../components/PickCurrency';
import PositiveNegativeBalanceInput from '../components/PositiveNegativeBalanceInput';
import AddAccountHeader from '../components/AddAccountHeader';
import useDummyAccountLogo from '../hooks/useDummyAccountLogo';
import CurrencyMenuContent from '../components/CurrencyMenuContent';
const strings = {
    next: 'Next',
    changeCurrency: 'Change Currency',
    balance: 'balance',
    account: 'account',
    property: 'property',
    vehicle: 'vehicle',
    asset: 'asset',
    value: 'value',
};
const propertyStringMap = {
    REAL_ESTATE: strings.property,
    VEHICLE: strings.vehicle,
    OTHER: strings.asset,
};
const incomeExpenseStrings = {
    [Sign.Positive]: 'Swap to negative',
    [Sign.Negative]: 'Swap to positive',
};
const getSign = (postedBalance, type) => {
    const isPrePopulatedBalanceNegative = (postedBalance || 0) < 0;
    if (postedBalance === undefined) {
        return type === 'CREDITCARD' || type === 'LOAN' ? Sign.Negative : Sign.Positive;
    }
    return isPrePopulatedBalanceNegative ? Sign.Negative : Sign.Positive;
};
const getFormatAmountTextResult = (nativeBalance, postedBalance, defaultValue) => {
    if (nativeBalance?.postedBalance !== undefined) {
        return formatAmountText(Math.abs(nativeBalance.postedBalance).toString(), null);
    }
    if (postedBalance !== undefined) {
        return formatAmountText(Math.abs(postedBalance).toString(), null);
    }
    if (defaultValue !== undefined) {
        return formatAmountText(Math.abs(defaultValue).toString(), null);
    }
    return {
        amount: 0,
        fixedText: '',
        displayAmount: '0',
        maxLength: DEFAULT_MAX_AMOUNT.toString().length + 3, // 3 is for cents
    };
};
const AddBalanceScreen = ({ navigation, route }) => {
    const styles = useStyles(styleSet);
    const account = useAppSelector((store) => selectAccountById(store, route.params.id));
    const type = route.params.type || account?.type;
    const { nativeBalance, postedBalance } = account || {};
    const { isChangingBalance, onContinue, custom } = route.params;
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTintColor: colors.text.primary,
            ...(isWeb && {
                title: isChangingBalance ? 'Add balance' : 'Current account',
            }),
        });
    }, [colors.text.primary, isChangingBalance]);
    const [state, setState] = useState(() => getFormatAmountTextResult(nativeBalance, postedBalance, custom?.defaultValue));
    const defaultValue = nativeBalance?.postedBalance || postedBalance || custom?.defaultValue;
    const [value, setValue] = useState(defaultValue?.toFixed(2) || '0.00');
    const [rawValue, setRawValue] = useState(defaultValue?.toString() || '0');
    const [sign, setSign] = useState(() => custom?.initialSign ?? getSign(defaultValue, type));
    const [showCurrencyPicker, setShowCurrencyPicker] = useState(false);
    const defaultCurrency = useAppSelector(selectDefaultCurrency);
    const [currency, setCurrency] = useState(custom?.defaultCurrency ?? nativeBalance?.currency ?? defaultCurrency);
    useEffect(() => {
        if (defaultValue && state.amount === 0) {
            setValue(defaultValue?.toFixed(2) || '0.00');
            setRawValue(defaultValue.toString());
            setSign(getSign(defaultValue, type));
            setCurrency(nativeBalance?.currency ?? defaultCurrency);
        }
    }, [defaultValue]);
    let icon = useDummyAccountLogo(type);
    if (custom?.icon)
        icon = custom.icon;
    const paddingBottomStyle = useMarginBottom('paddingBottom');
    const shake = useRef();
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    const amountScale = useRef(new Animated.Value(getInitialScale(DEFAULT_MAX_AMOUNT, (nativeBalance?.postedBalance ?? postedBalance ?? 0).toString()))).current;
    useAutoFocusInput(textInputRef);
    const onOpenCurrencyPicker = useCallback(() => {
        setShowCurrencyPicker(true);
    }, []);
    const hideCurrencySelector = useCallback(() => {
        setShowCurrencyPicker(false);
    }, []);
    const onSelectCurrency = useCallback((value) => {
        setCurrency(value);
    }, []);
    const warnAboutWrongAmount = useCallback(() => {
        if (shake.current) {
            shake.current();
        }
        ReactNativeHapticFeedback.trigger('notificationError');
        focus(textInputRef);
    }, []);
    const onChangeText = useCallback((text) => {
        const values = formatAmountText(text);
        if (values) {
            setState(values);
            Animated.timing(amountScale, {
                toValue: getScale(values.displayAmount),
                duration: 100,
                delay: 200,
                useNativeDriver: true,
            }).start();
        }
        else {
            warnAboutWrongAmount();
        }
    }, [amountScale, warnAboutWrongAmount]);
    const onSubmit = useCallback(() => {
        blur(textInputRef);
        const keyBack = route.params.keyBack || route.key;
        const data = route.params || {};
        const balance = isWeb ? Number(rawValue) : state.amount;
        if (onContinue) {
            onContinue(balance * (sign === Sign.Negative ? -1 : 1), currency);
        }
        else if (isChangingBalance) {
            navigation.navigate('DummyAccountCreated', {
                ...data,
                keyBack,
                currency,
                balance: balance * (sign === Sign.Negative ? -1 : 1),
            });
        }
        else {
            navigation.navigate('DummyAccountSetup', {
                ...data,
                keyBack,
                currency,
                balance: balance * (sign === Sign.Negative ? -1 : 1),
            });
        }
    }, [route.params, route.key, rawValue, state.amount, onContinue, isChangingBalance, sign, currency]);
    const onFlipIncomeExpense = useCallback(() => {
        setSign((currentSign) => {
            if (currentSign === Sign.Negative) {
                return Sign.Positive;
            }
            return Sign.Negative;
        });
    }, []);
    const controls = useMemo(() => (<View style={paddingBottomStyle}>
        {!isWeb && (<>
            <TouchableOpacity hoverOnLight onPress={onFlipIncomeExpense} hitSlop={hitSlop} style={styles.centered}>
              <Text Text-16 OnBrand>
                {custom?.swapText ? custom.swapText[sign] : incomeExpenseStrings[sign]}
              </Text>
            </TouchableOpacity>
            <Spacer h={16}/>
          </>)}
        <View style={styles.btnContainer}>
          {!isChangingBalance && !isWeb && (<>
              <Button hug brandReversed title={currency || ''} style={styles.currencyBtn} onPress={onOpenCurrencyPicker} rightIcon={<IconArrow direction="down" color={colors.text.textOnBrand}/>}/>
              <Spacer w={8}/>
            </>)}
          <Button flex brand title={custom?.button ?? strings.next} onPress={onSubmit}/>
        </View>
      </View>), [
        colors.text.textOnBrand,
        currency,
        custom?.button,
        custom?.swapText,
        isChangingBalance,
        onFlipIncomeExpense,
        onOpenCurrencyPicker,
        onSubmit,
        paddingBottomStyle,
        sign,
        styles.btnContainer,
        styles.centered,
        styles.currencyBtn,
    ]);
    const title = useMemo(() => {
        if (custom?.title)
            return custom.title;
        let firstText = strings.balance;
        let secondText = strings.account;
        if (type === 'REAL_ESTATE' || type === 'VEHICLE' || type === 'OTHER') {
            firstText = strings.value;
            secondText = propertyStringMap[type];
        }
        return `What's the current ${firstText}\nfor this ${secondText}?`;
    }, [custom?.title, type]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const onChangeMaskedText = useCallback((value, raw) => {
        setValue(value);
        if (typeof raw !== 'undefined') {
            setRawValue(raw);
        }
    }, []);
    const closeMenu = useCallback(() => {
        PopUpMenuModal.hide();
    }, []);
    const onOpenCurrencyMenu = useCallback((event) => {
        const target = event.currentTarget || event.target;
        const rect = target.getBoundingClientRect();
        PopUpMenuModal.show(<CurrencyMenuContent onSelect={onSelectCurrency} onClose={closeMenu}/>, {
            fx: rect.left,
            fy: rect.bottom,
            containerStyle: styles.currencyMenuPopup,
        });
    }, [closeMenu, onSelectCurrency, styles.currencyMenuPopup]);
    if (isWeb) {
        return (<AppView type="secondary" isInTab avoidHeader>
        <AddAccountHeader icon={icon}/>
        <CardView withPadding noMarginTop>
          <StatusBar animated barStyle="dark-content"/>
          <View style={styles.inputContainer}>
            <TouchableOpacity onPress={isChangingBalance ? undefined : onOpenCurrencyMenu} onCard hoverOnLight style={styles.buttonCurrency}>
              <Text Text-16 numberOfLines={1}>
                {currency}
              </Text>
              <Spacer w={16}/>
              <View style={styles.dropdownIconContainer}>
                <IconArrowTriangle color={colors.elements.secondary}/>
              </View>
            </TouchableOpacity>
            <PositiveNegativeBalanceInput sign={sign} value={value} smallerTextInput currency={currency} amountPositionRight onSubmitEditing={onSubmit} onChangeText={onChangeMaskedText} ref={textInputRef} maxLength={DEFAULT_MAX_AMOUNT.toString().length + 3}/>
          </View>
          <Spacer h={16}/>
          <TouchableOpacity hoverOnLight onPress={onFlipIncomeExpense} hitSlop={hitSlop} style={styles.end}>
            <Text Text-16 Primary>
              {custom?.swapText ? custom.swapText[sign] : incomeExpenseStrings[sign]}
            </Text>
          </TouchableOpacity>
        </CardView>
        <View pointerEvents="box-none" style={[styles.buttonContainer, paddingHorizontalStyle]}>
          {controls}
        </View>
      </AppView>);
    }
    return (<AppView style={styles.container}>
      <StatusBar animated barStyle="dark-content"/>
      <Squircle height={66} width={66} color={colors.cards.onDark}>
        {icon}
      </Squircle>
      <Spacer h={16}/>
      <Text TextThin-20 Secondary centered>
        {title}
      </Text>
      <ShakeContainer style={styles.amountContainer} registerCallback={(callback) => {
            shake.current = callback;
        }} shakeOnMount={false}>
        <Animated.View style={[styles.amountScalableContainer, { transform: [{ scale: amountScale }] }]}>
          <MoneyInputField color={colors.text.primary} currency={currency}>
            {`${sign}${state.displayAmount}`}
          </MoneyInputField>
        </Animated.View>
        <RNTextInput ref={textInputRef} style={styles.transparentTextInput} value={state.fixedText} allowFontScaling={false} blurOnSubmit={false} // on iOS there is no way to submit, and on android if user submits 0 then we would have an issue
     caretHidden contextMenuHidden enablesReturnKeyAutomatically keyboardType="decimal-pad" maxLength={state.maxLength} onChangeText={onChangeText} onSubmitEditing={onSubmit} testID="input"/>
      </ShakeContainer>
      {Platform.OS === 'android' ? (<View style={[styles.buttonContainer, paddingHorizontalStyle]}>{controls}</View>) : (<KeyboardAvoidingView style={[styles.buttonContainer, paddingHorizontalStyle]} behavior="position" pointerEvents="box-none">
          {controls}
        </KeyboardAvoidingView>)}
      <PickCurrency onSelectedCurrency={onSelectCurrency} onPickCancel={hideCurrencySelector} visible={showCurrencyPicker}/>
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    flex: {
        flex: 1,
    },
    container: isWeb
        ? {
            flex: 1,
            paddingHorizontal: rem(16),
        }
        : {
            flex: 1,
            alignItems: 'center',
            paddingTop: rem(100),
        },
    amountContainer: {
        marginTop: rem(40),
        width: '100%',
        height: rem(120),
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountScalableContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: -1000,
    },
    transparentTextInput: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: 'transparent',
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        justifyContent: 'flex-end',
        marginHorizontal: rem(16),
    },
    centered: {
        alignSelf: 'center',
    },
    end: {
        alignSelf: 'flex-end',
    },
    btnContainer: {
        flexDirection: 'row',
    },
    currencyBtn: {
        paddingHorizontal: rem(24),
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
    },
    buttonCurrency: {
        maxWidth: rem(200),
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: rem(1),
        borderRadius: rem(8),
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
        borderColor: colors.borders.divider,
        backgroundColor: colors.background.dark,
    },
    dropdownIconContainer: {
        width: rem(7),
    },
    currencyInput: {
        fontSize: rem(16),
        fontFamily: 'Nunito-Bold',
        textAlign: 'right',
        margin: 0,
        width: rem(120),
        color: colors.text.primary,
        backgroundColor: colors.background.dark,
    },
    currencyMenuPopup: {
        marginTop: 8,
        backgroundColor: colors.background.light,
        ...colors.shadows.webFloatingCard,
    },
}));
export default AddBalanceScreen;
