import * as React from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
import useColors from 'hooks/useColors';
export const IconArrowLeft = ({ ...props }) => {
    const id = React.useId();
    const colors = useColors();
    return (<IconWithGradient id={id} defaultGradientKey="green" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
      <Path fill={colors.elements.secondary} d="m7.825 13 5.6 5.6L12 20l-8-8 8-8 1.425 1.4-5.6 5.6H20v2H7.825Z"/>
    </IconWithGradient>);
};
