import React, { useCallback, useRef, useState } from 'react';
import { View } from 'react-native';
import Dropzone from 'react-dropzone';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import useStyles from 'hooks/useStyles';
import Modal from 'utils/packages/Modal';
import FastImage from 'utils/packages/rnFastImage';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import Text from './Text';
import { rem } from './values';
import { CardView } from './CardView';
import IconClose from './icons/IconClose';
import { IconUploadCloud } from './icons/IconUploadCloud';
import { IconPdf } from './icons';
const uuidV4 = require('uuid/v4');
const EachFile = ({ file, onDelete }) => {
    const styles = useStyles(styleSet);
    const isPdf = file.data.path.endsWith('.pdf');
    return (<View style={styles.eachFileContainer}>
      {isPdf ? (<View style={[styles.addBox, styles.pdfIcon]}>
          <IconPdf />
        </View>) : (<FastImage resizeMode={'cover'} source={{ uri: file.selectedImg }} style={styles.addBox}/>)}
      <Spacer w={16}/>
      <View style={styles.textContainer}>
        <Text Text-16 numberOfLines={1}>
          {file.data.name}
        </Text>
        <Spacer h={4}/>
        <Text TextThin-14 numberOfLines={1} Secondary>
          {file.data.size}
        </Text>
      </View>
      <View>
        <TouchableOpacity hoverOnDark onPress={onDelete} style={styles.deleteIcon}>
          <IconClose />
        </TouchableOpacity>
      </View>
    </View>);
};
const dropZoneAcceptedFiles = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'application/pdf': ['.pdf'],
};
const UploadReceiptModalContent = ({ handleSave }) => {
    const styles = useStyles(styleSet);
    const dropzoneRef = useRef(null);
    const [files, setFiles] = useState([]);
    const handleClose = useCallback(() => {
        Modal.hide();
    }, []);
    const onSave = useCallback(() => {
        if (files.length) {
            handleSave(files);
        }
        Modal.hide();
    }, [files, handleSave]);
    const openPicker = useCallback(() => {
        dropzoneRef.current?.open();
    }, []);
    const handleDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length) {
            const processedFiles = acceptedFiles.map((file) => ({
                data: file,
                id: uuidV4(),
                // @ts-ignore
                selectedImg: URL.createObjectURL(file),
            }));
            setFiles((oldFiles) => [...oldFiles, ...processedFiles]);
        }
    }, []);
    const handleRemove = useCallback((id) => () => {
        setFiles((files) => files.filter((file) => file.id !== id));
    }, []);
    return (<View style={styles.container}>
      <Spacer h={16}/>
      <View style={styles.line}/>
      <Dropzone ref={dropzoneRef} maxSize={52428800} onDrop={handleDrop} accept={dropZoneAcceptedFiles}>
        {({ getRootProps, getInputProps, isDragActive }) => (<div {...getRootProps()}>
            <input {...getInputProps()}/>
            <View style={[styles.dndContainer, isDragActive && styles.activeDnd]}>
              <IconUploadCloud colorKey="primary"/>
              <Spacer h={24}/>
              <Text Text-16>Choose a file or drag & drop it here</Text>
              <Spacer h={8}/>
              <Text TextThin-14>JPEG, PNG, and PDF formats, up to 50MB</Text>
              <Spacer h={24}/>
              <Button hug square brandBorder title="Browse" containerStyle={styles.browseBtn} onPress={openPicker}/>
            </View>
          </div>)}
      </Dropzone>
      {!!files.length && (<CardView style={styles.filesContainer} noMargin>
          {files.map((file, index) => (<EachFile file={file} key={String(index)} onDelete={handleRemove(file.id)}/>))}
        </CardView>)}
      <View style={styles.btnContainer}>
        <Button brandReversed title="Cancel" flex onPress={handleClose}/>
        <Spacer w={16}/>
        <Button brand title="Save" flex onPress={onSave}/>
      </View>
    </View>);
};
export const UploadReceiptModal = {
    show: (handleSave) => {
        Modal.show(<UploadReceiptModalContent handleSave={handleSave}/>, {
            title: 'Upload receipt',
        });
    },
    hide: () => {
        Modal.hide();
    },
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
    },
    btnContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    pdfIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.background.brandLight,
        borderColor: colors.borders.logoStroke,
        borderWidth: 1,
    },
    line: {
        height: 1,
        backgroundColor: colors.borders.divider,
    },
    dndContainer: {
        height: rem(240),
        borderWidth: rem(1),
        alignItems: 'center',
        borderStyle: 'dashed',
        borderRadius: rem(16),
        marginVertical: rem(16),
        justifyContent: 'center',
        borderColor: colors.borders.divider,
    },
    browseBtn: {
        paddingHorizontal: rem(56),
    },
    activeDnd: {
        opacity: 0.5,
    },
    eachFileContainer: {
        flexDirection: 'row',
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
    },
    filesContainer: {
        marginBottom: rem(16),
        paddingVertical: rem(8),
        backgroundColor: colors.background.dark,
    },
    addBox: {
        width: rem(64),
        height: rem(64),
        borderRadius: rem(12),
        backgroundColor: colors.cards.transparentOnDark,
    },
    textContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    deleteIcon: {
        padding: rem(8),
        borderRadius: rem(4),
    },
}));
export default UploadReceiptModal;
