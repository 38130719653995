import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { createSelector } from 'reselect';
import { selectAddress, postUserAddress, getUserAddresses, findAddress as findAddressAction, } from 'actions';
import useFetchOld, { muteErrorAndResult } from 'hooks/useFetch';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsGBUser, selectPrimaryAddressForGB } from 'reducers/selectors';
/**
 * userAddresses has address with KYC status already sorted
 */
const userAddressesSelector = (store) => store.addressReducer.userAddresses;
export const selectUserAddressById = createSelector([userAddressesSelector, (_, id) => id], (addresses, id) => addresses.find((address) => address.id === id));
const selectedAddressSelector = (store) => store.addressReducer.selectedAddress;
export const useUserAddress = (skipApiCallOnMount) => {
    const dispatch = useAppDispatch();
    const isGBUser = useAppSelector(selectIsGBUser);
    const [, fetchUserAddressesError, areUserAddressesFetching, fetch] = useFetchOld();
    const [, , isUserAddressUpdating, updateUserAddressFetch] = useFetchOld(muteErrorAndResult);
    const countryCode = useAppSelector((state) => state.user.user.guessedHomeCountry);
    const userAddresses = useAppSelector(userAddressesSelector);
    const selectedAddress = useAppSelector(selectedAddressSelector);
    const primaryAddress = useMemo(() => !isEmpty(selectedAddress) && selectedAddress?.isPrimary
        ? selectedAddress
        : userAddresses.find((a) => a.isPrimary), [selectedAddress, userAddresses]);
    const displayAddressGB = useAppSelector(selectPrimaryAddressForGB);
    useEffect(() => {
        if (!skipApiCallOnMount) {
            fetch(getUserAddresses());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onUpdateUserAddress = useCallback(async (address) => {
        const addressBody = {
            ...address,
            isPrimary: true,
            countryIsoCode: countryCode,
        };
        const response = await updateUserAddressFetch(postUserAddress(addressBody));
        if (response.address) {
            dispatch(selectAddress(response.address));
        }
    }, [countryCode, updateUserAddressFetch]);
    return {
        userAddresses,
        onUpdateUserAddress,
        isUserAddressUpdating,
        fetchUserAddressesError,
        areUserAddressesFetching,
        primaryAddress: isGBUser ? displayAddressGB : primaryAddress,
    };
};
const addressesSelector = (store) => store.addressReducer.addressesList || [];
export const useFindMyAddress = (postcode) => {
    const [, , isFetching, fetch] = useFetchOld(muteErrorAndResult);
    const addressList = useAppSelector(addressesSelector);
    useEffect(() => {
        fetch(findAddressAction(postcode));
    }, [postcode]);
    const sortedAddresses = useMemo(() => {
        if (addressList) {
            const result = [...addressList];
            result.sort((a, b) => {
                if (a.subBuildingName && !b.subBuildingName) {
                    return -1;
                }
                if (!a.subBuildingName && b.subBuildingName) {
                    return 1;
                }
                if (a.subBuildingName && b.subBuildingName) {
                    return a.subBuildingName.localeCompare(b.subBuildingName);
                }
                const aString = `${a.townOrCity}-${a.county}-${a.streetName}-${a.buildingNumber}`;
                const bString = `${b.townOrCity}-${b.county}-${b.streetName}-${b.buildingNumber}`;
                return aString.localeCompare(bString);
            });
            return result;
        }
        return [];
    }, [addressList]);
    return {
        isFetching,
        sortedAddresses,
    };
};
