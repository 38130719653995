import React, { memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { rem } from 'design-system/values';
import Section from 'design-system/Section';
import { getRentReporting } from 'features/rent-reporting/actions';
import { selectHasRentReporting } from 'features/rent-reporting/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import RentReportingCard from './RentReportingCard';
const strings = {
    title: 'Improve your credit history',
};
const RentReportingSection = ({ onLayout }) => {
    // const data: RentReportingData = {
    //   amount: 100,
    //   currency: 'GBP',
    //   latestTransaction: {},
    //   tenancyAddress: {
    //     buildingNumber: '100',
    //     streetName: 'Test Road',
    //     postcode: 'LA15EE',
    //   },
    //   isActive: true,
    // };
    const data = useAppSelector((store) => store.rentReporting.data);
    const hasRentReporting = useAppSelector(selectHasRentReporting);
    const dispatch = useAppDispatch();
    useFocusEffect(useCallback(() => {
        if (hasRentReporting && !data) {
            dispatch(getRentReporting({ isActive: true, withRentTransactions: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasRentReporting]));
    const layout = useCallback((event) => {
        onLayout?.('rentReporting', event);
    }, [onLayout]);
    if (!hasRentReporting || !data)
        return null;
    return (<Section title={strings.title} style={styles.rentReportingBorrowTab} onLayout={layout}>
      <RentReportingCard data={data?.current}/>
    </Section>);
};
const styles = StyleSheet.create({
    rentReportingBorrowTab: {
        marginBottom: rem(16),
    },
});
export default memo(RentReportingSection);
