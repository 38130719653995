import emoji from 'node-emoji';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState, } from 'react';
import { InteractionManager, LayoutAnimation, StyleSheet, View, } from 'react-native';
import Animated, { Extrapolate, interpolate, useAnimatedStyle, useSharedValue, } from 'react-native-reanimated';
import { Indicator } from 'design-system/Indicator';
import { AppView } from 'design-system/AppView';
import ScrollView from 'design-system/ScrollView';
import StatusBar from 'design-system/StatusBar';
import Text from 'design-system/Text';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import { clearSharedBillInfoCache, getCategories, getLinkedTransaction, getMerchantStats, getOriginalSplit, getTransaction, getTransactionReceipts, resetTransaction, getReward, } from 'actions';
import { getSubscriptionsById } from 'features/subscriptions/actions';
import { muteAll, useFetchOld } from 'hooks';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { selectAccountById } from 'reducers/selectors';
import { useAppDispatch, useAppSelector, useAppStore } from 'store/hooks';
import Amplitude from 'utils/amplitude';
import Alert from 'utils/packages/Alert';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import TransactionHeader, { useTransactionHeaderHeight, useTransactionHeaderUpgradeButtons, } from '../components/detail/TransactionHeader';
import { isWeb } from '../../../constants';
import TransactionCategorySection from '../components/detail/TransactionCategorySection';
import TransactionDateButton from '../components/detail/TransactionDateButton';
import TransactionDetailContent from '../components/detail/TransactionDetailContent';
import TransactionFooter from '../components/detail/TransactionFooter';
import TransactionPendingSection from '../components/detail/TransactionPendingSection';
import { makeSelectTransactionById } from '../selectors';
import TransactionAutoCreated from '../components/detail/TransactionAutoCreated';
const shadowOpacityOutputRange = [0, 0, 1];
const TransactionScreen = ({ navigation, route }) => {
    const { skipGetSubscription, id } = route.params;
    const colors = useColors();
    const contentContainerStyles = useMarginBottom('paddingBottom');
    const [state, setState] = useState({
        paymentActivities: undefined,
        reward: null,
    });
    const dispatch = useAppDispatch();
    const [, , , fetch] = useFetchOld(muteAll);
    const memoSelectTransactionById = useMemo(makeSelectTransactionById, []);
    const transaction = useAppSelector((store) => memoSelectTransactionById(store, id));
    const store = useAppStore();
    useEffect(() => {
        const id = transaction?.id || route.params.id;
        const isCompact = transaction && 'isCompact' in transaction;
        const wasCompact = isCompact;
        const getRelatedAndSplit = (transaction) => {
            if ('relatedTransactionId' in transaction &&
                transaction.relatedTransactionId) {
                dispatch(getLinkedTransaction(transaction.relatedTransactionId));
            }
            if ('splitFromId' in transaction && transaction.splitFromId) {
                dispatch(getOriginalSplit(transaction.splitFromId));
            }
        };
        if (!wasCompact && transaction) {
            getRelatedAndSplit(transaction);
        }
        Amplitude.logEvent('Transaction.Viewed');
        InteractionManager.runAfterInteractions(() => {
            dispatch(resetTransaction());
            fetch(getTransaction(id)).then(async (response) => {
                if (response) {
                    const { merchantId, merchant, subscriptionId, spendingGroupInfo, paymentActivities, } = response;
                    if (!response.isPending) {
                        if (wasCompact) {
                            getRelatedAndSplit(response);
                        }
                        if (merchant?.rewardId !== undefined &&
                            merchant?.rewardId !== null) {
                            await fetch(getReward(merchant.rewardId)).then((response) => {
                                if (response) {
                                    setState((s) => ({ ...s, reward: response }));
                                }
                            });
                        }
                        dispatch(getTransactionReceipts(id));
                        if (merchantId)
                            dispatch(getMerchantStats(merchantId));
                        if (subscriptionId && !skipGetSubscription) {
                            dispatch(getSubscriptionsById(subscriptionId));
                        }
                        const updatedState = {};
                        if (!spendingGroupInfo) {
                            // clear shared bill info cache for this transaction
                            dispatch(clearSharedBillInfoCache(id));
                        }
                        if (paymentActivities) {
                            updatedState.paymentActivities = paymentActivities;
                        }
                        if (Object.keys(updatedState).length) {
                            setState((s) => ({ ...s, ...updatedState }));
                        }
                    }
                }
                return response;
            });
            const { categories } = store.getState().user;
            if (categories && !categories.length)
                dispatch(getCategories());
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onChangeDate = useCallback(() => {
        if (!transaction)
            return;
        if (transaction.isPending) {
            Alert.alert(emoji.emojify('Hey, slow down :face_with_rolling_eyes:'), "Pending transactions like to change, so we can't edit them yet. Don't worry, they don't count in Analytics.");
        }
        else {
            navigation.navigate('EditDate', {
                id: transaction.id,
            });
        }
    }, [transaction]);
    const onRenameTransaction = useCallback(() => {
        if (!transaction)
            return;
        if (transaction.isPending) {
            Alert.alert(emoji.emojify('Hey, slow down :face_with_rolling_eyes:'), "Pending transactions like to change, so we can't edit them yet. Don't worry, they don't count in Analytics.");
        }
        else {
            navigation.navigate('RenameTransaction', {
                id: transaction.id,
            });
        }
    }, [transaction]);
    const account = useAppSelector((store) => selectAccountById(store, transaction?.accountId));
    // const openReward = (data) => {
    //   dispatch(setSelectedReward(data));
    //   navigation.navigate('Reward', { data });
    //   Amplitude.logEvent('Open Reward', { source: 'transaction_screen', data });
    // };
    const onRemovePaymentActivity = useCallback((paymentRequestId) => {
        if (paymentRequestId) {
            LayoutAnimation.configureNext({
                duration: 200,
                update: {
                    type: LayoutAnimation.Types.easeInEaseOut,
                    property: LayoutAnimation.Properties.opacity,
                    delay: 200,
                },
                delete: {
                    type: LayoutAnimation.Types.easeInEaseOut,
                    property: LayoutAnimation.Properties.opacity,
                    delay: 100,
                },
            });
            setState((state) => {
                if (state.paymentActivities) {
                    return {
                        ...state,
                        paymentActivities: state.paymentActivities.filter((paymentActivity) => paymentActivity.paymentRequestId !== paymentRequestId),
                    };
                }
                // the "else" case is too rare to hadle. It only appears if request for transaction comes back after request for payment request invalidation
                return {
                    ...state,
                };
            });
        }
        else {
            LayoutAnimation.configureNext({
                duration: 200,
                update: {
                    type: LayoutAnimation.Types.easeInEaseOut,
                    property: LayoutAnimation.Properties.opacity,
                    delay: 200,
                },
                delete: {
                    type: LayoutAnimation.Types.easeInEaseOut,
                    property: LayoutAnimation.Properties.opacity,
                    delay: 100,
                },
            });
            setState((s) => ({ ...s, paymentActivities: undefined }));
        }
    }, []);
    const scrollY = useSharedValue(0);
    const { showFirstUpgradeButton, showRenameUpgradeButton, useCustomName, onPressUpgrade, } = useTransactionHeaderUpgradeButtons(transaction);
    const [transactionHeaderHeightExpanded, transactionHeaderHeightFinal, scrollOffsetHeight, headerScrollOffset,] = useTransactionHeaderHeight(transaction, showRenameUpgradeButton, showFirstUpgradeButton);
    const animationInputRange = useMemo(() => [0, scrollOffsetHeight, scrollOffsetHeight + 1], [scrollOffsetHeight]);
    const bgColorOutputRange = useMemo(() => [
        colors.background.dark,
        colors.background.dark,
        colors.cards.onDark,
    ], [colors.background.dark, colors.cards.onDark]);
    const translateStyles = useAnimatedStyle(() => ({
        transform: [
            {
                translateY: interpolate(scrollY.value, [0, headerScrollOffset, headerScrollOffset + 1], [0, -headerScrollOffset, -headerScrollOffset], Extrapolate.CLAMP),
            },
        ],
    }), [headerScrollOffset]);
    useLayoutEffect(() => {
        const nameToDisplay = useCustomName
            ? transaction?.customName || transaction?.counterpartName
            : transaction?.counterpartName;
        navigation.setOptions({
            headerTitle: () => (<Animated.View style={translateStyles}>
          <TransactionDateButton transaction={transaction} onPressDate={onChangeDate}/>
        </Animated.View>),
            headerRight: () => transaction ? (<TouchableOpacity hoverOnDark onCard onPress={onRenameTransaction} style={styles.headerRightBtn}>
            <Text Text-14>Rename</Text>
          </TouchableOpacity>) : null,
            title: nameToDisplay,
            headerTransparent: true,
            headerTintColor: colors.text.primary,
        });
    }, [
        colors.text.primary,
        onChangeDate,
        onRenameTransaction,
        transaction,
        translateStyles,
        useCustomName,
    ]);
    return (<AppView withFrame={false}>
      <StatusBar barStyle="dark-content" animated/>
      {transaction ? (<>
          <ScrollView secondary finalHeaderHeight={transactionHeaderHeightFinal} expandedHeaderHeight={transactionHeaderHeightExpanded} animationInputRange={animationInputRange} shadowOpacityOutputRange={shadowOpacityOutputRange} bgColorOutputRange={bgColorOutputRange} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS} contentContainerStyle={contentContainerStyles} scrollY={scrollY} isInTab={isWeb}>
            <TransactionAutoCreated transaction={transaction} isPill/>
            <TransactionCategorySection transaction={transaction}/>
            {transaction?.isPending ? (<TransactionPendingSection />) : (<TransactionDetailContent transaction={transaction} paymentActivities={state.paymentActivities} onRemovePaymentActivity={onRemovePaymentActivity} reward={state.reward}/>)}
            <TransactionFooter realCounterpartName={'realCounterpartName' in transaction
                ? transaction.realCounterpartName
                : null} account={account}/>
          </ScrollView>
          <TransactionHeader scrollY={scrollY} headerScrollOffset={headerScrollOffset} transaction={transaction} showRenameUpgradeButton={showRenameUpgradeButton} showFirstUpgradeButton={!!showFirstUpgradeButton} useCustomName={useCustomName} onPressUpgrade={onPressUpgrade}/>
        </>) : (<View style={styles.indicatorContainer}>
          <Indicator dark/>
        </View>)}
    </AppView>);
};
const styles = StyleSheet.create({
    headerRightBtn: {
        ...(isWeb && {
            marginRight: rem(16),
            paddingVertical: rem(8),
            paddingHorizontal: rem(16),
        }),
    },
    indicatorContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default TransactionScreen;
