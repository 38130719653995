import Amplitude from 'utils/amplitude';
import { setScrambledData } from 'actions';
import { useCallback } from 'react';
import { logQuest } from 'features/quests/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
export const selectIsScrambled = (store) => store.utils.scrambled;
export const useScrambleMode = () => {
    const dispatch = useAppDispatch();
    const scrambled = useAppSelector(selectIsScrambled);
    const isLoggedIn = useAppSelector((store) => store.user.isLoggedIn);
    const setScrambled = useCallback((scrambled) => {
        if (!isLoggedIn)
            return;
        global.scrumble = scrambled;
        dispatch(setScrambledData(scrambled));
        if (scrambled) {
            Amplitude.logEvent('Settings.DataScrambled');
            dispatch(logQuest('SCRAMBLE'));
        }
    }, [isLoggedIn]);
    return {
        setScrambled,
        scrambled,
    };
};
