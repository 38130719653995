import { CALL_API } from 'middleware/api';
import * as types from '../features/smart-rules/actions';
export const getFilters = () => ({
    [CALL_API]: {
        endpoint: '/transaction-filters/',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_FILTERS_REQUEST,
            types.GET_FILTERS_SUCCESS,
            types.GET_FILTERS_FAILURE,
        ],
    },
});
export const createFilter = (body) => ({
    [CALL_API]: {
        endpoint: '/transaction-filters/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.CREATE_FILTER_REQUEST,
            types.CREATE_FILTER_SUCCESS,
            types.CREATE_FILTER_FAILURE,
        ],
    },
});
export const getFilterPreview = (parameters, page) => {
    const queryString = Object.keys(parameters)
        .filter((key) => {
        if (parameters[key])
            return true;
        return false;
    })
        .map((key) => {
        if (parameters[key])
            return `${key}=${parameters[key]}`;
        return null;
    })
        .concat(page ? [`page=${page}`, `perPage=20`] : [`page=1`, `perPage=1`])
        .join('&');
    return {
        [CALL_API]: {
            endpoint: `/transaction-filters/transactions?${queryString}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            types: [
                types.GET_FILTER_PREVIEW_REQUEST,
                types.GET_FILTER_PREVIEW_SUCCESS,
                types.GET_FILTER_PREVIEW_FAILURE,
            ],
        },
    };
};
export const editFilter = (id, body) => ({
    [CALL_API]: {
        endpoint: `/transaction-filters/${id}`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.EDIT_FILTER_REQUEST,
            types.EDIT_FILTER_SUCCESS,
            types.EDIT_FILTER_FAILURE,
        ],
    },
});
export const getSmartRules = () => ({
    [CALL_API]: {
        endpoint: '/automation-rules/',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_SMART_RULES_REQUEST,
            types.GET_SMART_RULES_SUCCESS,
            types.GET_SMART_RULES_FAILURE,
        ],
    },
});
export const createSmartRule = (body) => ({
    [CALL_API]: {
        endpoint: '/automation-rules/',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.CREATE_SMART_RULE_REQUEST,
            types.CREATE_SMART_RULE_SUCCESS,
            types.CREATE_SMART_RULE_FAILURE,
        ],
    },
});
export const editSmartRule = (id, body) => ({
    [CALL_API]: {
        endpoint: `/automation-rules/${id}`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.EDIT_SMART_RULE_REQUEST,
            types.EDIT_SMART_RULE_SUCCESS,
            types.EDIT_SMART_RULE_FAILURE,
        ],
    },
});
export const deleteSmartRule = (id) => ({
    [CALL_API]: {
        endpoint: `/automation-rules/${id}`,
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        extra: id,
        types: [
            types.DELETE_SMART_RULE_REQUEST,
            types.DELETE_SMART_RULE_SUCCESS,
            types.DELETE_SMART_RULE_FAILURE,
        ],
    },
});
export const getSmartRuleCreatedTransactions = (id, page = 1) => ({
    [CALL_API]: {
        endpoint: `/automation-rules/${id}/transactions?page=${page}&perPage=20`,
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.GET_SMART_RULE_CREATED_TRANSACTIONS_REQUEST,
            types.GET_SMART_RULE_CREATED_TRANSACTIONS_SUCCESS,
            types.GET_SMART_RULE_CREATED_TRANSACTIONS_FAILURE,
        ],
    },
});
