import * as React from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from './Icon';
export const IconBell = ({ alt, ...props }) => {
    const id = React.useId();
    return (<IconWithGradient id={id} width={25} height={24} viewBox="0 0 25 24" {...props} defaultGradientKey="turquoise">
      {alt ? (<>
          <Path strokeWidth="2" fillRule="evenodd" clipRule="evenodd" strokeLinecap="round" strokeLinejoin="round" stroke={`url(#gradient_${id})`} d="M11.65 17.2629C16.7253 17.2629 19.0732 16.6118 19.3 13.9985C19.3 11.3869 17.663 11.5549 17.663 8.3506C17.663 5.84773 15.2907 3 11.65 3C8.00929 3 5.63696 5.84773 5.63696 8.3506C5.63696 11.5549 4 11.3869 4 13.9985C4.22766 16.6217 6.5756 17.2629 11.65 17.2629Z"/>
          <Path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke={`url(#gradient_${id})`} d="M13.8004 19.9714C12.5726 21.3347 10.6574 21.3508 9.41797 19.9714"/>
        </>) : (<Path fillRule="evenodd" clipRule="evenodd" d="M19.2071 8.79633C19.2071 10.0523 19.539 10.7925 20.2695 11.6456C20.8231 12.2741 21 13.0808 21 13.956C21 14.8302 20.7128 15.6601 20.1373 16.3339C19.384 17.1417 18.3215 17.6573 17.2372 17.747C15.6659 17.8809 14.0937 17.9937 12.5005 17.9937C10.9063 17.9937 9.33505 17.9263 7.76375 17.747C6.67846 17.6573 5.61602 17.1417 4.86367 16.3339C4.28822 15.6601 4 14.8302 4 13.956C4 13.0808 4.1779 12.2741 4.73049 11.6456C5.48384 10.7925 5.79392 10.0523 5.79392 8.79633V8.3703C5.79392 6.68834 6.21333 5.58852 7.077 4.51186C8.36106 2.9417 10.4194 2 12.4558 2H12.5452C14.6254 2 16.7502 2.98702 18.0125 4.62466C18.8314 5.67916 19.2071 6.73265 19.2071 8.3703V8.79633ZM9.57367 20.0608C9.57367 19.5573 10.0358 19.3266 10.4632 19.2279C10.9631 19.1222 14.0093 19.1222 14.5092 19.2279C14.9366 19.3266 15.3987 19.5573 15.3987 20.0608C15.3738 20.5402 15.0926 20.9653 14.704 21.2352C14.2001 21.628 13.6088 21.8767 12.9906 21.9664C12.6487 22.0107 12.3128 22.0117 11.9828 21.9664C11.3636 21.8767 10.7723 21.628 10.2694 21.2342C9.87978 20.9653 9.59852 20.5402 9.57367 20.0608Z" fill={`url(#gradient_${id})`}/>)}
    </IconWithGradient>);
};
