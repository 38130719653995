import React from 'react';
import { View } from 'react-native';
import { rem } from 'design-system';
import { IconSmartRule } from 'design-system/icons';
import Text from 'design-system/Text';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
const TransactionAutoCreated = ({ transaction, isPill }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    if (!(transaction.smartRuleInfo && 'smartRuleId' in transaction.smartRuleInfo)) {
        return null;
    }
    const size = isPill ? 20 : 16;
    return (<View style={[styles.container, isPill && styles.pill]}>
      <IconSmartRule colorKey="positive" size={size}/>
      <Text TextThin-14 color={colors.status.positive}>
        {isPill ? 'Created by a Smart rule' : 'Auto created'}
      </Text>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: rem(8),
    },
    pill: {
        alignSelf: 'center',
        borderRadius: rem(42),
        backgroundColor: colors.elements.positiveLight,
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
        marginBottom: rem(24),
    },
}));
export default TransactionAutoCreated;
