import React, { memo, useEffect, useState } from 'react';
import Animated, { runOnJS, useAnimatedStyle, useDerivedValue, withTiming, } from 'react-native-reanimated';
import { StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
import IconCircle from 'design-system/icons/IconCircle';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import useColors from 'hooks/useColors';
import { calculateAndFormatPercentage } from 'utils/formatting';
import AnalyticsRow from './AnalyticsRow';
import { ChartType } from '../../types';
const RowCategory = ({ category, totalSpending, chartTypeToShow, onPress, onPressUpgrade, onLayout, selectable, categories, selectedCategoryId, customCategoryColorsMap, blurValue, }) => {
    const colors = useColors();
    const press = () => {
        onPress({ type: 'category', data: category });
    };
    const isSelectedSharedValue = useDerivedValue(() => selectedCategoryId?.value === category.id, [selectedCategoryId, category]);
    const [isSelected, setIsSelected] = useState(selectedCategoryId?.value === category.id);
    useDerivedValue(() => {
        runOnJS(setIsSelected)(selectedCategoryId?.value === category.id);
    }, [selectedCategoryId]);
    // These views can be recyled, so make sure to reset
    useEffect(() => {
        setIsSelected(selectedCategoryId?.value === category.id);
    }, [category, selectedCategoryId?.value]);
    const totalAmount = category.id === 'internal' ? 0 : category.total;
    const percentage = calculateAndFormatPercentage(Math.abs(totalAmount), totalSpending || 0);
    const categoryColor = category
        ? categories[category.id]?.color ||
            customCategoryColorsMap[category.color]?.[0] ||
            category.color
        : undefined;
    const animatedStyle = useAnimatedStyle(() => ({
        opacity: withTiming(isSelectedSharedValue.value ? 1 : 0),
    }), [isSelectedSharedValue]);
    return (<AnalyticsRow onLayout={onLayout} displayName={category.displayName} total={totalAmount} percentage={chartTypeToShow === ChartType.pie && totalAmount < 0 ? percentage : ''} count={category.transactionsCount} currency={category.currency} onPress={press} onPressUpgrade={onPressUpgrade} icon={<IconCircle width={44} height={44}>
          {selectable ? (<>
              <Animated.View style={[
                    styles.animatedBackground,
                    {
                        backgroundColor: categoryColor || colors.gradients.darkPurple.colors[0],
                    },
                    animatedStyle,
                ]}/>
              <IconTransactionCategory category={category} color={isSelected
                    ? colors.background.dark
                    : colors.text.tertiary} emojiBackgroundColor={colors.background.fullTransparent}/>
            </>) : (<IconTransactionCategory category={category}/>)}
        </IconCircle>} blurValue={blurValue}/>);
};
const styles = StyleSheet.create({
    animatedBackground: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        position: 'absolute',
        borderRadius: rem(44),
    },
});
export default memo(RowCategory);
