import React, { useMemo } from 'react';
import { StyleSheet, View, } from 'react-native';
import TouchableBounceCard from 'utils/packages/TouchableBounceCard';
import useColors from 'hooks/useColors';
import { CardView } from './CardView';
import Spacer from './Spacer';
import IconTooltip from './icons/IconTooltip';
const InfoCard = ({ children, icon, backgroundColor, style, onPress, onLight, ...props }) => {
    const colors = useColors();
    const bg = backgroundColor || (onLight ? colors.cards.onLight : colors.cards.onDark);
    const containerStyles = useMemo(() => [styles.container, { backgroundColor: bg }, style], [style, bg]);
    const content = (<>
      {icon || <IconTooltip colorKey="secondary"/>}
      <Spacer w={8}/>
      <View style={styles.flex}>{children}</View>
    </>);
    const commonProps = {
        withPadding: true,
        style: containerStyles,
        noMarginTop: true,
        noMarginBottom: true,
        ...props,
    };
    return onPress ? (<TouchableBounceCard {...commonProps} onPress={onPress}>
      {content}
    </TouchableBounceCard>) : (<CardView {...commonProps}>{content}</CardView>);
};
export default InfoCard;
const styles = StyleSheet.create({
    flex: {
        flex: 1,
    },
    container: {
        alignItems: 'center',
        flexDirection: 'row',
    },
});
