import React from 'react';
import { View } from 'react-native';
import useHeaderHeight from 'hooks/useHeaderHeight';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import Text from './Text';
import IconEmptyPlaceholder from './icons/IconEmptyPlaceholder';
import { rem } from './values';
const ListEmptyView = ({ title, subTitle, alt, happy, children, style, compact, onCard, subTitlePadding = true, }) => {
    const styles = useStyles(styleSet);
    const headerHeight = useHeaderHeight();
    return (<View style={[
            styles.container,
            { marginBottom: compact ? 0 : headerHeight },
            onCard && styles.onCard,
            style,
        ]}>
      <IconEmptyPlaceholder style={styles.icon} alt={alt} scale={compact ? 0.7 : 1} happy={happy}/>
      {Boolean(title) && (<Text Text-16 OffBlack style={styles.title} centered>
          {title}
        </Text>)}
      {Boolean(subTitle) && (<Text TextThin-14 Gray centered style={subTitlePadding && styles.subTitle}>
          {subTitle}
        </Text>)}
      {children && <View style={styles.childContainer}>{children}</View>}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: rem(16),
    },
    onCard: {
        borderRadius: rem(16),
        paddingVertical: rem(24),
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
    },
    childContainer: {
        marginTop: rem(16),
        alignSelf: 'stretch',
    },
    title: {
        marginBottom: rem(4),
    },
    subTitle: {
        paddingHorizontal: rem(8),
    },
    icon: {
        marginBottom: rem(16),
    },
}));
export default ListEmptyView;
