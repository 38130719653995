import React from 'react';
import Banner from 'design-system/Banner';
import IconPlus from 'design-system/icons/IconPlus';
import { rem } from 'design-system/values';
import IconMerchant from 'design-system/icons/IconMerchant';
import Spacer from 'design-system/Spacer';
import useColors from 'hooks/useColors';
const strings = {
    title: 'Merchant budgets',
    description: 'Get started tracking how much you spend at your favourite merchants and receive alerts when you go over your budgets',
    button: 'Add merchant budget',
};
const MerchantBudgetEmptyCard = ({ onPress }) => {
    const colors = useColors();
    return (<>
      <Spacer h={rem(16)}/>
      <Banner title={strings.title} description={strings.description} buttonTitle={strings.button} onPress={onPress} icon={<IconMerchant />} buttonLeftIcon={<IconPlus width={rem(10.6)} height={rem(10.6)} color={colors.text.textOnBrand}/>}/>
    </>);
};
export default MerchantBudgetEmptyCard;
