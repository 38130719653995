export const FETCH_COMMITTED_FOR_CURRENT_PAYDAY_RANGE = 'FETCH_COMMITTED_FOR_CURRENT_PAYDAY_RANGE';
export const COMMITTED_REQUEST = 'COMMITTED_REQUEST';
export const COMMITTED_SUCCESS = 'COMMITTED_SUCCESS';
export const COMMITTED_FAILURE = 'COMMITTED_FAILURE';
export const SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTIONS_SUCCESS';
export const SUBSCRIPTIONS_FAILURE = 'SUBSCRIPTIONS_FAILURE';
export const SUBSCRIPTIONS_REQUEST = 'SUBSCRIPTIONS_REQUEST';
export const EDIT_SUBSCRIPTIONS_SUCCESS = 'EDIT_SUBSCRIPTIONS_SUCCESS';
export const EDIT_SUBSCRIPTIONS_FAILURE = 'EDIT_SUBSCRIPTIONS_FAILURE';
export const EDIT_SUBSCRIPTIONS_REQUEST = 'EDIT_SUBSCRIPTIONS_REQUEST';
export const ADD_SUBSCRIPTIONS_SUCCESS = 'ADD_SUBSCRIPTIONS_SUCCESS';
export const ADD_SUBSCRIPTIONS_FAILURE = 'ADD_SUBSCRIPTIONS_FAILURE';
export const ADD_SUBSCRIPTIONS_REQUEST = 'ADD_SUBSCRIPTIONS_REQUEST';
export const SUBSCRIPTIONS_ID_SUCCESS = 'SUBSCRIPTIONS_ID_SUCCESS';
export const SUBSCRIPTIONS_ID_FAILURE = 'SUBSCRIPTIONS_ID_FAILURE';
export const SUBSCRIPTIONS_ID_REQUEST = 'SUBSCRIPTIONS_ID_REQUEST';
export const DELETE_TXN_FROM_SUBSCRIPTION_SUCCESS = 'DELETE_TXN_FROM_SUBSCRIPTION_SUCCESS';
export const DELETE_TXN_FROM_SUBSCRIPTION_FAILURE = 'DELETE_TXN_FROM_SUBSCRIPTION_FAILURE';
export const DELETE_TXN_FROM_SUBSCRIPTION_REQUEST = 'DELETE_TXN_FROM_SUBSCRIPTION_REQUEST';
export const ADD_TXNS_TO_SUBSCRIPTION_SUCCESS = 'ADD_TXNS_TO_SUBSCRIPTION_SUCCESS';
export const ADD_TXNS_TO_SUBSCRIPTION_FAILURE = 'ADD_TXNS_TO_SUBSCRIPTION_FAILURE';
export const ADD_TXNS_TO_SUBSCRIPTION_REQUEST = 'ADD_TXNS_TO_SUBSCRIPTION_REQUEST';
export const ADD_TXN_TO_SUBSCRIPTION_BY_ID_SUCCESS = 'ADD_TXN_TO_SUBSCRIPTION_BY_ID_SUCCESS';
export const ADD_TXN_TO_SUBSCRIPTION_BY_ID_FAILURE = 'ADD_TXN_TO_SUBSCRIPTION_BY_ID_FAILURE';
export const ADD_TXN_TO_SUBSCRIPTION_BY_ID_REQUEST = 'ADD_TXN_TO_SUBSCRIPTION_BY_ID_REQUEST';
export const CANCEL_SUBSCRIPTIONS_REQUEST = 'CANCEL_SUBSCRIPTIONS_REQUEST';
export const CANCEL_SUBSCRIPTIONS_SUCCESS = 'CANCEL_SUBSCRIPTIONS_SUCCESS';
export const CANCEL_SUBSCRIPTIONS_FAILURE = 'CANCEL_SUBSCRIPTIONS_FAILURE';
export const SEARCH_SUBSCRIPTION_REQUEST = 'SEARCH_SUBSCRIPTION_REQUEST';
export const SEARCH_SUBSCRIPTION_SUCCESS = 'SEARCH_SUBSCRIPTION_SUCCESS';
export const SEARCH_SUBSCRIPTION_FAILURE = 'SEARCH_SUBSCRIPTION_FAILURE';
export const IS_REFRESHING_SUBSCRIPTIONS = 'IS_REFRESHING_SUBSCRIPTIONS';
export const RESET_SUBSCRIPTION_TRANSACTION = 'RESET_SUBSCRIPTION_TRANSACTION';
export const CLEAN_SUBSCRIPTIONS = 'CLEAN_SUBSCRIPTIONS';
