import React, { memo, useCallback, useEffect, useMemo, useState, } from 'react';
import { View } from 'react-native';
import Animated, { useAnimatedScrollHandler, } from 'react-native-reanimated';
import { rem, SCREEN_BOTTOM_PADDING } from 'design-system/values';
import Indicator from 'design-system/Indicator';
import { AppView } from 'design-system/AppView';
import FlatListItemCard from 'design-system/FlatListItemCard';
import { UpgradeWebModal } from 'design-system/UpgradeWebModal';
import { SettingsItemSwitch, SettingsItemNavigate, } from 'design-system/SettingsItem';
import { isPremiumTier } from 'features/premium/entities';
import { isUnlocked } from 'features/premium/isUnlocked';
import { useNotificationsPermissions } from 'hooks/useNotificationsPermissions';
import { benefits } from 'features/premium/benefits';
import { checkIsBenefitIdAvailableOnFree } from 'features/premium/checkIsBenefitIdAvailableOnFree';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMarginBottom } from 'hooks/useMarginBottom';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import Text from 'design-system/Text';
import { NotificationsPermissionState } from 'hooks/useNotificationPermissionTypes';
import { selectUserTier } from 'features/premium/selectors/tier';
import { useAppSelector } from 'store/hooks';
import { selectFeatureFlags } from 'reducers/selectors';
import { EnableNotificationsView } from './EnableNotificationsView';
import { useNotificationsPreferences } from '../hooks/useNotificationsPreferences';
import { isWeb } from '../../../../constants';
import { filterPreferences } from '../utils';
const strings = {
    buttonTitle: 'Save',
    promotions: 'Promotions',
    budgeting: 'Budgeting',
    payments: 'Payments',
    investing: 'Investing',
};
const checkPreferenceItemUnlocked = (item, userTier, guessedHomeCountry, featureFlags) => {
    const { benefitId } = item;
    const minTier = benefitId ? benefits[benefitId].minTier : undefined;
    if (!isPremiumTier(minTier)) {
        return {
            isUnlocked: true,
        };
    }
    return {
        isUnlocked: isUnlocked(userTier, minTier) ||
            !benefitId ||
            checkIsBenefitIdAvailableOnFree(benefitId, guessedHomeCountry, featureFlags),
        minTier,
    };
};
const preferenceItems = {
    'alerts.product_update.push': {
        title: 'Product updates',
        description: 'Stay up to date on Emma products, services and offers',
    },
    'alerts.marketing.push': {
        title: 'Marketing',
        description: 'Receive exclusive, time-sensitive, offers just for you!',
    },
    'alerts.daily_balance.push': {
        title: 'Daily balances',
        description: 'Receive your selected balances in a daily notification',
    },
    'alerts.budgets.push': {
        title: 'Budgets',
        description: "Get updates about your budgets' progress",
    },
    'alerts.payment_received.push': {
        title: 'Payment received',
        description: "We'll send you a reminder when you receive a payment!",
    },
    'alerts.subscription_payment.push': {
        title: 'Recurring payments',
        description: 'Know when these payments hit your bank account',
    },
    'alerts.volatility.push': {
        title: 'Volatility alerts',
        description: 'Notify me whenever the price of shares in my portfolio or watchlist move more than 5%',
    },
};
const notificationKeys = Object.keys(preferenceItems);
const notificationSections = [
    {
        id: 'promotions',
        title: strings.promotions,
        preferenceKeys: ['alerts.product_update.push', 'alerts.marketing.push'],
    },
    {
        id: 'budgeting',
        title: strings.budgeting,
        preferenceKeys: ['alerts.daily_balance.push', 'alerts.budgets.push'],
    },
    {
        id: 'payments',
        title: strings.payments,
        preferenceKeys: [
            'alerts.payment_received.push',
            'alerts.subscription_payment.push',
        ],
    },
    {
        id: 'investing',
        title: strings.investing,
        preferenceKeys: ['alerts.volatility.push'],
    },
];
const PushNotificationSettings = ({ error, isFetching, scrollYPush, ListHeaderComponent, }) => {
    const { pushNormalizedNotificationPreferences: notificationPreferences, updatePreferences, } = useNotificationsPreferences();
    const styles = useStyles(styleSet);
    const colors = useColors();
    const userTier = useAppSelector(selectUserTier);
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const navigation = useMainStackNavigation();
    const [preferencesState, setPreferencesState] = useState(() => filterPreferences(notificationPreferences, notificationKeys));
    // Keep the local state in sync
    useEffect(() => {
        setPreferencesState(filterPreferences(notificationPreferences, notificationKeys));
    }, [notificationPreferences]);
    const featureFlags = useAppSelector(selectFeatureFlags);
    const onPreferenceChange = useCallback((value, preferenceKey) => {
        const { isUnlocked, minTier } = checkPreferenceItemUnlocked(preferenceItems[preferenceKey], userTier, guessedHomeCountry, featureFlags);
        if (isUnlocked) {
            setPreferencesState((prev) => {
                const newState = {
                    ...prev,
                    [preferenceKey]: value,
                };
                updatePreferences(newState);
                return newState;
            });
        }
        else if (isWeb) {
            UpgradeWebModal.show(minTier);
        }
        else {
            navigation.navigate('EmmaProModal', { tier: minTier });
        }
    }, [featureFlags, guessedHomeCountry, updatePreferences, userTier]);
    const getPreferenceValue = useCallback((preferenceKey) => {
        if (checkPreferenceItemUnlocked(preferenceItems[preferenceKey], userTier, guessedHomeCountry, featureFlags).isUnlocked) {
            return preferencesState[preferenceKey];
        }
        return false;
    }, [featureFlags, guessedHomeCountry, preferencesState, userTier]);
    const data = useMemo(() => notificationKeys.reduce((prev, preferenceKey) => ({
        ...prev,
        [preferenceKey]: {
            preferenceKey,
            title: preferenceItems[preferenceKey].title,
            description: preferenceItems[preferenceKey].description,
            value: getPreferenceValue(preferenceKey),
            onValueChange: onPreferenceChange,
        },
    }), {}), [getPreferenceValue, onPreferenceChange]);
    const { permissionState, activatePush } = useNotificationsPermissions('settings');
    const scrollHandlerPush = useAnimatedScrollHandler((handle) => {
        // eslint-disable-next-line no-param-reassign
        scrollYPush.value = handle.contentOffset.y;
    });
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', SCREEN_BOTTOM_PADDING, paddingHorizontalStyle);
    const renderItem = useCallback((item, index, itemsLength) => {
        const { preferenceKey, title, description, value, onValueChange } = item;
        return (<FlatListItemCard key={preferenceKey} index={index} style={styles.eachItem} itemsLength={itemsLength}>
          {preferenceKey === 'alerts.daily_balance.push' ? (<SettingsItemNavigate key={preferenceKey} title={title} description={description} onPress={() => navigation.navigate('DailyBalanceNotifications')} titleFirst/>) : (<SettingsItemSwitch key={preferenceKey} itemKey={preferenceKey} title={title} description={description} value={value} onValueChange={onValueChange}/>)}
        </FlatListItemCard>);
    }, [styles.eachItem]);
    if (permissionState === NotificationsPermissionState.Disabled) {
        return <EnableNotificationsView onPressEnable={activatePush}/>;
    }
    if ((isFetching && Object.keys(notificationPreferences).length === 0) ||
        error ||
        Object.keys(preferencesState).length === 0) {
        return (<AppView>
        <View style={styles.container}>
          <Indicator dark/>
        </View>
      </AppView>);
    }
    return (<Animated.ScrollView onScroll={scrollHandlerPush} scrollEventThrottle={16} showsVerticalScrollIndicator={false} contentContainerStyle={[styles.scrollContainer, paddingBottom]}>
      {ListHeaderComponent}
      {notificationSections.map((section) => (<View key={section.id}>
          {section.title && (<Text Text-16 color={colors.text.secondary} style={styles.sectionTitle}>
              {section.title}
            </Text>)}
          {section.preferenceKeys.map((key, index) => renderItem(data[key], index, section.preferenceKeys.length))}
        </View>))}
    </Animated.ScrollView>);
};
export default memo(PushNotificationSettings);
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
    },
    scrollContainer: {
        paddingTop: rem(8),
        gap: rem(24),
    },
    sectionTitle: {
        paddingBottom: rem(16),
        marginHorizontal: rem(16),
    },
    eachItem: {
        backgroundColor: colors.cards.onDark,
    },
}));
