import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import FastImage from 'utils/packages/rnFastImage';
import { CardView } from 'design-system/CardView';
import { rem } from 'design-system/values';
import { Separator } from 'design-system/Separator';
import { SettingsItem } from 'design-system/SettingsItem';
import Amount from 'design-system/Amount';
import Button from 'design-system/Button';
import { IconEdit, IconHome } from 'design-system/icons';
import useColors from 'hooks/useColors';
import Text from 'design-system/Text';
import { formatAddress, formatDateForItem } from 'utils';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { RENT_REPORTING_AGENCIES } from 'features/rent-reporting/utils';
import { RentReportingUnavailableModal } from './RentReportingUnavailableModal.web';
import RentReportingStatus from '../../../rent-reporting/components/RentReportingStatus';
import { isWeb } from '../../../../constants';
const RentReportingActiveCard = ({ data }) => {
    const navigation = useMainStackNavigation();
    const onPressManage = useCallback(() => {
        if (isWeb) {
            RentReportingUnavailableModal.show();
        }
        else {
            navigation.navigate('RentReporting');
        }
    }, []);
    const isTracking = data.isTracking === true || data.isTracking === undefined;
    const isUnlockedForTier = data.reportingAgencies != null && data.reportingAgencies.length > 0;
    const colors = useColors();
    return (<CardView noMarginTop noMarginBottom>
      <SettingsItem title="Rent reporting" description={data.tenancyAddress != null
            ? formatAddress(data.tenancyAddress)
            : undefined} descriptionStyle={styles.address} iconSize={44} icon={<IconHome gradientKey="peach"/>}/>
      <Separator />
      {isUnlockedForTier && (<>
          <View style={styles.row}>
            <Text TextThin-14 Secondary flex>
              Next payment
            </Text>
            <Text Text-14>{formatDateForItem(data.nextPaymentDate)}</Text>
          </View>
          <View style={styles.row}>
            <Text TextThin-14 Secondary flex>
              Amount
            </Text>
            <Amount Numbers-14 value={{ amount: data.amount, currency: data.currency }}/>
          </View>
        </>)}

      <View style={styles.row}>
        <Text TextThin-14 Secondary flex>
          Status
        </Text>
        <RentReportingStatus active={isTracking && isUnlockedForTier}/>
      </View>
      {data.reportingAgencies != null && data.reportingAgencies.length > 0 && (<View style={styles.row}>
          <Text TextThin-14 Secondary flex>
            Reporting to
          </Text>
          <View style={styles.statusRow}>
            {data.reportingAgencies.map((item) => RENT_REPORTING_AGENCIES[item] ? (<FastImage key={item} source={RENT_REPORTING_AGENCIES[item].image} accessibilityLabel={RENT_REPORTING_AGENCIES[item].label} style={styles.providerLogo}/>) : null)}
          </View>
        </View>)}
      <Button square brandReversed leftIcon={<IconEdit width={16} height={16} color={colors.text.textOnBrand}/>} leftIconContainerStyle={styles.buttonIcon} style={styles.button} onPress={onPressManage} title={isTracking && isUnlockedForTier
            ? 'Manage reporting'
            : 'Action required'}/>
    </CardView>);
};
const styles = StyleSheet.create({
    row: {
        padding: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
    },
    statusRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    button: {
        margin: rem(16),
        marginTop: rem(8),
    },
    buttonIcon: {
        paddingRight: rem(8),
    },
    providerLogo: {
        width: rem(32),
        height: rem(32),
        marginLeft: rem(8),
    },
    address: {
        marginTop: rem(4),
    },
});
export default RentReportingActiveCard;
