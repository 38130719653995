import { useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updatePreferences, updateBalancePreference, getPreferences, } from 'actions/user';
const normalize = (preferences) => {
    const pushPreferences = [];
    const emailPreferences = [];
    Object.entries(preferences).forEach((entry) => {
        const [key] = entry;
        if (key.includes('alerts') && key.includes('.push')) {
            pushPreferences.push(entry);
        }
        else if (key.includes('alerts') && key.includes('.email')) {
            emailPreferences.push(entry);
        }
    });
    return {
        pushPreferences: pushPreferences.reduce((value, item) => ({
            ...value,
            [item[0]]: item[1].value,
        }), {}),
        emailPreferences: emailPreferences.reduce((value, item) => ({
            ...value,
            [item[0]]: item[1].value,
        }), {}),
    };
};
const getBalancePreferenceSetting = (preferences) => {
    const dailyBalanceKey = 'alerts.daily_balance.push';
    const preference = preferences[dailyBalanceKey];
    if (!preference) {
        return { value: false };
    }
    if (typeof preference === 'boolean') {
        return { value: preference };
    }
    return {
        value: preference.value,
        info: preference.info,
    };
};
export const useNotificationsPreferences = (shouldFetchPreferences) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (shouldFetchPreferences) {
            dispatch(getPreferences());
        }
    }, [shouldFetchPreferences]);
    const notificationPreferences = useAppSelector((store) => store.user.preferences);
    const { pushPreferences, emailPreferences } = useMemo(() => normalize(notificationPreferences), [notificationPreferences]);
    const balancePreference = useMemo(() => getBalancePreferenceSetting(notificationPreferences), [notificationPreferences]);
    const debouncedUpdatePreferences = useMemo(() => debounce((preferences) => dispatch(updatePreferences(preferences)), 3000), []);
    const debouncedUpdateBalancePreference = useMemo(() => debounce((enabledAccountIds, disabledAccountIds) => dispatch(updateBalancePreference(enabledAccountIds, disabledAccountIds)), 3000), []);
    return {
        updatePreferences: debouncedUpdatePreferences,
        updateBalancePreference: debouncedUpdateBalancePreference,
        pushNormalizedNotificationPreferences: pushPreferences,
        emailNormalizedNotificationPreferences: emailPreferences,
        balancePreference,
    };
};
