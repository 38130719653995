import React, { memo } from 'react';
import { format, isThisYear, parseISO } from 'date-fns';
import { View, StyleSheet } from 'react-native';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import { rem } from 'design-system/values';
import Separator from 'design-system/Separator';
import { IconArrowBack } from 'design-system/icons/IconArrowBack';
import useColors from 'hooks/useColors';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
const SubscriptionPrediction = ({ date, amount, currency, style, isPredicted, onPress, }) => {
    const colors = useColors();
    if (!date) {
        return null;
    }
    const parsedDate = parseISO(date);
    let bookingDate = format(parsedDate, 'd MMMM yyyy');
    if (isThisYear(parsedDate)) {
        bookingDate = format(parsedDate, 'MMMM d');
    }
    return (<TouchableHighlight hoverOnDark underlayColor={colors.background.underlay} activeOpacity={1} disabled={!onPress} onPress={onPress}>
      <View>
        <View style={[styles.sectionHeader, style]}>
          <View style={styles.textContainer}>
            <Text Text-16>{bookingDate}</Text>
            <View style={styles.descriptionContainer}>
              <Text TextThin-14 Gray style={styles.description}>
                {isPredicted ? `Next predicted payment due ` : 'Last payment'}
              </Text>
              {isPredicted && (<IconArrowBack width={21} height={21} gradientKey="darkPink"/>)}
            </View>
          </View>
          <View style={styles.amountContainer}>
            <Amount Numbers-16 style={undefined} value={{ value: amount, currency }}/>
          </View>
        </View>
        <Separator style={styles.separator}/>
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    sectionHeader: {
        padding: rem(16),
        flexDirection: 'row',
        justifyContent: 'center',
    },
    textContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    descriptionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    description: {
        marginEnd: rem(4),
    },
    separator: {
        marginHorizontal: rem(16),
    },
    amountContainer: {
        justifyContent: 'center',
    },
});
export default memo(SubscriptionPrediction);
