import { shuffle } from 'lodash';
import React, { useMemo, useState } from 'react';
import { rem } from 'design-system/values';
import { Spacer } from 'design-system/Spacer';
import SectionHeader from 'design-system/SectionHeader';
import useIsTablet from 'hooks/useIsTablet';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import SuggestionsList from './SuggestionsList';
import SuggestionsListWrap from './SuggestionsListWrap';
import { isWeb } from '../../constants';
const Suggestions = ({ title, data, name, suggestedData, handleSuggestionPress, oneLine, }) => {
    const [suggestions] = useState(() => {
        const shuffledData = shuffle(data);
        if (oneLine) {
            return [shuffledData, []];
        }
        const midIndex = Math.floor(shuffledData.length / 2);
        return [
            shuffledData.slice(0, midIndex),
            shuffledData.slice(midIndex, shuffledData.length),
        ];
    });
    const didUserAlterName = name !== suggestedData?.name;
    const { paddingHorizontal, paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const suggestionContentContainerStyle = useMemo(() => ({
        paddingHorizontal: (paddingHorizontal || 0) + rem(12),
    }), [paddingHorizontal]);
    const isTablet = useIsTablet();
    return (<>
      <Spacer h={8}/>
      <SectionHeader horizontalMargins verticalMargins style={paddingHorizontalStyle}>
        {title}
      </SectionHeader>
      <Spacer h={4}/>
      {isTablet && !oneLine && suggestions[1].length < 10 ? (<SuggestionsListWrap suggestions={suggestions} handleSuggestionPress={handleSuggestionPress} didUserAlterName={didUserAlterName} suggestedName={suggestedData?.name} contentContainerStyle={suggestionContentContainerStyle}/>) : (<SuggestionsList suggestions={suggestions} handleSuggestionPress={handleSuggestionPress} didUserAlterName={didUserAlterName} suggestedName={suggestedData?.name} contentContainerStyle={suggestionContentContainerStyle}/>)}
    </>);
};
export default React.memo(Suggestions);
