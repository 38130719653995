// use for web to redirect to join space page
import { useEffect } from 'react';
import { useIsSignedInWeb } from 'features/web/hooks/useIsSignedInWeb';
import { navigate } from 'utils/navigationv6';
export const useJoinSpaceRedirect = () => {
    const { isSignedIn } = useIsSignedInWeb();
    useEffect(() => {
        if (isSignedIn &&
            typeof window !== 'undefined' &&
            'sessionStorage' in window) {
            // Check for the join space information stored by PrivateRoute
            const joinSpaceToken = window.sessionStorage.getItem('joinSpaceToken');
            const joinSpaceName = window.sessionStorage.getItem('joinSpaceName');
            if (joinSpaceToken) {
                try {
                    // Navigate to join space page using the navigation function
                    navigate('JoinSpace', {
                        accessCode: joinSpaceToken,
                        spaceName: joinSpaceName || undefined,
                    });
                    // Clear the stored info after redirecting
                    window.sessionStorage.removeItem('joinSpaceToken');
                    window.sessionStorage.removeItem('joinSpaceName');
                }
                catch (e) {
                    // noop
                }
            }
        }
    }, [isSignedIn]);
};
