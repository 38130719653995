import { CALL_API } from '../middleware/api';
import * as types from './types';
export const setWalkthroughStatus = (data) => ({
    type: types.SET_WALKTHROUGH_STATUS,
    payload: data,
});
export const setWalkthroughStep = (payload) => ({
    type: types.SET_WALKTHROUGH_STEP,
    payload,
});
export const getFeed = (onboarding) => ({
    [CALL_API]: {
        endpoint: '/feed',
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.GET_FEED_REQUEST,
            types.GET_FEED_SUCCESS,
            types.GET_FEED_FAILURE,
        ],
        addSpaceIdHeader: true,
        extra: onboarding,
    },
});
export const getAdsAction = () => ({
    [CALL_API]: {
        method: 'GET',
        endpoint: '/pop-ups',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        types: [
            types.GET_ADS_REQUEST,
            types.GET_ADS_SUCCESS,
            types.GET_ADS_FAILURE,
        ],
    },
});
export const markAdAsSeenAction = (adId, body) => ({
    [CALL_API]: {
        method: 'PUT',
        endpoint: `/pop-ups/${adId}`,
        body: JSON.stringify(body),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        types: [
            types.MARK_AD_AS_SEEN_REQUEST,
            types.MARK_AD_AS_SEEN_SUCCESS,
            types.MARK_AD_AS_SEEN_FAILURE,
        ],
        muteAlert: true,
    },
});
