import React, { useCallback, useEffect, useMemo } from 'react';
import Section from 'design-system/Section';
import { CardView } from 'design-system/CardView';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { makeSelectTransactionById } from 'features/transactions/selectors';
import { getLinkedTransaction } from 'actions';
import { formatPlural } from 'utils';
import { styles } from './styles';
import RowTransaction from '../RowTransaction';
const TransactionLinkedTransactionSection = ({ transaction, }) => {
    const memoSelectTransactionByIdForLinked = useMemo(makeSelectTransactionById, []);
    const linkedTransactionId = 'relatedTransactionId' in transaction
        ? transaction.relatedTransactionId
        : undefined;
    const relatedSmartRuleTransactionId = 'smartRuleInfo' in transaction &&
        transaction.smartRuleInfo &&
        'relatedTransactionId' in transaction.smartRuleInfo
        ? transaction.smartRuleInfo.relatedTransactionId
        : undefined;
    const linkedTransaction = useAppSelector((store) => memoSelectTransactionByIdForLinked(store, linkedTransactionId));
    const relatedSmartRuleTransaction = useAppSelector((store) => memoSelectTransactionByIdForLinked(store, relatedSmartRuleTransactionId));
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (linkedTransactionId) {
            dispatch(getLinkedTransaction(linkedTransactionId));
        }
        if (relatedSmartRuleTransactionId) {
            dispatch(getLinkedTransaction(relatedSmartRuleTransactionId));
        }
    }, [linkedTransactionId, relatedSmartRuleTransactionId]);
    const navigation = useMainStackNavigation();
    const openLinked = useCallback((id) => {
        navigation.push('Item', {
            id,
        });
    }, []);
    if (!linkedTransaction && !relatedSmartRuleTransaction) {
        return null;
    }
    return (<Section title={formatPlural('Linked Transaction', 'Linked Transactions', linkedTransaction && relatedSmartRuleTransaction ? 2 : 1)} style={styles.section}>
      <CardView noMarginTop noMarginBottom webPadding>
        {linkedTransaction && (<RowTransaction backgroundDark onPress={() => openLinked(linkedTransaction.id)} item={linkedTransaction}/>)}
        {relatedSmartRuleTransaction && (<RowTransaction backgroundDark onPress={() => openLinked(relatedSmartRuleTransaction.id)} item={relatedSmartRuleTransaction}/>)}
      </CardView>
    </Section>);
};
export default TransactionLinkedTransactionSection;
