export const initialState = {
    investWalkthrough: undefined,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_INVEST_WALKTHROUGH_STATUS': {
            return {
                ...state,
                investWalkthrough: {
                    ...state.investWalkthrough,
                    step: 0,
                    status: action.payload.status,
                    isInOnboarding: action.payload.isInOnboarding,
                },
            };
        }
        case 'SET_INVEST_PORTFOLIO_INFO': {
            return {
                ...state,
                investWalkthrough: {
                    ...state.investWalkthrough,
                    info: action.payload,
                },
            };
        }
        case 'SET_INVEST_WALKTHROUGH_STEP': {
            return {
                ...state,
                investWalkthrough: {
                    ...state.investWalkthrough,
                    status: 'in-progress',
                    step: action.payload.step,
                },
            };
        }
        default:
            return state;
    }
};
export default reducer;
