import React, { memo, useCallback } from 'react';
import { View } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import IconSpace from 'design-system/icons/IconSpace';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import createStyleSheets from 'utils/createStyleSheets';
import { TAB_SCREEN_HEADER_HEIGHT_WEB } from 'navigators/styles';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { IconBell } from 'design-system/icons';
import { selectSelectedSpaceData } from 'features/spaces/reducers/selectors';
import { selectFeedHeader } from '../selectors';
const iconSize = rem(24);
const FeedHeader = () => {
    const nav = useMainStackNavigation();
    const { notificationsNum } = useAppSelector(selectFeedHeader);
    const styles = useStyles(styleSet);
    const colors = useColors();
    const selectedSpace = useAppSelector(selectSelectedSpaceData);
    const onPressSpaces = useCallback(() => {
        nav.navigate('MySpaces');
    }, [nav]);
    const onPressNotifications = useCallback(() => {
        nav.navigate('NotificationsList');
    }, [nav]);
    return (<View style={styles.safeAreaContainer}>
      <View style={styles.container}>
        {selectedSpace !== undefined && !selectedSpace.isDefault && (<Text Text-16 Primary numberOfLines={1} ellipsizeMode="tail" style={styles.spaceName}>
            {selectedSpace.name}
          </Text>)}
        <View style={styles.iconContainer}>
          <TouchableOpacity hoverOnDark onPress={onPressSpaces} accessibilityHint="Spaces" style={styles.navButton}>
            <IconSpace width={iconSize} height={iconSize} color={colors.elements.primary}/>
          </TouchableOpacity>
          <Spacer w={8}/>
          <TouchableOpacity hoverOnDark style={styles.navButton} onPress={onPressNotifications} accessibilityHint="Notifications">
            <IconBell height={rem(28)} width={iconSize} color={colors.elements.primary}/>
            {notificationsNum ? (<View style={styles.notificationIconTextContainer}>
                <Text Numbers-10 White style={styles.notificationIconText}>
                  {notificationsNum <= 99 ? notificationsNum : '+'}
                </Text>
              </View>) : null}
          </TouchableOpacity>
        </View>
      </View>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    safeAreaContainer: {
        flex: 1,
    },
    container: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingHorizontal: rem(16),
        height: TAB_SCREEN_HEADER_HEIGHT_WEB,
    },
    iconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    notificationIconTextContainer: {
        borderRadius: rem(14),
        position: 'absolute',
        backgroundColor: colors.gradients.red.colors[0],
        top: 0,
        width: rem(14),
        height: rem(14),
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    notificationIconText: {
        textAlign: 'center',
        alignSelf: 'center',
    },
    navButton: {
        width: 40,
        height: 40,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
    },
    spaceName: {
        marginRight: 16,
    },
}));
export default memo(FeedHeader);
