import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
const IconSpace = (props) => {
    const id = useId();
    return (<IconWithGradient id={id} defaultGradientKey="apricot" viewBox="0 0 24 24" {...props}>
      <Path d="m 11.9999,19.856 -8.51498,-4.73 -0.971,1.748 8.99998,5 c 0.1485,0.0825 0.3156,0.1258 0.4855,0.1258 0.1699,0 0.337,-0.0433 0.4855,-0.1258 l 9,-5 -0.971,-1.748 z m 0,-4 -8.51498,-4.73 -0.971,1.748 8.99998,5 c 0.1485,0.0825 0.3156,0.1258 0.4855,0.1258 0.1699,0 0.337,-0.0433 0.4855,-0.1258 l 9,-5 -0.971,-1.748 z m 9.4842,-8.731 -9.022,-5 c -0.1483,-0.08167 -0.3148,-0.1245 -0.484,-0.1245 -0.1693,0 -0.3358,0.04283 -0.484,0.1245 L 2.51607,7.085 C 2.3601,7.17136 2.23006,7.29785 2.13941,7.45137 2.04876,7.60488 2.0008,7.77984 2.00049,7.95812 2.00018,8.1364 2.04754,8.31152 2.13767,8.46534 2.22779,8.61917 2.3574,8.7461 2.51307,8.833 l 9.02203,5.04 c 0.1485,0.0835 0.316,0.1274 0.4863,0.1275 0.1704,2e-4 0.338,-0.0434 0.4867,-0.1265 l 8.978,-5 C 21.642,8.78727 21.7719,8.66038 21.8623,8.5065 21.9527,8.35263 22.0002,8.17737 22,7.99891 21.9998,7.82046 21.9519,7.64531 21.8612,7.49164 21.7704,7.33797 21.6402,7.21138 21.4841,7.125 Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
export default IconSpace;
