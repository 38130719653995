import React from 'react';
import { View } from 'react-native';
import { rem } from 'design-system/values';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
export const AvatarWrapper = ({ children, color, size, style, borderWidth = 0, }) => {
    const styles = useStyles(styleSet);
    const borderStyle = [
        styles.border,
        {
            width: rem(size + 2 * borderWidth),
            height: rem(size + 2 * borderWidth),
            borderRadius: (size + 2 * borderWidth) / 2,
            borderWidth,
        },
        style,
    ];
    const containerStyle = [
        styles.container,
        {
            width: rem(size),
            height: rem(size),
            borderRadius: size / 2,
            backgroundColor: color,
        },
    ];
    return (<View style={borderStyle}>
      <View style={containerStyle}>{children}</View>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    border: {
        borderColor: colors.borders.logoStroke,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
