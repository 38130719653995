import React from 'react';
import { formatStringList } from 'utils';
import { IconBoldCalendar } from 'design-system/icons';
import { RentReportingFrequency } from '../types';
export const RENTAL_FREQUENCIES = {
    [RentReportingFrequency.WEEKLY]: {
        label: 'Weekly',
        gradientKey: 'ultimate',
    },
    [RentReportingFrequency.FORTNIGHTLY]: {
        label: 'Fortnightly',
        gradientKey: 'darkPink',
    },
    [RentReportingFrequency.MONTHLY]: {
        label: 'Monthly',
        gradientKey: 'apricot',
    },
    [RentReportingFrequency.QUARTERLY]: {
        label: 'Quarterly',
        gradientKey: 'orange',
    },
    [RentReportingFrequency.BIANNUALLY]: {
        label: '6 months',
        gradientKey: 'darkPink',
    },
    [RentReportingFrequency.ANNUALLY]: {
        label: 'Yearly',
        gradientKey: 'lightPink',
    },
    // These types won't be selectable in the frontend yet, adding for backwards compat in case we ever add
    [RentReportingFrequency.PERIODIC]: {
        label: 'Periodic',
        gradientKey: 'darkPurple',
    },
};
export const FREQUENCY_OPTIONS = [
    RentReportingFrequency.WEEKLY,
    RentReportingFrequency.FORTNIGHTLY,
    RentReportingFrequency.MONTHLY,
    RentReportingFrequency.QUARTERLY,
    RentReportingFrequency.BIANNUALLY,
    RentReportingFrequency.ANNUALLY,
].map((item) => ({
    label: RENTAL_FREQUENCIES[item].label,
    icon: <IconBoldCalendar gradientKey={RENTAL_FREQUENCIES[item].gradientKey}/>,
    value: item,
}));
export const RENT_REPORTING_REQUIRED_HISTORY = {
    [RentReportingFrequency.WEEKLY]: 'at least 8 payments in the last 6 months',
    [RentReportingFrequency.FORTNIGHTLY]: 'at least 8 payments in the last 6 months',
    [RentReportingFrequency.MONTHLY]: 'at least 6 payments in the last year',
    [RentReportingFrequency.QUARTERLY]: 'at least 3 payments in the last year',
    [RentReportingFrequency.BIANNUALLY]: 'at least 2 payments in the last year',
    [RentReportingFrequency.ANNUALLY]: 'at least 1 payment in the last year',
    [RentReportingFrequency.PERIODIC]: '',
};
export const RENT_REPORTING_AGENCIES = {
    EQUIFAX: {
        label: 'Equifax',
        id: 'EQUIFAX',
        image: require('../img/equifax.png'),
    },
    EXPERIAN: {
        label: 'Experian',
        id: 'EXPERIAN',
        image: require('../img/experian.png'),
    },
    TRANSUNION: {
        label: 'TransUnion',
        id: 'TRANSUNION',
        image: require('../img/transunion.png'),
    },
};
export const formatRentReportingAgencies = (array) => {
    if (!array)
        return '';
    const formattedAgencies = array.map((agency) => RENT_REPORTING_AGENCIES[agency]?.label ||
        agency.charAt(0).toUpperCase() + agency.slice(1).toLowerCase());
    return formatStringList(formattedAgencies);
};
