import React, { useCallback } from 'react';
import { useAppSelector } from 'store/hooks';
import { Spacer } from 'design-system';
import Banner from 'design-system/Banner';
import { IconBoldArrow } from 'design-system/icons';
import Section from 'design-system/Section';
import { selectFeatureFlag } from 'reducers/selectors';
import links from 'utils/links';
export const ImproveCreditScoreCard = ({ url, }) => {
    const handleCreateAccount = useCallback(() => {
        links.openLink(url);
    }, [url]);
    return (<>
      <Spacer h={8}/>
      <Section title="Grow your credit score">
        <Banner buttonTitle="Create account" onPress={handleCreateAccount} title="Start with Credito today" icon={<IconBoldArrow alt colorKey="brand" direction="top"/>} description="Create a Credit Builder Account and your own personal credit coach with Credito."/>
      </Section>
    </>);
};
const ImproveCreditScoreCardWrapper = () => {
    const creditoFlag = useAppSelector((store) => selectFeatureFlag(store, 'show_create_credit_builder_card'));
    if (!creditoFlag.value || !creditoFlag.extra?.url) {
        return null;
    }
    return <ImproveCreditScoreCard url={creditoFlag.extra?.url}/>;
};
export default ImproveCreditScoreCardWrapper;
