import React from 'react';
import { Spacer } from 'design-system';
import InfoCard from 'design-system/InfoCard';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import Text from 'design-system/Text';
const EditCategoryVerticalListHeader = () => (<>
    <ScreenInfoHeader title="Manage custom categories"/>
    <InfoCard>
      <Text TextThin-14 Secondary>
        Default categories can’t be edited, but you can create unlimited custom
        categories.
      </Text>
    </InfoCard>
    <Spacer h={16}/>
  </>);
export default EditCategoryVerticalListHeader;
