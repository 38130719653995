import React from 'react';
import { View } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import Modal from 'design-system/ModalWeb';
import { rem } from 'design-system/values';
import { QRCode } from 'design-system/QRCode';
import { CardView } from 'design-system/CardView';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const logo = require('../../../pay/img/bear.png');
const RentReportingUnavailableModalContent = () => {
    const styles = useStyles(styleSet);
    return (<View>
      <Text TextThin-14 Secondary>
        {`This functionality is not available on the web version. Scan the QR code to manage rent reporting using the Emma mobile app.`}
      </Text>
      <Spacer h={24}/>
      <CardView noMargin style={styles.qrContainer}>
        <QRCode value={`emma://rent-reporting`} ecl="quartile" logo={logo}/>
      </CardView>
    </View>);
};
export const RentReportingUnavailableModal = {
    show: () => {
        const title = 'Manage rent reporting';
        Modal.show(<RentReportingUnavailableModalContent />, {
            title,
        });
    },
    hide: () => {
        Modal.hide();
    },
};
const styleSet = createStyleSheets((colors) => ({
    qrContainer: {
        paddingVertical: rem(24),
        alignItems: 'center',
        backgroundColor: colors.borders.divider,
    },
}));
