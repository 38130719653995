import React, { useCallback } from 'react';
import { View } from 'react-native';
import Spacer from 'design-system/Spacer';
import EmmaBetaLogo from 'design-system/EmmaBetaLogo';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { useNavigationWebState } from 'hooks/useNavigationWebState';
import { useIsSignedInWeb } from 'features/web/hooks/useIsSignedInWeb';
import AvatarFeed from 'features/feed/components/avatar-feed/AvatarFeed';
import { rem } from '../values';
import PayIcon from './PayIcon';
import SaveIcon from './SaveIcon';
import FeedIcon from './FeedIcon';
import BorrowIcon from './BorrowIcon';
import InvestIcon from './InvestIcon';
import NavBarItem from './NavBarItem';
const WebNavigationBar = React.memo(() => {
    const styles = useStyles(styleSet);
    const { sidebarWidth } = useAppFrameDimensions(true);
    const navigation = useMainStackNavigation();
    const { isSignedIn } = useIsSignedInWeb();
    const { currentScreen } = useNavigationWebState();
    const handleFeed = useCallback(() => {
        navigation.navigate('Feed');
    }, []);
    const handleBorrow = useCallback(() => {
        navigation.navigate('Borrow');
    }, []);
    if (sidebarWidth && isSignedIn) {
        return (<View style={[styles.container, { width: sidebarWidth }]}>
        <View style={styles.flex}>
          <AvatarFeed />
          <Spacer.H16 />
          <NavBarItem title="Feed" Icon={FeedIcon} onPress={handleFeed} isActive={currentScreen === 'Feed'}/>
          <Spacer.H16 />
          <NavBarItem title="Save" Icon={SaveIcon} showComingSoon isActive={currentScreen === 'Save'}/>
          <Spacer.H16 />
          <NavBarItem title="Pay" Icon={PayIcon} showComingSoon isActive={currentScreen === 'Pay'}/>
          <Spacer.H16 />
          <NavBarItem title="Invest" showComingSoon Icon={InvestIcon} isActive={currentScreen === 'Invest'}/>
          <Spacer.H16 />
          <NavBarItem title="Borrow" Icon={BorrowIcon} onPress={handleBorrow} isActive={currentScreen === 'Borrow'}/>
        </View>
        <View style={styles.logoContainer}>
          <EmmaBetaLogo />
        </View>
      </View>);
    }
    return null;
});
export default WebNavigationBar;
const styleSet = createStyleSheets((colors) => ({
    container: {
        paddingTop: rem(32),
        borderRightWidth: 1,
        paddingBottom: rem(40),
        paddingHorizontal: rem(16),
        backgroundColor: colors.background.dark,
        borderRightColor: colors.borders.divider,
    },
    flex: {
        flex: 1,
    },
    logoContainer: {
        paddingLeft: rem(16),
    },
}));
