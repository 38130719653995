import React, { useCallback, useLayoutEffect } from 'react';
import { Button } from 'design-system/Button';
import { ButtonGroup } from 'design-system/ButtonGroup';
import { RequestActionView } from 'design-system/RequestActionView';
import NavigationCloseButton from 'design-system/NavigationCloseButton';
import IconGift from 'design-system/icons/IconGift';
import useColors from 'hooks/useColors';
import { setSignInSpace } from 'login/actions/signinNavigation';
import { useAppDispatch } from 'store/hooks';
import { setSelectedSpace } from '../actions';
import { useJoinSpace } from '../hooks';
import { isWeb } from '../../../constants';
const ModalJoinSpace = ({ route, navigation, }) => {
    const colors = useColors();
    const spaceName = route.params?.spaceName;
    const accessCode = route.params?.accessCode;
    const onDismiss = useCallback(() => {
        dispatch(setSignInSpace(undefined));
        navigation.goBack();
    }, []);
    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: isWeb
                ? undefined
                : () => (<NavigationCloseButton onPress={onDismiss} colorKey="primary"/>),
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary, colors.text.white, onDismiss]);
    const message = spaceName
        ? `You have been invited to join\nthe ${spaceName} space.`
        : 'You have been invited to join\na shared space.';
    const dispatch = useAppDispatch();
    const onJoined = useCallback((space) => {
        dispatch(setSignInSpace(undefined));
        if (route.params.selectOnJoin) {
            navigation.goBack();
            dispatch(setSelectedSpace(space.id));
        }
        else {
            navigation.replace('EditSpace', { id: space.id, space });
        }
    }, [route.params.selectOnJoin]);
    const [joining, join] = useJoinSpace(accessCode ?? '', onJoined, onDismiss);
    return (<RequestActionView primary title="You got an invite!" subTitle={message} icon={<IconGift width={54} height={54} colorKey="brand"/>}>
      <ButtonGroup type="floating">
        <Button brand onPress={join} title="Join Space" isFetching={joining}/>
      </ButtonGroup>
    </RequestActionView>);
};
export default ModalJoinSpace;
