import React, { useRef, useState, useEffect, useCallback, useLayoutEffect, } from 'react';
import { View, Platform, Keyboard } from 'react-native';
import Animated, { useSharedValue, useAnimatedScrollHandler, useAnimatedStyle, interpolateColor, } from 'react-native-reanimated';
import { debounce } from 'lodash';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { FlashList } from '@shopify/flash-list';
import SearchBar, { SEARCH_BAR_TOTAL_HEIGHT, } from 'design-system/SearchBar';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import Spacer from 'design-system/Spacer';
import { AppView } from 'design-system/AppView';
import Indicator from 'design-system/Indicator';
import { CardView } from 'design-system/CardView';
import Button, { HEIGHT } from 'design-system/Button';
import ListEmptyView from 'design-system/ListEmptyView';
import EmptyComponent from 'design-system/EmptyComponent';
import { NavigationHeader } from 'design-system/NavigationHeader';
import KeyboardAvoidingView from 'design-system/KeyboardAvoidingView';
import { useFocusEffect } from 'hooks';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import { useKeyboard } from 'hooks/useKeyBoard';
import { getSearchTransactions } from 'actions';
import createStyleSheets from 'utils/createStyleSheets';
import { useMarginBottom } from 'hooks/useMarginBottom';
import Modal from 'utils/packages/Modal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useEstimatedListSize from 'hooks/useEstimatedListSize';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import { searchSubscriptions, addTxnsToSubscription, cleanSubscriptionSearch, } from '../actions';
import { isWeb } from '../../../constants';
import RowSubscriptionTransaction from '../components/transactions/RowSubscriptionTransaction';
const keyExtractor = (item) => item.id.toString();
const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);
const estimatedListItemSize = rem(98);
const extraOffset = SEARCH_BAR_TOTAL_HEIGHT;
const strings = {
    trackingPayment: 'Tracking payment',
    trackingPaymentMessage: 'You are already tracking this recurring payment',
    editPayment: 'Edit this recurring payment',
};
const SubscriptionSearchModalScreen = ({ navigation, route }) => {
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Search',
            headerShown: false,
            headerLeft: EmptyComponent,
        });
    }, []);
    const colors = useColors();
    const styles = useStyles(styleSet);
    const { subscriptionId, empty } = route.params;
    const subscriptionsSearch = useAppSelector((store) => store.subscriptions.subscriptionsSearch);
    const isSearchingSubscription = useAppSelector((store) => store.subscriptions.isSearchingSubscription);
    const refSearchBar = useRef(null);
    const [text, setText] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const showButton = !!(subscriptionId && selectedIds.length);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', showButton ? HEIGHT + rem(16) : rem(16), paddingHorizontalStyle);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(cleanSubscriptionSearch());
    }, []);
    const filterResponse = useCallback((text) => {
        setText(text);
        if (text.length <= 1) {
            dispatch(cleanSubscriptionSearch());
            return;
        }
        dispatch(searchSubscriptions(text));
    }, []);
    const onChangeText = debounce(filterResponse, 150);
    const openSubscription = useRequiredBenefitCallbackWithSpaces(useCallback((item) => {
        if (item.subscriptionId === null) {
            return;
        }
        navigation.navigate('Subscription', {
            id: item.subscriptionId,
        });
    }, []), 'recurringPayments');
    const onSelected = useCallback((data) => {
        if (data.subscriptionId && 'isRecurring' in data && data.isRecurring) {
            Keyboard.dismiss();
            Modal.showConfirmation(strings.trackingPayment, strings.trackingPaymentMessage, strings.editPayment, undefined, () => openSubscription(data), undefined, undefined, true // brandReversed Confirm Button
            );
            return;
        }
        if (subscriptionId) {
            if (selectedIds.includes(data?.id)) {
                setSelectedIds((oldState) => oldState.filter((id) => id !== data?.id));
            }
            else {
                setSelectedIds((oldState) => [...oldState, data?.id]);
            }
        }
        else {
            navigation.navigate('AdjustSubscription', {
                data,
            });
        }
    }, [openSubscription, selectedIds, subscriptionId]);
    const onCancelPressed = useCallback(() => {
        navigation.goBack();
    }, []);
    const onPressSetRecurring = useCallback(async () => {
        if (subscriptionId) {
            if (selectedIds.length) {
                navigation.goBack();
                await dispatch(addTxnsToSubscription(subscriptionId, selectedIds));
                dispatch(getSearchTransactions());
            }
        }
    }, [selectedIds, subscriptionId]);
    const renderRow = useCallback(({ item, index, }) => (<View style={[
            styles.rowTxnContainer,
            index === 0 && styles.itemContainerFirst,
            index === subscriptionsSearch.length - 1 &&
                styles.itemContainerLast,
        ]}>
          <RowSubscriptionTransaction data={item} onPress={onSelected} canSelect selected={selectedIds.includes(item.id)} isTracking={item.isRecurring} isTransaction/>
        </View>), [
        onSelected,
        selectedIds,
        styles.itemContainerFirst,
        styles.itemContainerLast,
        styles.rowTxnContainer,
        subscriptionsSearch.length,
    ]);
    const renderEmpty = useCallback(() => {
        if (!text.length) {
            return (<CardView>
            <ListEmptyView alt onCard happy title={empty.title} subTitle={empty.subtitle}/>
          </CardView>);
        }
        if (!isSearchingSubscription) {
            return (<CardView>
            <ListEmptyView alt onCard title="No results found"/>
          </CardView>);
        }
        return (<View style={styles.indicatorContainer}>
          <Indicator dark/>
        </View>);
    }, [
        isSearchingSubscription,
        styles.indicatorContainer,
        text.length,
        empty,
    ]);
    const scrollY = useSharedValue(0);
    const onScroll = useAnimatedScrollHandler((event) => {
        scrollY.value = event.contentOffset.y;
    });
    useFocusEffect(useCallback(() => {
        const timeout = setTimeout(() => {
            refSearchBar.current?.focus();
        });
        return () => {
            clearTimeout(timeout);
        };
    }, []));
    const { top } = useSafeAreaInsets();
    const keyboardHeight = useKeyboard();
    const iosMargin = keyboardHeight > 0 ? keyboardHeight - rem(16) : 0;
    const textInputStyle = useAnimatedStyle(() => ({
        backgroundColor: interpolateColor(scrollY.value, [0, 40], [
            colors.cards.onDark,
            colors.background.dark,
        ]),
    }), [colors.background.dark, colors.cards.onDark, scrollY.value]);
    const estimatedListSize = useEstimatedListSize(subscriptionsSearch, extraOffset, true);
    const baseProps = {
        onScroll,
        keyExtractor,
        renderItem: renderRow,
        scrollEventThrottle: 16,
        data: subscriptionsSearch,
        ListEmptyComponent: renderEmpty,
        contentContainerStyle: paddingBottom,
        keyboardDismissMode: 'none',
        keyboardShouldPersistTaps: 'always',
        scrollIndicatorInsets: SCROLL_INDICATOR_INSETS,
    };
    return (<AppView withFrame={false}>
        <NavigationHeader noPaddingTop skipSafeArea scrollY={scrollY} withFrame isInTab={isWeb} regularTitleHeight>
          <Spacer h={isWeb ? rem(16) : Math.max(36, top)}/>
          <SearchBar ref={refSearchBar} forSearchHeader alwaysShowCancel onCancel={onCancelPressed} onChangeSearchTerm={onChangeText} textInputBgStyles={textInputStyle}/>
        </NavigationHeader>
        <KeyboardAvoidingView style={styles.container} behavior={Platform.OS === 'android' ? undefined : 'padding'}>
          {isWeb ? (<Animated.FlatList {...baseProps}/>) : (<AnimatedFlashList {...baseProps} extraData={selectedIds} estimatedListSize={estimatedListSize} estimatedItemSize={estimatedListItemSize}/>)}
          {showButton && (<Button brand floating style={{
                marginBottom: Platform.OS === 'android' ? undefined : iosMargin,
            }} title="Continue" onPress={onPressSetRecurring}/>)}
        </KeyboardAvoidingView>
      </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
    },
    rowTxnContainer: {
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    itemContainerFirst: {
        marginTop: rem(16),
        borderTopLeftRadius: rem(16),
        borderTopRightRadius: rem(16),
        ...(Platform.OS !== 'android' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingTop: rem(8),
        }),
    },
    itemContainerLast: {
        borderBottomLeftRadius: rem(16),
        borderBottomRightRadius: rem(16),
        ...(Platform.OS !== 'android' && {
            overflow: 'hidden',
        }),
        ...(isWeb && {
            paddingBottom: rem(8),
        }),
    },
    indicatorContainer: {
        marginTop: rem(48),
    },
}));
export default SubscriptionSearchModalScreen;
